import React, {useState} from "react"
import fileDownload from "js-file-download"
import styled from "styled-components"

// App
import {Button} from "./Forms"
import {downloadBase64Order} from "../../../services/helpers/FrontApiHelper"

const Download = styled(Button)`
  font-size: 16px !important;
  line-height: 25px !important;
  padding: 4px 10px 4px 10px !important;
  margin-left: 5px !important;
  border-color: #66b821 !important;

  &.btn.outlined.primary {
    border: none;
  }
`

/*
const Img = styled.img`
    display:inline-block;
    margin: 0;
    border: 0;
    max-width: 30px;
    @media screen and (max-width: 2000px){
        max-width: 25px;
    }
`;

const Icon = styled.img`
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 40px;
  margin-right: 13px;
  @media screen and (max-width: 2000px){
    max-width: 30px;
  }
`;
*/
function DownloadOrderBase64(props) {

    const [loader, setLoader] = useState(false)

    const _downloadOrder = () => {
        const {CodeBP, id} = props
        setLoader(true)
        downloadBase64Order(id)
            .then(response => {
                fileDownload(response, `Commande-${CodeBP}.json`)
            })
            .finally(() => setLoader(false))
            .catch(e => console.log(e))
    }

    return (
        <div
            title={"Télécharger votre commande dans un fichier propriétaire / Permet de réinjecter ultérieurement les produits (disponibles) de la commande dans votre panier"}>
            <Download type="button" className="btn btn-warning border bg-transparent" loader={loader} contain="outlined"
                      onClick={() => _downloadOrder()}>
                <img src="/img/cart-shopping-solid.svg" alt="Shopping" width={16}/>
            </Download>
        </div>
    )
}

export default DownloadOrderBase64