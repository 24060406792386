// format number
import {
    ROLE_ADMIN,
    ROLE_CUSTOMER,
    ROLE_MAINTAINER_SEDIS,
    ROLE_REDACTOR_BO,
    ROLE_SALESMANAGER_BO,
    ROLE_USER_BO
} from "../../constants"
import _ from "lodash"

/**
 *
 * @param role {string}
 * @param minRole {string}
 * @return boolean
 */
export function isGranted(role, minRole = ROLE_USER_BO) {
    if (minRole === ROLE_ADMIN) {
        return _.includes([ROLE_ADMIN], role)
    }
    if (minRole === ROLE_MAINTAINER_SEDIS) { // Only Admin and Maintainer have this role
        return _.includes([ROLE_ADMIN, ROLE_MAINTAINER_SEDIS], role)
    }
    if (minRole === ROLE_REDACTOR_BO) {
        return _.includes([ROLE_ADMIN, ROLE_MAINTAINER_SEDIS, ROLE_REDACTOR_BO], role)
    }
    if (minRole === ROLE_SALESMANAGER_BO) {
        return _.includes([ROLE_ADMIN, ROLE_MAINTAINER_SEDIS, ROLE_SALESMANAGER_BO], role)
    }
    if (minRole === ROLE_USER_BO) { // Maintainer and redactor are granted with ROLE_USER by default
        return _.includes([ROLE_ADMIN, ROLE_MAINTAINER_SEDIS, ROLE_REDACTOR_BO, ROLE_USER_BO, ROLE_SALESMANAGER_BO], role)
    }
}

/**
 *
 * @param role {string|null}
 * @return {{ROLE_CUSTOMER: {role: string, description: string}, ROLE_USER_BO: {role: string, description: string}, ROLE_ADMIN: {role: string, description: string}, ROLE_MAINTAINER_SEDIS: {role: string, description: string}, ROLE_REDACTOR_BO: {role: string, description: string}}|{role: string, description: string}}
 */
export function getRoleStrings(role = null) {
    const roleStrings = {
        ROLE_ADMIN: {role: "Administrateur", description: "Administrateur global du site"},
        ROLE_MAINTAINER_SEDIS: {role: "Gestionnaire BO", description: "Gestionnaire avec accès total au BO"},
        ROLE_SALESMANAGER_BO: {
            role: "Gestionnaire de magasins",
            description: "Profil permettant l'accès au module de consultation des ventes magasin"
        },
        ROLE_REDACTOR_BO: {
            role: "Rédacteur BO",
            description: "Profil rédacteur pour le BO (accès à la gestion whatsapp par exemple)"
        },
        ROLE_USER_BO: {role: "Utilisateur BO", description: "Simple utilisateur du backoffice"},
        ROLE_CUSTOMER: {role: "Client", description: "Client pouvant accéder au site et commander"}
    }

    if (role === null) {
        return roleStrings
    }

    switch (role) {
        case ROLE_ADMIN:
            return roleStrings[ROLE_ADMIN]
        case ROLE_MAINTAINER_SEDIS:
            return roleStrings[ROLE_MAINTAINER_SEDIS]
        case ROLE_REDACTOR_BO:
            return roleStrings[ROLE_REDACTOR_BO]
        case ROLE_SALESMANAGER_BO:
            return roleStrings[ROLE_SALESMANAGER_BO]
        case ROLE_USER_BO:
            return roleStrings[ROLE_USER_BO]
        case ROLE_CUSTOMER:
            return roleStrings[ROLE_CUSTOMER]
        default:
            return {role: "Rôle inconnu", description: "Rôle non reconnu"}
    }
}
