import React from "react"
import {DOCUMENT_TITLE_BACKOFFICE} from "../../constants"
import Header from "../Mixed/Header/Header"
import Menu from "../Mixed/Menu/Menu"
import Footer from "../Mixed/Footer/Footer"
import {BrowserRouter as Router} from "react-router-dom"

import "./errorPage.css"

export default function ErrorPage(props) {
    const {code, title, message} = props

    document.title = `${title} - ${DOCUMENT_TITLE_BACKOFFICE}`

    return <Router>
        <div className="wrapper">
            <Header/>
            <Menu nameMenu="errorPage"/>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h5 className="m-0 text-dark">{title}</h5>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">Erreur</li>
                                        <li className="breadcrumb-item active">{title}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <section className="error-content">
                                        <div className="error-code">{code}</div>
                                        <div className="error-title">{title}</div>
                                        <div className="error-message">{message}</div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Footer/>
            </div>
        </div>
    </Router>
}