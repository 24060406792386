import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, prefixFront } from '../../../../constants'
import Loading from '../../Loading'

const newHistory = createBrowserHistory()

export default class CompareGeslotShopAPI extends Component {

    state = {
      isAuthenticated: false,
      nbProductGeslot: 0,
      nbProductShopAPI: 0,
    };

    nameMenu = 'CompareGeslotShopAPI'

    componentDidMount() {
        this.renderListProduct();
    }

    renderListProduct = async () => {
        const data = await connectApi('products-geslot-shopapi?isSalable=true', 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                this.setState({
                    nbProductGeslot: data.nbProductGeslot,
                    nbProductShopAPI: data.nbProductShopAPI
                });
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    render() {
        
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Statistique des produits GESLOT et SHOP API</h5>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">Administration</li>
                            <li className="breadcrumb-item active">Statistique produit GESLOT et SHOP API</li>
                            </ol>
                        </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid" id="statProduct">
                        {/* Small boxes (Stat box) */}
                        <div className="row" id="content-dashboard-1">
                            <div className="col-lg-3 col-6">
                            {/* small box */}
                            {
                                <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>
                                    { 
                                        this.state.nbProductGeslot
                                        ?
                                        this.state.nbProductGeslot
                                        :
                                        0
                                    }
                                    </h3>
                                    <p>produits dans l'API GESLOT</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
        {/*                           <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
        */}                        </div>
                            }
                            
                            </div>
                            
                            <div className="col-lg-3 col-6">
                            {
                                <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>
                                    { 
                                        this.state.nbProductShopAPI ?
                                        this.state.nbProductShopAPI :
                                        0
                                    }
                                    </h3>
                                    <p>produits vendables dans SHOP API</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
        {/*                           <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
        */}                        </div>
                            }
                            
                            </div>
                        </div>
                        <Loading/>
                    </div>
                </section>
                
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}