export default function MinimumCommandableReducer(state = {}, action) {
    let nextState
    switch (action.type) {
        case "SET_MIN_COMMANDABLE" :
            nextState = {
                ...state,
                minimumCommandable: action.value
            }
            return nextState

        case "SET_ID_MIN_COMMANDABLE" :
            nextState = {
                ...state,
                idminimumCommandable: action.value
            }
            return nextState

        case "SET_LIST_MIN_COMMANDABLE":
            nextState = {
                ...state,
                listminimumCommandable: action.value
            }
            return nextState

        default :
            return nextState || state
    }
}
