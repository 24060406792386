import React, {Component} from "react"
import Header from "../Header/Header"
import Menu from "../Menu/Menu"
import Footer from "../Footer/Footer"
import {createBrowserHistory} from "history"
import {
    HTTP_UNAUTHORIZED, prefixBack, prefixFront, ROLE_MAINTAINER_SEDIS, ROLE_REDACTOR_BO, ROLE_USER_BO
} from "../../../constants"
import connectApi from "../../../services/helpers/connectApi"
import Loading from "../../Backoffice/Loading"
import getDateFullTime from "../../../services/helpers/getDateFullTime"
import formateDatetime from "../../../services/helpers/formateDatetime"
import {isGranted} from "../../../services/helpers/RolesHelper"
import "../../Backoffice/adminColors.css"
import "./dashboard.css"
import moment from "moment"
import DataTable from "react-data-table-component";

const newHistory = createBrowserHistory()

export default class Dashboard extends Component {
    currentState = JSON.parse(localStorage.getItem("state"))
    user = this.currentState.user
    state = {
        isAuthenticated: this.currentState.isAuthenticated,
        token: this.currentState.token,
        user: {
            id: this.user.id,
            email: this.user.email,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            role: this.user.role,
            isCustomer: this.user.isCustomer,
            CodeClient: this.user.CodeClient
        },
        listConstantes: [],
        listOrders: {
            numberOrders: 0,
            totalSale: 0.0
        },
        stats: {
            products: "-",
            variants: "-",
            users: "-",
            orders: "-",
            lastShopBot: "chargement ...",
            orderProducts: [],
            nbDay: 7
        },
        listExecutionSHOPBOT: [],
        loading: true
    }

    nameMenu = "dashboard"

    constructor(props) {
        super(props);
        this._onOrderProductsDateChange = this._onOrderProductsDateChange.bind(this)
    }

    componentDidMount() {
        this.getStats()
        this.renderListOrder()
        this.renderListConstantes()
        this.renderListExecutionSHOPBOT()
    }

    getStats() {
        (async function () {
            const data = await connectApi("getBackOfficeStats", "GET", null)
            this.setState({
                stats: {
                    products: data.products,
                    variants: data.variants,
                    users: data.users,
                    orders: data.orders,
                    lastShopBot: moment(data.lastShopBot).format("DD-MM-YYYY HH:mm:ss"),
                    orderProducts: data.orderProducts
                },
                loading: false
            })
        }).bind(this)()
    }

    _onOrderProductsDateChange(event) {
        this.setState({
            loading: true
        }, async () => {
            const data = await connectApi("getBackOfficeOrderProductStats?nbDay=" + event.target.value, "GET", null)
            this.setState({
                stats: {
                    ...this.state.stats,
                    orderProducts: data,
                },
                loading: false
            })
        })
    }

    renderListExecutionSHOPBOT = async () => {
        if (!isGranted(this.user.role, ROLE_MAINTAINER_SEDIS)) {
            return
        }
        const data = await connectApi("shop-bot", "GET", null)
        if (typeof (data) === "object") {
            if (data?.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + "/login")
            } else {
                if (data.hasOwnProperty("@type") && data["@type"] === "hydra:Error") {
                    this.setState({
                        listExecutionSHOPBOT: []
                    })
                    return
                }

                this.setState({
                    listExecutionSHOPBOT: data
                })
                const script = document.createElement("script")
                script.src = "../../../../../custom-code/js/datatable-custom.js"
                script.async = true
                document.body.appendChild(script)
            }
        }
    }

    renderListConstantes = async () => {
        const data = await connectApi("constantes", "GET", null)
        if (typeof (data) === "object") {
            if (data.hasOwnProperty("code") && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + "/login")
            } else {
                this.setState({
                    listConstantes: data
                })
                localStorage.setItem("listConstantes", JSON.stringify(this.state.listConstantes))
            }
        } else {
            console.log(data)
        }
    }

    renderListOrder = async () => {
        if (!isGranted(this.user.role, ROLE_MAINTAINER_SEDIS)) {
            return
        }

        let currentDay = new Date()

        let dayMinus6 = (new Date()).setDate(currentDay.getDate() - 6)
        dayMinus6 = new Date(dayMinus6)

        let startDate = getDateFullTime(dayMinus6, "-", "en")
        let endDate = getDateFullTime(currentDay, "-", "en")
        const data = await connectApi("recap-orders?startDate=" + startDate + "&endDate=" + endDate, "GET", null)
        if (typeof (data) === "object") {
            if (data.hasOwnProperty("code") && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + "/login")
            } else {
                // this will re render the view with new data
                this.setState({
                    listOrders: data
                })
                localStorage.setItem("listOrders", JSON.stringify(this.state.listOrders))

                const script = document.createElement("script")
                script.src = "../../../../../custom-code/js/graphes.js"
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    _formatRows(data) {
        return data.map(elm => {
            return {
                ...elm,
                PathPhoto: <div style={{width: "92px"}}><img src={elm.PathPhoto}
                                                             style={{maxWidth: "92px", maxHeight: "64px"}}
                                                             alt="product"/></div>,
                Notification: elm.Notification > 0 ? <span>Oui</span> : '',
                Quantite: +elm.Quantite
            }
        })
    }

    render() {
        const listExecutionSHOPBOT = this.state.listExecutionSHOPBOT?.map((shopbot, i) => (<tr key={`shopbot${i}`}>
            <td className="date">
                {formateDatetime(shopbot.createdAt, "d/m/Y", true)}
            </td>
            <td className="Motif">
                {shopbot.Motif === "start" ? "Début du SHOP BOT" : "Fin du SHOP BOT"}
            </td>
        </tr>))

        const columns = [
            {name: "", selector: row => row.PathPhoto, sortable: false, width: "128px"},
            {name: "Produit", selector: row => row.CodeArticle, sortable: true},
            {name: "Ventes", selector: row => row.Quantite, sortable: true},
            {name: "Notification", selector: row => row.Notification, sortable: true},
        ]

        const hasMaintainerRole = isGranted(this.state.user.role, ROLE_MAINTAINER_SEDIS)
        const hasRedactorRole = isGranted(this.state.user.role, ROLE_REDACTOR_BO)
        const hasUserRole = isGranted(this.state.user.role, ROLE_USER_BO)

        return <div className="">
            <Header/>
            <Menu nameMenu={this.nameMenu}/>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 text-dark">Tableau de bord</h1>
                                </div>

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item active">Tableau de bord</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">

                            <div className="row align-items-baseline justify-content-between"
                                 id="content-dashboard-1">
                                {hasMaintainerRole && <>
                                    <div className="col-lg-3 col-3">
                                        <div className="small-box bg-info">
                                            <div className="inner">
                                                <h3>
                                                    {this.state.listOrders.numberOrders}
                                                </h3>
                                                <p>Nouvelles commandes ces 7 derniers jours</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-bag"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-3">
                                        <div className="small-box bg-warning">
                                            <div className="inner">
                                                <h3>
                                                    {parseFloat(this.state.listOrders.totalSale).toLocaleString("fr") + " €"}
                                                </h3>
                                                <p>de Chiffre d'affaires ces 7 derniers jours</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-pie-graph"/>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                            <div className="row align-items-md-stretch justify-content-between"
                                 id="content-dashboard-1">
                                {hasUserRole && <>
                                    <div className="card dashboard-col-6">
                                        <div className="card-header border-0">
                                            <div className="d-flex justify-content-between">
                                                <h3 className="card-title">Liens rapides</h3>
                                            </div>
                                        </div>
                                        <div className="quick-links">
                                            {hasRedactorRole && <div className="quick-links-items">
                                                <i className="fab fa-whatsapp"/>
                                                <a href={prefixBack + "/notifications"}
                                                   title="Créer un nouveau message Whatsapp">
                                                    <span>Messages WhatsApp</span>
                                                </a>
                                            </div>}
                                            {hasRedactorRole && <div className="quick-links-items">
                                                <i className="fas fa-gauge-high"/>
                                                <a href={prefixBack + "/notifications-sedis"}
                                                   title="Consulter les messages Whatsapp envoyés via SEDISDESTOCK">
                                                    <span>Notifications SEDISDESTOCK</span>
                                                </a>
                                            </div>}
                                            {hasMaintainerRole && <>
                                                <div className="quick-links-items">
                                                    <i className="fas fa-comment"/>
                                                    <a href={prefixBack + "/selection-du-moment"}
                                                       title="Modifier le texte de séléction du moment">
                                                        <span>Sélection du moment</span>
                                                    </a>
                                                </div>
                                                <div className="quick-links-items">
                                                    <i className="fas fa-arrows-up-to-line"/>
                                                    <a href={prefixBack + "/maximumcommandable"}
                                                       title="Modifier le maximum commandable">
                                                        <span>Maximum commandable</span>
                                                    </a>
                                                </div>
                                                <div className="quick-links-items">
                                                    <i className="fas fa-arrows-down-to-line"/>
                                                    <a href={prefixBack + "/montantmincommandable"}
                                                       title="Modifier le minimum commandable">
                                                        <span>Minimum commandable</span>
                                                    </a>
                                                </div>
                                                <div className="quick-links-items">
                                                    <i className="fas fa-users-gear"/>
                                                    <a href={prefixBack + "/users-sedis"}
                                                       title="Gérer les utilisateurs BO">
                                                        <span>Gérer les utilisateurs BO</span>
                                                    </a>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                    <div className="card dashboard-col-6">
                                        <div className="card-header border-0">
                                            <div className="d-flex justify-content-between">
                                                <h3 className="card-title">Statistiques</h3>
                                            </div>
                                        </div>
                                        <div className="bo-stats">
                                            <div className="bo-stats-top">
                                                <div>
                                                    <i className="fas fa-chart-column"/>
                                                    <a href={hasMaintainerRole ? prefixBack + "/products-geslot-shopapi" : "#"}
                                                       title="Accéder aux statistiques produits">
                                                        <span> <b>{this.state.stats.products}</b> Produits connus</span>
                                                    </a>
                                                </div>
                                                <div>
                                                    <i className="fas fa-chart-column"/>
                                                    <a href={hasMaintainerRole ? prefixBack + "/products-geslot-shopapi" : "#"}
                                                       title="Accéder aux statistiques produits">
                                                        <span> <b>{this.state.stats.variants}</b> Variantes disponibles</span>
                                                    </a>
                                                </div>
                                                <div>
                                                    <i className="fas fa-users"/>
                                                    <a href={hasMaintainerRole ? prefixBack + "/users-sedis" : "#"}
                                                       title="Voir les utilisateurs BO">
                                                        <span> <b>{this.state.stats.users}</b> Utilisateurs BO</span>
                                                    </a>
                                                </div>
                                                <div>
                                                    <i className="fas fa-cart-flatbed"/>
                                                    <a href="/"
                                                       title="Commandes des 7 derniers jours">
                                                        <span> <b>{this.state.stats.orders}</b> Commandes</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="bo-stats-bottom">
                                                <div>
                                                    <span>Dernier import Geslot</span>
                                                    <span
                                                        className="bo-stats-date">{this.state.stats.lastShopBot}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                            {hasMaintainerRole && <Loading/>}

                            {hasMaintainerRole && <div className="row" id="content-dashboard-2">
                                <div className="card col-lg-12">
                                    <div className="card-header border-0">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title">Nouvelles commandes ces 7 derniers
                                                jours</h3>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <p className="d-flex flex-column">
                                                <span className="text-bold text-lg">
                                                  {this.state.listOrders.totalSale ? parseFloat(this.state.listOrders.totalSale).toLocaleString("fr") + " € de chiffre d'affaires" : 0.00 + " € de chiffre d'affaires"}
                                                </span>
                                            </p>
                                        </div>

                                        <div className="position-relative mb-12">
                                            <canvas id="sales-chart" height="200"></canvas>
                                        </div>

                                        <div className="d-flex flex-row justify-content-end">
                                                <span className="mr-2">
                                                    <i className="fas fa-square text-primary"></i> Commandes passées les 7 derniers jours
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>}


                            {hasMaintainerRole && <div className="row" id="content-dashboard-2">
                                <div className="card col-lg-12">
                                    <div className="card-header border-0">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title">Les produits les plus vendus</h3>
                                            <select style={{padding: '0.25rem'}}
                                                    onChange={this._onOrderProductsDateChange}>
                                                <option value={7}>Les 7 derniers jours</option>
                                                <option value={30}>Les 30 derniers jours</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div style={{position: "relative"}}>
                                            {this.state.loading && (
                                                <div className="loading-overlay"><i
                                                    className="fas fa-gear fa-spin loading-icon"></i></div>)}
                                            <DataTable
                                                columns={columns}
                                                data={this._formatRows(this.state.stats.orderProducts)}
                                                defaultSortFieldId={3}
                                                defaultSortAsc={false}
                                                noDataComponent={<p className={"py-3"}>Il n'y a aucun donné à afficher</p>}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Lignes par page:'
                                                }}
                                            />
                                        </div>

                                        <div className="d-flex flex-row justify-content-end">
                                            <span className="mr-2">
                                                <i className="fas fa-square text-primary"></i> Les produits les plus vendus
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {hasMaintainerRole && <div className="row" id="content-dashboard-3">
                                <div className="card col-lg-12">
                                    <div className="card-header border-0">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title">Execution du SHOP BOT</h3>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table id="listExecutionSHOPBOT"
                                                   className="table table-bordered table-hover">
                                                <thead>
                                                <tr className="table-dark">
                                                    <th className="date">Date et heure</th>
                                                    <th className="Motif">Modif</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {listExecutionSHOPBOT}
                                                </tbody>
                                                <tfoot>
                                                <tr className="table-dark">
                                                    <th className="date">Date et heure</th>
                                                    <th className="Motif">Motif</th>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </section>
                </div>
            </div>
            <Footer/>
        </div>
    }
}
