import styled from "styled-components";
import {Box, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import {
    DataGrid, GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {frFR} from "@mui/x-data-grid/locales";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import _ from "lodash";
import useSalesFilterStore from "../../../reducers/SalesFilterStore";
import useMeStore from "../../../reducers/MeStore";
import {memo, useState} from "react";
import {FilterAlt, Restore} from "@mui/icons-material";
import dayjs from "dayjs";
import Grid2 from "@mui/material/Unstable_Grid2";
import {DEFAULT_DATAGRID_PAGE_SIZE, DEFAULT_DIFF_DAYS_SALES} from "../../../constants";

const StyledGridOverlay = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: "#aeb8c2",
    },
    '& .ant-empty-img-2': {
        fill: "#f5f5f7",
    },
    '& .ant-empty-img-3': {
        fill: "#dce0e6",
    },
    '& .ant-empty-img-4': {
        fill: "#fff",
    },
    '& .ant-empty-img-5': {
        fillOpacity: "0.8",
        fill: "#f5f5f5",
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"/>
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"/>
                    </g>
                </g>
            </svg>
            <Box sx={{mt: 1}}>Aucun élément</Box>
        </StyledGridOverlay>
    );
}

const CustomToolbar = memo(({onFilterChange, onResetFilter}) => {
    const {filters, setFilters} = useSalesFilterStore()
    const {info} = useMeStore()
    const [stores] = useState(info ? info.userStores.map(us => us.store) : [])

    return (
        <GridToolbarContainer>
            <Box sx={{width: "100%"}}>
                <Grid2 container spacing={1}>
                    <Grid2 xs={2.5}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Magasin</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filters.currentStore}
                                label="Magasin"
                                onChange={(e, newValue) => {
                                    const _store = _.find(stores, {code: e.target.value})
                                    if (_store) {
                                        setFilters({
                                            ...filters,
                                            currentStore: _store.code
                                        })

                                        onFilterChange(_store.code)
                                    }
                                }}
                            >
                                {stores.map(store => {
                                    return (<MenuItem key={store.code} value={store.code}>{store.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={"auto"}>
                        <DatePicker
                            label="Date de début"
                            sx={{maxWidth: 160}}
                            slotProps={{field: {size: 'small'}}}
                            value={filters.beginDate}
                            maxDate={filters.endDate}
                            onChange={(date) => {
                                setFilters({
                                    ...filters,
                                    beginDate: date
                                })
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={"auto"}>
                        <DatePicker
                            sx={{maxWidth: 160}}
                            label="Date de fin"
                            slotProps={{field: {size: 'small'}}}
                            value={filters.endDate}
                            minDate={filters.beginDate}
                            onChange={(date) => {
                                setFilters({
                                    ...filters,
                                    endDate: date
                                })
                            }}
                        />
                    </Grid2>

                    <Grid2 xs={"auto"}>
                        <Tooltip title="Appliquer">
                            <IconButton
                                color={"primary"}
                                disabled={!filters.currentStore}
                                onClick={() => {
                                    if (onFilterChange) {
                                        onFilterChange()
                                    }
                                }}
                            >
                                <FilterAlt/>
                            </IconButton>
                        </Tooltip>
                    </Grid2>

                    <Grid2 xs={"auto"}>
                        <DatePicker
                            label="Date de livraison"
                            slotProps={{
                                field: {size: 'small'},
                                actionBar: {actions: ['clear']},
                                textField: {
                                    InputLabelProps: {
                                        shrink: true
                                    }
                                }
                            }}
                            sx={{maxWidth: 160}}
                            value={filters.shippingDate}
                            minDate={filters.beginDate}
                            // maxDate={filters.endDate}
                            onChange={(date) => {
                                setFilters({
                                    ...filters,
                                    shippingDate: date
                                })
                            }}
                            localeText={{
                                clearButtonLabel: "Effacer"
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={2.5}>
                        <GridToolbarQuickFilter
                            variant="outlined"
                            size={"small"}
                            sx={{width: "100%"}}
                        />
                    </Grid2>
                    <Grid2 xs={"auto"}>
                        <Tooltip title="Réinitialiser tous les filtres">
                            <IconButton
                                color={"warning"}
                                onClick={() => {
                                    const begin = (new dayjs()).subtract(DEFAULT_DIFF_DAYS_SALES, "day")
                                    const end = new dayjs()

                                    setFilters({
                                        ...filters,
                                        beginDate: begin,
                                        endDate: end,
                                        shippingDate: null
                                    })

                                    onResetFilter()
                                    onFilterChange(filters.currentStore, begin.format("YYYYMMDD"), end.format("YYYYMMDD"))
                                }}
                            >
                                <Restore/>
                            </IconButton>
                        </Tooltip>
                    </Grid2>
                    <Grid2 sx={{ml: "auto"}}>
                        <GridToolbarColumnsButton
                            slotProps={{
                                button: {
                                    size: "medium",
                                    variant: "text",
                                    className: "toolbarBtn"
                                },
                            }}
                        />
                    </Grid2>
                </Grid2>
            </Box>
        </GridToolbarContainer>
    );
}, (prevProps, nextProps) => true)


export default function SedisDateGrid(
    {
        rows,
        columns,
        loading,
        rowCount,
        columnVisibilityModel,
        onFilterChange,
        onColumnVisibilityModelChange,
        apiRef,
        onPaginationModelChange,
        onFilterModelChange
    }
) {
    const [filterModel, setFilterModel] = useState({
        "items": [],
        "logicOperator": "and",
        "quickFilterValues": [],
        "quickFilterLogicOperator": "and"
    })

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: DEFAULT_DATAGRID_PAGE_SIZE
    })

    return <DataGrid
        apiRef={apiRef}
        sx={{background: "white"}}
        // disableColumnSelector
        disableDensitySelector
        ignoreDiacritics
        density={"compact"}
        columnHeaderHeight={42}
        disableRowSelectionOnClick={true}
        slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: memo(() => {
                return <CustomToolbar
                    onResetFilter={() => {
                        setFilterModel({
                            ...filterModel,
                            quickFilterValues: []
                        })
                    }}
                    onFilterChange={(code = null, begin = null, end = null) => {
                        setPaginationModel({
                            ...paginationModel,
                            page: 0
                        })

                        onFilterChange(code, begin, end)
                    }}
                />
            }, (prevProps, nextProps) => {
                return true
            })
        }}
        slotProps={{
            toolbar: {
                showQuickFilter: true,
                content: "Soft"
            },
        }}
        columns={columns}
        rows={rows}
        loading={loading}
        rowCount={rowCount}
        localeText={{
            ...frFR.components.MuiDataGrid.defaultProps.localeText,
            columnsManagementReset: "Réinitialiser",
            toolbarColumns: "",
            MuiTablePagination: {
                labelDisplayedRows: ({from, to, count}) =>
                    `${from} à ${to} sur ${count}`,
                labelRowsPerPage: "Lignes par page :"
            },
        }}
        initialState={{
            sorting: {
                sortModel: [{field: 'DateLivraison', sort: 'desc'}],
            },
            pagination: {paginationModel: {pageSize: DEFAULT_DATAGRID_PAGE_SIZE, page: 0}},
            columns: {
                columnVisibilityModel: columnVisibilityModel
            },
        }}

        paginationModel={paginationModel}
        pageSizeOptions={[DEFAULT_DATAGRID_PAGE_SIZE]}
        onColumnVisibilityModelChange={(model) => {
            if (onColumnVisibilityModelChange) {
                onColumnVisibilityModelChange(model)
            }
        }}

        // filterModel={filterModel}
        onFilterModelChange={(model) => {
            // setFilterModel(model)
            onFilterModelChange()
        }}
        onPaginationModelChange={(paginationModel) => {
            setPaginationModel(paginationModel)
            onPaginationModelChange(paginationModel)
        }}
    />
}
