import React from "react"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"
import {connect} from "react-redux"

// App
import {Div} from "../../Mixed/Composants"
import {UrlPrefixContext} from "../../../context"
import {DownloadOrderBase64, SectionTitle} from "../Composants"
import {Header, Footer, Content} from "../Composants/Template"
import {getCustomerOrders} from "../../../services/helpers/FrontApiHelper"
import {formatCurrency} from "../../../services/helpers/FrontHelper"
import formateDatetime from "../../../services/helpers/formateDatetime"
import {Link} from "react-router-dom"

const Wrap = styled.div`
  padding: 2em;
  margin-top: 2em;
  background: #fff;
  box-shadow: 0 0 8px #f3e6e6;
`

const Alert = styled.span`
  padding: .3rem 1rem;
  margin-bottom: 0;
`

const WrapLoader = styled.div`
  padding: 9px 0 0;
`

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .7rem 0;
`

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-style: italic;
`

/*
const voirDetail = styled.p`
    margin: 0;
    text-align: center;
    font-style: italic;
`;
*/
class MesCommandesPage extends React.Component {

    static contextType = UrlPrefixContext
    _isMounted = false

    state = {
        paged: 1,
        orders: [],
        loader: false,
        loading: false,
        hasOrders: false,
        tmp_listCmdID: []
    }

    _getOrders(reset = false) {
        const tmp_orders = []
        const {paged} = this.state
        const {customer} = this.props
        if (customer && customer.hasOwnProperty("user")) {
            getCustomerOrders(customer.user.CodeClient, paged)
                .then(response => {
                    if (!response || response.code === 0 || response.code === 200) {
                        this.setState({hasOrders: false, orders: []})
                        return false
                    }
                    response.forEach(order => {
                        if (this.state.tmp_listCmdID.indexOf(order.CodeBP) === -1) {
                            tmp_orders.push(order)
                            this.state.tmp_listCmdID.push(order.CodeBP)
                        }
                    })
                    if (!tmp_orders.length && !this.state.orders.length) {
                        this.setState({hasOrders: false, orders: []})
                        return false
                    }
                    if (tmp_orders.length) {
                        const data = reset ? tmp_orders : [...this.state.orders, ...tmp_orders]
                        this.setState({orders: data, hasOrders: true})
                    }
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                        loader: false
                    })
                })
                .catch(e => console.log(e))
        }
    }

    _getMoreOrders = () => {
        this.setState({loading: true, paged: this.state.paged + 1}, () => {
            this._getOrders()
        })
    }

    _renderLoader = () => {
        const {loading} = this.state
        return (
            <WrapLoader className={loading ? "d-block" : "d-none"}>
                <Loader>
                    <Text>Chargement de la liste en cours...</Text>
                </Loader>
            </WrapLoader>
        )
    }

    _renderNoOrders = () => {
        return (
            <center><p>Aucune commande n'a été enregistrée</p></center>
        )
    }

    _renderOrders = () => {
        const {orders} = this.state
        return (
            <React.Fragment>
                <SectionTitle tag="h1">Vos commandes</SectionTitle>
                <Wrap className="table-responsive">
                    <InfiniteScroll
                        hasMore={true}
                        dataLength={orders.length}
                        loader={this._renderLoader()}
                        next={this._getMoreOrders}
                    >
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th>Date Commande</th>
                                <th>Commande N°</th>
                                <th>N° BL</th>
                                <th>Articles</th>
                                <th>Montant HT</th>
                                <th width="20%">Etat</th>
                                <th width="20%">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                orders.map((order, index) => {
                                    let txt = "Validée"
                                    let cls = "alert alert-success"
                                    if (order.Statut === "Proposition à valider" && (order.CodeFacture === "" || order.CodeFacture === null)) {
                                        txt = "En cours de validation"
                                        cls = "alert alert-info"
                                    } else if ((order.Statut !== "Proposition à valider" && order.Statut !== "") && (order.CodeFacture === "" || order.CodeFacture === null)) {
                                        txt = order.Statut
                                        cls = "alert alert-warning"
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className="v-align-center">
                                                {formateDatetime(order.OrderDate, "d/m/Y")}
                                            </td>
                                            <td className="v-align-center">
                                                <strong>{order.CodeBP ? order.CodeBP : "-"}</strong></td>
                                            <td className="v-align-center">
                                                <strong>{order.CodeBL ? order.CodeBL : "-"}</strong></td>
                                            <td className="v-align-center">{order.orderLines.length} article(s)</td>
                                            <td className="v-align-center">{formatCurrency(order.montantTotalHT)}</td>
                                            <td className="v-align-center"><Alert className={cls}>{txt}</Alert></td>
                                            <td className="v-align-center d-flex align-items-center justify-content-start">
                                                <Link to={`${this.context.prefix}/detail-commande/${order.id}`}
                                                      className="btn btn-warning">Voir détail</Link>
                                                <DownloadOrderBase64 CodeBP={order.CodeBP} id={order.id}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </Wrap>
            </React.Fragment>
        )
    }

    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.setState({loader: true, tmp_listCmdID: []})
            this._getOrders()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {loader, hasOrders} = this.state

        return (
            <Div className="front__wrap-screen">
                <Header/>
                <Content loading={loader}>
                    {!loader && !hasOrders && this._renderNoOrders()}
                    {!loader && hasOrders && this._renderOrders()}
                </Content>
                <Footer/>
            </Div>
        )
    }

}

const mapStateToProps = state => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(MesCommandesPage)
