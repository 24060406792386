import React,{ useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// App
import { UrlPrefixContext } from '../../../context';

const Img = styled.img`
    display:inline-block;
    margin: 0;
    border: 0;
    max-width: 120px;
    @media screen and (max-width: 2000px){
        max-width: 103px;
    }
`;

function Branding(props){

    const context = useContext(UrlPrefixContext);

    return (
        <Link to={`${context.prefix}/selection-du-moment`} className="navbar-brand" {...props}>
            <Img src="/img/logo.png" alt="Sedis alimentaire" />
        </Link>
    )
}

export default Branding;
