import React from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';

//App
import {createBrowserHistory} from 'history';
import {Cart, OrderDetail} from '../Composants';
import {Div} from '../../Mixed/Composants';
import {UrlPrefixContext} from '../../../context';
import {Header, Content, Footer} from '../Composants/Template';
import {validateOrder} from '../../../services/helpers/FrontApiHelper';
import connectApi from "../../../services/helpers/connectApi";
import {HTTP_OK, HTTP_UNAUTHORIZED, prefixFront} from "../../../constants";

const newHistory = createBrowserHistory()

class PanierPage extends React.Component {

    _isCalled = false;
    state = {
        loading: false,
        orderDetail: null,
        minCommandable: 0
    }

    componentDidMount() {
        this.renderMinimumCommandable()
    }

    renderMinimumCommandable = async () => {
        const data = await connectApi('default-min-commandable', 'GET', null)
        if (typeof (data) === 'object') {
            if (data.hasOwnProperty('code')) {
                if (data.code === HTTP_OK) {
                    this.setState({
                        minCommandable: data['minCommandable']
                    });
                } else if (data.code === HTTP_UNAUTHORIZED) {
                    newHistory.push(prefixFront + '/login')
                } else if (data.code !== HTTP_OK && data.code !== HTTP_UNAUTHORIZED) {
                    toast(data.message, {
                        type: 'error',
                        autoDismiss: true
                    });
                }
            } else {
                console.log(data)
            }
        } else {
            console.log(data)
        }
    }

    _validateOrder = () => {
        const {order, customer} = this.props;
        const orderLine = this._getLocalOrderLine();
        if (this._isCalled) {
            return false;
        }
        this._isCalled = true;
        if (orderLine && order && customer) {
            let montantHT = 0;
            let montantTTC = 0;
            let OrderLines = [];

            order.forEach(item => {
                montantHT += parseFloat(item.orderLine.MontantHT);
                montantTTC += parseFloat(item.orderLine.MontantTTC);
                OrderLines.push(item);
            });

            const DelaiEchanceFacture = customer.DelaiEchanceFacture;
            // const encours_flexible  = customer.MontantEncoursAutoriseFlexible;
            const encours_autorise = customer.MontantEncoursAutoriseFixe;
            const encours_utilise = customer.MontantEncoursUtilise;
            const encours_restent = encours_autorise - encours_utilise;

            if (DelaiEchanceFacture > 0) {
                toast("Vous avez des factures non-réglées ayant dépassé votre délai de paiement de x jours, merci de régulariser la situation pour pouvoir commander de nouveau !", {
                    type: 'error',
                    autoDismiss: true
                });
                this._isCalled = false;
                return false;
            }

            if (encours_restent < 0) {
                toast("Vous ne disposez pas d'encours suffisant pour passer la commande", {
                    type: 'error',
                    autoDismiss: true
                });
                this._isCalled = false;
                return false;
            }

            // DEBUT EVOLUTION
            // Seul le 1er panier de la journée exige un montant total minimum.
            // Le panier suivant n'exige plus cela
            //
            // Le lendemain, on exige de nouveau à ce que le 1er panier du jour soit supérieur ou égal au montant minimum de commande.
            // Et ainsi de suite chaque jour
            let timestampDuJour = Date.now();
            let tempsReinitialisationControl = 12 * 60 * 60 * 1000; // 12h : temps de reinitialisation du contrôle
            if (localStorage.getItem('timestampInitial') === undefined || localStorage.getItem('timestampInitial') === null) {
                localStorage.setItem('timestampInitial', timestampDuJour);
            }
            if (localStorage.getItem('numPanier') === undefined || localStorage.getItem('numPanier') === null) {
                localStorage.setItem('numPanier', 1);
            }
            if ((timestampDuJour - parseInt(localStorage.getItem('timestampInitial'))) > tempsReinitialisationControl) {
                // On reinitialise le timestampInitial
                localStorage.setItem('timestampInitial', timestampDuJour);
                // On reinitialise le numPanier
                localStorage.setItem('numPanier', 1);
            }
            if ((timestampDuJour - parseInt(localStorage.getItem('timestampInitial'))) <= tempsReinitialisationControl) {
                // on lance le controle
                if (parseInt(localStorage.getItem('numPanier')) === 1) {
                    if (montantHT < this.state.minCommandable) {
                        toast("Désolé, vous n'avez pas atteint le montant minimum de commande de " + this.state.minCommandable + "€", {
                            type: 'error',
                            autoDismiss: true
                        });
                        this._isCalled = false;
                        return false;
                    }
                }
            }
            // FIN EVOLUTION
            this.setState({loading: true})
            validateOrder(orderLine.OrderId, orderLine.CodeBP, montantHT, montantTTC)
                .then(response => {
                    if (response.Statut === "Proposition à valider") {
                        localStorage.setItem('numPanier', parseInt(localStorage.getItem('numPanier')) + 1);
                        this.setState({orderDetail: OrderLines}, () => {
                            this._deleteStorage('frontOrderLine');
                            this._deleteStorage('frontOrderProducts');
                            localStorage.removeItem('errorsDuplicationPanier');
                            this.props.dispatch({type: 'RESET_ORDER', value: []})
                        })
                    } else {
                        toast("Il y avait une erreur lors de la validation de votre panier", {
                            type: 'error',
                            autoDismiss: true
                        });
                    }
                })
                .finally(() => this.setState({loading: false}, () => this._isCalled = false))
        }
    }

    _deleteStorage = (name) => {
        if (!name) {
            return false;
        }
        localStorage.removeItem(name);
    }

    _getLocalOrderLine = () => {
        const storage = localStorage.getItem('frontOrderLine');
        return storage != null ? JSON.parse(storage) : null;
    }

    render() {
        const {loading, orderDetail} = this.state;
        return (
            <Div className="front__wrap-screen">
                <Header/>
                <Content loading={loading}>
                    {!orderDetail && <Cart onValidate={() => this._validateOrder()}/>}
                    {orderDetail && <OrderDetail data={orderDetail}/>}
                </Content>
                <Footer/>
            </Div>
        )
    }

}

PanierPage.contextType = UrlPrefixContext;

const mapStateToProps = state => {
    return {
        order: state.order.order,
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(PanierPage);