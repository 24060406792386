import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';


const options = {
    settings: {
        disableKeyboardControls: true,
        disablePanzoom: true,
        disableWheelControls: true,
    },
    buttons: {
        showAutoplayButton: false,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false
      },
    thumbnails: {
        showThumbnails: false,
    }
};

function Lightbox(props){
    return (
        <SRLWrapper options={options}>
            {props.children}
        </SRLWrapper>
    )
}

export default Lightbox;