import React, {Component} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import formateDatetime from '../../../../services/helpers/formateDatetime'
import {createBrowserHistory} from 'history'
import {AUTHENTICATION_SUCCESS, HTTP_UNAUTHORIZED, MAX_RESULT_ERROR, prefixFront} from '../../../../constants'
import Loading from '../../Loading'
import {CheckboxField} from "../Notifications/FormComponents";
import DataTable from "react-data-table-component";

const newHistory = createBrowserHistory()

const SearchComponent = ({onChange}) => {
    return (
        <>
            <div className="d-flex justify-content-between w-100">
                <h5 className={"m-0 text-dark"}>Historique des connexions</h5>
                <div className={"d-flex align-items-end justify-content-center small"}>
                    <label form={"searchInput"} className={"mr-2"}>Rechercher:</label>
                    <input onChange={onChange} id={"searchInput"} type="text"
                           className={"form-control form-control-sm"}/>
                </div>
            </div>
        </>
    )
}

export default class HistoriqueConnexion extends Component {

    state = {
        isAuthenticated: false,
        historiqueConnexion: [],
        excludeShopBot: true,
        searchQuery: ""
    };

    nameMenu = 'historiqueConnexion'

    componentDidMount() {
        this.renderHistoriqueConnexion();
    }

    renderHistoriqueConnexion = async () => {
        const data = await connectApi('log-error?maxResultError=' + MAX_RESULT_ERROR
            + '&SousType=' + AUTHENTICATION_SUCCESS + "&excludeShopBot=" + this.state.excludeShopBot, 'GET', null)
        if (typeof (data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                // this will re-render the view with new data
                this.setState({
                    historiqueConnexion: data
                });
            }
        }
    }

    render() {
        const columns = [
            {
                name: 'Date et heure',
                selector: row => row.createdAt,
            },
            {
                name: 'Code Client',
                selector: row => row.CodeClient,
            },
            {
                name: 'Détail',
                selector: row => row.Motif,
            },
            {
                name: 'Adresse IP',
                selector: row => row.adressIp,
            },
        ];

        let regex = new RegExp(this.state.searchQuery, 'i');

        const data = this.state.historiqueConnexion?.map((traceConnexion) => {
            return {
                id: traceConnexion.id,
                createdAt: formateDatetime(traceConnexion.createdAt, 'd/m/Y', true),
                CodeClient: traceConnexion.detail['CodeClient'] ?? "-",
                Motif: traceConnexion.Motif,
                adressIp: traceConnexion.adressIp
            }
        }).filter(trace => {
            return regex.test(trace.Motif) || regex.test(trace.adressIp) || regex.test(trace.createdAt);
        });

        return (
            <Router>
                <div className="wrapper">
                    <Header/>
                    <Menu nameMenu={this.nameMenu}/>
                    <div>
                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <CheckboxField
                                                onChange={() => {
                                                    this.setState({
                                                        excludeShopBot: !this.state.excludeShopBot,
                                                        historiqueConnexion: []
                                                    }, () => {
                                                        this.renderHistoriqueConnexion()
                                                    })
                                                }}
                                                name={'default-group'}
                                                checked={this.state.excludeShopBot}
                                                children={'Exclure les connexions du SHOP-Bot'}/>
                                        </div>
                                        {/* /.col */}
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                                                <li className="breadcrumb-item">Administration</li>
                                                <li className="breadcrumb-item active">Historique des connexions</li>
                                            </ol>
                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </div>
                            <section className="content px-3">
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    subHeaderComponent={<SearchComponent onChange={(e) => {
                                        this.setState({
                                            searchQuery: e.target.value
                                        })
                                    }}/>}
                                    subHeader
                                    persistTableHead
                                    noDataComponent={<p className={"py-3"}>Il n'y a aucun donné à afficher</p>}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Lignes par page:'
                                    }}
                                />
                                <br/>
                                {this.state.historiqueConnexion.length === 0 &&
                                    <Loading/>
                                }
                            </section>

                        </div>
                        <Footer/>
                    </div>
                </div>
            </Router>
        )
    }
}