import React from 'react'
import PropTypes from 'prop-types';

// App
import { Div } from '../../Mixed/Composants';

function SectionTitle(props){
    return(
        <Div className="front__section-title" {...props}>
            <props.tag className="front__tag-title"><span>{props.children}</span></props.tag>
        </Div>
    )
}

SectionTitle.defaultProps = {
    tag : 'h2'
}

SectionTitle.propTypes = {
    tag : PropTypes.string.isRequired
}

export default SectionTitle;