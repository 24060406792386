import React, {Component} from "react"
import {BrowserRouter as Router} from "react-router-dom"
import connectApi from "../../../../services/helpers/connectApi"
import Header from "../../../Mixed/Header/Header"
import Menu from "../../../Mixed/Menu/Menu"
import Footer from "../../../Mixed/Footer/Footer"
import {createBrowserHistory} from "history"
import {HTTP_FOUND, HTTP_UNAUTHORIZED, prefixBack, prefixFront, ROLE_ADMIN, ROLE_CUSTOMER} from "../../../../constants"
import styled from "styled-components"
import {toast} from "react-toastify"
import {connect} from "react-redux"
import {envVars} from "../../../../env_var"
import {checkUserForm} from "./userHelper"
import {CheckboxField, SelectField} from "../Notifications/FormComponents"
import {getRoleStrings} from "../../../../services/helpers/RolesHelper"
import _ from "lodash"

const Alert = styled.span`
  color: red;
  display: block;
  font-style: italic;
  font-size: .8rem;
`
const newHistory = createBrowserHistory()

class CreateUserSEDIS extends Component {

    state = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        role: null,
        stores: [],
        selectedStores: []
    }

    roleOptions = [
        {
            label: "Roles",
            type: "role",
            icon: "fa-user",
            options: []
        }
    ]

    nameMenu = "UtilisateurSEDIS"

    displayFieldsError = () => {
        document.getElementById("errorEmail").style.display = "none"
        document.getElementById("errorFormatEmail").style.display = "none"
        document.getElementById("errorPassword").style.display = "none"
        document.getElementById("errorFormatPassword").style.display = "none"
        document.getElementById("errorfirstName").style.display = "none"
        document.getElementById("errorlastName").style.display = "none"
        document.getElementById("errorRole").style.display = "none"
    }

    enregistrer = async (event) => {
        event.preventDefault()
        this.displayFieldsError()
        let formatFieldsAreOK = checkUserForm(this.state)

        if (formatFieldsAreOK) {
            document.body.style.cursor = "wait"
            let uri = "create-user-sedis"
            let method = "POST"
            let dataObject = {
                email: this.state.email,
                password: this.state.password,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                role: this.state.role.value,
                stores: this.state.selectedStores
            }
            const data = await connectApi(uri, method, dataObject)
            if (typeof (data) === "object") {
                if (data.hasOwnProperty("code")) {
                    if (data.code === HTTP_UNAUTHORIZED) {
                        newHistory.push(prefixFront + "/login")
                    } else if (data.code === HTTP_FOUND) {
                        toast("L'utilisateur existe déjà. Veuillez saisir une autre adresse mail", {
                            type: "error",
                            autoDismiss: true
                        })
                    }
                    document.body.style.cursor = "default"
                } else {
                    this.setState({
                        email: data["email"],
                        firstName: data["firstName"],
                        lastName: data["lastName"],
                        role: data["role"]
                    })
                    toast("Utilisateur SEDIS enregistré avec succès", {
                        type: "success",
                        autoDismiss: true
                    })
                    document.body.style.cursor = "default"
                    window.location.href = envVars["scheme"] + window.location.host + `${prefixBack}/users-sedis`
                }
            }
        }
    }

    _handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleRoleSelectChange = (selectedOptions) => {
        this.setState({
            role: selectedOptions
        })
    }

    componentDidMount() {
        this.displayFieldsError()

        connectApi("stores", "GET").then(response => {
            this.setState({
                stores: response["hydra:member"]
            })
        })

        /* ---------------------- Load roles ---------------------- */
        const roles = getRoleStrings()
        _.map(roles, (role, roleString) => {
            //Ne pas autoriser l'ajout d'administrateurs ou de clients !
            if (roleString !== ROLE_ADMIN && roleString !== ROLE_CUSTOMER) {
                this.roleOptions[0].options.push({
                    value: roleString,
                    label: `${role.role}  -  ${role.description}`
                })
            }
        })
    }

    render() {
        return (
            <Router>
                <div className="wrapper">
                    <Header/>
                    <Menu nameMenu={this.nameMenu}/>
                    <div>
                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h5 className="m-0 text-dark">Création d'un utilisateur SEDIS</h5>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">Administration</li>
                                                <li className="breadcrumb-item active">Utilisateurs SEDIS</li>
                                                <li className="breadcrumb-item active">Création utilisateur SEDIS</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="form col-lg-3 col-3">
                                            Email *
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <input id="email" name="email" type="text" size={50}
                                                   onChange={(e) => this._handleTextChange(e)}
                                                   value={this.state.email ? this.state.email : ""}>
                                            </input>
                                            <br/>
                                            <Alert id="errorEmail">(*) champ obligatoire</Alert>
                                            <Alert id="errorFormatEmail">Format d'adresse email invalide</Alert>
                                            <br/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form col-lg-3 col-3">
                                            Mot de passe *
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <input id="password" name="password" type="password" size={50}
                                                   onChange={(e) => this._handleTextChange(e)}
                                                   value={this.state.password ? this.state.password : ""}>
                                            </input>
                                            <br/>
                                            <Alert id="errorPassword">(*) champ obligatoire</Alert>
                                            <Alert id="errorFormatPassword">Le mot de passe doit faire au moins 6
                                                caractères</Alert>
                                            <br/>
                                            <div style={{width: "50%"}}>
                                                <Alert>
                                                    <b>Le mot de passe de l'utilisateur ne lui sera pas communiqué
                                                        directement. Veuillez noter celui-ci et lui transmettre de
                                                        manière
                                                        sécurisée par Privnote par exemple (https://privnote.com)</b>
                                                </Alert>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form col-lg-3 col-3">
                                            Prénom *
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <input id="firstName" name="firstName" type="text" size={50}
                                                   onChange={(e) => this._handleTextChange(e)}
                                                   value={this.state.firstName ? this.state.firstName : ""}>
                                            </input>
                                            <br/>
                                            <Alert id="errorfirstName">(*) champ obligatoire</Alert>
                                            <br/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form col-lg-3 col-3">
                                            Nom *
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <input id="lastName" name="lastName" type="text" size={50}
                                                   onChange={(e) => this._handleTextChange(e)}
                                                   value={this.state.lastName ? this.state.lastName : ""}>
                                            </input>
                                            <br/>
                                            <Alert id="errorlastName">(*) champ obligatoire</Alert>
                                            <br/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form col-lg-3 col-3">
                                            Rôle *
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <div style={{width: "50%"}}>
                                                <SelectField
                                                    className="role-selector"
                                                    classNamePrefix="reactSelect-custom"
                                                    options={this.roleOptions}
                                                    value={this.state.role}
                                                    isMulti={false}
                                                    closeMenuOnSelect={true}
                                                    placeholder="Selectionnez un Rôle"
                                                    onChange={this.handleRoleSelectChange.bind(this)}
                                                    optionFocusedBackground={"#66B82126"}
                                                    optionActiveBackground={"#66B82133"}
                                                    context={this}
                                                />
                                            </div>
                                            <Alert id="errorRole">(*) champ obligatoire</Alert>
                                            <br/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form col-lg-3 col-3">
                                            Magasins SEDIS
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <div style={{width: "75%"}}>
                                                <div className={"row"} style={{position: "relative", top: -20}}>
                                                    {this.state.stores.map(store => {
                                                        return (<div key={store.id} className={"col-auto"}>
                                                            <CheckboxField
                                                                onChange={(e) => {
                                                                    let _selectedStore = this.state.selectedStores
                                                                    if (_selectedStore.includes(store.id)) {
                                                                        _selectedStore = _.pull(_selectedStore, store.id)
                                                                    } else {
                                                                        _selectedStore.push(store.id)
                                                                    }
                                                                    this.setState({
                                                                        selectedStores: _selectedStore
                                                                    })
                                                                }}
                                                                name={'default-group'}
                                                                checked={this.state.selectedStores.includes(store.id)}
                                                                children={store.name}/>
                                                        </div>)
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div>
                                    <button onClick={(event) => {
                                        this.enregistrer(event)
                                    }} className="btn btn-info btn-login">Enregistrer
                                    </button>
                                </div>
                            </section>

                        </div>
                        <Footer/>
                    </div>
                </div>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return {
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName
    }
}

export default connect(mapStateToProps)(CreateUserSEDIS)
