import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, prefixFront } from '../../../../constants'
import Loading from '../../Loading'

const newHistory = createBrowserHistory()

export default class MaximumCommandable extends Component {

    state = {
      isAuthenticated: false,
      listProduct: [],
      listCodeProduct: [],
      listConstantes: [],
      listProductToUpdate: []
    };

    nameMenu = 'maximumCommandable'

    componentDidMount() {
        this.renderListProduct();
    }

    renderListProduct = async () => {
        const data = await connectApi('salable-products-for-sedis?isSalable=true&MaxOrderEnabled=true', 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                this.setState({
                    listProduct: data,
                    listConstantes: localStorage.getItem('listConstantes')
                });
                for (let i= 0; i < this.state.listProduct.length; i++) {
                    this.state.listCodeProduct.push(this.state.listProduct[i].CodeArticle)
                }
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    enregistrer = async () => {
        for (let product of this.state.listProduct) {
            // Sauvegarder dans le SHOP API
            let maxOrderPerDayPerCustomer = document.getElementById('max-' + product.CodeArticle).value
            let thresholdFullRelease = document.getElementById('seuil-' + product.CodeArticle).value
            maxOrderPerDayPerCustomer = maxOrderPerDayPerCustomer === '' ? 0 : Number.parseInt(maxOrderPerDayPerCustomer, 10)
            thresholdFullRelease = thresholdFullRelease === '' ? 0 : Number.parseInt(thresholdFullRelease, 10)

            if (maxOrderPerDayPerCustomer === 0 && thresholdFullRelease === 0) {
            } else {
                document.body.style.cursor = "wait";
                product.MaxOrderPerDayPerCustomer = maxOrderPerDayPerCustomer
                product.ThresholdFullRelease = thresholdFullRelease
                const data = await connectApi('products/' + product.CodeArticle, 'PUT', product)
                if (typeof(data) === 'object') {
                    if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                        newHistory.push(prefixFront + '/login')
                    } else {
                        document.body.style.cursor = "default";
                    }
                } else {
                    console.log(data)
                }
            }
        }
        window.location.reload();
    }

    changeNumber(identifiant) {
        document.getElementById(identifiant).data = document.getElementById(identifiant).value
        let id = identifiant.replace('max-', '')
        id = id.replace('seuil-', '')
        if (this.state.listProductToUpdate.indexOf(id) === -1) {
            this.state.listProductToUpdate.push(id)
        }
    }

    render() {
        const listProduct = this.state.listProduct?.map((product) => (
                <tr key={
                    `product${
                        product.CodeArticle }`
                    }>
                    <td className="CodeArticle">{ product.CodeArticle }</td>
                    <td className="Libelle">{ product.Libelle }</td>
                    <td className="GrandeFamilleID">{ product.GrandeFamilleID }</td>
                    <td className="FamilleID">{ product.FamilleID }</td>
                    <td className="SousFamilleID">{ product.SousFamilleID }</td>
                    <td className="MaxOrderPerDayPerCustomer">
                        <input type="text" min="0" max="100"
                        id={ `max-${ product.CodeArticle }` } /*onChange={ this.changeNumber(`max-${ product.CodeArticle }`) } */
                        data={
                            (product.MaxOrderPerDayPerCustomer && product.MaxOrderPerDayPerCustomer > 0)
                            ?
                            product.MaxOrderPerDayPerCustomer
                            :
                            this.state.listConstantes['MAX_ORDER_PER_DAY_PER_CUSTOMER']
                            }
                        placeholder={
                            (product.MaxOrderPerDayPerCustomer && product.MaxOrderPerDayPerCustomer > 0)
                            ?
                            product.MaxOrderPerDayPerCustomer
                            :
                            this.state.listConstantes['MAX_ORDER_PER_DAY_PER_CUSTOMER']
                            } 
                        /> %
                    </td>
                    <td className="ThresholdFullRelease">
                        <input type="text" min="0" max="100"
                        id={ `seuil-${ product.CodeArticle }` } /*onChange={ this.changeNumber(`seuil-${ product.CodeArticle }`) } */
                        data={
                            (product.ThresholdFullRelease && product.ThresholdFullRelease > 0)
                            ?
                            product.ThresholdFullRelease
                            :
                            this.state.listConstantes['THRESHOLD_FULL_RELEASE']
                            }
                        placeholder={
                            (product.ThresholdFullRelease && product.ThresholdFullRelease > 0)
                            ?
                            product.ThresholdFullRelease
                            :
                            this.state.listConstantes['THRESHOLD_FULL_RELEASE']
                            }
                        />
                    </td>
                </tr>
        ));
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Liste des produits avec un maximum commandable</h5>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                            <li className="breadcrumb-item">Administration</li>
                            <li className="breadcrumb-item active">Maximum commandable</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="table-responsive">
                                    <table id="maxCommandable" className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="table-dark">
                                                <th className="CodeArticle">CodeArticle</th>
                                                <th className="Libelle">Libelle</th>
                                                <th className="GrandeFamilleID">GrandeFamilleID</th>
                                                <th className="FamilleID">FamilleID</th>
                                                <th className="SousFamilleID">SousFamilleID</th>
                                                <th className="MaxOrderPerDayPerCustomer">
                                                    Maximum commandable par jour et par client (%)
                                                </th>
                                                <th className="ThresholdFullRelease">
                                                    Seuil (en quantité restante) avant libération totale
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listProduct }
                                        </tbody>
                                        <tfoot>
                                        <tr className="table-dark">
                                                <th className="CodeArticle">CodeArticle</th>
                                                <th className="Libelle">Libelle</th>
                                                <th className="GrandeFamilleID">GrandeFamilleID</th>
                                                <th className="FamilleID">FamilleID</th>
                                                <th className="SousFamilleID">SousFamilleID</th>
                                                <th className="MaxOrderPerDayPerCustomer">
                                                    Maximum commandable par jour et par client (%)
                                                </th>
                                                <th className="ThresholdFullRelease">
                                                    Seuil (en quantité restante) avant libération totale
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div>
                            <button onClick={ this.enregistrer } className="btn btn-info btn-login">Enregistrer</button>
                            </div>
                        </div>
                        <Loading/>
                    </div>
                </section>
                
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}