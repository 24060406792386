import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Button = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 119px;
    height: 120px;
    border-radius: 6px;
    /* background: #847f7f; */
    background-color: #404040;
    @media screen and (max-width:2000px){
        width: 102px;
        height: 104px;
    }
`;

const Img = styled.img`
    display: block;
    margin: 0 auto 11px;
    @media screen and (max-width:2000px){
        max-width: 26px;
    }
    @media screen and (max-width:1500px){
        max-width: 24px;
    }
`;

const Text = styled.span`
    display: block;
    font-size: 30px;
    line-height: 1;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
    font-family: 'Covered By Your Grace', sans-serif;
    text-transform: uppercase;
    @media screen and (max-width:2000px){
        font-size: 22px;
    }
    @media screen and (max-width:1500px){
        font-size: 20px;
    }
`;

function Filter(props){
    return (
        <Button to="#" onClick={ () => document.getElementById('triggerModal').click() }>
            <Img src="/img/i-filter.svg" />
            <Text>FILTRE</Text>
        </Button>
    )
}

export default Filter;