import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BeatLoader } from  'react-spinners';

// App
import { Section, Container } from '../../../Mixed/Composants';

const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 120px 0;
    @media screen and (max-width:2000px){
        margin: 80px 0
    }
`;

const Body = styled(Section)`
    margin: 100px 0;
    @media screen and (max-width:2000px){
        margin: 70px 0
    }
`;

function Content(props){
    const { loading } = props;
    return (
        <Body className="front__main-content">
            <Container fluid>
                { loading && <Loader><BeatLoader loading={loading} color="#ff5d1f" /></Loader> }
                { !loading && props.children}
            </Container>
        </Body>
    )
}

Content.defaultProps ={
    loading : false
}

Content.propTypes = {
    loading : PropTypes.bool
}

export default Content;