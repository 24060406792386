import React from "react"
import styled from "styled-components"

// App
import {formatCurrency} from "../../../services/helpers/FrontHelper"

const Bold = styled.b`
  font-weight: 700;
`

const Medium = styled.b`
  font-weight: 500;
`

const Title = styled.h4`
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 35px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  padding: 11px 20px;
  color: #ffffff;
  background: #b3db11;
  text-transform: uppercase;
  @media screen and (max-width: 2000px) {
    font-size: 27px;
  }
`

const Inner = styled.div`
  background: #fff;
  padding: 38px 20px;
  @media screen and (max-width: 2000px) {
    padding: 32px 20px;
  }
`

const OrderDetail = styled.div`
  position: relative;
`

const Recap = styled.p`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 31px;
  font-weight: bold;
  line-height: 1.39;
  color: #404040;
  margin: 0 0 1rem;
  @media screen and (max-width: 2000px) {
    font-size: 21px;
    margin-bottom: .7rem;
  }
`

const Info = styled.p`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  color: #404040;
  margin: 0;
  @media screen and (max-width: 2000px) {
    font-size: 18px;
  }
`

const Total = styled(Info)`
  color: #ff0000
`

class CartDetailItem extends React.Component {

    _isMounted = false

    state = {
        nbreColis: 0,
        nbrePalette: 0,
        poidsTotal: 0,
        priceTotal: 0
    }

    _renderRecap = () => {
        const {data} = this.props
        return data.length === 0 || !data ? "Aucun produit" : data.length === 1 ? "1 produit" : `${data.length} products`
    }

    _getCartInfo = (data) => {
        let nbreColis = 0
        let nbrePalette = 0
        let poidsTotal = 0
        let priceTotal = 0
        if (data && data.length) {
            data.forEach(item => {
                poidsTotal += parseFloat(item.PoidsColis*item.Quantite)
                priceTotal += parseFloat(item.priceTotal)
                nbreColis += parseInt(item.Quantite)
            })
        }
        return {
            nbreColis: nbreColis,
            nbrePalette: nbrePalette,
            poidsTotal: Number.isInteger(poidsTotal) ? poidsTotal : parseFloat(poidsTotal).toFixed(2),
            priceTotal: priceTotal
        }
    }

    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            const {data} = this.props
            const meta = this._getCartInfo(data)
            this.setState({
                nbreColis: meta.nbreColis,
                nbrePalette: meta.nbrePalette,
                poidsTotal: meta.poidsTotal,
                priceTotal: meta.priceTotal
            })
        }
    }

    componentDidUpdate(prevProps) {
        const prevMeta = this._getCartInfo(prevProps.data)
        const currentMeta = this._getCartInfo(this.props.data)
        if (prevMeta.priceTotal !== currentMeta.priceTotal) {
            this.setState({
                nbreColis: currentMeta.nbreColis,
                nbrePalette: currentMeta.nbrePalette,
                poidsTotal: currentMeta.poidsTotal,
                priceTotal: currentMeta.priceTotal
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {

        const {title} = this.props
        const {nbreColis, poidsTotal, priceTotal} = this.state

        return (
            <OrderDetail>
                <Title>{title}</Title>
                <Inner>
                    <Recap>Récapitulatif : <Medium>({this._renderRecap()})</Medium></Recap>
                    <Info>Nombre de colis : <Bold>{nbreColis}</Bold></Info>
                    <Info>Poids total (kg) : <Bold>{poidsTotal}</Bold></Info>
                    <Total>Total : <Bold>{formatCurrency(priceTotal)}</Bold></Total>
                </Inner>
            </OrderDetail>
        )
    }
}

export default CartDetailItem