import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

// App
import {envVars} from '../../../env_var';
import {Rayons, Forms} from '../Composants';
import {UrlPrefixContext} from '../../../context';
import ProductContext from '../../../context/ProductContext';
import {Div, Row, Column, Lightbox} from '../../Mixed/Composants';
import {Header, Content, Footer} from '../Composants/Template';
import {
    formatDate,
    formatNumber,
    getUnitPrice,
    getPiceGrid,
    getPiceGridNormal,
    getPiceGridRemise,
    strToSlug,
    applyProductMaximumCommandale,
    getDefaultImage,
    formatCurrency
} from '../../../services/helpers/FrontHelper';
import {
    createOrderLine,
    updateOrderLine,
    deleteOrderLine,
    getCustomerInfo,
    getProduct,
    getProductStock
} from '../../../services/helpers/FrontApiHelper';

const {Select, TextInput, Button} = Forms;

const Fiche = styled.div`
  background: #ffffff;
  border: 1px solid #e0dfd7;
`;

const GoBack = styled.div`
  padding: 11px 30px;
  border-bottom: solid 1px #e0dfd7;
  @media screen and (max-width: 2000px) {
    padding: 8px 20px;
  }
`;

const LinkText = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ff5d1f;
  @media screen and (max-width: 2000px) {
    font-size: 17px;
  }
`;

const Inner = styled.div`
  padding: 30px 48px;
`;

const Figure = styled.figure`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px;

  &.has-no-image {
    img {
      height: 150px;
    }
  }
`;

const Image = styled.img`
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 100%;
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 1.3;
  color: #404040;
  margin: 0 0 2rem;
  text-transform: capitalize;
  @media screen and (max-width: 2000px) {
    font-size: 26px;
  }
`;

const Text = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.41;
  color: #272727;
  margin: 0 0 2px;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
  }
`;

const Detail = styled(Row)`
  margin: 0 0 30px;
  padding: 14px;
  background: #dedede;
  border-radius: 5px;
`;

const DlcDluo = styled.label`
  display: block;
  position: relative;
  margin-bottom: 25px;

  .form-group {
    margin: 0;
  }
`;

const Label = styled.span`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 1.95;
  color: #272727;
  @media screen and (max-width: 2000px) {
    font-size: 17px;
  }
`;

const RadioWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .form-group {
    margin-bottom: 0;
  }
`;

const RadioBlock = styled.div`
  width: 32px;
  box-shadow: 0 0 5px 0 #000000b1;
  border-radius: 50%;
  height: 32px;
  margin: 0 2rem;
`;

const Radio = styled.input`
  width: 32px;
  height: 32px;
  appearance: none;
  border: 1px solid #75314e;
  border-radius: 50%;

  &:checked {
    background: #75314e
  }

  &:disabled {
    background: #e9ecef;
  }
`;

const PictoWrap = styled.div`
  position: relative;
`;

const Picto = styled.img`
  padding: 0;
  margin: 0;

  &.remiseVolume {
    position: absolute;
    left: 12px;
    top: 9px;
    max-width: 30px;
  }
`;

const Input = styled(TextInput)`
  font-size: 18px;
  font-weight: 600;
  color: #75314e;
  border: 1px solid #75314e;
  height: auto;
  line-height: 1;
  padding: 15px 1.1rem;
  border-radius: 0;

  &::placeholder {
    color: #75314e;
    font-weight: 600;
  }
`;

const WrapColis = styled.div`
  display: block;
`;

const TipRemise = styled.div`
  display: ${props => props.displayed ? 'block' : 'none'};
  position: absolute;
  bottom: 65%;

  span {
    background: #66b821;
    color: #ffffff;
    padding: 10px;
    font-size: 14px;
    line-height: 1.2;
    opacity: 0.95;
    display: block;
  }
`;

const WrapPalette = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  // border-left: 1px solid #707070;
`;

const Infos = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Info = styled.span`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: right;
  color: #272727;
  border-radius: 5px;
  border: solid 1px #272727;
  width: 95px;
  height: 53px;
  line-height: 53px;
  padding: 0 10px;
  @media screen and (max-width: 2000px) {
    font-size: 22px;
  }
`;

const Validate = styled(Button)`
  float: right;
  width: 260px;
  border-radius: 50px;
  background: #e82424 !important;
`;

const RowDetail = styled(Row)`
  align-items: flex-end;
`;

const RowColis = styled(Row)`
  margin-bottom: 25px;
`;

const Alert = styled.span`
  color: red;
  display: inline-block;
  font-style: italic;
  font-size: .8rem;
  margin-left: 1rem;
  vertical-align: middle;
`;

const Notice = styled.span`
  color: #17a2b8;
  display: block;
  font-style: italic;
  font-size: .8rem;
  margin: 1rem 0 0;
`;

const Tags = styled.div`
  min-height: 23px;
  margin-bottom: 1em;
`;

const Tag = styled.span`
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  background: ${props => props.tag === 'halal' ? '#117e15' : props.tag === 'promo' || props.tag === 'promotion' ? '#fd5b05' : props.tag === 'derniere minute' ? '#fd0505' : props.tag === 'bio' ? '#8BD600' : '#87a7a7'};
  display: inline-block;
  padding: .2rem .5rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const Prix = styled.div`
  text-align: center;
  font-size: 1.2em;
  margin: 20px 0;
`;

class FicheProduitPage extends React.Component {

    state = {
        loader: false,
        loading: false,
        product: null,
        qtColis: "",
        qtPalette: 0,
        nbrColis: 0,
        nbrPalette: 0,
        c_colis: false,
        dlc_dluo: false,
        hasStock: false,
        c_palette: false,
        isVenteColis: false,
        updatedStock: false,
        onlineStockValue: 0,
        isVentePalette: false,
        isPromo: false,
        select_dlc_dluo: [],
        remiseVolume: 0,
        texteRemise: '',
        priceNormal: 0,
        priceRemise: 0,
        unitPrice: 0
    }

    _isCalled = false;
    _isMounted = false;

    _getProduct = (CodeArticle) => {
        const dlcdluo = [];
        const {quantite} = this.state;
        const {customer} = this.props;
        getProduct(CodeArticle)
            .then(response => {
                if (response.hasOwnProperty('CodeArticle')) {
                    setTimeout(() => {
                        window.scroll(0, document.querySelector(".front__main-header").offsetHeight - 85)
                    })
                    this.setState({product: response});
                    if (response.hasOwnProperty('variantes') && response.variantes.length > 0) {
                        let data = null;
                        response.variantes.forEach(item => {
                            const price_grid = getPiceGridRemise(item, customer);
                            const price_unit = getUnitPrice(price_grid, response.PCB, true);
                            const stock_label = `Stock - ${price_unit} €`;
                            const dlc_label = `DLC ${formatDate(item.DateDLCDLUO)}`;
                            const dluo_label = `DLUO ${formatDate(item.DateDLCDLUO)}`;
                            const arrivage_label = `Arrivage ${formatDate(item.DateArrivagePrev)} - ${price_unit} €`;
                            const label = `${response.DlcOrDluo === 1 ? dlc_label : dluo_label} - ${item.ArriveEntrepot ? stock_label : arrivage_label}`;
                            dlcdluo.push({value: item.CodeVarianteArticle, label: label})
                            const productLocal = this._getProductFromStorage(item);
                            if (productLocal) {
                                data = productLocal
                            }
                        });
                        const priceGrid = getPiceGrid(response.variantes[0], customer, quantite);
                        let unitPrice = getUnitPrice(priceGrid, response.PCB, true);
                        const price_grid_simple = getPiceGridNormal(response.variantes[0], customer);
                        const price_unit_simple = getUnitPrice(price_grid_simple, response.PCB, true);
                        const price_grid_remise = getPiceGridRemise(response.variantes[0], customer, response.variantes[0].SeuilRemiseVolume);
                        const price_unit_remise = getUnitPrice(price_grid_remise, response.PCB, true);
                        this.setState({
                            select_dlc_dluo: dlcdluo,
                            dlc_dluo: response.variantes[0].CodeVarianteArticle,
                            hasStock: !!response.variantes[0].LeStock,
                            c_colis: response.variantes[0].VenteAuColis ? response.variantes[0].VenteAuColis && response.variantes[0].VenteALaPalette ? true : true : false,
                            c_palette: response.variantes[0].VenteALaPalette ? !(response.variantes[0].VenteALaPalette && response.variantes[0].VenteAuColis) : false,
                            isVenteColis: !!response.variantes[0].VenteAuColis,
                            isVentePalette: response.variantes[0].VenteALaPalette ? (response.NbrColisParPalette !== 0) : false,
                            isPromo: response.variantes[0].EnPromo === '1',
                            remiseVolume: response.variantes[0].SeuilRemiseVolume,
                            unitPrice: unitPrice,
                            priceNormal: price_unit_simple,
                            priceRemise: price_unit_remise
                        })

                        if (data) {
                            let nbre_colis = 0;
                            let nbre_palette = 0;
                            const qtColis = data.VenteAuColis ? data.Quantite : 0;
                            const qtPalette = data.VenteALaPalette ? data.Quantite : 0;
                            let c_colis = true;
                            let c_palette = false;
                            if (data.VenteAuColis) {
                                nbre_colis = qtColis;
                                if (data.NbrColisParPalette) {
                                    const nbre = parseFloat((1 * data.Quantite) / data.NbrColisParPalette);
                                    nbre_palette = Number.isInteger(nbre) ? nbre : nbre.toFixed(1);
                                }
                            }
                            if (data.VenteALaPalette) {
                                nbre_colis = qtPalette;
                                if (data.NbrColisParPalette) {
                                    const nbre = parseFloat((1 * data.Quantite) / data.NbrColisParPalette);
                                    nbre_palette = Number.isInteger(nbre) ? nbre : nbre.toFixed(1);
                                }
                                c_colis = false;
                                c_palette = true;
                            }
                            this.setState({
                                qtColis: qtColis,
                                qtPalette: qtPalette,
                                nbrColis: nbre_colis,
                                nbrPalette: nbre_palette,
                                dlc_dluo: data.CodeVarianteArticle,
                                c_colis: c_colis,
                                c_palette: c_palette
                            })
                            if (data.hasOwnProperty('geslotStockIs') && data.geslotStockIs === data.Quantite) {
                                this.setState({
                                    updatedStock: true,
                                    onlineStockValue: data.geslotStockIs
                                })
                            }
                        }
                    } else {
                        this.setState({
                            product: false
                        })
                    }
                } else {
                    this.setState({
                        product: false
                    })
                }
            })
            .finally(() => this.setState({loader: false}));
    }

    _getCustomerInfo = (customer, dataProductDetailCommandee) => {
        return getCustomerInfo(customer.user.CodeClient)
            .then(response => {
                if (response) {
                    const info = {
                        MontantEncoursUtilise: response.MontantEncoursUtilise,
                        MontantEncoursAutoriseFixe: response.MontantEncoursAutoriseFixe,
                        MontantEncoursAutoriseFlexible: response.MontantEncoursAutoriseFlexible,
                        DelaiEchanceFacture: response.DelaiEchanceFacture,
                    };
                    let dataCustomerWithProductCommandee = {...customer, ...dataProductDetailCommandee};
                    const data = {...dataCustomerWithProductCommandee, ...info};
                    const DelaiEchanceFacture = data.DelaiEchanceFacture;
                    const encours_autorise = data.MontantEncoursAutoriseFixe;
                    const encours_autorise_flexible = data.MontantEncoursAutoriseFlexible;
                    const encours_utilise = data.MontantEncoursUtilise;
                    const encours_restent = encours_autorise - encours_utilise;
                    const encours_restent_flexible = encours_autorise_flexible - encours_utilise;
                    // var montantHT = this._getOrderPriceTotal();
                    let MontantHTArticleCommandee = parseFloat(data.priceTotal);

                    let compteurDepassementEncoursFlexible = localStorage.getItem('compteurDepassementEncoursFlexible');
                    if (compteurDepassementEncoursFlexible == null && compteurDepassementEncoursFlexible === undefined) {
                        compteurDepassementEncoursFlexible = 0;
                    }

                    // montantHT += MontantHTArticleCommandee;
                    let diffAmountArticleCommandee = MontantHTArticleCommandee;
                    const products = this._getLocalProducts();
                    if (products && products.length) {
                        products.forEach((item) => {
                            if (item.CodeArticle === data.CodeArticle) {
                                // montantHT -= parseFloat(item.priceTotal);
                                diffAmountArticleCommandee -= parseFloat(item.priceTotal);
                            }
                        });
                    }

                    diffAmountArticleCommandee = diffAmountArticleCommandee < 0 ? -1 * diffAmountArticleCommandee : diffAmountArticleCommandee
                    this.props.dispatch({type: 'SET_CUSTOMER', value: data});

                    if (DelaiEchanceFacture > 0) {
                        toast("Vous avez des factures non-réglées ayant dépassé votre délai de paiement de x jours, merci de régulariser la situation pour pouvoir commander de nouveau !", {
                            type: 'error',
                            autoDismiss: true
                        });
                        return false;
                    }

                    if (encours_restent <= 0 || (diffAmountArticleCommandee > encours_restent && diffAmountArticleCommandee > encours_restent_flexible)) {
                        toast("Votre encours est insuffisant pour passer une commande !", {
                            type: 'error',
                            autoDismiss: true
                        });
                        return false;
                    } else if (diffAmountArticleCommandee < encours_restent && encours_autorise_flexible > 0 && diffAmountArticleCommandee > encours_restent_flexible) {
                        localStorage.setItem('compteurDepassementEncoursFlexible', parseInt(compteurDepassementEncoursFlexible) + 1);
                        compteurDepassementEncoursFlexible = localStorage.getItem('compteurDepassementEncoursFlexible');
                        if (compteurDepassementEncoursFlexible === 2) {
                            localStorage.setItem('firstMontantHTDepassantEncoursFlexible', diffAmountArticleCommandee);
                        }
                        if (compteurDepassementEncoursFlexible > 1) {
                            let firstMontantHTDepassantEncoursFlexible = parseFloat(localStorage.getItem('firstMontantHTDepassantEncoursFlexible'));
                            if (diffAmountArticleCommandee > firstMontantHTDepassantEncoursFlexible) {
                                toast("Votre encours est insuffisant pour passer une commande !", {
                                    type: 'error',
                                    autoDismiss: true
                                });
                                return false;
                            }
                            return true;
                        }
                        return true;
                    }
                    return true
                }
                return false
            })
    }

    async _createOrderLine(data) {
        const {customer} = this.props;
        const orderLine = this._getLocalOrderLine();
        const qtMAxCom = applyProductMaximumCommandale(data);

        if (qtMAxCom) {
            data = this._getProductDetail(qtMAxCom);
        }

        const response = await createOrderLine(data, customer, orderLine);
        // if( customer && customer.isAuthenticated && customer.user.role  === "ROLE_CUSTOMER" ){
        //     this._getCustomerInfo(customer);
        // }

        if (response.code === 0 || response.code === 400 || response.code === 401 || response.code === 403) {
            const message = response.code === 0 || response.code === 401 ? "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible." : response.message;
            this._disableProductAndDisplayError(message);
            return false;
        } else if (response.code === 480) {
            const LeStock = response.data.varianteArticle.LeStock;
            if (LeStock === 0) {
                this._disableProductAndDisplayError();
            } else {
                const stock = this._getProductDetail(LeStock);
                const qColis = stock.VenteAuColis ? LeStock : 0;
                const qPalette = stock.VenteALaPalette ? LeStock : 0;
                this.setState({
                    qtColis: qColis,
                    qtPalette: qPalette,
                    updatedStock: true,
                    onlineStockValue: LeStock
                }, () => {
                    this._createOrderLine(stock);
                });
            }
            return false;
        } else {
            const products = this._getLocalProducts();
            if (products.length === 0 || products.length === 1) {
                const orderLineData = {
                    "id": response.id,
                    "OrderId": response.OrderId,
                    "OrderLineId": response.OrderLineId,
                    "CodeBP": response.CodeBP,
                }
                this._storeToStorage('frontOrderLine', orderLineData);
            }
            if (!response.hasOwnProperty('CodeArticle')) {
                this._disableProductAndDisplayError();
                return false;
            }
            toast("Le produit est ajouté à votre panier", {
                type: 'success',
                autoDismiss: true
            });
            this.setState({loading: false}, () => this._addProductToLocal({...data, orderLine: response}));
        }
    }

    async _updateOrderLine(data) {
        // const { customer } = this.props;
        const product = this._getProductFromStorage(data);
        const qtMAxCom = applyProductMaximumCommandale(data);

        if (qtMAxCom) {
            data = this._getProductDetail(qtMAxCom);
        }

        if (product && product.hasOwnProperty('orderLine')) {

            const order = {...product, ...data};
            const response = await updateOrderLine(order.orderLine.id, order);

            if (response.code === 0 || response.code === 400 || response.code === 401 || response.code === 403) {
                const message = response.code === 0 || response.code === 401 ? "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible." : response.message;
                this._deleteStorage('frontOrderProducts', data);
                this._disableProductAndDisplayError(message);
                return false;
            } else if (response.code === 480) {
                const LeStock = response.data.varianteArticle.LeStock;
                if (LeStock === 0) {
                    this._deleteOrderLine(data);
                    this._disableProductAndDisplayError();
                } else {
                    const stock = this._getProductDetail(LeStock);
                    const item = {...order, ...stock};
                    const qColis = item.VenteAuColis ? LeStock : 0;
                    const qPalette = item.VenteALaPalette ? LeStock : 0;
                    this.setState({
                        qtColis: qColis,
                        qtPalette: qPalette,
                        updatedStock: true,
                        onlineStockValue: LeStock
                    }, () => {
                        this._reUpdateOrderAfterDissatisfiedStock(LeStock, item.orderLine);
                    });
                }
                return false;
            } else {
                if (!response.hasOwnProperty('CodeArticle')) {
                    this._disableProductAndDisplayError();
                    return false;
                }
                toast("Le produit est ajouté à votre panier", {
                    type: 'success',
                    autoDismiss: true
                });

                this.setState({loading: false}, () => this._updateStorage('frontOrderProducts', {
                    ...order,
                    orderLine: response
                }));
            }

        }

    }

    _deleteOrderLine = (data) => {
        const product = this._getProductFromStorage(data);
        if (product && product.hasOwnProperty('orderLine')) {
            deleteOrderLine(product.orderLine.id)
                .then((response) => {
                    if (!response.hasOwnProperty('trace')) {
                        this._deleteStorage('frontOrderProducts', data);
                    }
                })
                .finally(() => this.setState({loading: false}))
        }
    }

    _reUpdateOrderAfterDissatisfiedStock = (quantite, response) => {
        const detail = this._getProductDetail(quantite);
        const item = {...detail, orderLine: response, geslotStockIs: quantite};
        this._updateStorage('frontOrderProducts', item);
        this._updateOrderLine(item);
        this.setState({
            quantite: item.Quantite,
            priceTotal: item.priceTotal
        })
    }

    _disableProductAndDisplayError = (message = null) => {
        const error = message ? message : "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible.";
        this.setState({
            loading: false,
            quantite: 0,
            nbreColis: 0,
            nbrPalette: 0,
            c_colis: 0,
            c_palette: 0,
            isVenteColis: false,
            isVentePalette: false
        });
        toast(error, {
            type: 'error',
            autoDismiss: true
        });
    }

    _getLocalOrderLine = () => {
        const storage = localStorage.getItem('frontOrderLine');
        return storage != null ? JSON.parse(storage) : null;
    }

    _getLocalProducts = () => {
        const storage = localStorage.getItem('frontOrderProducts');
        return storage != null ? JSON.parse(storage) : [];
    }

    _persistDataToStore = (data) => {
        let value = [];
        if (data.length) {
            data.forEach(item => {
                if (item.hasOwnProperty('orderLine')) {
                    value.push(item);
                }
            })
            this.props.dispatch({type: 'SET_ORDER', value: value});
        } else {
            this.props.dispatch({type: 'SET_ORDER', value: []});
        }
    }

    _storeToStorage = (name, data) => {
        const {onUpdate} = this.props;
        if (!name || !data) {
            return false;
        }
        localStorage.setItem(name, JSON.stringify(data));
        if (typeof (onUpdate) === 'function') {
            onUpdate();
        }
        if (name === 'frontOrderProducts') {
            this._persistDataToStore(data);
        }
    }

    _addProductToLocal = (data) => {
        let products = this._getLocalProducts();
        if (products) {
            products.push(data);
        }
        this._storeToStorage('frontOrderProducts', products);
    }

    _updateStorage = (name, value) => {
        if (!name || !value) {
            return false;
        }
        const tmp = [];
        const data = name === 'frontOrderProducts' ? this._getLocalProducts() : this._getLocalOrderLine();
        data.forEach(item => {
            if (name === 'frontOrderProducts' && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                item = value;
            }
            if (name === 'frontOrderLine' && item.OrderId === value.OrderId) {
                item = value;
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp);
    }

    _deleteStorage = (name, value) => {
        if (!name || !value) {
            return false;
        }
        const tmp = [];
        const data = name === 'frontOrderProducts' ? this._getLocalProducts() : this._getLocalOrderLine();
        data.forEach(item => {
            if (name === 'frontOrderProducts' && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                // item = value;
                return false;
            }
            if (name === 'frontOrderLine' && item.OrderId === value.OrderId) {
                // item = value;
                return false
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp);

    }

    _getProductFromStorage = (data) => {
        let product = null;
        const products = this._getLocalProducts();
        products.forEach(item => {
            if (item.CodeVarianteArticle === data.CodeVarianteArticle) {
                product = item;
            }
        })
        return product;
    }

    _getProductDetail = (quantite) => {
        const {product, dlc_dluo, c_colis, c_palette} = this.state;
        const {customer} = this.props;

        let priceTotal;
        const dlc_dluo_value = this._getDLCLDUOValue(dlc_dluo);

        const price_grid = getPiceGrid(dlc_dluo_value, customer, quantite, c_palette);
        const price_unit = getUnitPrice(price_grid, product.PCB, true);

        if (product.UniteDeVente === 'K' && product.PoidsColis > 0) {
            priceTotal = price_grid * product.PoidsColis * quantite;
        } else if (product.UniteDeVente === 'K' && product.PoidsColis === 0) {
            priceTotal = 0.00;
        } else {
            priceTotal = price_unit * product.PCB * quantite;
        }
        priceTotal = Number.isInteger(priceTotal) ? priceTotal : parseFloat(priceTotal).toFixed(2);
        const response = {
            PCB: product.PCB,
            Tags: product.Tags,
            Quantite: quantite,
            PU: parseFloat(price_unit),
            PUsimple: parseFloat(price_unit),
            SeuilRemiseVolume: dlc_dluo_value.SeuilRemiseVolume,
            priceTotal: priceTotal,
            CodeTVA: product.CodeTVA,
            Libelle: product.Libelle,
            PriceGrid: price_grid,
            PathPhoto: product.PathPhoto,
            FamilleID: product.FamilleID,
            DlcOrDluo: product.DlcOrDluo,
            PoidsColis: product.PoidsColis,
            CodeArticle: product.CodeArticle,
            UniteDeVente: product.UniteDeVente,
            PoidsUnitaire: product.PoidsUnitaire,
            PoidsVariable: product.PoidsVariable,
            MaxOrderEnabled: product.MaxOrderEnabled,
            NbrColisParPalette: product.NbrColisParPalette,
            ThresholdFullRelease: product.ThresholdFullRelease,
            MaxOrderPerDayPerCustomer: product.MaxOrderPerDayPerCustomer
        }
        if (dlc_dluo_value.SeuilRemiseVolume > 0) {
            // remise au volume
            const price_grid_simple = getPiceGridNormal(dlc_dluo_value, customer);
            const price_unit_simple = getUnitPrice(price_grid_simple, product.PCB, true);
            const price_grid_remise = getPiceGrid(dlc_dluo_value, customer, dlc_dluo_value.SeuilRemiseVolume);
            const price_unit_remise = getUnitPrice(price_grid_remise, product.PCB, true);
            response.PUsimple = parseFloat(price_unit_simple);
            response.PUremise = parseFloat(price_unit_remise);
        }
        if (dlc_dluo_value) {
            response.EnPromo = dlc_dluo_value.EnPromo;
            response.LeStock = dlc_dluo_value.LeStock;
            response.DateDLCDLUO = dlc_dluo_value.DateDLCDLUO;
            response.DateArrivage = dlc_dluo_value.DateArrivage;
            response.VenteAuColis = c_colis;
            response.ArriveEntrepot = dlc_dluo_value.ArriveEntrepot;
            response.VenteALaPalette = c_palette;
            response.DateArrivagePrev = dlc_dluo_value.DateArrivagePrev;
            response.CodeVarianteArticle = dlc_dluo_value.CodeVarianteArticle;
        }
        return response;
    }

    _getOrderPriceTotal = () => {
        let total = 0;
        const products = this._getLocalProducts();
        if (products && products.length) {
            products.forEach((item) => {
                total += parseFloat(item.priceTotal)
            });
        }
        return total
    }

    _getDLCLDUOValue = (CodeVarianteArticle) => {
        const {product} = this.state;
        if (product && product.variantes && CodeVarianteArticle) {
            const dlc_dluo = product.variantes.filter(item => item.CodeVarianteArticle === CodeVarianteArticle);
            return dlc_dluo.length ? dlc_dluo[0] : false;
        }
        return false;
    }

    _handleDlcDluoChange = (e) => {
        const {product} = this.state;
        const {customer} = this.props;
        const value = e.target.value;
        let dlc_dluo = {};
        let hasStock = false;
        let venteColis = false;
        let ventePalette = false;
        let isPromo = false;
        let remiseVolume = 0;
        let price_unit_remise;
        let price_unit_simple;
        if (product.variantes) {
            dlc_dluo = this._getDLCLDUOValue(value);
        }
        const price_grid_simple = getPiceGridNormal(dlc_dluo, customer);
        price_unit_simple = getUnitPrice(price_grid_simple, product.PCB, true);
        const price_grid_remise = getPiceGridRemise(dlc_dluo, customer, dlc_dluo.SeuilRemiseVolume);
        price_unit_remise = getUnitPrice(price_grid_remise, product.PCB, true);
        if (dlc_dluo.hasOwnProperty('CodeVarianteArticle')) {
            venteColis = dlc_dluo.VenteAuColis;
            ventePalette = dlc_dluo.VenteALaPalette ? product.NbrColisParPalette !== 0 : false;
            hasStock = !!dlc_dluo.LeStock;
            isPromo = dlc_dluo.EnPromo === '1';
            remiseVolume = dlc_dluo.SeuilRemiseVolume;
            const price_grid_simple = getPiceGridNormal(dlc_dluo, customer);
            price_unit_simple = getUnitPrice(price_grid_simple, product.PCB, true);
            const price_grid_remise = getPiceGrid(dlc_dluo, customer, remiseVolume);
            price_unit_remise = getUnitPrice(price_grid_remise, product.PCB, true);
        }
        const unitPrice = dlc_dluo.SeuilRemiseVolume > 0 ?
            (this.state.qtColis >= dlc_dluo.SeuilRemiseVolume ? price_unit_remise : price_unit_simple)
            : price_unit_simple
        this.setState({
            dlc_dluo: value,
            hasStock: hasStock,
            isVenteColis: venteColis,
            isVentePalette: ventePalette,
            isPromo: isPromo,
            remiseVolume: remiseVolume,
            priceNormal: price_unit_simple,
            priceRemise: price_unit_remise,
            unitPrice: unitPrice
        })
    }

    _handleQuantiteChange = (type, value) => {
        const {product, remiseVolume, dlc_dluo, priceRemise, priceNormal} = this.state;
        const {customer} = this.props;
        let nbre_colis = 0;
        // let nbre_palette = 0;
        let unitPrice = this.state.unitPrice;
        let variant = this._getDLCLDUOValue(dlc_dluo);

        if (type === 'colis') {
            // if( product.NbrColisParPalette ){
            //     const nbre = parseFloat( (1 * value) / product.NbrColisParPalette );
            //     nbre_palette = Number.isInteger(nbre) ? nbre : nbre.toFixed(1);
            // }
            if (remiseVolume > 0) {
                if (value < remiseVolume) {
                    this.setState({texteRemise: `Commandez plus de ${remiseVolume} colis pour bénéficier du tarif préférentiel avec remise au volume (${formatCurrency(priceRemise)} au lieu de ${formatCurrency(priceNormal)}), plus que ${remiseVolume - value} colis pour y arriver`});
                } else {
                    this.setState({texteRemise: `Félicitations, vous bénéficiez désormais du tarif avec remise au volume !`});
                }
            }
            const priceGrid = getPiceGrid(variant, customer, value);
            unitPrice = getUnitPrice(priceGrid, product.PCB, true);

            this.setState({
                qtPalette: 0,
                qtColis: value,
                nbrColis: value,
                nbrPalette: 0,
                c_colis: true,
                c_palette: false,
                unitPrice: unitPrice
            })
        } else {
            if (product.NbrColisParPalette) {
                nbre_colis = product.NbrColisParPalette * value;
            }
            if (remiseVolume > 0) {
                if (value < remiseVolume) {
                    this.setState({texteRemise: `Commandez plus de ${remiseVolume} colis pour bénéficier du tarif préférentiel avec remise au volume (${formatCurrency(priceRemise)} au lieu de ${formatCurrency(priceNormal)}), plus que ${remiseVolume - value} colis pour y arriver`});
                } else {
                    this.setState({texteRemise: `Félicitations, vous bénéficiez désormais du tarif avec remise au volume !`});
                }
            }
            const priceGrid = getPiceGrid(variant, customer, value, true);
            unitPrice = getUnitPrice(priceGrid, product.PCB, true);

            this.setState({
                qtColis: 0,
                qtPalette: value,
                nbrPalette: value,
                nbrColis: nbre_colis,
                c_palette: true,
                c_colis: false,
                unitPrice: unitPrice
            })
        }

    }

    async _addToCart() {
        const {customer, availability} = this.props;
        const {dlc_dluo, c_colis, qtColis, qtPalette, nbrColis} = this.state;
        if (this._isCalled) {
            return false;
        }
        this._isCalled = true;

        if (availability && availability.previousStateGeslotIsAvailable === false) {
            toast("Les commandes sont temporairement indisponibles.", {
                type: 'error',
                autoDismiss: true
            });
            this._isCalled = false;
            return false;
        }

        let stockValue = 0;
        let hasStock = false;
        let quantite = c_colis ? qtColis : qtPalette;
        let nbreColis = c_colis ? qtColis : nbrColis;

        if (!dlc_dluo || !quantite) {
            const message = !quantite ? "Veuillez indiquer la quantité souhaitée" : "Veuillez sélectionner un DLC / DLUO";
            toast(message, {
                type: 'error',
                autoDismiss: true
            });
            this._isCalled = false;
            return false;
        }

        this.setState({loading: true})
        if (customer && customer.isAuthenticated && customer.user.role === "ROLE_CUSTOMER") {
            const status = await this._getCustomerInfo(customer, this._getProductDetail(nbreColis));
            if (!status) {
                this.setState({
                    loading: false
                })
                this._isCalled = false;
                return false
            }
        }

        const stock = await getProductStock(dlc_dluo);
        const dlc_dluo_value = this._getDLCLDUOValue(dlc_dluo);

        if (dlc_dluo_value) {
            hasStock = true;
            stockValue = dlc_dluo_value.LeStock;
        }

        if (stock && stock.hasOwnProperty('LeStock')) {
            hasStock = true;
            stockValue = stock.LeStock;
        }

        // if( c_colis && quantite > stockValue && stockValue > 0){
        //     nbreColis = stockValue;
        // }

        if (!hasStock || stockValue <= 0) {
            this._disableProductAndDisplayError();
            this._isCalled = false;
            return false;
        }

        const data = this._getProductDetail(nbreColis);
        const localProduct = this._getProductFromStorage(data);

        if (localProduct) {
            this._updateOrderLine(data);
        } else {
            this._createOrderLine(data);
        }

        this._isCalled = false;
    }

    _getBackLinkInfo = () => {
        const {product} = this.state;
        const {rayons} = this.props
        let url = `${this.context.prefix}/commande-rapide`;
        if (product) {
            const rayon = rayons.filter(item => item.Identifiant === product.FamilleID)[0];
            if (rayon && rayon.hasOwnProperty('Identifiant')) {
                url = `${this.context.prefix}/nos-produits/${strToSlug(rayon.Nomcategorie)}`;
            }
        }
        return url
    }

    _matchProduct = () => {
        const {match} = this.props;
        if (match && match.hasOwnProperty('params')) {
            const {id} = match.params;
            if (id) {
                this.setState({loader: true}, () => this._getProduct(id));
            }
        }
    }

    _renderProductTags = () => {
        const {product, isPromo} = this.state;
        let tags = [];

        product.Tags.forEach(tag => {
            tags = tags.concat(tag.split(',').map(t => t.trim()));
        });

        if (isPromo && !tags.includes('Derniere minute')) {
            tags = [...tags, "Promo"];
        }

        if (tags && tags.length) {
            return (
                <Tags>
                    {
                        tags.map((tag, index) => {
                            if (tag) {
                                return (
                                    <Tag tag={tag.toLowerCase()} key={index}>{tag}</Tag>
                                )
                            }
                            return null;
                        })
                    }
                </Tags>
            )
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this._matchProduct();
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this._matchProduct();
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {
            loader,
            loading,
            product,
            qtColis,
            c_colis,
            c_palette,
            isVenteColis,
            isVentePalette,
            updatedStock,
            onlineStockValue,
            dlc_dluo,
            select_dlc_dluo,
            nbrColis,
            nbrPalette,
            remiseVolume,
            texteRemise,
            unitPrice,
            priceRemise,
            priceNormal
        } = this.state;
        const {rayons} = this.props;
        const back_link = this._getBackLinkInfo();
        const api_path = envVars.url_shop_api.replace('/api/', '');
        const img_path = product && product.PathPhoto ? api_path + product.PathPhoto : getDefaultImage(rayons, product);
        let poidskg = 0;
        if (product) {
            poidskg = c_colis ? qtColis * product.PoidsColis : nbrColis * product.PoidsColis;
        }

        return (
            <ProductContext.Provider value={{product: product}}>
                <Div className="front__wrap-screen">
                    <Header/>
                    <Content loading={loader}>
                        <Row>
                            <Column lg={3}>
                                <Rayons familleID={product ? product.FamilleID : null}/>
                            </Column>
                            <Column lg={9}>
                                {
                                    product &&
                                    <Fiche>
                                        <GoBack>
                                            <Link to={back_link} onClick={event => {
                                                event.preventDefault();
                                                this.props.history.goBack();
                                            }}>
                                                <img src="/img/chevron-left.svg" className={"mr-3"} width={12} alt={"Left"} />
                                                <LinkText>Retour aux produits</LinkText>
                                            </Link>
                                        </GoBack>
                                        <Inner>
                                            <Row>
                                                <Column lg={4}>
                                                    <Lightbox>
                                                        <Figure
                                                            className={product.PathPhoto ? 'has-image' : 'has-no-image'}>
                                                            <a href={img_path} title={product.Libelle}>
                                                                <Image src={img_path} alt={product.Libelle}/>
                                                            </a>
                                                        </Figure>
                                                    </Lightbox>
                                                </Column>
                                                <Column lg={8}>
                                                    {this._renderProductTags()}
                                                    <Title>
                                                        {product.Libelle.toLowerCase()}
                                                        {product.UniteDeVente === 'K' &&
                                                            <Alert>** Poids variable - Prix au Kg</Alert>}
                                                    </Title>
                                                    <Detail>
                                                        <Column lg={6}>
                                                            <Text>Numéro d’article
                                                                : <strong>{product.CodeArticle ? product.CodeArticle : 'N/A'}</strong></Text>
                                                            <Text>Ref fournisseur
                                                                : <strong>{product.RefFournisseur ? product.RefFournisseur : 'N/A'}</strong></Text>
                                                            <Text>Pièces/colis
                                                                : <strong>{product.PCB ? product.PCB : 'N/A'}</strong></Text>
                                                        </Column>
                                                        <Column lg={6}>
                                                            <Text>Colis/rang
                                                                : <strong>{product.NbrColisParRangpalette ? product.NbrColisParRangpalette : 'N/A'}</strong></Text>
                                                            <Text>Colis/palette
                                                                : <strong>{product.NbrColisParPalette ? product.NbrColisParPalette : 'N/A'}</strong></Text>
                                                            <Text>Rangs/palette
                                                                : <strong>{product.NbrRangParPalette ? product.NbrRangParPalette : 'N/A'}</strong></Text>
                                                        </Column>
                                                    </Detail>
                                                    <DlcDluo className="front__select-dlcdluo">
                                                        <Select fieldname="dlc_dluo"
                                                                placeholder={!isVenteColis && !isVentePalette ? "DLC/DLUO" : false}
                                                                value={dlc_dluo} data={select_dlc_dluo}
                                                                disabled={!isVenteColis && !isVentePalette ? 'disabled' : dlc_dluo.length === 0 ? 'disabled' : ''}
                                                                onChange={this._handleDlcDluoChange}/>
                                                    </DlcDluo>
                                                    <RowColis>
                                                        <Column lg={6}>
                                                            <WrapColis>
                                                                <Label>Quantité colis :</Label>
                                                                <RadioWrap>
                                                                    <PictoWrap>
                                                                        <Picto src="/img/icons-colis.svg"/>
                                                                        {isVenteColis && remiseVolume > 0 &&
                                                                            <Picto src="/img/icon-pourcentage.png"
                                                                                   className="remiseVolume"
                                                                                   title={`Commandez plus de ${remiseVolume} colis et bénéficiez d'un tarif de ${formatCurrency(priceRemise)} au lieu de ${formatCurrency(priceNormal)}`}/>}
                                                                    </PictoWrap>
                                                                    <RadioBlock>
                                                                        <Radio type="radio" name="quantite"
                                                                               value="colis"
                                                                               disabled={isVenteColis ? '' : 'disabled'}
                                                                               onChange={(e) => this._handleQuantiteChange('colis', 0)}
                                                                               checked={c_colis ? 'checked' : ''}/>
                                                                    </RadioBlock>
                                                                    <TipRemise
                                                                        displayed={texteRemise !== ''}><span>{texteRemise}</span></TipRemise>
                                                                    <Input type="number" fieldname="qtColis"
                                                                           value={qtColis} placeholder="0" min={0}
                                                                           max={500}
                                                                           disabled={isVenteColis ? '' : 'disabled'}
                                                                           onChange={(e) => this._handleQuantiteChange('colis', e.target.value)}
                                                                           onBlur={() => this.setState({texteRemise: ''})}
                                                                           onFocus={(e) => e.target.select()}/>
                                                                </RadioWrap>
                                                                {c_colis && updatedStock &&
                                                                    <Notice>Le stock disponible ne nous permet pas de
                                                                        satisfaire votre
                                                                        demande, <b>{onlineStockValue}</b> {onlineStockValue === 1 ? 'colis a été ajouté.' : 'colis ont été ajoutés'}
                                                                    </Notice>}
                                                            </WrapColis>
                                                        </Column>
                                                        <Column lg={6}>
                                                            <WrapPalette>
                                                                <Label>Quantité palette :</Label>
                                                                <RadioWrap>
                                                                    <PictoWrap>
                                                                        <Picto src="/img/icons-palette.svg"/>
                                                                    </PictoWrap>
                                                                    <RadioBlock>
                                                                        <Radio type="radio" name="quantite"
                                                                               value="palette"
                                                                               disabled={isVentePalette ? '' : 'disabled'}
                                                                               onChange={(e) => this._handleQuantiteChange('palette', 0)}
                                                                               checked={c_palette ? 'checked' : ''}/>
                                                                    </RadioBlock>
                                                                    <Input type="number" fieldname="qtPalette"
                                                                           value={nbrPalette} min={0} max={500}
                                                                           disabled={isVentePalette ? '' : 'disabled'}
                                                                           onChange={(e) => this._handleQuantiteChange('palette', e.target.value)}/>
                                                                </RadioWrap>
                                                                {c_palette && updatedStock &&
                                                                    <Notice>Le stock disponible ne nous permet pas de
                                                                        satisfaire votre
                                                                        demande, <b>{onlineStockValue}</b> {onlineStockValue === 1 ? 'palette a été ajouté.' : 'palette ont été ajoutés'}
                                                                    </Notice>}
                                                            </WrapPalette>
                                                        </Column>
                                                    </RowColis>
                                                    <Prix>
                                                        Votre prix: <strong>{formatCurrency(unitPrice)}</strong> l'unité
                                                    </Prix>
                                                    <RowDetail>
                                                        <Column lg={6}>
                                                            <Infos>
                                                                <Div>
                                                                    <Label>Colis</Label>
                                                                    <Info>{formatNumber(nbrColis)}</Info>
                                                                </Div>
                                                                <Div>
                                                                    <Label>Palette</Label>
                                                                    <Info>{formatNumber(nbrPalette)}</Info>
                                                                </Div>
                                                                <Div>
                                                                    <Label>Poids (kg)</Label>
                                                                    <Info>{formatNumber(poidskg)}</Info>
                                                                </Div>
                                                            </Infos>
                                                        </Column>
                                                        <Column lg={6}>
                                                            <Validate
                                                                type="button"
                                                                variant="secondary"
                                                                contain="contained"
                                                                disabled={!isVentePalette && !isVenteColis ? 'disabled' : ''}
                                                                loader={loading}
                                                                onClick={() => this._addToCart()}
                                                            >
                                                                Je commande !
                                                            </Validate>
                                                        </Column>
                                                    </RowDetail>
                                                </Column>
                                            </Row>
                                        </Inner>
                                    </Fiche>
                                }
                                {
                                    product === false &&
                                    <p>Ce produit n'est pas disponible</p>
                                }
                            </Column>
                        </Row>
                    </Content>
                    <Footer/>
                </Div>
            </ProductContext.Provider>
        )
    }
}

FicheProduitPage.contextType = UrlPrefixContext;

const mapStateToProps = state => {
    return {
        rayons: state.rayons.rayons,
        customer: state.customer.customer,
        availability: state.availability.availability
    }
}

export default connect(mapStateToProps)(FicheProduitPage);
