import React, {memo, useContext, useEffect, useState} from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import {connect} from "react-redux"

// App
import {UrlPrefixContext} from "../../../../context"
import {Container, Div} from "../../../Mixed/Composants"
import useCustomerStore from "../../../../reducers/CustomerStore";

const Bandeau = styled(Div)`
  display: flex;
  background: #ffcc00;
`

const Inner = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  @media screen and (max-width: 2000px) {
    height: 50px;
  }
`

const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  color: #404040;
  text-transform: uppercase;
  @media screen and (max-width: 2000px) {
    font-size: 18px;
  }
  @media screen and (max-width: 820px) {
    font-size: 16px;
  }
`

const Img = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  border-radius: 50%;
  @media screen and (max-width: 2000px) {
    max-width: 38px;
  }
`
const Promo = styled(Div)``
const PromoLink = styled.a`
  display: block;
  text-align: center;
`
const PromoImg = styled.img`
  object-fit: cover;
  max-width: 100%;
`

const Middle = memo(function MiddleHeader(props) {

    const context = useContext(UrlPrefixContext)
    const [promolink, setPromolink] = useState("")

    const customerStore = useCustomerStore()

    useEffect(() => {
        if (customerStore.customer && customerStore.customer.ClientToken) {
            setPromolink(`https://www.sedistradition.com/GESLOTWEBSEDIS?PARAM1=${customerStore.customer.CodeClient}&PARAM2=${customerStore.customer.ClientToken}&PARAM3=TOPDESVENTES`);
        }
    }, [customerStore.customer])

    return (
        <>
            <Bandeau className="front__middle-header">
                <Container fluid>
                    <Inner>
                        <Link to={`${context.prefix}/commande-rapide`}>
                            <Text>MA COMMANDE RAPIDE, C’EST ICI !</Text>
                            <Img src="/img/like.webp"/>
                        </Link>
                    </Inner>
                </Container>
            </Bandeau>
            <Promo>
                <PromoLink href={promolink} target="_blank">
                    <PromoImg src="/img/banniere-sedis-tradition.jpg"/>
                </PromoLink>
            </Promo>
        </>
    )

})

const mapStateToProps = state => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(Middle)
