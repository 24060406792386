import React from 'react';
import {useLocation} from 'react-router-dom';

// App
import Top from './TopHeader';
import Middle from './MiddleHeader';
import Bottom from './BottomHeader';
import GeslotApiDisponibility from '../GeslotApiDisponibility';
import {Section} from '../../../Mixed/Composants';
import FrontMessage from "../FrontMessage";
import useMaintenanceStore from "../../../../reducers/MaintenanceStore";

const MaintenanceMode = () => {
    const maintenance = useMaintenanceStore((state) => state.maintenance)
    return (
        <>
            {maintenance &&
                <div className={"h-100 w-100 d-flex align-items-center justify-content-center"}
                     style={{position: "fixed", zIndex: 99, background: "white"}}>
                    <b className={"h2 text-bold"}>Maintenance en cours ...</b>
                </div>
            }
        </>
    )
}

function Header(props) {

    const location = useLocation();

    return (
        <Section className="front__main-header" {...props}>
            <FrontMessage/>
            <MaintenanceMode/>
            <GeslotApiDisponibility/>
            <Top/>
            {!location.pathname.includes('commande-rapide') && <Middle/>}
            <Bottom/>
        </Section>
    )
}

export default Header