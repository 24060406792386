import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Select = React.forwardRef( (props, ref) => {

    let customProps = {};
    const { data, placeholder, value, fieldname, required, disabled, onChange } = props;

    if( fieldname ){
        customProps.name = fieldname;
    }

    if( required ){
        customProps.required = required;
    }

    if( disabled ){
        customProps.disabled = disabled;
    }

    if( onChange && typeof(onChange) == 'function' ){
        customProps.onChange = onChange;
    }

    return (
        <div className="form-group">
            <select className={ classNames('form-control', props.className) } value={ value || "DEFAULT" } ref={ref} {...customProps}>
                { placeholder && <option value="DEFAULT" disabled>{placeholder}</option> }
                {
                    data.map( (item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                    ))
                }
            </select>
        </div>
    )
});

Select.propTypes = {
    value : PropTypes.any,
    data : PropTypes.array.isRequired,
    onChange : PropTypes.func.isRequired,
    fieldname : PropTypes.string.isRequired
}

export default Select