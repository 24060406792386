import React from "react"
import {createBrowserHistory} from "history"
import {InputField, Modal, SelectField, CheckboxField} from "./FormComponents"
import "./notifications.css"
import connectApi from "../../../../services/helpers/connectApi"
import {HTTP_UNAUTHORIZED, prefixFront} from "../../../../constants"

const _ = require("lodash")

class CustomerSelector extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        /* used to populate select options */
        this.options = [
            {
                label: "Clients",
                type: "customers",
                icon: "fa-user",
                options: []
            },
            {
                label: "Clients refusant les notifications",
                type: "forbidden-customers",
                icon: "fa-user-lock",
                options: []
            },
            {
                label: "Clients sans numéros",
                type: "incorrect-customers",
                icon: "fa-phone-slash",
                options: []
            }
        ]
        this.state = {
            optionsCustomers: [],
            loading: true,
            displayFeedback: false
        }

        this.errorMessage = ""
        this.hasError = false

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    componentDidMount() {
        (async function () {
            const result = await connectApi("customers", "GET", null)

            if (typeof (result) !== "object") {
                return
            }
            if (result.hasOwnProperty("code") && result.code === HTTP_UNAUTHORIZED) {
                const history = createBrowserHistory()
                history.push(prefixFront + "/login")
                return
            }

            let customers = result["hydra:member"]
            customers.forEach((customer, index) => {
                if (!customer.hasOwnProperty("customerPhones") || !customer.hasOwnProperty("NotificationAccepted")) {
                    return
                }

                if (!customer.NotificationAccepted) {
                    this.options[1].options.push({
                        value: customer.CodeClient,
                        label: `${customer.CodeClient} : ${customer.NomDuClient}`,
                        isDisabled: true,
                        color: "#FF5630"
                    })
                    return
                }
                if (!customer.customerPhones.length) {
                    this.options[2].options.push({
                        value: customer.CodeClient,
                        label: `${customer.CodeClient} : ${customer.NomDuClient}`,
                        isDisabled: true,
                        color: "#FF5630"
                    })
                    return
                }

                this.options[0].options.push({
                    value: `${customer.CodeClient}|$|${customer.customerPhones[0].phone}|$|${customer.NotificationAccepted}`,
                    label: `${customer.CodeClient} : ${customer.NomDuClient}`,
                    color: "#36B37E"
                })
            })
            this.setState({loading: false})
        }).bind(this)()
    }

    handleSubmit(e) {
        (async function () {
            this.setState({loading: true})

            let customers = this.state.optionsCustomers
            let currentGroup = getCurrentGroup()

            if (currentGroup === null) {
                this.errorMessage = "Il faut d'abord sélectionner un groupe !"
                this.hasError = true
                displayFeedback.bind(this)()
                return
            }

            customers = _.map(customers, (c) => {
                return {
                    CodeClient: c.value.split("|$|")[0],
                    NomDuClient: c.label.split(" : ")[1],
                    // TelephoneNotifications: c.value.split("|$|")[1],
                    NotificationAccepted: c.value.split("|$|")[2]
                }
            })

            currentGroup.customerList = _.unionBy(currentGroup.customerList, customers, "CodeClient")

            const data = prepareDataForPutCall(currentGroup)

            // Send user input to API
            const result = await connectApi(`notification-groups/${currentGroup.id}`, "PUT", data)
            checkApiResult.bind(this)(result, "Groupe mis à jour", () => {
                updateCustomersList(currentGroup)
            })

            // clear Selected options
            this.setState({optionsCustomers: [], loading: false})
        }).bind(this)()
    }

    handleSelectChange(selectedOptions) {
        this.setState({
            optionsCustomers: selectedOptions
        })
    }

    render() {
        return <>
            {this.state.loading && (
                <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i></div>)}

            <div style={{zIndex: 2}}>
                <SelectField
                    className="formField reactSelect-custom"
                    classNamePrefix="reactSelect-custom"
                    options={this.options}
                    value={this.state.optionsCustomers}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    placeholder="Selectionne un ou plusieurs client(s)"
                    onChange={this.handleSelectChange}
                    optionFocusedBackground={"#66B82126"}
                    optionActiveBackground={"#66B82133"}
                    context={this}
                />

                <button type="button" className="btn btn-info"
                        title="Clique pour ajouter les destinataires aux groupe"
                        onClick={this.handleSubmit}>
                    <i className="fas fa-arrow-alt-circle-left"/> Ajouter
                </button>
            </div>

            {this.state.displayFeedback && (
                <div
                    className={`alert notification-group-feedback ${this.hasError ? "alert-danger" : "alert-success"}`}
                    role="alert">
                    {this.errorMessage}
                </div>
            )}
        </>
    }
}

class GroupList extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        this.state = {
            groups: [],
            displayAddForm: false,
            loading: true,
            displayFeedback: false,
            errorMessage: "",
            hasError: false,
            isModalShowing: false
        }

        this.groupToDelete = null

        this.addGroupInput = React.createRef()
        this.handleClick = this.handleClick.bind(this)
        this.handleAddClick = this.handleAddClick.bind(this)
        this.handleAddSubmit = this.handleAddSubmit.bind(this)
        this.handleRemoveClick = this.handleRemoveClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleAddformClose = this.handleAddformClose.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
    }

    componentDidMount() {
        (async function () {
            const result = await connectApi("notification-groups", "GET", null)

            if (!Array.isArray(result)) {
                return
            }

            this.setState({
                groups: result,
                loading: false
            })
        }).bind(this)()
    }

    handleAddClick() {
        this.setState({
            displayAddForm: true
        }, () => {
            this.addGroupInput.current.focus()
        })
    }

    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.handleAddSubmit()
        }
        if (event.key === "Escape") {
            this.handleAddformClose()
        }
    }

    handleAddformClose() {
        this.setState({
            displayAddForm: false
        })
    }

    handleAddSubmit() {
        (async function () {
            // default values
            this.hasError = false
            const groupName = this.addGroupInput.current.value
            this.errorMessage = `Le groupe '${groupName}' a été créé`
            this.setState({
                loading: true
            })

            // Send user input to API
            const result = await connectApi("notification-groups", "POST", {"name": groupName})

            if (result.hasOwnProperty("error") && result.error) {
                this.hasError = true
                this.errorMessage = result.message
            } else {
                this.handleAddformClose()
                //Add created group to the state
                this.setState((prevState) => ({
                    groups: [...prevState.groups, {
                        "id": result.id,
                        "name": result.name,
                        "customerList": result.customerList
                    }]
                }))
            }

            displayFeedback.bind(this)()

        }).bind(this)()
    }


    handleClick(e) {
        this.setState({loading: true})

        const selectedGroup = e.currentTarget
        clearSelection()
        selectedGroup.classList.add("selected")

        const groupId = parseInt(selectedGroup.dataset.id)
        let group = this.state.groups.filter((group) => group.id === groupId)
        updateCustomersList((group.length === 1) ? group[0] : null)
        this.setState({loading: false})
    }

    handleRemoveClick(e) {
        (async function () {
            this.setState({loading: true})

            const groupId = parseInt(e.currentTarget.dataset.id)

            // Send user input to API
            const result = await connectApi(`notification-groups/${groupId}`, "DELETE", null)
            checkApiResult.bind(this)(result, "Le groupe a bien été supprimé", () => {
                // Call form closeing function
                this.handleAddformClose()
                //delete group from the state
                this.setState((prevState) => ({
                    groups: prevState.groups.filter(function (e) {
                        return e.id !== groupId
                    })
                }))
                updateCustomersList(null)
                this.toggleModal()
                this.setState({loading: false})
            })

        }).bind(this)()
    }

    toggleModal(e) {
        this.groupToDelete = (e === undefined) ? null : e.currentTarget.dataset.id
        this.setState((prevState) => (
            {isModalShowing: !prevState.isModalShowing}
        ))
    }

    render() {
        return <>
            <div className="groups-managment-left bo-box-container">
                <h6>Groupes</h6>

                <div className="item-container">
                    {this.state.loading && (
                        <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i>
                        </div>)}
                    {this.state.groups.map(g => {
                        return <div key={`notification-group-${g.id}`} data-id={g.id}
                                    className="notification-group-item flex-column border-bottom"
                                    title="Configurer ce groupe" onClick={this.handleClick}>
                            <div className={'w-100 d-flex'}>
                                <div className="notification-group-item-title w-100">
                                    {g.name}
                                </div>
                                <button type="button" className="notification-group-remove btn btn-danger"
                                        data-id={g.id}
                                        onClick={this.toggleModal} title="Supprimer ce groupe">
                                    <i className="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    })}
                </div>
                {!this.state.displayAddForm && (
                    <div className="notification-group-add-button">
                        <button type="button" onClick={this.handleAddClick} className="btn btn-dark"
                                title="Clique pour ajouter ce nouveau groupe">
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                )}

                {this.state.displayAddForm && (
                    <div className="notification-group-add-form">
                        <AddGroupField ref={this.addGroupInput} onKeyDown={this.handleKeyPress}/>
                        <button type="button" onClick={this.handleAddSubmit} className="btn btn-dark"
                                title="Créer un nouveau groupe">
                            <i className="fas fa-plus"></i>
                        </button>
                        <span onClick={this.handleAddformClose} title="Fermer le formulaire"
                              className="notification-group-close absolute-top-right">
                            <i className="fas fa-times"></i>
                        </span>
                    </div>
                )}

                {this.state.displayFeedback && (
                    <div
                        className={`alert notification-group-feedback ${this.hasError ? "alert-danger" : "alert-success"}`}
                        role="alert">
                        {this.errorMessage}
                    </div>
                )}
                <Modal isShowing={this.state.isModalShowing} hide={this.toggleModal} title="Confirmer la suppression"
                       className="notification-group-confirm">
                    <button type="button" onClick={this.toggleModal} className="btn btn-secondary"
                            title="Annuler la suppression">
                        Finalement non
                    </button>
                    <button type="button" onClick={this.handleRemoveClick} className="btn btn-danger"
                            data-id={this.groupToDelete}
                            title="Confirmer la suppression">
                        Je veux supprimer le groupe <i className="fas fa-trash-can"></i>
                    </button>
                </Modal>
            </div>
        </>
    }
}

class CustomerList extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        this.state = {
            group: null,
            loading: false,
            displayFeedback: false
        }

        this.handleRemoveClick = this.handleRemoveClick.bind(this)
        // eslint-disable-next-line no-func-assign
        updateCustomersList = updateCustomersList.bind(this)
        // eslint-disable-next-line no-func-assign
        getCurrentGroup = getCurrentGroup.bind(this)
        this.setDefaultGroup = this.setDefaultGroup.bind(this)
    }

    setDefaultGroup() {
        const group = this.state.group

        if (group && !group.defaultGroup) {
            if (window.confirm('Voulez-vous que le groupe "' + group.name + '" soit le Groupe par défaut pour l\'envoi quotidien via SEDISDESTOCK ?')) {
                group.defaultGroup = true
                this.setState({group: group})
                connectApi("notification-groups/set-default/" + group.id, "GET", null)
            }
        }
    }

    handleRemoveClick(e) {
        (async function () {
            this.setState({loading: true})

            // default values
            this.hasError = false
            this.errorMessage = ``
            const customerId = e.currentTarget.dataset.id
            let currentGroup = getCurrentGroup()

            _.remove(currentGroup.customerList, (elt) => {
                return elt.CodeClient === customerId
            })

            const data = prepareDataForPutCall(currentGroup)
            // Send user input to API
            const result = await connectApi(`notification-groups/${currentGroup.id}`, "PUT", data)
            checkApiResult.bind(this)(result, "Groupe mis à jour", () => {
                if (result.hasOwnProperty("customerList")) {
                    updateCustomersList(result)
                }
                this.setState({loading: false})
            })
        }).bind(this)()
    }

    render() {
        return <>
            <div className="groups-managment-right bo-box-container">
                <h6>Clients</h6>
                {this.state.group !== null && (
                    <span
                        className="customer-group-infos absolute-top-right"> {this.state.group.name} ({this.state.group.customerList.length} clients)</span>
                )}
                <div className="item-container">
                    {this.state.loading && (
                        <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i></div>
                    )}
                    {this.state.group !== null && this.state.group.customerList.map(c => {
                        return <div key={`customer-group-${c.CodeClient}`} data-id={c.CodeClient}
                                    className={`customer-group-item ${(!c.NotificationAccepted || (!c.customerPhones || (c.customerPhones && c.customerPhones.legnth === 0))) ? "customer-group-item--disabled" : ""}`}>
                        <span className="customer-group-item-title">
                            {`${c.CodeClient} : ${c.NomDuClient}`}
                            <span className="customer-disabled-message">
                                {!c.NotificationAccepted && " (Notifications refusées)"}
                                {(c.NotificationAccepted && (!c.customerPhones || (c.customerPhones && c.customerPhones.length === 0))) && " (aucun numéro de tél.)"}
                            </span>
                        </span>
                            <button type="button" className="customer-group-remove btn btn-danger"
                                    data-id={c.CodeClient}
                                    title="Supprimer le client du groupe"
                                    onClick={this.handleRemoveClick}><i className="fas fa-trash"></i></button>
                        </div>
                    })}
                </div>
                <div>
                    {this.state.group !== null && (
                        <div className="d-flex align-items-center justify-content-center"
                             style={{fontSize: 0.75 + 'rem'}}>
                            <CheckboxField onChange={this.setDefaultGroup}
                                           name={'default-group'}
                                           value={this.state.group.id}
                                           checked={this.state.group.defaultGroup}
                                           children={'Groupe par défaut pour l\'envoi quotidien via SEDISDESTOCK'}/>
                        </div>
                    )}
                </div>
                {this.state.displayFeedback && (
                    <div
                        className={`alert notification-group-feedback ${this.hasError ? "alert-danger" : "alert-success"}`}
                        role="alert">
                        {this.errorMessage}
                    </div>
                )}
            </div>
        </>
    }
}

/* ================================== EXPORTED COMPONENTS ================================== */
const exported = {
    CustomerList,
    GroupList,
    CustomerSelector
}
export default exported

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 * Check if Api result contains an error and display a feedback message.
 * You can additionally set a message to display and a callback function that will be executed if the result does not contains any error
 *
 * @param result {Object}
 * @param defaultMessage {string}
 * @param callback {?function}
 */
function checkApiResult(result, defaultMessage = "updated", callback = null) {

    if (result.hasOwnProperty("@type") && result["@type"] === "hydra:Error") {
        this.hasError = true
        this.errorMessage = result["hydra:description"]
    } else {
        this.errorMessage = defaultMessage
        this.hasError = false
        if (callback !== null && typeof callback === "function") {
            callback()
        }
    }
    displayFeedback.bind(this)(1000)
}

/**
 * Display an alert for 2 sec
 */
function displayFeedback(timeout = 2000) {
    //End of the script : display feedback
    this.setState({
        displayFeedback: true,
        loading: false
    })

    // Hide Feedback message ater 2sec
    window.setTimeout(() => {
        this.setState({
            displayFeedback: false
        })
    }, timeout)
}

/**
 * Clear all 'selected' classes for notification group items list
 */
function clearSelection() {
    const selectedGroups = document.querySelectorAll("div.notification-group-item.selected")
    selectedGroups.forEach(g => {
        g.classList.remove("selected")
    })
}

/**
 * Global function called by GroupList component to update CustomerList component
 *
 * @param groupObject {Object}
 * @param apiCall {boolean}
 */
function updateCustomersList(groupObject) {
    this.setState({loading: true})

    connectApi("notification-groups/" + groupObject.id, "GET", null).then((group) => {
        this.setState({group: group}, () => {
            this.setState({loading: false})
        })
    })
}

function prepareDataForPutCall(currentGroup) {
    let data = {"customerList": []}
    currentGroup.customerList.forEach(function (e) {
        data.customerList.push(`/api/customers/${e.CodeClient}`)
    })
    return data
}

/**
 * Global function called to get the current group state of the CustomerList component
 */
function getCurrentGroup() {
    return this.state.group
}

/**
 * Forward ref for add group field : we don't want this field to be controled by react because we need to do some process
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const AddGroupField = React.forwardRef(function AddGroupForm(props, ref) {
    return <InputField type="text" name="notificationGroup-name" title="Saisis le nom du groupe"
                       required={true} forwardedRef={ref} onKeyDown={props.onKeyDown}>Nom du groupe</InputField>
})

