import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import styled from 'styled-components';

// App
import { Button } from './Forms';
import { downloadCustomerInvoice } from '../../../services/helpers/FrontApiHelper';

const Download = styled(Button)`
    font-size: 16px !important;
    line-height: 46px !important;
    padding: 0px 40px !important;
`;

const SpecificDownload = styled(Button)`
    font-size: 16px !important;
    line-height: 25px !important;

`;

function DownloadInvoice(props){

    const [loader, setLoader] = useState(false);

    const _downloadInvoice = () => {
        const { codeFacture } = props;
        setLoader(true);
        downloadCustomerInvoice(codeFacture)
            .then( response => {
                fileDownload(response, `Facture-${codeFacture}.pdf`);
            } )
            .finally( () => setLoader(false) )
            .catch( e => console.log(e) )
    }

    const { labelButton, isSpecificButton } = props;
    return (
        isSpecificButton
        ?
        <SpecificDownload type="button" variant="secondary" loader={loader} contain="outlined" onClick={ () => _downloadInvoice() }>{ labelButton ? labelButton : 'Télécharger' }</SpecificDownload>
         :
        <Download type="button" variant="secondary" loader={loader} contain="outlined" onClick={ () => _downloadInvoice() }>{ labelButton ? labelButton : 'Télécharger' }</Download>
    )
}

export default DownloadInvoice;