import React, {Component} from "react"
import {Router} from "react-router-dom"
import {createBrowserHistory} from "history"
import {
    prefixBack,
    prefixFront,
    ROLE_MAINTAINER_SEDIS,
    ROLE_REDACTOR_BO,
    ROLE_SALESMANAGER_BO,
    ROLE_USER_BO
} from "../../../constants"
import {isGranted} from "../../../services/helpers/RolesHelper"
import PropTypes from "prop-types"
import _ from "lodash"
import * as packageInfo from "../../../../package.json"

import "./Menu.css"

const newHistory = createBrowserHistory()
const packageInformation = packageInfo

export const DashboardMenu = (props) => {
    return (
        <li className="nav-item has-treeview" role="menuitem">
            {generateMenuItem("/dashboard", "nav-icon--parent fas fa-tachometer-alt", "Tableau de bord", (props === "dashboard"))}
        </li>
    )
}

export const AdministrationMenu = (props) => {
    let isOpen = ""
    if (_.includes(["lastActivityUsers", "maximumCommandable", "MinimumCommandable", "CompareGeslotShopAPI", "SelectionDuMoment"], props)) {
        isOpen = "menu-open"
    }
    return <li className={`nav-item has-treeview ${isOpen}`} role="menuitem">
        <button type="button" className="nav-link">
            <i className="nav-icon nav-icon--parent fas fa-copy"/>
            <p>
                Administration
                <i className="fas fa-angle-left right"/>
            </p>
        </button>
        <ul className="nav nav-treeview" role="menu">
            <li className="nav-item" role="menuitem">
                {generateMenuItem("/lastactivityusers", "fas fa-eye", "Dernières activités", (props === "lastActivityUsers"))}
            </li>
            <li className="nav-item" role="menuitem">
                {generateMenuItem("/maximumcommandable", "fas fa-arrows-up-to-line", "Maximum commandable", (props === "maximumCommandable"))}
            </li>
            <li className="nav-item" role="menuitem">
                {generateMenuItem("/montantmincommandable", "fas fa-arrows-down-to-line", "Minimum Commandable", (props === "MinimumCommandable"))}
            </li>
            <li className="nav-item" role="menuitem">
                {generateMenuItem("/products-geslot-shopapi", "fas fa-chart-column", "Statistique produits", (props === "CompareGeslotShopAPI"))}
            </li>
            <li className="nav-item" role="menuitem">
                {generateMenuItem("/selection-du-moment", "fas fa-comment", "Sélection du moment", (props === "SelectionDuMoment"))}
            </li>
        </ul>
    </li>
}

export const NotificationMenu = (props) => {
    let isOpen = ""
    if (_.includes(["NotificationForm", "NotificationSedis", "NotificationGroupsForm", "NotificationHistory"], props)) {
        isOpen = "menu-open"
    }
    return <li className={`nav-item has-treeview ${isOpen}`} role="menuitem">
        <button type="button" className="nav-link">
            <i className="nav-icon nav-icon--parent fab fa-whatsapp"/>
            <p>
                Notifications
                <i className="right fas fa-angle-left"/>
            </p>
        </button>
        <ul className="nav nav-treeview" role="menu">
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/notifications", "fas fa-square-pen", "Envoyer un message", props === "NotificationForm", "Créer un nouveau message Whatsapp")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/notifications-sedis", "fas fa-gauge-high", "Notifications SEDIS", props === "NotificationSedis", "Consulter les notifications programmées sur SEDISDESTOCK")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/notifications-groups", "fas fa-users-viewfinder", "Gérer les groupes", props === "NotificationGroupsForm", "Gérer les groupes et destinataires des messages Whatsapp")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/notifications-history", "fas fa-table-list", "Historique des envois", props === "NotificationHistory", "Consulter l'historique d'envois des messages avec leur status")}
            </li>
        </ul>
    </li>
}
export const CartMenu = (props) => {
    let isOpen = ""
    if (_.includes(["activePanier", "historiquePanier"], props)) {
        isOpen = "menu-open"
    }
    return <li className={`nav-item has-treeview ${isOpen}`} role="menuitem">
        <button type="button" className="nav-link">
            <i className="nav-icon nav-icon--parent fas fa-cart-shopping"/>
            <p>
                Paniers
                <i className="right fas fa-angle-left"/>
            </p>
        </button>
        <ul className="nav nav-treeview" role="menu">
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/activpanier", "fas fa-cart-arrow-down", "Liste des paniers actifs", props === "activePanier")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/historiquepanier", "fas fa-list-check", "Historique des paniers", props === "historiquePanier")}
            </li>
        </ul>
    </li>
}

export const UsersMenu = (props) => {
    let nameClass = "nav-item has-treeview"
    if (_.includes(["UtilisateurSEDIS"], props)) {
        nameClass = "nav-item has-treeview menu-open"
    }
    return (
        <li className={nameClass} role="menuitem">
            <button type="button" className="nav-link">
                <i className="nav-icon nav-icon--parent fas fa-users-cog"/>
                <p>
                    Utilisateurs
                    <i className="right fas fa-angle-left"/>
                </p>
            </button>
            <ul className="nav nav-treeview" role="menu">
                <li className="nav-item" role="menuitem">
                    {generateMenuItem("/users-sedis", "fas fa-users", "Gestion utilisateurs BO", (props === "UtilisateurSEDIS"))}
                </li>
            </ul>
        </li>
    )
}


export const SystemMenu = (props) => {
    let isOpen = ""
    if (_.includes([
        "TotalGardeFou",
        "CustomerInvalid",
        "ProduitVarianteInvalid",
        "FamilyInvalid", "LogErreur",
        "historiqueConnexion",
        "Message",
        "Maintenance"
    ], props)) {
        isOpen = "menu-open"
    }
    return <li className={`nav-item has-treeview ${isOpen}`} role="menuitem">
        <button type="button" className="nav-link">
            <i className="nav-icon nav-icon--parent fas fa-tools"/>
            <p>
                Système
                <i className="right fas fa-angle-left"/>
            </p>
        </button>
        <ul className="nav nav-treeview" role="menu">
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/historiqueconnexion", "fas fa-user-lock", "Historique connexions", props === "historiqueConnexion")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/totalgardefou", "fas fa-shield-halved", "Garde-fou", props === "TotalGardeFou")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/logerror", "fas fa-circle-exclamation", "Erreurs API", props === "LogErreur")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/message", "fas fa-message", "Message", props === "Message")}
            </li>
            <li className="nav-item has-treeview" role="menuitem">
                {generateMenuItem("/maintenance", "fas fa-cog", "Mode maintenance", props === "Maintenance")}
            </li>
        </ul>
    </li>
}

export function AccountMenu(props, logoutFunction) {
    let nameClass = "nav-item has-treeview"
    if (_.includes(["userProfile"], props)) {
        nameClass = "nav-item has-treeview menu-open"
    }
    return (
        <li className={nameClass} role="menuitem">
            <button type="button" className="nav-link">
                <i className="nav-icon nav-icon--parent fas fa-user"/>
                <p>
                    Mon compte
                    <i className="right fas fa-angle-left"/>
                </p>
            </button>
            <ul className="nav nav-treeview" role="menu">
                <li className="nav-item has-treeview" role="menuitem">
                    {generateMenuItem("/profile", "fas fa-user-gear", "Profil", props === "userProfile")}
                </li>
                <li className="nav-item has-treeview" role="menuitem">
                    <button type="button" onClick={logoutFunction} className="nav-link">
                        <i className="fas fa-power-off nav-icon"/>
                        <p>Déconnexion</p>
                    </button>
                </li>
            </ul>
        </li>
    )
}

export function SalesMenu(props) {
    let nameClass = "nav-item has-treeview"
    if (_.includes(["saleItem"], props)) {
        nameClass = "nav-item has-treeview menu-open"
    }
    return (
        <li className={nameClass} role="menuitem">
            <button type="button" className="nav-link">
                <i className="nav-icon nav-icon--parent fas fa-credit-card"/>
                <p>
                    Magasins
                    <i className="right fas fa-angle-left"/>
                </p>
            </button>
            <ul className="nav nav-treeview" role="menu">
                <li className="nav-item has-treeview" role="menuitem">
                    {generateMenuItem("/suivi-achats", "fas fa-cart-flatbed", "Suivi achats", props === "saleItem")}
                </li>
            </ul>
        </li>
    )
}

export function generateMenuItem(link, className, label, isActive = false, title = null) {
    return <a href={prefixBack + link} title={title ?? label}
              className={`nav-link ${isActive ? "custom-active" : ""}`}>
        <i className={`${className} nav-icon`}/>
        <p>{label}</p>
    </a>
}

export default class Menu extends Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        this.currentState = JSON.parse(localStorage.getItem("state"))
        this.user = this.currentState.user
        this.state = {
            isAuthenticated: this.currentState.isAuthenticated,
            token: this.currentState.token,
            user: {
                id: this.user.id,
                email: this.user.email,
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                role: this.user.role,
                isCustomer: this.user.isCustomer,
                CodeClient: this.user.CodeClient
            }
        }
        this.logout = this.logout.bind(this)
    }

    logout = () => {
        localStorage.removeItem("state")
        localStorage.removeItem("errorsDuplicationPanier")
        newHistory.push(`${prefixFront}/login`)
        document.location.reload()
    }

    render() {
        return (
            <Router history={newHistory}>
                <div>
                    <aside className="main-sidebar sidebar-dark-primary elevation-4">
                        <div className="sidebar">
                            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                                <div className="info">
                                    <span className="bo-title">
                                        Back office - SEDIS Alim.
                                    </span>
                                </div>
                            </div>
                            <nav className="mt-2">
                                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    {isGranted(this.state.user.role, ROLE_MAINTAINER_SEDIS) && DashboardMenu(this.props.nameMenu)}
                                    {isGranted(this.state.user.role, ROLE_MAINTAINER_SEDIS) && AdministrationMenu(this.props.nameMenu)}
                                    {isGranted(this.state.user.role, ROLE_MAINTAINER_SEDIS) && CartMenu(this.props.nameMenu)}
                                    {isGranted(this.state.user.role, ROLE_REDACTOR_BO) && NotificationMenu(this.props.nameMenu)}
                                    {isGranted(this.state.user.role, ROLE_MAINTAINER_SEDIS) && UsersMenu(this.props.nameMenu)}
                                    {isGranted(this.state.user.role, ROLE_MAINTAINER_SEDIS) && SystemMenu(this.props.nameMenu)}
                                    {isGranted(this.state.user.role, ROLE_SALESMANAGER_BO) && SalesMenu(this.props.nameMenu)}
                                    {isGranted(this.state.user.role, ROLE_USER_BO) && AccountMenu(this.props.nameMenu, this.logout)}
                                </ul>
                            </nav>
                        </div>
                        <div className="bo-version-number"> Bo - {packageInformation.boVersion}</div>
                    </aside>
                </div>
            </Router>
        )
    }
}

Menu.propTypes = {
    nameMenu: PropTypes.string.isRequired
}
