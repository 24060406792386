import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {ClipLoader} from 'react-spinners';

// App
import InputDebounce from './InputDebounce';
import {envVars} from '../../../env_var';
import {UrlPrefixContext} from '../../../context';
import {
    getUnitPrice,
    getPiceGrid,
    getPiceGridNormal,
    formatCurrency,
    formatDate,
    applyProductMaximumCommandale
} from '../../../services/helpers/FrontHelper';
import {
    createOrderLine,
    updateOrderLine,
    deleteOrderLine,
    getCustomerInfo
} from '../../../services/helpers/FrontApiHelper';
import {Lightbox} from '../../Mixed/Composants';
import {getDefaultImage} from '../../../services/helpers/FrontHelper';


const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GoToFiche = styled(Link)`
  display: block;
  max-width: 90%;

  &:hover,
  &:focus,
  &:active {
    span {
      color: #ff5d1f
    }

    img {
      opacity: .8;
    }
  }
`;

const PictoWrap = styled.div`
  position: relative;
  max-width: 20px;
`;

const Picto = styled.img`
  padding: 0;
  margin: 0;
  max-width: 20px;

  &.remiseVolume {
    position: absolute;
    right: 5px;
    top: 9px;
    max-width: 10px;
  }
`;

const Thumbnail = styled.figure`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin: 0;

  &.has-no-image {
    background: #ffffff;
    padding: 5px;
  }
`;

const Loader = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .8);
  display: ${props => (props.loading === 'true') ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  max-width: 100%;
  max-height: 95px;
  transition: all 0.2s ease;
  z-index: 800;
  @media only screen and (min-width: 1000px) {
    &:hover {
      transform: scale(2);
    }
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  font-style: italic;
  font-size: .8rem;
`;

const Notice = styled.span`
  color: #17a2b8;
  display: block;
  font-style: italic;
  font-size: .8rem;
`;

const TipRemise = styled.div`
  display: ${props => props.displayed ? 'block' : 'none'};
  margin-top: 10px;

  span {
    background: #66b821;
    color: #ffffff;
    padding: 5px;
    font-size: 14px;
    line-height: 1.2;
    opacity: 0.95;
    display: block;
  }
`;

const Text = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #404040;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
  }
`;

const Input = styled(InputDebounce)`
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  height: 50px;
  border: 2px solid ${props => props.disabled ? '#404040' : '#ff5d1f'};
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 50%);
  border-radius: 6px;
  font-weight: 700;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    height: 43px;
  }
`;

const Total = styled.span`
  flex: 1;
  height: 50px;
  border-radius: 6px;
  border: solid 2px #404040;
  background-color: #ffffff;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0 auto;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    height: 43px;
  }
`;

const Tr = styled.tr`
  position: relative;
`;


const Td = styled.td`
  position: relative;
  text-align: center;
  background: ${props => props.disabled === 'disabled' ? '#f2d2c1' : props.disabled === 'added' ? '#b3e0b3' : '#dedddd'};
  border-bottom: 3px solid #eeede4;
  padding: 24px 15px;

  &:nth-child(2) {
    text-align: left;
    padding-left: 0;
  }

  &:not(:first-child):before {
    content: "";
    display: block;
    width: 1px;
    height: 72%;
    position: absolute;
    left: -1px;
    z-index: 99;
    background: #fff;
    top: 14%;
  }

  &:nth-child(2):before {
    display: none;
  }

  @media screen and (max-width: 2000px) {
    padding: 15px;
    &:not(:first-child):before {
      height: 68%;
      top: 16%;
    }
  }
`;


class CommandeRapideItem extends React.Component {

    _tmpProduct = null;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            quantite: 0,
            unitPrice: 0,
            priceTotal: 0,
            stockValue: 0,
            added: false,
            loading: false,
            disabled: false,
            updatedStock: false,
            quantityNotZero: false,
            texteRemise: '',
            priceNormal: 0,
            priceRemise: 0
        }
    }

    _getCustomerInfo = (customer, dataProductDetailCommandee) => {
        return getCustomerInfo(customer.user.CodeClient)
            .then(response => {
                if (response) {
                    const info = {
                        MontantEncoursUtilise: response.MontantEncoursUtilise,
                        MontantEncoursAutoriseFixe: response.MontantEncoursAutoriseFixe,
                        MontantEncoursAutoriseFlexible: response.MontantEncoursAutoriseFlexible,
                        DelaiEchanceFacture: response.DelaiEchanceFacture,
                    };
                    let dataCustomerWithProductCommandee = {...customer, ...dataProductDetailCommandee};
                    const data = {...dataCustomerWithProductCommandee, ...info};
                    const DelaiEchanceFacture = data.DelaiEchanceFacture;
                    const encours_autorise = data.MontantEncoursAutoriseFixe;
                    const encours_autorise_flexible = data.MontantEncoursAutoriseFlexible;
                    const encours_utilise = data.MontantEncoursUtilise;
                    const encours_restent = encours_autorise - encours_utilise;
                    const encours_restent_flexible = encours_autorise_flexible - encours_utilise;
                    // let montantHT = this._getOrderPriceTotal();
                    let MontantHTArticleCommandee = parseFloat(data.priceTotal);

                    let compteurDepassementEncoursFlexible = localStorage.getItem('compteurDepassementEncoursFlexible');
                    if (compteurDepassementEncoursFlexible == null && compteurDepassementEncoursFlexible === undefined) {
                        compteurDepassementEncoursFlexible = 0;
                    }

                    // montantHT += MontantHTArticleCommandee;
                    let diffAmountArticleCommandee = MontantHTArticleCommandee;
                    const products = this._getLocalProducts();
                    if (products && products.length) {
                        products.forEach((item) => {
                            if (item.CodeArticle === data.CodeArticle) {
                                // montantHT -= parseFloat(item.priceTotal);
                                diffAmountArticleCommandee -= parseFloat(item.priceTotal);
                            }
                        });
                    }

                    diffAmountArticleCommandee = diffAmountArticleCommandee < 0 ? -1 * diffAmountArticleCommandee : diffAmountArticleCommandee
                    this.props.dispatch({type: 'SET_CUSTOMER', value: data});

                    if (DelaiEchanceFacture > 0) {
                        toast("Vous avez des factures non-réglées ayant dépassé votre délai de paiement de x jours, merci de régulariser la situation pour pouvoir commander de nouveau !", {
                            type: 'error',
                            autoDismiss: true
                        });
                        return false;
                    }

                    if (encours_restent <= 0 || (diffAmountArticleCommandee > encours_restent && diffAmountArticleCommandee > encours_restent_flexible)) {
                        toast("Votre encours est insuffisant pour passer une commande !", {
                            type: 'error',
                            autoDismiss: true
                        });
                        return false
                    } else if (diffAmountArticleCommandee < encours_restent && encours_autorise_flexible > 0 && diffAmountArticleCommandee > encours_restent_flexible) {
                        localStorage.setItem('compteurDepassementEncoursFlexible', parseInt(compteurDepassementEncoursFlexible) + 1);
                        compteurDepassementEncoursFlexible = localStorage.getItem('compteurDepassementEncoursFlexible');
                        if (compteurDepassementEncoursFlexible === 2) {
                            localStorage.setItem('firstMontantHTDepassantEncoursFlexible', parseFloat(diffAmountArticleCommandee));
                        }
                        if (compteurDepassementEncoursFlexible > 1) {
                            let firstMontantHTDepassantEncoursFlexible = parseFloat(localStorage.getItem('firstMontantHTDepassantEncoursFlexible'));
                            if (diffAmountArticleCommandee > firstMontantHTDepassantEncoursFlexible) {
                                toast("Votre encours est insuffisant pour passer une commande !", {
                                    type: 'error',
                                    autoDismiss: true
                                });
                                return false;
                            }
                            return true;
                        }
                        return true;
                    }
                    return true
                }
                return false
            })
    }

    async _createOrderLine(data, stockAjuste = false) {
        const {customer} = this.props;
        const orderLine = this._getLocalOrderLine();
        const qtMAxCom = applyProductMaximumCommandale(data);

        if (qtMAxCom) {
            data = this._getProductDetail(qtMAxCom);
        }

        this._addProductToLocal(data);

        const response = await createOrderLine(data, customer, orderLine);

        if (response.code === 0) {
            this._deleteStorage('frontOrderProducts', data);
            this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
            console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${data.Libelle})`)
            return false;
        } else if (response.code === 400 || response.code === 403) {
            this._deleteStorage('frontOrderProducts', data);
            this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
            console.error(response.message);
            return false;
        } else if (response.code === 401) {
            this._deleteStorage('frontOrderProducts', data);
            this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
            console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${data.Libelle})`)
            return false;
        }
        if (response.code === 480) {
            const LeStock = response.data.varianteArticle.LeStock;
            if (LeStock === 0) {
                this._deleteStorage('frontOrderProducts', data);
                this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
                console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${data.Libelle})`)
            } else {
                this._deleteStorage('frontOrderProducts', data);
                const stock = this._getProductDetail(LeStock);
                this._createOrderLine(stock, LeStock !== data.Quantite);
            }
            return false;
        } else {
            const products = this._getLocalProducts();
            if (products.length === 0 || products.length === 1) {
                const orderLineData = {
                    "id": response.id,
                    "OrderId": response.OrderId,
                    "OrderLineId": response.OrderLineId,
                    "CodeBP": response.CodeBP,
                }
                this._storeToStorage('frontOrderLine', orderLineData);
            }

            if (!response.hasOwnProperty('CodeArticle')) {
                this._deleteStorage('frontOrderProducts', data);
                this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
                console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${data.Libelle})`)
                return false;
            }
            if (stockAjuste) {
                this.setState({
                    quantite: data.Quantite,
                    updatedStock: true,
                    stockValue: data.Quantite
                })
            }
            if (this._tmpProduct) {
                const stock = {...this._tmpProduct, orderLine: response};
                this._updateStorage('frontOrderProducts', stock);
                this._updateOrderLine(stock);
            } else {
                this.setState({loading: false, added: true}, () => this._updateStorage('frontOrderProducts', {
                    ...data,
                    orderLine: response
                }));
            }
        }
    }

    async _updateOrderLine(data) {
        // const { customer }= this.props;
        const product = this._getProductFromStorage(data);
        const qtMAxCom = applyProductMaximumCommandale(data)

        if (qtMAxCom) {
            data = this._getProductDetail(qtMAxCom);
        }

        if (product && product.hasOwnProperty('orderLine')) {
            const order = {...product, ...data};
            const response = await updateOrderLine(order.orderLine.id, order);

            if (response.code === 0) {
                this._deleteStorage('frontOrderProducts', data);
                this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
                console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${data.Libelle})`)
                return false;
            } else if (response.code === 400 || response.code === 403) {
                this._deleteStorage('frontOrderProducts', data);
                this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
                console.error(response.message);
                return false;
            } else if (response.code === 401) {
                this._deleteStorage('frontOrderProducts', data);
                this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
                console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${order.Libelle})`)
                return false;
            } else if (response.code === 480) {
                const LeStock = response.data.varianteArticle.LeStock;
                if (LeStock === 0) {
                    this._deleteOrderLine(data);
                    this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
                    console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${order.Libelle})`)
                } else {
                    const stock = this._getProductDetail(LeStock);
                    const item = {...product, ...stock};
                    this.setState({
                        added: true,
                        quantite: LeStock,
                        updatedStock: true,
                        stockValue: LeStock
                    }, () => {
                        this._reUpdateOrderAfterDissatisfiedStock(LeStock, item.orderLine);
                    });
                }
                return false;
            } else {
                this._tmpProduct = null;
                if (!response.hasOwnProperty('CodeArticle')) {
                    this._deleteStorage('frontOrderProducts', data);
                    this.setState({disabled: true, loading: false, quantite: 0, priceTotal: 0});
                    console.error(`Erreur technique dans la gestion du stock. Ce produit n'est pas disponible. (${data.Libelle})`)
                    return false;
                }
                this.setState({loading: false, added: true}, () => this._updateStorage('frontOrderProducts', {
                    ...order,
                    orderLine: response
                }));
            }
        } else {
            this._tmpProduct = data;
        }

    }

    _getOrderPriceTotal = () => {
        let total = 0;
        const products = this._getLocalProducts();
        if (products && products.length) {
            products.forEach((item) => {
                total += parseFloat(item.priceTotal)
            });
        }
        return total
    }

    _deleteOrderLine = (data) => {
        const product = this._getProductFromStorage(data);
        if (product && product.hasOwnProperty('orderLine')) {
            deleteOrderLine(product.orderLine.id)
                .then((response) => {
                    if (!response.hasOwnProperty('trace')) {
                        this._deleteStorage('frontOrderProducts', data);
                    }
                })
                .finally(() => this.setState({loading: false, added: false}))
        }
    }

    _reUpdateOrderAfterDissatisfiedStock = (quantite, response) => {
        const detail = this._getProductDetail(quantite);
        const item = {...detail, orderLine: response, geslotStockIs: quantite};
        this._updateStorage('frontOrderProducts', item);
        this._updateOrderLine(item);
        this.setState({
            quantite: item.Quantite,
            priceTotal: item.priceTotal
        })
    }

    async _handleInputOnChange(value) {

        const {customer, availability} = this.props;
        const quantite = parseInt(value);
        const data = this._getProductDetail(quantite);
        const product = this._getProductFromStorage(data);

        if (availability && availability.previousStateGeslotIsAvailable === false) {
            toast("Les commandes sont temporairement indisponibles.", {
                type: 'error',
                autoDismiss: true
            });
            return false;
        }

        this.setState({loading: true})
        if (customer && customer.isAuthenticated && customer.user.role === "ROLE_CUSTOMER") {
            const status = await this._getCustomerInfo(customer, data);
            if (!status) {
                this.setState({
                    loading: false
                })
                return false
            }
        }

        if (!quantite && quantite !== 0) {
            this.setState({quantityNotZero: true});
            console.error('La quantité doit être supérieure à 0');
            return false;
        } else if (quantite === 0) {
            this._deleteOrderLine(data);
        } else if (product) {
            this._updateOrderLine(data);
        } else if (product === null) {
            this._createOrderLine(data);
        }
        this.setState({
            // loading : true,
            disabled: false,
            updatedStock: false,
            quantityNotZero: false,
            // quantite : data.Quantite,
            priceTotal: data.priceTotal,
        })
    }

    _addProductToLocal = (data) => {
        let products = this._getLocalProducts();
        if (products) {
            products.push(data);
        }
        this._storeToStorage('frontOrderProducts', products);
    }

    _persistDataToStore = (data) => {
        let value = [];
        if (data.length) {
            data.forEach(item => {
                if (item.hasOwnProperty('orderLine')) {
                    value.push(item);
                }
            })
            this.props.dispatch({type: 'SET_ORDER', value: value});
            console.info('Update store', value.length)
        } else {
            this.props.dispatch({type: 'SET_ORDER', value: []});
        }
    }

    _storeToStorage = (name, data) => {
        const {onUpdate} = this.props;
        if (!name || !data) {
            return false;
        }
        localStorage.setItem(name, JSON.stringify(data));
        if (typeof (onUpdate) === 'function') {
            onUpdate();
        }
        if (name === 'frontOrderProducts') {
            this._persistDataToStore(data);
        }
    }

    _deleteStorage = (name, value) => {
        if (!name || !value) {
            return false;
        }
        const tmp = [];
        const data = name === 'frontOrderProducts' ? this._getLocalProducts() : this._getLocalOrderLine();
        data.forEach(item => {
            if (name === 'frontOrderProducts' && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                // item = value;
                return false;
            }
            if (name === 'frontOrderLine' && item.OrderId === value.OrderId) {
                // item = value;
                return false
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp);

    }

    _updateStorage = (name, value) => {
        if (!name || !value) {
            return false;
        }
        const tmp = [];
        const data = name === 'frontOrderProducts' ? this._getLocalProducts() : this._getLocalOrderLine();
        data.forEach(item => {
            if (name === 'frontOrderProducts' && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                item = value;
            }
            if (name === 'frontOrderLine' && item.OrderId === value.OrderId) {
                item = value;
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp);
    }

    _getLocalOrderLine = () => {
        const storage = localStorage.getItem('frontOrderLine');
        return storage != null ? JSON.parse(storage) : null;
    }

    _getLocalProducts = () => {
        const storage = localStorage.getItem('frontOrderProducts');
        return storage != null ? JSON.parse(storage) : [];
    }

    _getProductFromStorage = (data) => {
        let product = null;
        const products = this._getLocalProducts();
        products.forEach(item => {
            if (item.CodeVarianteArticle === data.CodeVarianteArticle) {
                product = item;
            }
        })
        return product;
    }

    _getProductDetail = (quantite) => {
        let priceTotal;
        const {product, variant, customer} = this.props;
        const priceGrid = getPiceGrid(variant, customer, quantite);
        const unitPrice = getUnitPrice(priceGrid, product.PCB, true);
        if (product.UniteDeVente === 'K' && product.PoidsColis > 0) {
            priceTotal = priceGrid * product.PoidsColis * quantite;
        } else if (product.UniteDeVente === 'K' && product.PoidsColis === 0) {
            priceTotal = 0.00;
        } else {
            priceTotal = unitPrice * product.PCB * quantite;
        }
        priceTotal = Number.isInteger(priceTotal) ? priceTotal : parseFloat(priceTotal).toFixed(2);
        const response = {
            PCB: product.PCB,
            Tags: product.Tags,
            Quantite: quantite,
            PU: parseFloat(unitPrice),
            PUsimple: parseFloat(this.priceNormal),
            SeuilRemiseVolume: variant.SeuilRemiseVolume,
            priceTotal: priceTotal,
            CodeTVA: product.CodeTVA,
            Libelle: product.Libelle,
            PriceGrid: priceGrid,
            PathPhoto: product.PathPhoto,
            FamilleID: product.FamilleID,
            DlcOrDluo: product.DlcOrDluo,
            PoidsColis: product.PoidsColis,
            CodeArticle: product.CodeArticle,
            UniteDeVente: product.UniteDeVente,
            PoidsUnitaire: product.PoidsUnitaire,
            PoidsVariable: product.PoidsVariable,
            MaxOrderEnabled: product.MaxOrderEnabled,
            NbrColisParPalette: product.NbrColisParPalette,
            ThresholdFullRelease: product.ThresholdFullRelease,
            MaxOrderPerDayPerCustomer: product.MaxOrderPerDayPerCustomer
        }
        if (variant.SeuilRemiseVolume > 0) {
            // remise au volume
            response.PUsimple = this.state.priceNormal;
            response.PUremise = this.state.priceRemise;
        }
        if (variant !== null) {
            response.EnPromo = variant.EnPromo;
            response.LeStock = variant.LeStock;
            response.DateDLCDLUO = variant.DateDLCDLUO;
            response.DateArrivage = variant.DateArrivage;
            response.VenteAuColis = variant.VenteAuColis;
            response.ArriveEntrepot = variant.ArriveEntrepot;
            response.VenteALaPalette = variant.VenteALaPalette;
            response.DateArrivagePrev = variant.DateArrivagePrev;
            response.CodeVarianteArticle = variant.CodeVarianteArticle;
        }
        return response;
    }

    _renderArrivageDate = () => {
        const {variant} = this.props;
        if (!variant.ArriveEntrepot) {
            return formatDate(variant.DateArrivagePrev)
        }
        return "En stock";
    }

    _handleQtyChange = (q) => {
        const {variant} = this.props;
        const {priceNormal, priceRemise} = this.state;
        this.setState({quantite: q})
        if (variant.SeuilRemiseVolume > 0) {
            if (q < variant.SeuilRemiseVolume) {
                this.setState({texteRemise: `Commandez plus de ${variant.SeuilRemiseVolume} colis pour bénéficier du tarif préférentiel avec remise au volume (${formatCurrency(priceRemise)} au lieu de ${formatCurrency(priceNormal)}), plus que ${variant.SeuilRemiseVolume - q} colis pour y arriver`});
            } else {
                this.setState({texteRemise: `Félicitations, vous bénéficiez désormais du tarif avec remise au volume !`});
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const {product, variant, customer} = this.props;
        const order = this._getProductFromStorage(variant);
        if (this._isMounted) {
            const priceGrid = getPiceGridNormal(variant, customer);
            const unitPrice = getUnitPrice(priceGrid, product.PCB, true);

            const price_grid_simple = getPiceGridNormal(variant, customer);
            const price_unit_simple = getUnitPrice(price_grid_simple, product.PCB, true);
            const price_grid_remise = getPiceGrid(variant, customer, variant.SeuilRemiseVolume);
            const price_unit_remise = getUnitPrice(price_grid_remise, product.PCB, true);
            this.setState({
                unitPrice: unitPrice,
                priceNormal: price_unit_simple,
                priceRemise: price_unit_remise
            });
            if (order) {
                this.setState({
                    added: true,
                    quantite: order.Quantite,
                    priceTotal: order.priceTotal
                });
                if (order.hasOwnProperty('geslotStockIs') && order.geslotStockIs === order.Quantite) {
                    this.setState({
                        updatedStock: true,
                        stockValue: order.geslotStockIs
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        const {tabIndex, product, variant, rayons} = this.props;
        const {
            loading,
            quantite,
            priceTotal,
            added,
            disabled,
            updatedStock,
            quantityNotZero,
            texteRemise,
            priceNormal,
            priceRemise
        } = this.state;
        const api_path = envVars.url_shop_api.replace('/api/', '');
        const img_path = product.PathPhoto ? api_path + product.PathPhoto : getDefaultImage(rayons, product);

        return (
            <Tr className="commande-rapide-item" data-product-id={product.CodeArticle}
                data-variant-id={variant.CodeVarianteArticle}>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}>
                    <Lightbox>

                        <a href={img_path} title={product.Libelle}>
                            <Thumbnail className={product.PathPhoto ? 'has-image' : 'has-no-image'}>
                                <Image src={img_path} alt={product.Libelle}/>
                                <Loader loading={loading.toString()}><ClipLoader loading={loading} color="#ff5d1f"
                                                                                 size={24}/></Loader>
                            </Thumbnail>
                        </a>
                    </Lightbox>
                </Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}>
                    <WrapperTitle>
                        <GoToFiche to={`${this.context.prefix}/fiche-produit/${product.CodeArticle}`}>
                            <Text>{product.Libelle}</Text>
                        </GoToFiche>
                        {variant.VenteAuColis && variant.SeuilRemiseVolume > 0 &&
                            <PictoWrap>
                                <Picto src="/img/icons-colis.svg"/>
                                <Picto src="/img/icon-pourcentage.png" className="remiseVolume"
                                       title={`Commandez plus de ${variant.SeuilRemiseVolume} colis et bénéficiez d'un tarif de ${formatCurrency(priceRemise)} au lieu de ${formatCurrency(priceNormal)}`}/>
                            </PictoWrap>
                        }
                    </WrapperTitle>
                    <TipRemise displayed={texteRemise !== ''}><span>{texteRemise}</span></TipRemise>
                    {product.UniteDeVente === 'K' && <Alert>** Poids variable</Alert>}
                    {disabled && <Alert>Ce produit n'est plus disponible en stock</Alert>}
                    {quantityNotZero && <Alert>La quantité doit être supérieure à 0</Alert>}
                    {updatedStock && <Notice>Le stock disponible ne nous permet pas de satisfaire votre
                        demande, <b>{quantite}</b> {quantite === 1 ? 'colis a été ajouté.' : 'colis ont été ajoutés'}
                    </Notice>}
                </Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}><Text>{product.PCB ? product.PCB : '-'}</Text></Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}><Text>{product.PoidsColis}</Text></Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}><Text>{product.UniteDeVente}</Text></Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}><Text>{priceRemise} €</Text></Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}><Text>{this._renderArrivageDate()}</Text></Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}><Text>{formatDate(variant.DateDLCDLUO)}</Text></Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}>
                    <Total className="front__order-item-total">{formatCurrency(priceTotal)}</Total>
                </Td>
                <Td disabled={disabled ? 'disabled' : added ? 'added' : 'default'}>
                    <Input tabIndex={tabIndex} type="number" disabled={disabled} min={0} max={500}
                           placeholder={quantite === 0 ? '0' : ''} defaultValue={quantite === 0 ? '' : quantite}
                           onInputChange={(q) => this._handleQtyChange(q)}
                           onEndChanged={(value) => this._handleInputOnChange(value)}
                           onBlur={() => this.setState({texteRemise: ''})}/>
                </Td>
            </Tr>
        )
    }

}

CommandeRapideItem.contextType = UrlPrefixContext;

CommandeRapideItem.propTypes = {
    product: PropTypes.object.isRequired,
    variant: PropTypes.object.isRequired
}


const mapStateToProps = (state) => {
    return {
        order: state.order.order,
        availability: state.availability.availability,
        rayons: state.rayons.rayons
    }
}

export default connect(mapStateToProps)(CommandeRapideItem)
