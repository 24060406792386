const initialOrder = {};

export default function OrderReducer(state = initialOrder, action){
    let nextState;
    switch(action.type){
        case "SET_ORDER" :
            nextState = {
                ...state,
                order : action.value
            }
            return nextState;
            // break;

        case "ADD_ITEM_TO_CART" :
            const cart = state.order;
            nextState = {
                ...state,
                order : [...cart, action.value]
            };
            return nextState;
            // break;


        case "UPDATE_ITEM_FROM_CART" :
            let products = [];
            const orders = state.order;
            if( orders && orders.length ){
                orders.forEach( item => {
                    if( item.CodeVarianteArticle === action.value.CodeVarianteArticle ){
                        item = action.value
                    }
                    products.push(item);
                } )
            }else{
                products = orders;
            }
            nextState = {
                ...state,
                order : products
            };
            return nextState;
            // break;

        case "DELETE_ITEM_FROM_CART" :
            const data = state.order;
            nextState = {
                ...state,
                order : data.filter( order => order.CodeVarianteArticle !== action.value.CodeVarianteArticle )
            };
            return nextState;
            // break;

        case "RESET_ORDER":
            nextState = {
                ...state,
                order : initialOrder
            }
            return nextState;
            // break;

        default :
            return nextState || state
    }
}