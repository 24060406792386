import React, {useEffect, useState} from "react"
import {Route, Redirect, useLocation} from "react-router-dom"
import {prefixFront} from "../../../constants"
import {isJWTExpired} from "../../../services/helpers/FrontApiHelper"
import {isGranted} from "../../../services/helpers/RolesHelper"
import ErrorPage from "../../Backoffice/ErrorPage"
import connectApi from "../../../services/helpers/connectApi";
import useMaintenanceStore from "../../../reducers/MaintenanceStore";
import useMeStore from "../../../reducers/MeStore";

const LoadingSpinner = () => {
    return (
        <div className="loading-spinner h-100 d-flex align-items-center justify-content-center">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

const SecretRoute = ({component: Component, minRole, ...rest}) => {
    const setMaintenance = useMaintenanceStore((state) => state.setMaintenance)

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const location = useLocation();
    const meStore = useMeStore()

    useEffect(() => {
        let isCancelled = false;

        const checkMaintenanceMode = async () => {
            return new Promise(async (resolve, reject) => {
                if (rest.path && (rest.path.includes("/backoffice/") || rest.path === "/")) {
                    resolve(false)
                    return
                }

                const response = await connectApi("check-maintenance", "GET", null)
                const mode = response["hydra:member"] && response["hydra:member"][0]
                setMaintenance(mode)
                setIsLoading(false);

                resolve(mode)
            })
        }

        const fetchUser = async () => {
            try {
                const data = await connectApi('me', 'GET', null);
                if (!isCancelled) {
                    setUser(data);
                    meStore.setInfo(data)
                    setIsLoading(false);
                }
            } catch (e) {

            }
        }

        checkMaintenanceMode().then((mode) => {
            if (!mode) {
                fetchUser().then()
            }
        })

        return () => {
            isCancelled = true;
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [minRole, location, rest.path, setMaintenance]);

    return (
        <Route {...rest} render={(props) => {
            if (isLoading) {
                return <LoadingSpinner/>
            }

            const state = localStorage.getItem("state")
            const jsonState = JSON.parse(state)

            if (jsonState && user) {
                jsonState.user = user
                jsonState.user.role = (user && user.roles) ? user.roles[0] : ''
            }

            if (state != null && minRole != null && !isGranted(jsonState?.user?.role, minRole)) {
                return <ErrorPage code="403" title="Accès refusé"
                                  message="Vous n'avez pas les droits suffisants pour accéder à cette page"/>
            }

            if (state != null && jsonState["isAuthenticated"] === true && !isJWTExpired(jsonState["token"])) {
                return <Component {...props} />
            }
            if (!window.location.pathname.includes("/login")) {
                localStorage.setItem("redirectUrl", window.location.pathname);
            }
            return <Redirect to={{pathname: prefixFront + "/login", state: {from: props.location}}}/>
        }}/>
    )
}

export default SecretRoute
