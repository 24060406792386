const initialFilter = { filter: ['Frais', 'Sec', 'DPH', 'PetsFood'] };

export default function FilterReducer(state = initialFilter, action){
    let nextState;
    switch(action.type){
        case "SET_FILTER" :
            nextState = {
                ...state,
                filter : action.value
            };
            return nextState;
        // break;
        case "RESET_FILTER" :
            nextState = {
                ...state,
                filter : initialFilter
            };
            return nextState;
        // break;
        default :
            return nextState || state;
    }
}