import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"

// App
import {Container} from "../../Mixed/Composants"

const Alert = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin: 0;
  text-align: center;
`

class GeslotApiDisponibility extends React.Component {

    state = {
        disponibility: true
    }

    componentDidUpdate(prevProps) {
        const {availability} = this.props
        if (prevProps.availability && availability && prevProps.availability.previousStateGeslotIsAvailable !== availability.previousStateGeslotIsAvailable) {
            this.setState({disponibility: availability.previousStateGeslotIsAvailable})
        }
    }

    componentDidMount() {
        const {availability} = this.props
        if (availability) {
            this.setState({disponibility: availability.previousStateGeslotIsAvailable})
        }
    }

    render() {
        const {disponibility} = this.state
        return (
            !disponibility ?
                <Alert className="alert alert-danger alert-dismissible">
                    <Container fluid>
                        Suite à un problème technique, les commandes sont temporairement indisponibles, nos équipes sont
                        prévenues et nous faisons le maximum pour rétablir le service.
                    </Container>
                </Alert>
                :
                null
        )
    }

}

const mapStateToProps = state => {
    return {
        availability: state.availability.availability
    }
}

export default connect(mapStateToProps)(GeslotApiDisponibility)