import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"

// App
import {DownloadInvoice, DownloadOrderBase64, SectionTitle} from "../Composants"
import {Column, Div, Row} from "../../Mixed/Composants"
import {formatCurrency} from "../../../services/helpers/FrontHelper"
import {getOrder} from "../../../services/helpers/FrontApiHelper"
import {Content, Footer, Header} from "../Composants/Template"
import formateDatetime from "../../../services/helpers/formateDatetime"
// import {Link} from "react-router-dom";
/*
const Pie = styled.div`
    max-width: 320px;
    margin: 0 auto 0;
`;


const Menu = styled(Card)`
    padding : 0;
`;

const NavLink = styled.a`
    font-family: 'Raleway',sans-serif;
    font-weight: 500;
    font-size: 17px;
    padding: 13px 22px;
    line-height: 1.1;
    border-radius: 0 !important;
    &:hover,
    &:focus,
    &.active{
        color: #fff !important;
        background-color: #b3db11 !important;
    }
`;

const Input = styled.input`
    height: auto;
    padding: .8em 1em;
    border-radius: 0;
`;

const Value = styled.p`
    margin: 0;
    text-align: right;
`;

const Title = styled.h2`
    font-size: 40px;
    font-family: 'Covered By Your Grace', sans-serif;
    font-weight: 400;
    margin-bottom: .7em;
`;
*/
const Card = styled.div`
  padding: 2em;
  margin: 0;
  border-radius: 0;
  box-shadow: inherit;
  border: 1px solid #e0dfd7;
`

const Wrap = styled.div`
  margin-top: 2em;
`

const Alert = styled.span`
  padding: .3rem 1rem;
  margin-bottom: 0;
`

const TfootAmount = styled.th`
  background-color: silver;
`

class DetailCommandPage extends React.Component {

    _isMounted = false

    state = {
        loading: false,
        command: null
    }

    _getCommand = (id) => {
        getOrder(id)
            .then(response => {
                this.setState({command: response})
            })
            .finally(() => this.setState({loading: false}))
    }

    _renderTabCommand = () => {
        const command = this.state.command
        return (
            <React.Fragment>
                <SectionTitle tag="h1">Commande N° {command.CodeBP}</SectionTitle>
                <Wrap>
                    <Card className="card">
                        {this._renderTabDetailCommand(command)}
                    </Card>
                </Wrap>
            </React.Fragment>
        )
    }

    _renderTabDetailCommand = (command) => {
        let txt = "Validée"
        let cls = "alert alert-success"
        if (command.Statut === "Proposition à valider" && (command.CodeFacture === "" || command.CodeFacture === null)) {
            txt = "En cours de validation"
            cls = "alert alert-info"
        } else if ((command.Statut !== "Proposition à valider" && command.Statut !== "") && (command.CodeFacture === "" || command.CodeFacture === null)) {
            txt = command.Statut
            cls = "alert alert-warning"
        }
        const orderLines = command.orderLines
        return (
            <Row>
                <Column lg={12}>
                    <Row>
                        <Column lg={3}>
                            <strong>Date commande :</strong> {formateDatetime(command.OrderDate, "d/m/Y")}
                        </Column>
                        <Column lg={command.CodeBL ? 2 : 4}><strong>Statut : </strong><Alert
                            className={cls}>{txt}</Alert></Column>
                        {
                            command.CodeBL
                                ?
                                <Column lg={3}>
                                    <strong>N° BL : </strong>
                                    {command.CodeBL}
                                </Column>
                                :
                                null
                        }
                        <Column lg={3}>
                            {
                                command.CodeFacture
                                &&
                                <DownloadInvoice codeFacture={command.CodeFacture}
                                                 labelButton={"Télécharger la facture"} isSpecificButton={true}/>
                            }
                        </Column>
                        <Column lg={1}>
                            {
                                command.CodeBP
                                &&
                                <DownloadOrderBase64 CodeBP={command.CodeBP} id={command.id}/>
                            }
                        </Column>
                    </Row>
                    <Row>
                        <Wrap className="table-responsive">
                            <table className="table">
                                <thead className="thead-dark">
                                <tr>
                                    <th>Libellé Article</th>
                                    <th>PU € HT</th>
                                    <th>Quantité</th>
                                    <th>Montant HT</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orderLines.map((orderproduct, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="v-align-center">
                                                    {orderproduct.libelleArticle ? orderproduct.libelleArticle : "-"}
                                                </td>
                                                <td className="v-align-center">{formatCurrency(orderproduct.PrixVenteUnitaireHT)}</td>
                                                <td className="v-align-center">{orderproduct.Quantite}</td>
                                                <td className="v-align-center">{formatCurrency(orderproduct.MontantHT)}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Nombre de ligne(s) : {orderLines ? orderLines.length : 0}</th>
                                    <th></th>
                                    <TfootAmount>Montant total HT</TfootAmount>
                                    <TfootAmount>{formatCurrency(command.montantTotalHT)}</TfootAmount>
                                </tr>
                                </tfoot>
                            </table>
                        </Wrap>
                    </Row>
                </Column>
            </Row>
        )
    }

    _matchCommand = () => {
        const {match} = this.props
        if (match && match.hasOwnProperty("params")) {
            const {id} = match.params
            if (id) {
                this.setState({loading: true}, () => this._getCommand(id))
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this._matchCommand()
        }
    }

    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this._matchCommand()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {loading, command} = this.state
        return (
            <Div className="front__wrap-screen">.
                <Header/>
                <Content loading={loading}>
                    {!loading && command && this._renderTabCommand()}
                </Content>
                <Footer/>
            </Div>
        )
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(DetailCommandPage)
