import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styled from "styled-components"

const Accessory = styled.span`
  display: flex;
  padding: 0;
  width: 60px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 2000px) {
    width: 50px;
  };
`

const TextInput = React.forwardRef((props, ref) => {

    let customProps = {}
    let {
        fieldname,
        label,
        labelText,
        type,
        value,
        placeholder,
        min,
        max,
        step,
        accessoryLeft,
        accessoryRight,
        tabIndex,
        required,
        disabled,
        autoComplete,
        onChange,
        onFocus,
        onBlur
    } = props

    const authorize_type = ["text", "email", "tel", "number", "password"]
    const id = `field_${Math.random().toString(4)}`

    if (required) {
        customProps = {
            required: required
        }
    }

    if (fieldname) {
        customProps = {
            name: fieldname
        }
    }

    if (disabled) {
        customProps.disabled = disabled
    }

    if (autoComplete) {
        customProps.autoComplete = autoComplete
    }

    if (tabIndex) {
        customProps.tabIndex = tabIndex
    } else if (tabIndex === 0) {
        customProps.tabIndex = 0
    }

    if (onChange && typeof (onChange) == "function") {
        customProps.onChange = onChange
    }

    if (onFocus && typeof (onFocus) == "function") {
        customProps.onFocus = onFocus
    }

    if (onBlur && typeof (onBlur) == "function") {
        customProps.onBlur = onBlur
    }

    if (!authorize_type.includes(type)) {
        type = "text"
    }

    const _renderFieldWithAccessoryLeft = () => {
        return (
            <div className="form-group" ref={ref}>
                <div className="input-group prepend">
                    <div className="input-group-prepend">
                        <Accessory className="input-group-text">{accessoryLeft()}</Accessory>
                    </div>
                    {_renderInput(false)}
                </div>
            </div>
        )
    }

    const _renderFieldWithAccessoryRight = () => {
        return (
            <div className="form-group" ref={ref}>
                <div className="input-group append">
                    {_renderInput(false)}
                    <div className="input-group-append">
                        <Accessory className="input-group-text">{accessoryRight()}</Accessory>
                    </div>
                </div>
            </div>
        )
    }

    const _renderInput = (setRef = true) => {
        if (setRef) {
            customProps.ref = ref
        }
        return (
            <input
                type={type}
                value={value}
                placeholder={placeholder}
                className={classNames("form-control", props.className)}
                {...customProps}
            />
        )
    }

    const _renderFields = () => {
        if (type === "number") {
            return (
                <div className="form-group">
                    <input
                        type={type}
                        value={value}
                        min={min}
                        max={max}
                        step={step}
                        placeholder={placeholder}
                        className={classNames("form-control", props.className)}
                        {...customProps}
                    />
                </div>
            )
        }
        if (accessoryLeft && typeof (accessoryLeft) === "function") {
            return _renderFieldWithAccessoryLeft()
        }
        if (accessoryRight && typeof (accessoryRight) === "function") {
            return _renderFieldWithAccessoryRight()
        }
        return (
            <div className="form-group">{_renderInput()}</div>
        )
    }

    return (
        <>
            {label && <label htmlFor={id}>{labelText}</label>}
            {_renderFields()}
        </>
    )
})

TextInput.defaultProps = {
    min: 0,
    step: 1,
    max: 255,
    type: "text",
    placeholder: "Text input"
}

TextInput.propTypes = {
    value: PropTypes.any,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    labelText: PropTypes.string,
    accessoryLeft: PropTypes.func,
    accessoryRight: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    fieldname: PropTypes.string,
    placeholder: PropTypes.string.isRequired
}

export default TextInput