import React, {Component} from "react"
import styled from "styled-components"

// App
import {Button} from "./Forms"
import {
    createOrderLine,
    getCustomerInfo,
    getProduct,
    getProductStock,
    updateOrderLine,
    uploadBase64Order
} from "../../../services/helpers/FrontApiHelper"
import {
    addSubClass,
    applyProductMaximumCommandale,
    getAllLocalProducts,
    getLocalOrderLine,
    getPiceGrid,
    getProductDetail,
    getProductFromStorage,
    getUnitPrice,
    removeSubClass
} from "../../../services/helpers/FrontHelper"
import {
    HTTP_FILE_EMPTY,
    HTTP_FILE_NOTFOUND,
    HTTP_INVALID_FILE_EXTENSION,
    HTTP_INVALID_FILESIZE
} from "../../../constants"
import {toast} from "react-toastify"
import {connect} from "react-redux"
import "./Css/style.css"

// const history = createBrowserHistory()

const Wrap = styled.div``

const Upload = styled(Button)`
  font-size: 16px !important;
  height: 60px;
  width: 60px;
  padding: 0 !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

/*
const Img = styled.img`
  display: inline-block;
  margin: 0;
  border: 0;
  max-width: 30px;
  @media screen and (max-width: 2000px) {
    max-width: 25px;
  }
`

const Icon = styled.img`
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 40px;
  margin-right: 13px;
  @media screen and (max-width: 2000px) {
    max-width: 30px;
  }
`
*/
class UploadOrderBase64 extends Component {

    duplicatePanier = null

    componentDidMount() {
        this.duplicatePanier = require("../duplicate-panier.png")
    }

    async _createOrUpdatePanier(listNewOrderLines, customer) {
        let numberListNewOrderLines = listNewOrderLines.length
        for (const element of listNewOrderLines) {
            let cartItem = {
                "CodeVarianteArticle": element.CodeVarianteArticle,
                "CodeClient": customer.user.CodeClient,
                "MontantHT": element.MontantHT,
                "MontantTTC": element.MontantTTC,
                "StatutReglementFacture": element.StatutReglementFacture,
                "CodeArticle": element.CodeArticle,
                "Quantite": element.Quantite,
                "UniteDeVente": element.UniteVente,
                "PU": element.PrixVenteUnitaireHT,
                "priceTotal": element.PrixVenteTotalHT,
                "MontantTVA": element.MontantTVA,
                "ObjectLastUpdate": element.ObjectLastUpdate,
                "createdAt": element.createdAt,
                "updatedAt": element.updatedAt,
                "libelleArticle": element.libelleArticle,
                "dateArrivagePrev": element.dateArrivagePrev
            }
            await this.createOrUpdatePanier(cartItem, customer).then(
                () => {
                    let numberForRefreshPanier = parseInt(localStorage.getItem("numberForRefreshPanier"))
                    localStorage.setItem("numberForRefreshPanier", numberForRefreshPanier + 1)
                    numberForRefreshPanier = parseInt(localStorage.getItem("numberForRefreshPanier"))
                    if (numberForRefreshPanier === numberListNewOrderLines) {
                        const data = JSON.parse(localStorage.getItem("frontOrderProducts"))
                        localStorage.setItem("numberForRefreshPanier", 0)
                        document.body.style.cursor = "default"
                        this._persistDataToStore(data)
                        this.setState({loading: false})
                        removeSubClass(document.body, "stop-scrolling")
                        window.location.reload()
                    }
                }
            )
        }
    }

    async createOrUpdatePanier(cartItem, customer) {
        const localProduct = getProductFromStorage(cartItem)
        if (localProduct) {
            return await this._updateOrderLine(cartItem, customer)
        } else {
            return await this._createOrderLine(cartItem, customer)
        }
    }

    async _createOrderLine(data, customer) {
        let product = await getProduct(data.CodeArticle)
        let variante = await getProductStock(data.CodeVarianteArticle)
        let dataTemp = {Quantite: data.Quantite, LeStock: variante.LeStock}
        product = {...product, ...dataTemp}
        const orderLine = getLocalOrderLine()
        data = {...data, ...variante, ...product}
        const qtMAxCom = applyProductMaximumCommandale(data)
        if (qtMAxCom) {
            data = getProductDetail(qtMAxCom, product, data.CodeVarianteArticle, customer)
        }

        const status = await this._getCustomerInfo(customer, data)
        if (!status) {
            return false
        } else {
            const response = await createOrderLine(data, customer, orderLine)
            let libelleCurrentArticleAdded = data.libelleArticle

            if (response.code === 0 || response.code === 400 || response.code === 401 || response.code === 403) {
                const message = response.code === 0 || response.code === 401 ? "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible." : response.message
                this._disableProductAndDisplayError(message)
                return false
            } else if (response.code === 480) {
                const LeStock = response.data.varianteArticle.LeStock
                if (LeStock === 0) {
                    this._disableProductAndDisplayError()
                } else {
                    const stock = getProductDetail(LeStock, product, data.CodeVarianteArticle, customer)
                    this._createOrderLine(stock, customer)
                }
                return false
            } else {
                const products = getAllLocalProducts()
                if (products.length === 0 || products.length === 1) {
                    const orderLineData = {
                        "id": response.id,
                        "OrderId": response.OrderId,
                        "OrderLineId": response.OrderLineId,
                        "CodeBP": response.CodeBP
                    }
                    this._storeToStorage("frontOrderLine", orderLineData)
                }
                if (!response.hasOwnProperty("CodeArticle")) {
                    this._disableProductAndDisplayError()
                    return false
                }
                toast("Le produit \"" + libelleCurrentArticleAdded + "\" est ajouté à votre panier", {
                    type: "success",
                    autoDismiss: true
                })
                this.setState({loading: true, added: true}, () => this._addProductToLocal({
                    ...data,
                    orderLine: response
                }))
                return true
            }
        }
    }

    async _updateOrderLine(data, customer) {
        let product = await getProduct(data.CodeArticle)
        let variant = await getProductStock(data.CodeVarianteArticle)
        const localProduct = getProductFromStorage(data)
        let newQuantityProduct = parseInt(localProduct.Quantite) + parseInt(data.Quantite)
        data.Quantite = newQuantityProduct
        localProduct.Quantite = newQuantityProduct
        let priceGrid = getPiceGrid(variant, customer)
        let unitPrice = getUnitPrice(priceGrid, product.PCB, true)
        let priceTotal
        if (product.UniteDeVente === "K" && product.PoidsColis > 0) {
            priceTotal = priceGrid * product.PoidsColis * newQuantityProduct
        } else if (product.UniteDeVente === "K" && product.PoidsColis === 0) {
            priceTotal = 0.00
        } else {
            priceTotal = unitPrice * product.PCB * newQuantityProduct
        }
        priceTotal = Number.isInteger(priceTotal) ? priceTotal : parseFloat(priceTotal).toFixed(2)
        data.PU = parseFloat(unitPrice)
        data.priceTotal = priceTotal
        data.PriceGrid = priceGrid

        const qtMAxCom = applyProductMaximumCommandale(data)

        if (qtMAxCom) {
            data = getProductDetail(qtMAxCom, product, variant.CodeVarianteArticle, customer)
        }

        const status = await this._getCustomerInfo(customer, data)
        if (!status) {
            return false
        } else {
            if (localProduct && localProduct.hasOwnProperty("orderLine")) {
                const order = {...localProduct, ...data}
                order.orderLine.PrixVenteUnitaireHT = data.PU
                const response = await updateOrderLine(order.orderLine.id, order)

                if (response.code === 0 || response.code === 400 || response.code === 401 || response.code === 403) {
                    const message = response.code === 0 || response.code === 401 ? "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible." : response.message
                    this._deleteStorage("frontOrderProducts", data)
                    this._disableProductAndDisplayError(message)
                    return false
                } else if (response.code === 480) {
                    const LeStock = response.data.varianteArticle.LeStock
                    if (LeStock === 0) {
                        this._deleteStorage("frontOrderProducts", data)
                        this._disableProductAndDisplayError()
                    } else {
                        const stock = getProductDetail(LeStock, product, variant.CodeVarianteArticle, customer)
                        const item = {...order, ...stock}
                        // const qColis = item.VenteAuColis ? LeStock : 0;
                        // const qPalette = item.VenteALaPalette ? LeStock : 0;
                        this.setState({
                            quantity: LeStock,
                            added: true,
                            updated: true,
                            isVenteColis: item.VenteAuColis,
                            isVentePalette: item.VenteALaPalette
                        }, () => {
                            this._reUpdateOrderAfterDissatisfiedStock(customer, product, variant, LeStock, item.orderLine)
                        })
                    }
                    return false
                } else {
                    if (!response.hasOwnProperty("CodeArticle")) {
                        this._disableProductAndDisplayError()
                        return false
                    }
                    toast("Le produit est ajouté à votre panier", {
                        type: "success",
                        autoDismiss: true
                    })
                    this.setState({
                        loading: true,
                        added: true
                    }, () => this._updateStorage("frontOrderProducts", {...order, orderLine: response}))
                    return true
                }
            }
        }

        return false
    }

    _getOrderPriceTotal = () => {
        let total = 0
        const products = getAllLocalProducts()
        if (products && products.length) {
            products.forEach((item) => {
                total += parseFloat(item.priceTotal)
            })
        }
        return total
    }

    _getCustomerInfo = (customer, dataProductDetailCommandee) => {
        return getCustomerInfo(customer.user.CodeClient)
            .then(response => {
                if (response) {
                    const info = {
                        MontantEncoursUtilise: response.MontantEncoursUtilise,
                        MontantEncoursAutoriseFixe: response.MontantEncoursAutoriseFixe,
                        MontantEncoursAutoriseFlexible: response.MontantEncoursAutoriseFlexible,
                        DelaiEchanceFacture: response.DelaiEchanceFacture
                    }
                    let dataCustomerWithProductCommandee = {...customer, ...dataProductDetailCommandee}
                    const data = {...dataCustomerWithProductCommandee, ...info}
                    const DelaiEchanceFacture = data.DelaiEchanceFacture
                    const encours_autorise = data.MontantEncoursAutoriseFixe
                    const encours_autorise_flexible = data.MontantEncoursAutoriseFlexible
                    const encours_utilise = data.MontantEncoursUtilise
                    const encours_restent = encours_autorise - encours_utilise
                    const encours_restent_flexible = encours_autorise_flexible - encours_utilise
                    // let montantHT = this._getOrderPriceTotal()
                    let MontantHTArticleCommandee = parseFloat(data.priceTotal)

                    let compteurDepassementEncoursFlexible = localStorage.getItem("compteurDepassementEncoursFlexible")
                    if (compteurDepassementEncoursFlexible == null && compteurDepassementEncoursFlexible === undefined) {
                        compteurDepassementEncoursFlexible = 0
                    }

                    // montantHT += MontantHTArticleCommandee
                    let diffAmountArticleCommandee = MontantHTArticleCommandee
                    const products = getAllLocalProducts()
                    if (products && products.length) {
                        products.forEach((item) => {
                            if (item.CodeArticle === data.CodeArticle) {
                                // montantHT -= parseFloat(item.priceTotal)
                                diffAmountArticleCommandee -= parseFloat(item.priceTotal)
                            }
                        })
                    }

                    diffAmountArticleCommandee = diffAmountArticleCommandee < 0 ? -1 * diffAmountArticleCommandee : diffAmountArticleCommandee
                    this.props.dispatch({type: "SET_CUSTOMER", value: data})

                    let libelleArticle = dataProductDetailCommandee.libelleArticle
                    if (DelaiEchanceFacture > 0) {
                        toast("Le produit " + libelleArticle + " n'a pas été ajouté au panier car vous avez des factures non-réglées ayant dépassé votre délai de paiement de " + DelaiEchanceFacture + " jours, merci de régulariser la situation pour pouvoir commander de nouveau !", {
                            type: "error",
                            autoDismiss: true
                        })
                        return false
                    }

                    if (encours_restent <= 0 || (diffAmountArticleCommandee > encours_restent && diffAmountArticleCommandee > encours_restent_flexible)) {
                        toast("Le produit " + libelleArticle + " n'a pas été ajouté au panier car votre encours est insuffisant pour passer une commande !", {
                            type: "error",
                            autoDismiss: true
                        })
                        return false
                    } else if (diffAmountArticleCommandee < encours_restent && encours_autorise_flexible > 0 && diffAmountArticleCommandee > encours_restent_flexible) {
                        localStorage.setItem("compteurDepassementEncoursFlexible", parseInt(compteurDepassementEncoursFlexible) + 1)
                        compteurDepassementEncoursFlexible = localStorage.getItem("compteurDepassementEncoursFlexible")
                        if (compteurDepassementEncoursFlexible === 2) {
                            localStorage.setItem("firstMontantHTDepassantEncoursFlexible", diffAmountArticleCommandee)
                        }
                        if (compteurDepassementEncoursFlexible > 1) {
                            let firstMontantHTDepassantEncoursFlexible = parseFloat(localStorage.getItem("firstMontantHTDepassantEncoursFlexible"))
                            if (diffAmountArticleCommandee > firstMontantHTDepassantEncoursFlexible) {
                                toast("Le produit " + libelleArticle + " n'a pas été ajouté au panier car votre encours est insuffisant pour passer une commande !", {
                                    type: "error",
                                    autoDismiss: true
                                })
                                return false
                            }
                            return true
                        }
                        return true
                    }
                    return true
                }
                return false
            })
    }

    _reUpdateOrderAfterDissatisfiedStock = (customer, product, variant, quantite, response) => {
        const detail = getProductDetail(quantite, product, variant.CodeVarianteArticle, customer)
        const item = {...detail, orderLine: response, geslotStockIs: quantite}
        this._updateStorage("frontOrderProducts", item)
        this._updateOrderLine(item)
        this.setState({
            quantity: item.Quantite
        })
    }

    _updateStorage = (name, value) => {
        if (!name || !value) {
            return false
        }
        const tmp = []
        const data = name === "frontOrderProducts" ? getAllLocalProducts() : getLocalOrderLine()
        data.forEach(item => {
            if (name === "frontOrderProducts" && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                item = value
            }
            if (name === "frontOrderLine" && item.OrderId === value.OrderId) {
                item = value
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp)
    }

    _deleteStorage = (name, value) => {
        if (!name || !value) {
            return false
        }
        const tmp = []
        const data = name === "frontOrderProducts" ? getAllLocalProducts() : getLocalOrderLine()
        data.forEach(item => {
            if (name === "frontOrderProducts" && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                // item = value
                return false
            }
            if (name === "frontOrderLine" && item.OrderId === value.OrderId) {
                // item = value
                return false
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp)

    }

    _disableProductAndDisplayError = (message = null) => {
        const error = message ? message : "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible."
        this.setState({
            quantity: 0,
            loading: true,
            added: false,
            disabled: true,
            isVenteColis: false,
            isVentePalette: false
        })
        toast(error, {
            type: "error",
            autoDismiss: true
        })
    }

    _storeToStorage = (name, data) => {
        const {onUpdate} = this.props
        if (!name || !data) {
            return false
        }
        localStorage.setItem(name, JSON.stringify(data))
        if (typeof (onUpdate) === "function") {
            onUpdate()
        }
    }

    _persistDataToStore = (data) => {
        let value = []
        if (data) {
            if (data.length) {
                data.forEach(item => {
                    if (item.hasOwnProperty("orderLine")) {
                        value.push(item)
                    }
                })
                this.props.dispatch({type: "SET_ORDER", value: value})
            } else {
                this.props.dispatch({type: "SET_ORDER", value: []})
            }
        } else {
            this.props.dispatch({type: "SET_ORDER", value: []})
        }
    }

    _addProductToLocal = (data) => {
        let products = getAllLocalProducts()
        if (products) {
            products.push(data)
        }
        this._storeToStorage("frontOrderProducts", products)
    }

    _handleUpload = (event) => {
        event.preventDefault()
        document.getElementById("file").click()
    }

    _uploadOrder = () => {
        const {availability} = this.props
        if (availability && availability.previousStateGeslotIsAvailable === false) {
            toast("Les commandes sont temporairement indisponibles.", {
                type: "error",
                autoDismiss: true
            })
            return false
        }
        this.setState({loading: true})
        document.body.style.cursor = "wait"
        let numberForRefreshPanier = localStorage.getItem("numberForRefreshPanier")
        if (numberForRefreshPanier === null || numberForRefreshPanier === undefined) {
            numberForRefreshPanier = 0
        }
        localStorage.setItem("numberForRefreshPanier", numberForRefreshPanier)
        const input = document.getElementById("file")
        uploadBase64Order(input.files[0])
            .then(response => {
                if (response.hasOwnProperty("code")) {
                    let arrayCodeError = [
                        HTTP_FILE_EMPTY,
                        HTTP_INVALID_FILE_EXTENSION,
                        HTTP_INVALID_FILESIZE,
                        HTTP_FILE_NOTFOUND
                    ]
                    if (arrayCodeError.includes(response.code)) {
                        let message = "Une erreur est survenue car le fichier n'est pas valide"
                        if (response.hasOwnProperty("message")) {
                            message = response.message
                            toast(message, {
                                type: "error",
                                autoDismiss: true
                            })
                            document.body.style.cursor = "default"
                            this.setState({loading: false})
                            return false
                        }
                    }
                }
                let listNewOrderLines = response.listNewOrderLines
                let errors = response.errors
                localStorage.setItem("errorsDuplicationPanier", JSON.stringify(errors))
                let customer = JSON.parse(localStorage.getItem("persist:customer")).customer
                customer = JSON.parse(customer)
                if (listNewOrderLines.length === 0) {
                    toast("Désolé, cette commande n'est pas duplicable car aucun des produits commandés dans celle-ci n'est disponible ni remplaçable.", {
                        type: "error",
                        autoDismiss: true
                    })
                    document.body.style.cursor = "default"
                    this.setState({loading: false})
                    return false
                } else {
                    addSubClass(document.body, "stop-scrolling")
                    this._createOrUpdatePanier(listNewOrderLines, customer)
                }
            })
            .finally(
                () => {
                    //this.setState({ loading : false })
                }
            )
            .catch(
                e => {
                    console.log(e)
                    document.body.style.cursor = "default"
                }
            )
    }

    render() {
        return (
            <div id="form-import-order">
                <input type="file" style={{display: "none"}} id="file" onChange={() => this._uploadOrder()}/>
                <Wrap
                    title={"Remplissez votre panier avec les produits d'une commande précédente (seuls les produits encore disponibles seront rajoutés)"}>
                    <Upload className="btn btn-warning bg-transparent" onClick={(event) => {
                        event.preventDefault()
                        document.getElementById("file").click()
                    }} contain="outlined">
                        <img src="/img/cart-shopping-solid.svg" alt="Shopping" width={36}/>
                    </Upload>
                </Wrap>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        order: state.order.order,
        availability: state.availability.availability
    }
}

export default connect(mapStateToProps)(UploadOrderBase64)
