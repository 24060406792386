import { strToSlug } from './services/helpers/FrontHelper';
const rayons = [];
const fixRayons = [
    'Animaux',
    'Bébé',
    'Boissons',
    'Charcuterie',
    'Conserverie',
    'Crèmerie',
    'Entretien',
    'Divers',
    'Epicerie salée',
    'Epicerie sucrée',
    'Fromage',
    'Fruits & légumes',
    'Hygiène Beauté',
    'Maison',
    'Produits du Monde',
    'Saurisserie',
    'Surgelés',
    'Traiteur',
    'Viande',
    'Volaille'
];

fixRayons.forEach( rayon => {
    const slug = rayon  === 'Boissons' ? strToSlug('Boisson') : strToSlug(rayon);
    rayons.push({
        Nomcategorie : rayon,
        Slugcategorie : slug
    })
} )

export default rayons;