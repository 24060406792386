import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, prefixBack, prefixFront } from '../../../../constants'
import Loading from '../../Loading'

const newHistory = createBrowserHistory()

export default class Family extends Component {

    state = {
      isAuthenticated: false,
      listFamily: []
    };

    nameMenu = 'FamilyInvalid'

    componentDidMount() {
        this.renderListFamily();
    }

    renderListFamily = async () => {
        const data = await connectApi('incoherent-familles', 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                // this will re render the view with new data
                this.setState({
                    listFamily: data
                });
                localStorage.setItem('totalFamily', JSON.stringify(data.length))
                localStorage.setItem('totalUnreadFamily', 0)
                localStorage.setItem('gardeFouFamilyIsClicked', true)
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    render() {
        const listFamily = this.state.listFamily?.map((family, i) => (
            <tr key={
                `family${ family.Identifiant }`
                }>
                <td className="Identifiant">{family.Identifiant}</td>
                <td className="Nomcategorie">{family.Nomcategorie}</td>
                <td className="Famille">{family.Famille}</td>
                <td className="Grandefamille">{family.Grandefamille}</td>
                <td className="Motif">Sans grande famille ou famille</td>
            </tr>
        ));
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Liste des familles incohérentes</h5>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                            <li className="breadcrumb-item">Système</li>
                            <li className="breadcrumb-item"><a href={ prefixBack + '/totalgardefou' }>Garde-fou</a></li>
                            <li className="breadcrumb-item active">Familles incohérentes</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="table-responsive">
                                    <table id="familyInvalid" className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="table-dark">
                                            <td className="Identifiant">Identifiant</td>
                                            <td className="Nomcategorie">Nomcategorie</td>
                                            <td className="Famille">Famille</td>
                                            <td className="Grandefamille">Grandefamille</td>
                                            <td className="Motif">Motif</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listFamily }
                                        </tbody>
                                        <tfoot>
                                            <tr className="table-dark">
                                            <td className="Identifiant">Identifiant</td>
                                            <td className="Nomcategorie">Nomcategorie</td>
                                            <td className="Famille">Famille</td>
                                            <td className="Grandefamille">Grandefamille</td>
                                            <td className="Motif">Motif</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Loading/>
                    </div>
                </section>
                
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}