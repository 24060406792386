import React, {useCallback, useEffect, useState} from "react"
import debounce from "lodash.debounce"

// App
import {TextInput} from "./Forms"

function InputDebounce(props) {

    let customProps = {}
    const {disabled, defaultValue, tabIndex, onInputChange, onEndChanged} = props
    const [value, setValue] = useState(0)

    if (tabIndex) {
        customProps.tabIndex = tabIndex
    } else if (tabIndex === 0) {
        customProps.tabIndex = 0
    }

    useEffect(() => {
        if (!value && defaultValue) {
            setValue(defaultValue)
        }
    }, [value, defaultValue])

    //TODO: React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead
    //eslint-disable-next-line
    const debouncedSave = useCallback(
        debounce((val) => onEndChanged(val), 1000),
        []
    )

    const _handleInputChange = (event) => {
        const val = event.target.value
        setValue(val)
        onInputChange(val)
        debouncedSave(val)
    }

    return (
        <TextInput {...props} {...customProps} type="number" disabled={disabled} min={0} max={500} value={defaultValue}
                   onChange={_handleInputChange}/>
    )

}

export default InputDebounce