import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
          <footer className="main-footer" style={{marginLeft: "0!important"}}>
            <strong>Copyright © {(new Date().getFullYear())} <a href="https://nextaura.com/" className={"link"} target="_blank" rel="noreferrer">Nextaura</a>. </strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
              {/* <b>Version</b> 1.0.0 */}
            </div>
          </footer>
        )
    }
}
