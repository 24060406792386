import React, {useContext, useEffect, useState} from "react"
import styled from "styled-components"
import {connect} from "react-redux"
import {Link, useParams} from "react-router-dom"

// App
import RayonsFixtures from "../../../fixture_rayons"
import {Div} from "../../Mixed/Composants"
import {strToSlug} from "../../../services/helpers/FrontHelper"
import {UrlPrefixContext} from "../../../context"

const WidgetTitle = styled.span`
  display: block;
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 35px;
  line-height: 1;
  color: #ffffff;
  padding: 11px 22px;
  background: #b3db11;
  @media screen and (max-width: 2000px) {
    font-size: 28px
  }
`

const Title = styled(WidgetTitle)`
  background: none;
  color: #404040;
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 28px;
  line-height: 1.25;
  padding: 10px 22px;
  @media screen and (max-width: 2000px) {
    font-size: 24px
  }
`

const Inner = styled(Div)`
  padding: 10px 0 0;
  background: #fff;
`

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const MenuItem = styled.li`
  list-style: none;

  &.menu-item-active a {
    background: #b3db11;
    color: #fff;
  }

  &.no-products a {
    color: #949191;
    pointer-events: none;
  }
`

const MenuLink = styled(Link)`
  font-family: 'Raleway', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  color: #404040;
  display: block;
  padding: 13px 22px;
  text-transform: capitalize;

  &:hover,
  &:active {
    background: #b3db11;
    color: #fff;
  }
`

function Rayons(props) {

    const [categories, setCategories] = useState([])
    const {categorie} = useParams()
    const context = useContext(UrlPrefixContext)
    useEffect(() => {
        const {rayons} = props
        if (rayons) {
            setCategories(RayonsFixtures)
        }
    }, [props])

    const getRayonDetail = (param) => {
        let rayon = null
        const {rayons} = props
        if (rayons) {
            const data = rayons.filter(item => strToSlug(item.Nomcategorie) === param.Slugcategorie)
            if (data.length) {
                rayon = {...param, ...data[0]}
            }
        }
        return rayon
    }

    return (
        <Div className="front__sidebar-rayons">
            <WidgetTitle>NOS RAYONS</WidgetTitle>
            <Inner>
                <Menu>
                    <MenuItem key="selection" className="menu-item">
                        <MenuLink to={`${context.prefix}/selection-du-moment`}>Sélection du moment</MenuLink>
                    </MenuItem>
                    <MenuItem key="promo" className="menu-item">
                        <MenuLink to={`${context.prefix}/promotions`}>Promotions</MenuLink>
                    </MenuItem>
                    <MenuItem key="dmin" className="menu-item">
                        <MenuLink to={`${context.prefix}/derniere-minute`}>Dernière minute</MenuLink>
                    </MenuItem>
                    <MenuItem key="halal" className="menu-item">
                        <MenuLink to={`${context.prefix}/halal`}>Halal</MenuLink>
                    </MenuItem>
                    <MenuItem key="bio" className="menu-item">
                        <MenuLink to={`${context.prefix}/bio`}>Bio</MenuLink>
                    </MenuItem>
                </Menu>
                <Title>TOUS NOS PRODUITS :</Title>
                <Menu>
                    {
                        categories.length && categories.map((item, index) => {
                            let cls = "menu-item"
                            const rayon = getRayonDetail(item)
                            if (rayon) {
                                if (props.familleID === rayon.Identifiant) {
                                    cls += " menu-item-active"
                                }
                                if (categorie && categorie === rayon.Slugcategorie) {
                                    cls += " menu-item-active"
                                }
                            } else {
                                cls += " no-products"
                            }
                            return (
                                <MenuItem key={index} className={cls}>
                                    <MenuLink to={{
                                        pathname: `${context.prefix}/nos-produits/${item.Slugcategorie}`,
                                        rayonId: item.Identifiant,
                                        termName: item.Nomcategorie
                                    }}>{item.Nomcategorie.toLowerCase()}</MenuLink>
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </Inner>
        </Div>
    )

}

const mapStateToProps = state => {
    return {
        rayons: state.rayons.rayons
    }
}

export default connect(mapStateToProps)(Rayons)
