/* Liste params */
export const MAX_RESULT_ERROR = 5000//max number error
export const CART_DELETE_DELAY = 1800000
export const AVAILABILITY_DELAY = 60000

/* ------- Code HTTP --------  */
export const HTTP_OK = 200
export const HTTP_FOUND = 302
export const HTTP_BAD_REQUEST = 400
export const HTTP_UNAUTHORIZED = 401
export const HTTP_MIN_COMMAND = 460
export const HTTP_NOT_ACCESS = 461
export const HTTP_PARAM_REQUIRED = 462
export const HTTP_VERSION_SHOP_MOBILE_OBSOLETE = 463
export const HTTP_USER_NOT_FOUND = 464
export const HTTP_INVALID_PASSWORD = 465
export const HTTP_OBJECT_NOT_FOUND = 466
export const HTTP_STOCK_INSUFICIENT = 480

export const HTTP_STOCK_ABSENT = 481
export const HTTP_PRODUCT_REPLACED = 482

export const HTTP_FILE_EMPTY = 493
export const HTTP_INVALID_FILE_EXTENSION = 494
export const HTTP_INVALID_FILESIZE = 495
export const HTTP_FILE_NOTFOUND = 496

export const HTTP_USER_LOCKED = 497

/* Droit d'acces */
export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_MAINTAINER_SEDIS = "ROLE_MAINTAINER_SEDIS"
export const ROLE_SALESMANAGER_BO = "ROLE_SALESMANAGER_BO"
export const ROLE_REDACTOR_BO = "ROLE_REDACTOR_BO"
export const ROLE_USER_BO = "ROLE_USER_BO"
export const ROLE_CUSTOMER = "ROLE_CUSTOMER"

/* Prefix url */
export const prefixBack = "/home/backoffice"
export const prefixFront = "/home/frontoffice"

/* ------ PARAM QUERY */
export const SHOP_API = "SHOP_API"
export const SHOP_BOT = "SHOP_BOT"
export const SHOP_MOBILE = "SHOP_MOBILE"
export const SHOP_OFFICE = "SHOP_OFFICE"
export const AUTHENTICATION = "AUTHENTICATION"
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS"
export const ALL = "ALL"
export const PANIER = "PANIER"
export const SUCCESS_PANIER = "SUCCESS_PANIER"
export const ERROR_PANIER = "ERROR_PANIER"
export const SUB_OTHER = "SUB_OTHER"
export const PROPOSITION_TEMPORAIRE = "PROPOSITION_TEMPORAIRE"
export const PROPOSITION_A_VALIDER = "PROPOSITION_A_VALIDER"

/* Document title */
export const DOCUMENT_TITLE_BACKOFFICE = 'SEDIS Backoffice'
export const DOCUMENT_TITLE_FRONTOFFICE = 'SEDIS ALIMENTAIRE'

/* Backoffice */
export const NOTIFICATION_INPROGRESS = 0
export const NOTIFICATION_SENT = 1
export const NOTIFICATION_HASERROR = 2

export const  DEFAULT_DIFF_DAYS_SALES= 10
export const  DEFAULT_DATAGRID_PAGE_SIZE= 30