import React, {useEffect, useRef, useState, useLayoutEffect, useContext} from "react"
import styled from "styled-components"
import {connect} from "react-redux"
import {useLocation} from "react-router-dom"

// App
import {Header, Footer, Content} from "../Composants/Template"
import {Div} from "../../Mixed/Composants/"
import {UrlPrefixContext} from "../../../context"
import {getTitreSelection} from "../../../services/helpers/FrontApiHelper"
import {Products} from "../Composants"

const HeadTitle = styled(Div)`
  background: #ffcc00;
  border-radius: 20px;
  padding: ${props => props.textmoment !== "false" ? "25px 50px" : "10px 50px"};
  font-size: 25px;
  color: #404040;
  font-weight: 500;
  font-family: Raleway, Roboto, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -35px;

  span {
    flex-shrink: 0;
    margin-right: 30px;
  }

  h1 {
    font-family: 'Covered By Your Grace', sans-serif;
    color: #ffffff;
    font-size: 50px;
    margin: 0;
  }
  @media (max-width: 820px) {
    flex-direction: column;
  }
`

const Parcourir = styled.a`
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 40px;
  margin: 60px auto 0;
  display: block;
  color: #404040;
  transition: all 0.5s ease;

  &:hover {
    color: #66b821;
  }
`

function ListeSelectionDuMomentPage(props) {
    const isMounted = useRef(false)
    const location = useLocation()
    const contextUrl = useContext(UrlPrefixContext)
    const [loader, setLoader] = useState(true)
    const [textMoment, setTextMoment] = useState(false)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    useEffect(() => {
        if (!isMounted.current) {
            getTitreSelection()
                .then(response => {
                    setTextMoment(response["hydra:member"].length && response["hydra:member"][0]["textDuMoment"])
                })
            isMounted.current = true
            setLoader(false)
        }
    }, [props.filter])

    const _renderProducts = () => {
        return (
            <Products
                paged={1}
                special="selmoment"
            />
        )
    }

    return (
        <Div className="front__wrap-screen page-selection">
            <Header/>
            <Content loading={loader}>
                <HeadTitle textmoment={textMoment.toString()}>
                    {textMoment ?
                        <><span>Notre sélection du moment : </span><h1>{textMoment}</h1></>
                        : <span>Notre sélection du moment</span>
                    }
                </HeadTitle>
                {_renderProducts()}
                <Div className="d-flex">
                    <Parcourir href={contextUrl.prefix + "/nos-produits/cremerie"}>PARCOURIR NOS RAYONS</Parcourir>
                </Div>
            </Content>
            <Footer/>
        </Div>
    )

}

const mapStateToProps = (state) => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(ListeSelectionDuMomentPage)
