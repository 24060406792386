import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// App
import CartItem from "./CartItem";
import { UrlPrefixContext } from '../../../context';

const Lists = styled.div`
  background: #ffffff;
  border : 1px solid #e0dfd7;
`;

const LinkText = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ff5d1f;
  @media screen and (max-width:2000px){
    font-size: 17px;
  }
`;

const GoBack = styled.div`
  padding: 11px 30px;
  border-bottom: solid 1px #e0dfd7;
  @media screen and (max-width:2000px){
    padding: 8px 20px;
  }
`;

const Inner = styled.div`
  padding: 11px 0px;
`;

class CartLists extends  React.Component{
    render(){
        const { data } = this.props
        return (
            <Lists>
                <GoBack>
                    <Link to={`${this.context.prefix}/commande-rapide`}>
                        <img src="/img/chevron-left.svg" className={"mr-3"} width={12} alt={"Left"} />
                        <LinkText>Retour aux produits</LinkText>
                    </Link>
                </GoBack>
                <Inner>
                    { data && data.length &&
                        data.map( (item, key) => <CartItem key={item.CodeArticle} data={item} /> )
                    }
                </Inner>
            </Lists>
        )
    }

}

CartLists.contextType = UrlPrefixContext

CartLists.defaultProps = {
    data : []
}

CartLists.propTypes = {
    data : PropTypes.array.isRequired
}

export default CartLists;