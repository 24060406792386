import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, prefixBack, prefixFront } from '../../../../constants'
import formateDatetime from '../../../../services/helpers/formateDatetime'
import Loading from '../../Loading'

const newHistory = createBrowserHistory()

export default class Customer extends Component {

    state = {
      isAuthenticated: false,
      listCustomer: []
    };

    nameMenu = 'CustomerInvalid'

    componentDidMount() {
        this.renderListCustomer();
    }

    renderListCustomer = async () => {
        const data = await connectApi('customers-failure', 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                // this will re-render the view with new data
                this.setState({
                    listCustomer: data
                });
                localStorage.setItem('totalCustomer', JSON.stringify(data.length))
                localStorage.setItem('totalUnreadCustomer', 0)
                localStorage.setItem('gardeFouCustomerIsClicked', true)
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    render() {
        const listCustomer = this.state.listCustomer?.map((customer) => (
            <tr key={
                `customer${ customer.Identifiant }`
                }>
                <td className="date">
                    { formateDatetime(customer.createdAt, 'd/m/Y', true) }
                </td>
                <td className="CodeClient">{customer.CodeClient}</td>
                <td className="Identifiant">{customer.Identifiant}</td>
                <td className="Email">{customer.Email}</td>
                <td className="Motif">{customer.Motif}</td>
            </tr>
        ));
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Liste des clients invalides</h5>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                            <li className="breadcrumb-item">Système</li>
                            <li className="breadcrumb-item"><a href={ prefixBack + '/totalgardefou' }>Garde-fou</a></li>
                            <li className="breadcrumb-item active">clients invalides</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="table-responsive">
                                    <table id="customerInvalid" className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="table-dark">
                                            <th className="date">Date et heure</th>
                                            <td className="CodeClient">CodeClient</td>
                                            <td className="Identifiant">Identifiant</td>
                                            <td className="Email">Email</td>
                                            <td className="Motif">Motif</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listCustomer }
                                        </tbody>
                                        <tfoot>
                                            <tr className="table-dark">
                                            <th className="date">Date et heure</th>
                                            <td className="CodeClient">CodeClient</td>
                                            <td className="Identifiant">Identifiant</td>
                                            <td className="Email">Email</td>
                                            <td className="Motif">Motif</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Loading/>
                    </div>
                </section>
                
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}
