import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Column(props){
    const { sm, md, lg, xl, xxl, className } = props;
    let columnClass = 'col-12';
    if( sm !== undefined  ) columnClass += ` col-sm-${sm}`;
    if( md !== undefined  ) columnClass += ` col-md-${md}`;
    if( lg !== undefined  ) columnClass += ` col-lg-${lg}`;
    if( xl !== undefined  ) columnClass += ` col-xl-${xl}`;
    if( xxl !== undefined  ) columnClass += ` col-xxl-${xxl}`;
    return (    
        <div {...props} className={ classNames(columnClass, className) }>{props.children}</div>
    )
}

Column.propTypes = {
    sm : PropTypes.number,
    md : PropTypes.number,
    lg : PropTypes.number,
    xl : PropTypes.number
}

export default Column;