

export function checkUserForm(state){
    let formatFieldsAreOK = true
    if (state.email.length > 0) {
        if (checkEmail(state.email)) {
            //document.getElementById('errorFormatEmail').style.display = 'block';
            formatFieldsAreOK = false
        }
        if (state.email.trim() === "") {
            document.getElementById("errorEmail").style.display = "block"
            formatFieldsAreOK = false
        }
    } else {
        document.getElementById("errorEmail").style.display = "block"
        formatFieldsAreOK = false
    }

    if (state.password.length > 0) {
        if (state.password.trim().length < 6) {
            document.getElementById("errorFormatPassword").style.display = "block"
            formatFieldsAreOK = false
        }
    }
    if (state.firstName.length > 0) {
        if (state.firstName.trim() === "") {
            document.getElementById("errorfirstName").style.display = "block"
            formatFieldsAreOK = false
        }
    } else {
        document.getElementById("errorfirstName").style.display = "block"
        formatFieldsAreOK = false
    }
    if (state.lastName.length > 0) {
        if (state.lastName.trim() === "") {
            document.getElementById("errorlastName").style.display = "block"
            formatFieldsAreOK = false
        }
    } else {
        document.getElementById("errorlastName").style.display = "block"
        formatFieldsAreOK = false
    }
    if (state.role === null) {
        document.getElementById("errorRole").style.display = "block"
        formatFieldsAreOK = false
    }
    return formatFieldsAreOK
}

export function checkEmail(email){
    return /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(email.trim()) === false
}