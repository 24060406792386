import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"
import {toast} from "react-toastify"

// App
import Top from "./TopFooter"
import Bottom from "./BottomFooter"
import {Button} from "../Forms"
import {Container, Modal, Section} from "../../../Mixed/Composants"

const Wrap = styled(Section)`
  background: #fff;
  padding: 66px 0;
  @media screen and (max-width: 2000px) {
    padding: 56px 0 60px;
  }
`
const InnerModal = styled.div`
  padding: 0;
`

const Validate = styled(Button)`
  line-height: 50px !important;
  font-size: 16px !important;
  padding: 0 26px !important;
  border-radius: 50px !important;
`
const WrapButton = styled.div`
  margin: 1.1em 0 1em;
  display: flex;
  justify-content: space-between;
`

const Label = styled.p`
  font-size: 1em;
  margin: 0 0 1.1em;
`

const Input = styled.input`
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  margin-right: 1em;
`

const Span = styled.label`
  display: inline-block;
  vertical-align: middle;
  font-weight: 400 !important;
  cursor: pointer;
`

const Filtres = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 .5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const Filtre = styled.li`
  flex: 0 0 50%;
  margin-bottom: 10px;
`

const Space = styled.hr`
  margin: 1em 0;
`

const Tag = styled(Filtre)`
  flex: 0 0 100%;
`

class Footer extends React.Component {

    _isMounted = false
    state = {
        frais: false,
        dph: false,
        sec: false,
        pets: false,
        halal: false,
        promo: false,
        dluo: false
    }

    _resetProductFilter = () => {
        localStorage.setItem("index_rayon", 0)
        const {dispatch} = this.props
        this.setState({
            frais: true,
            dph: true,
            sec: true,
            pets: true,
            halal: false,
            promo: false,
            dluo: false
        })

        let tags = []
        let filter = ["Frais", "DPH", "Sec", "PetsFood", "Halal"]

        toast("Vos filtres sont désactivés", {
            type: "success",
            autoDismiss: true
        })
        document.getElementById("closeThisModal").click()
        dispatch([
            {type: "SET_FILTER", value: filter},
            {type: "SET_TAG", value: tags}
        ])
    }

    _applyProductFilter = () => {
        localStorage.setItem("index_rayon", 0)
        let tags = []
        let filter = []
        const {dispatch} = this.props
        const {frais, dph, sec, pets, halal, promo, dluo} = this.state

        if (frais) filter.push("Frais")
        if (dph) filter.push("DPH")
        if (sec) filter.push("Sec")
        if (pets) filter.push("PetsFood")
        if (halal) tags.push("Halal")
        if (promo) tags.push("Promotion")
        if (dluo) tags.push("Depassee")

        if (filter.length === 0) {
            toast("Aucun filtre n'est appliqué.", {
                type: "error",
                autoDismiss: true
            })
            return false
        }
        toast("Votre filtre est appliqué", {
            type: "success",
            autoDismiss: true
        })
        document.getElementById("closeThisModal").click()
        dispatch([
            {type: "SET_FILTER", value: filter},
            {type: "SET_TAG", value: tags}
        ])
    }

    _renderModalMagasin = () => {
        const {frais, dph, sec, pets, halal, promo, dluo} = this.state
        return (
            <Modal title="Filtrer vos produits" show={false}>
                <InnerModal>
                    <Label>Catégories :</Label>
                    <Filtres>
                        <Filtre>
                            <Span>
                                <Input type="checkbox" checked={frais} value="frais" className="form-control"
                                       onChange={() => this.setState({frais: !frais})}/>Frais
                            </Span>
                        </Filtre>
                        <Filtre>
                            <Span>
                                <Input type="checkbox" checked={dph} value="dph" className="form-control"
                                       onChange={() => this.setState({dph: !dph})}/>DPH
                            </Span>
                        </Filtre>
                        <Filtre>
                            <Span>
                                <Input type="checkbox" checked={sec} value="sec" className="form-control"
                                       onChange={() => this.setState({sec: !sec})}/>Sec
                            </Span>
                        </Filtre>
                        <Filtre>
                            <Span>
                                <Input type="checkbox" checked={pets} value="pets" className="form-control"
                                       onChange={() => this.setState({pets: !pets})}/>Pets-Food
                            </Span>
                        </Filtre>
                    </Filtres>
                    <Space/>
                    <Label>N’afficher que les produits :</Label>
                    <Filtres>
                        <Tag>
                            <Span>
                                <Input type="checkbox" checked={halal} value="halal" className="form-control"
                                       onChange={() => this.setState({halal: !halal})}/>Halal
                            </Span>
                        </Tag>
                        <Tag>
                            <Span>
                                <Input type="checkbox" checked={promo} value="promo" className="form-control"
                                       onChange={() => this.setState({promo: !promo})}/>Promotion
                            </Span>
                        </Tag>
                        <Tag>
                            <Span>
                                <Input type="checkbox" checked={dluo} value="dluo" className="form-control"
                                       onChange={() => this.setState({dluo: !dluo})}/>DLUO Dépassé
                            </Span>
                        </Tag>
                    </Filtres>
                    <WrapButton>
                        <Validate type="button" variant="secondary" loader={false} contain="outlined"
                                  onClick={this._resetProductFilter}>Désactiver les filtres</Validate>
                        <Validate type="button" variant="secondary" loader={false} contain="filled"
                                  onClick={this._applyProductFilter}>Appliquer les filtres</Validate>
                    </WrapButton>
                </InnerModal>
            </Modal>
        )
    }

    componentDidMount() {
        const {filter, tag} = this.props
        this._isMounted = true
        if (this._isMounted && typeof (filter) == "object") {
            this.setState({
                frais: !!filter.includes("Frais"),
                dph: !!filter.includes("DPH"),
                sec: !!filter.includes("Sec"),
                pets: !!filter.includes("PetsFood")
            })
        }
        if (this._isMounted && typeof (tag) == "object") {
            this.setState({
                halal: !!tag.includes("Halal"),
                promo: !!tag.includes("Promotion"),
                dluo: !!tag.includes("Depassee")
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <Wrap className="front__main-footer">
                <Container fluid>
                    <Top/>
                    <Bottom/>
                </Container>
                {this._renderModalMagasin()}
            </Wrap>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        tag: state.tag.tag,
        filter: state.filter.filter
    }
}

export default connect(mapStateToProps)(Footer)