import {Component} from "react"
import styled from "styled-components"
import DatePicker, {registerLocale} from "react-datepicker"
import fr from 'date-fns/locale/fr'
import {format} from "date-fns";
import {UrlPrefixContext} from "../../../context"
import {connect} from "react-redux"
import {BeatLoader} from "react-spinners"

// App
import {Content} from "../Composants/Template"
import {SectionTitle} from "../Composants"
import {Div, Column, Row} from '../../Mixed/Composants'
import connectApi from "../../../services/helpers/connectApi"

import "react-datepicker/dist/react-datepicker.css"
import {toast} from "react-toastify"
import {prefixFront} from "../../../constants";

const Wrapall = styled(Div)`
  .front__main-content {
    margin: 0;
    padding: 30px 0;
  }
`
const Card = styled.div`
  padding: 2em;
  margin: 0;
  border-radius: 0;
  box-shadow: inherit;
  border: 1px solid #e0dfd7;
`;
const Input = styled.input`
  height: auto;
  padding: .8em 1em;
  border-radius: 0;
  background: #ffffff !important;
`;
const Textarea = styled.textarea`
  height: 3em;
  padding: .8em 1em;
  border-radius: 0;
  background: #ffffff !important;
`;
const DateWrapper = styled.div`
  input {
    height: auto;
    padding: .8em 1em;
    border-radius: 0;
    background: #ffffff !important;
  }
`

const Info = styled(Div)`
  margin-top: 1em
`

const Loader = styled(Column)`
  display: flex;
  align-items: center;
`

const BackToHome = styled.a`
  margin-bottom: 2em;
  color: #000000;
  display: block;
`

class DemandeEnlevement extends Component {
    constructor(props) {
        super(props);
        const date = this._roundMinDate(new Date());
        this.state = {
            isConnected: false,
            codeClient: '',
            societe: '',
            currentDate: '',
            dateEnlevement: '',
            nomTransporteur: '',
            commentaires: '',
            loading: false,
            minDate: new Date(),
            dateValid: this._isWeekday(date) && this._checktime(date),
            dateChanged: false
        };

        connectApi('server-datetime/1', 'GET', null).then(result => {
            this.setState({
                currentDate: new Date(result.date)
            })

            this._getMinDate()
        })
    }

    componentDidMount() {
        if (typeof (this.props.customer) !== 'undefined' && this.props.customer.user) {
            this.setState({
                isConnected: true,
                codeClient: this.props.customer.user.CodeClient,
                societe: this.props.customer.user.firstname
            })
        }
    }

    _handleSubmitForm(e) {
        this.setState({loading: true})

        connectApi('demande-enlevement', 'POST', {
            CodeClient: +this.state.codeClient,
            Societe: this.state.societe,
            DateEnlevement: format(this.state.dateEnlevement, "yyyy/MM/dd HH:mm"),
            NomTransporteur: this.state.nomTransporteur,
            Commentaires: this.state.commentaires
        }).then(response => {
            if (response.hasOwnProperty('status') && response.status === 1) {
                toast('Votre demande a été bien envoyé', {
                    type: "success",
                    autoDismiss: true
                })
            } else {
                toast("Il y a eu une erreur dans l'envoi du formulaire, vous pouvez réessayer ou nous contacter au contact@sedisalimentaire.com ou 09 54 45 67 88", {
                    type: "error",
                    autoDismiss: true
                })
            }
        }).finally(() => {
            this.setState({loading: false})
        })
        e.preventDefault();
    }

    _isWeekday(date) {
        const day = date.getDay();
        return day !== 0;
    }

    // Function that checks if the time is available
    _checktime(date) {
        const h = date.getHours();
        const mn = date.getMinutes();

        // only future time
        const currentDate = new Date();
        var isFutureTime = currentDate.getTime() < date.getTime();

        // dispo
        const dispo = {
            1: [[9, 15], [23, 24]],
            2: [[0, 15], [23, 24]],
            3: [[0, 15], [23, 24]],
            4: [[0, 15], [23, 24]],
            5: [[0, 15], [23, 24]],
            6: [[0, 12]]
        }
        const horaires = dispo[date.getDay()];
        var timevalidated = false;
        for (const id in horaires) {
            const element = horaires[id];
            timevalidated = timevalidated || (element[0] <= h && (h < element[1] || (h === element[1] && mn === 0)));
        }
        return isFutureTime && timevalidated;
    }

    _getMinDate = () => {
        const currDate = this.state.currentDate;
        const h = currDate.getHours() + currDate.getMinutes() / 60;
        let addDate = 1;

        if (h > 21.5 && currDate.getDay() > 0) {
            addDate = 2;
        }

        const result = currDate.setDate(currDate.getDate() + addDate)

        this.setState({
            minDate: result
        })

        return result
    }

    // Function that handles the change of the date
    _handleChange = (date) => {
        // Update the state with the new date
        this.setState({dateEnlevement: date});
        // Check if the date is valid
        const valid = this._isWeekday(date) && this._checktime(date);
        // Update the state with the validity of the date
        this.setState({
            dateValid: valid,
            dateChanged: true
        });
    };

    // Function that rounds the min date in minute to the nearest hour
    _roundMinDate = (date) => {
        const minutes = date.getMinutes();
        // If the minutes are less than 15, round down to 00
        if (minutes < 15) {
            date.setMinutes(0);
        }
        // If the minutes are greater than 45, round up to the next hour
        else if (minutes > 45) {
            date.setHours(date.getHours() + 1);
            date.setMinutes(0);
        }
        // Otherwise, round to 30
        else {
            date.setMinutes(30);
        }

        // Check if the rounded date is greater than the current date
        if (date.getTime() > new Date().getTime()) {
            // If yes, return the rounded date
            return date;
        } else {
            // If no, add 30 minutes to the rounded date
            date.setMinutes(date.getMinutes() + 30);
            // Return the updated date
            return date;
        }
    };

    _disableSundays = (date) => {
        const day = date.getDay();
        return day !== 0;
    }


    render() {
        registerLocale('fr', fr)
        return (
            <Wrapall className="front__wrap-screen">
                <Content>
                    <BackToHome href={prefixFront}>&larr; Retour à l'accueil</BackToHome>
                    <SectionTitle tag="h1">Demande d'enlèvement marchandise </SectionTitle>
                    <Card className="card">
                        <form onSubmit={e => this._handleSubmitForm(e)}>
                            <Row>
                                <Column lg={6}>
                                    <div className="form-group">
                                        <label htmlFor="codeclient">Code Client</label>
                                        <Input type="text" id="codeclient" className="form-control" name="CodeClient"
                                               value={this.state.codeClient}
                                               readOnly={this.state.isConnected}
                                               onChange={e => this.setState({codeClient: e.target.value})} required/>
                                    </div>
                                </Column>
                                <Column lg={6}>
                                    <div className="form-group">
                                        <label htmlFor="societe">Nom de la société</label>
                                        <Input type="text" id="societe" className="form-control" name="societe"
                                               required={true}
                                               value={this.state.societe}
                                               onChange={e => this.setState({societe: e.target.value})}/>
                                    </div>
                                </Column>
                                <Column lg={6}>
                                    <DateWrapper className="form-group">
                                        <label htmlFor="date">Date et heure d'enlèvement souhaitée</label>
                                        <DatePicker
                                            selected={this.state.dateEnlevement}
                                            onChange={this._handleChange}
                                            className="form-control"
                                            showPopperArrow={false}
                                            dateFormat="dd/MM/yyyy - HH:mm"
                                            showTimeSelect
                                            locale="fr"
                                            minDate={this.state.minDate} // Use the custom function to round the min date in minutefilterDate={this._isWeekday}
                                            filterDate={this._disableSundays}
                                            filterTime={this._checktime}
                                            timeIntervals={30} // Set the time interval to 30 minutes
                                            required
                                        />
                                    </DateWrapper>
                                </Column>
                                <Column lg={6}>
                                    <div className="form-group">
                                        <label htmlFor="transport">Nom du transporteur (si applicable)</label>
                                        <Input type="text" id="transport" className="form-control"
                                               name="NomTransporteur" value={this.state.nomTransporteur}
                                               onChange={e => this.setState({nomTransporteur: e.target.value})}/>
                                    </div>
                                </Column>
                                <Column lg={12}>
                                    <div className="form-group">
                                        <label htmlFor="comments">Commentaires/Observations </label>
                                        <Textarea id="comments" required={true} className="form-control" name="Commentaires"
                                                  value={this.state.commentaires}
                                                  onChange={e => this.setState({commentaires: e.target.value})}></Textarea>
                                    </div>
                                </Column>
                                <Column lg={2}>
                                    <button type="submit" disabled={!this.state.dateValid && this.state.dateChanged}
                                            className="btn filled secondary">Envoyer
                                    </button>
                                </Column>
                                <Column lg={12}>
                                    {!this.state.dateValid && this.state.dateChanged && ( // If the date is not valid, display an error message
                                        <p style={{color: 'red'}} className={'mt-1'}>
                                            Merci de choisir une date/heure d'enlèvement souhaitée
                                        </p>
                                    )}
                                </Column>
                                <Loader lg={3}>
                                    <BeatLoader loading={this.state.loading} color="#ff5d1f" size={15}/>
                                </Loader>
                            </Row>
                        </form>

                        <Info>
                            Les demandes doivent être effectuées au plus tard le jour pour le lendemain jusqu'à 21h30
                            (sauf cas exceptionnel à valider directement auprès du service logistique)
                        </Info>
                        <Info className={'mt-2'}>
                            Les enlèvements sont possibles sur les créneaux suivants :<br/>
                            - Lundi : 9h00-15h00 puis 23h00-0h00<br/>
                            - Mardi-Vendredi 0h00-15h00 puis 23h00-0h00<br/>
                            - Samedi 0h00-12h00<br/>
                        </Info>
                        <Info className="mt-2">
                            Pour toute demande exceptionnelle, merci de nous contacter au 06.08.30.19.96
                        </Info>
                    </Card>
                </Content>
            </Wrapall>
        );
    }
}

DemandeEnlevement.contextType = UrlPrefixContext

const mapStateToProps = (state) => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(DemandeEnlevement)