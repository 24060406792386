import React, {useState} from "react"
import {createBrowserHistory} from "history"
import "./notifications.css"
import connectApi from "../../../../services/helpers/connectApi"
import {
    HTTP_UNAUTHORIZED,
    prefixFront
} from "../../../../constants"
import DataTable from "react-data-table-component"
import NotificationsHelper from "./NotificationsHelper"
import {CheckboxField, Modal} from "./FormComponents";

export class NotificationStats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hour: '16:00',
            nbProduct: 0,
            nbCustomer: 0,
            nbMessageToSend: 0,
            limit: 6000,
            nbQueue: 0,
            onPurge: false
        };

        this._purge = this._purge.bind(this)
        this._toggleModal = this._toggleModal.bind(this)
    }

    componentDidMount() {
        const url = `notification-quota?originGeslot=true`

        connectApi(url, "GET", null).then(result => {
            this.setState({
                nbProduct: result.nbProduct,
                limit: (result.limit - result.nbSended),
                nbCustomer: result.nbCustomer,
                toSend: result.toSend,
                nbMessageToSend: result.toSend,
                isModalShowing: false
            })
        })

        connectApi('notification-schedules', "GET", null).then(result => {
            this.setState({
                hour: result[0].hour
            })
        })

        connectApi('notification-queue', "GET", null).then(result => {
            if (result.stats) {
                this.setState({
                    nbQueue: result.stats.sending
                })
            }
        });
    }

    _purge() {
        const purge = window.confirm('Voulez-vous vraiment purger la file d\'attente ?')
        if (purge) {
            this.setState({
                onPurge: true
            })
            connectApi('notification-purge-queue', 'GET', null).then(result => {
                this.setState({
                    onPurge: false,
                    isModalShowing: true
                })
            })
        }
    }

    _toggleModal(e) {
        this.setState((prevState) => ({
            isModalShowing: !prevState.isModalShowing,
            nbQueue: prevState.isModalShowing ? 0 : this.state.nbQueue
        }))
    }

    render() {
        const {hour, nbProduct, nbCustomer, nbMessageToSend, limit, nbQueue, onPurge} = this.state;

        return (<div>
            <div className="notification-stats">
                <SendingHour hour={hour}/>
                <ProductCount nbProduct={nbProduct}/>
                <RecipientCount nbCustomer={nbCustomer}/>
                <EstimateQuota nbMessageToSend={nbMessageToSend} limit={limit}/>
            </div>
            {nbQueue > 0 &&
                <div className={'mt-3 text-right w-100 d-block'}>
                    <div className="d-inline-block">
                        <div className={'small'}>Il reste <b>{nbQueue}</b> messages dans la file d'attente</div>
                        <button disabled={onPurge} onClick={this._purge}
                                className={'d-inline-block btn btn-danger btn-sm mt-1'}>
                            <i className={'fa fa-trash mr-2'}></i>
                            {onPurge ? 'Purge en cours ...' : 'Purger la file d\'attente'}
                        </button>
                    </div>

                    <Modal isShowing={this.state.isModalShowing} hide={this._toggleModal} title="File d'attente purgée"
                           className="notification-group-confirm" style={{width: 'auto'}}>
                        <button type="button" onClick={this._toggleModal} className="btn btn-secondary"
                                title="Annuler la suppression">
                            Fermer
                        </button>
                    </Modal>
                </div>
            }
            {(limit - nbMessageToSend) < 0 &&
                <div className="d-flex justify-content-center">
                    <div className={'p-4 mt-4 alert alert-danger'}>
                        <span className="icon"><i className="fas fa-circle-exclamation"></i></span>
                        Attention ! Vous allez dépasser le quota restant de <b>{limit}</b> pour l'envoi du jour
                    </div>
                </div>
            }
        </div>);
    }
}

function SendingHour(data) {
    return <div className="sending-hour">
        <div className="small-box bg-success">
            <div className="inner">
                <h6>Heure d'envoi</h6>
                <div className="data">{data.hour}</div>
            </div>
            <div className="icon"><i className="fas fa-clock"></i></div>
        </div>
    </div>
}

function ProductCount(data) {
    return <div className="product-count">
        <div className="small-box bg-success">
            <div className="inner">
                <h6>Nombre de produits valides</h6>
                <div className="data">{data.nbProduct}</div>
            </div>
            <div className="icon"><i className="fas fa-box"></i></div>
        </div>
    </div>
}

function RecipientCount(data) {
    return <div className="recipient-count">
        <div className="small-box bg-success">
            <div className="inner">
                <h6>Nombre de destinataires</h6>
                <div className="data">{data.nbCustomer}</div>
            </div>
            <div className="icon"><i className="fas fa-users-line"></i></div>
        </div>
    </div>
}

function EstimateQuota(data) {
    const countLeft = data.limit
    return <div className="estimate-quota">
        <div className="small-box bg-success">
            <div className="inner">
                <h6>Quota consommé estimé</h6>
                {isNaN(data.nbMessageToSend) &&
                    <div className="quota-info text-white d-none d-xl-inline"
                    style={{fontSize: '13px'}}>Connexion API impossible</div>}
                {!isNaN(data.nbMessageToSend) &&
                    <>
                        <div className="data">{data.nbMessageToSend}</div>
                        <div className="quota-info text-white d-none d-xl-inline"> sur <b>{countLeft}</b> restants</div>
                    </>
                }
            </div>
            <div className="icon"><i className="fas fa-gauge"></i></div>
        </div>
    </div>
}

export function SendingConditions() {
    const [active, setActive] = useState(false)
    const appEnv = process.env.REACT_APP_ENV

    useState(() => {
        connectApi('notification-schedules', "GET", null).then(result => {
            setActive(result[0].modeTest)
        })
    })

    function handleChange() {
        const data = !active
        setActive(data)
        connectApi('notification-modetest', "POST", {
            modeTest: data
        }).then()
    }

    return <div className="sending-conditions">
        <h6>Conditions d'envoi</h6>
        <div style={{display: 'flex'}}>
            <ul style={{marginBottom: '0'}}>
                <li>Produit arrivé en entrepôt</li>
                <li>Photo associée à l'article</li>
                <li>Lot débloqué</li>
            </ul>
            {appEnv === "dev" &&
                <div style={{marginLeft: '3rem', fontSize: "0.85rem"}}>
                    <CheckboxField
                        onChange={handleChange}
                        name={'modeTest'}
                        value={1}
                        checked={active}
                        children={'Envoyer les notifications uniquement au numéro défini par la variable "WHATSAPP_RECIPIENT_PHONE_NUMBER"'}/>
                </div>
            }
        </div>
    </div>
}

export class Table extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalRows: 0,
            perPage: 10,
            loading: true
        }

        this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount() {
        (async function () {
            // const count = await connectApi(`notification-quota?originGeslot=true&allProduct=true`, "GET", null)
            // this.setState({totalRows: count.nbProduct})

            await getNotificationLines.bind(this)(1)
        }).bind(this)()
    }

    handlePerRowsChange(newPerPage, page) {
        (async function () {
            this.setState({perPage: newPerPage, loading: true}, () => {
                getNotificationLines.bind(this)(page)
            })
        }).bind(this)()
    }

    handlePageChange(page) {
        (async function () {
            this.setState({loading: true}, () => {
                getNotificationLines.bind(this)(page)
            })
        }).bind(this)()
    }

    render() {
        const columns = [
            {name: "Id", selector: row => row.productId, sortable: true, width: "7vw"},
            {name: "Produit", selector: row => row.product, sortable: true, width: "20vw"},
            {name: "Variante", selector: row => row.variante, width: "15vw"},
            {name: "Arrivée entrepôt", selector: row => row.warehouseArrival, width: "7vw"},
            {name: "Photo", selector: row => row.picture, width: "7vw"},
            {name: "Lot débloqué", selector: row => row.batchUnlocked, width: "7vw"},
            {name: "Date DLC/DLUO valide", selector: row => row.validDLCDLUO, width: "12vw"}
        ]

        return <>
            <div className="notification-table">
                {this.state.loading && (
                    <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i></div>)}
                <DataTable
                    title="Produits à envoyer, en cours d'envoi ou déjà envoyés aujourd'hui"
                    columns={columns}
                    data={this.state.data}
                    fixedHeader
                    fixedHeaderScrollHeight="41vh"
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    responsive
                    className="notificationTable"
                    subHeaderAlign="center"
                    subHeaderWrap
                    noDataComponent={<p className={"py-3"}>Il n'y a aucun donné à afficher</p>}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Lignes par page:'
                    }}
                />
            </div>
        </>
    }
}


/* ================================== GLOBAL FUNCTIONS ================================== */

async function getNotificationLines(page = 1, callback = null) {
    let count = await connectApi('notification_products-count', "GET", null)
    let notificationProducts = await connectApi(`notification_products?per_page=${this.state.perPage}&page=${page}`, "GET", null)

    if (typeof (notificationProducts) !== "object") {
        return
    }
    if (notificationProducts.hasOwnProperty("code") && notificationProducts.code === HTTP_UNAUTHORIZED) {
        const history = createBrowserHistory()
        history.push(prefixFront + "/login")
        return
    }
    let data = []
    for (const notificationProduct of notificationProducts) {
        data.push(await formatNotificationRow.bind(this)(notificationProduct))
    }
    this.setState({
        data: data,
        loading: false,
        totalRows: count.count
    }, () => {
        if (callback !== null && callback instanceof Function) {
            callback()
        }
    })
}


async function formatNotificationRow(notificationProduct) {
    return {
        id: notificationProduct.stocksGeslot.CodeVarianteArticle,
        productId: notificationProduct.productsGeslot.CodeArticle,
        product: formatText(notificationProduct.productsGeslot.Libelle),
        variante: NotificationsHelper.formatDlcDluoPriceText(notificationProduct.productsGeslot, notificationProduct.stocksGeslot),
        warehouseArrival: getState(notificationProduct.warehouseArrival),
        picture: getState(notificationProduct.picture),
        batchUnlocked: getState(notificationProduct.batchUnlocked),
        validDLCDLUO: getState(notificationProduct.validDLCDLUO)
    }
}

function getState(state) {
    switch (state) {
        case true:
            return <span className="notification-status-badge badge bg-success">✔️</span>
        case false:
            return <span className="notification-status-badge badge bg-danger">✖️</span>
        default:
            return <span className="notification-status-badge badge bg-secondary">❓</span>
    }
}

function formatText(text) {
    return <div className="text-display" title={text}>
        <span>{text}</span>
    </div>
}
