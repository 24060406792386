import React, {useEffect, useState} from "react"
import HtmlStructure from "./NotificationsHtmlStructure"
import {DOCUMENT_TITLE_BACKOFFICE} from "../../../../constants"
import NotificationsComponents from "./NotificationsComponents"
import connectApi from "../../../../services/helpers/connectApi";


export default function Notifications() {
    let url = `notification-quota?originGeslot=true`
    const [limit, setLimit] = useState(0)

    useEffect(() => {
        connectApi(url, "GET", null).then(result => {
            setLimit(result.limit - result.nbSended)
        })
    });

    document.title = "Envoyer une message promotionnel - " + DOCUMENT_TITLE_BACKOFFICE
    return <HtmlStructure titleText={"Rédiger un message promotionnel"} breadcrumActiveText={"Envoyer un message"}
                          menuName="NotificationForm">
        <small className={'mb-1 font-weight-bold'}># Quota message: {limit}</small>
        <section className="notifications-send-form">
            <NotificationsComponents.Form/>
        </section>
    </HtmlStructure>
}
