const initialAvailability = {};

export default function AvailabilityReducer(state = initialAvailability, action){
    let nextState;
    switch(action.type){
        case "UPDATE_AVAILABILITY" :
            nextState = {
                ...state,
                availability : action.value
            };
            return nextState;
        // break;

        case "RESET_AVAILABILITY" :
            nextState = {
                ...state,
                availability : initialAvailability
            };
            return nextState;
        // break;

        default :
            return nextState || state;
    }
}