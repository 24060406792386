import React, {Component} from 'react'
import Header from "../../Mixed/Header/Header";
import Menu from "../../Mixed/Menu/Menu";
import {BrowserRouter as Router} from 'react-router-dom'
import Footer from "../../Mixed/Footer/Footer";
import {InputField, TextareaField} from "../Administration/Notifications/FormComponents";
import moment from "moment";
import connectApi from "../../../services/helpers/connectApi";
import DataTable from "react-data-table-component";

export default class Message extends Component {
    nameMenu = 'Message'

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            onSave: false,
            frontMessage: {
                startDate: moment().format("YYYY-MM-DD HH:mm"),
                endDate: moment().add(1, 'hour').format("YYYY-MM-DD HH:mm"),
                message: ""
            },
            frontMessages: []
        }

        this._handleTextInputChange = this._handleTextInputChange.bind(this)
        this._handleChangeDate = this._handleChangeDate.bind(this)
        this._handleCreateFrontMessage = this._handleCreateFrontMessage.bind(this)
        this._getFrontMessages = this._getFrontMessages.bind(this)
        this._deleteFrontMessage = this._deleteFrontMessage.bind(this)
        this._editFrontMessage = this._editFrontMessage.bind(this)
        this._initState = this._initState.bind(this)
        this._handleCancel = this._handleCancel.bind(this)
    }

    componentDidMount() {
        this._getFrontMessages()
    }

    _getFrontMessages() {
        this.setState({
            loading: true
        })
        connectApi("front-messages", "GET", null).then(frontMessages => {
            this.setState({
                frontMessages: frontMessages["hydra:member"]
            }, () => {
                this._initState()
                this.setState({
                    loading: false
                })
            })
        })
    }

    _handleTextInputChange(e) {
        this.setState({
            frontMessage: {
                ...this.state.frontMessage,
                message: e.target.value
            }
        })
    }

    _handleChangeDate(e) {
        const frontMessage = this.state.frontMessage
        frontMessage[e.target.name] = moment(e.target.value).format('YYYY-MM-DD HH:mm')
        this.setState({
            frontMessage
        })
    }

    _handleCreateFrontMessage(e) {
        e.preventDefault()

        if (moment(this.state.frontMessage.startDate).isAfter(this.state.frontMessage.endDate)) {
            alert("La date de début est antérieure à la date de fin.")
            return
        }

        if (!this.state.frontMessage.message) {
            alert("Veuillez renseigner un message")
            return
        }

        const frontMessage = this.state.frontMessage
        const url = frontMessage.id ? "update-front-message/" + frontMessage.id : "create-front-message"
        const method = frontMessage.id ? "PUT" : "POST"

        this.setState({
            onSave: true
        }, () => {
            connectApi(url, method, frontMessage).then(() => {
                this._getFrontMessages()
                this.setState({
                    onSave: false
                })
            })
        })
    }

    _handleCancel() {
        this._initState()
    }

    _initState() {
        this.setState({
            frontMessage: {
                startDate: moment().format("YYYY-MM-DD HH:mm"),
                endDate: moment().add(1, 'hour').format("YYYY-MM-DD HH:mm"),
                message: ""
            }
        })
    }

    _editFrontMessage(data) {
        this.setState({
            frontMessage: data
        })
    }

    _deleteFrontMessage(id) {
        if (window.confirm("Voulez-vous vraiment supprimer ?")) {
            this.setState({
                loading: true
            }, () => {
                connectApi("front-message/" + id, "DELETE", null).then(() => {
                    this._getFrontMessages()
                })
            })
        }
    }

    render() {
        const columns = [
            {
                name: "Début",
                selector: row => moment(row.startDate).format("DD/MM/YYYY HH:mm"),
                sortable: true,
                width: "150px"
            },
            {
                name: "Fin",
                selector: row => moment(row.endDate).format("DD/MM/YYYY HH:mm"),
                sortable: true,
                width: "150px"
            },
            {
                name: "Message",
                selector: row => <span title={row.message}>{row.message}</span>,
                width: "220px"
            },
            {
                name: "",
                selector: row => {
                    return <>
                        <button
                            className="btn btn-primary btn-sm d-inline-block"
                            onClick={() => {
                                this._editFrontMessage({
                                    id: row.id,
                                    startDate: moment(row.startDate).format("YYYY-MM-DD HH:mm"),
                                    endDate: moment(row.endDate).format("YYYY-MM-DD HH:mm"),
                                    message: row.message
                                })
                            }}
                            style={{
                                height: 24,
                                width: 24,
                                padding: 0,
                                fontSize: "0.65rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <i className="fa fa-edit"></i>
                        </button>
                        <button
                            className="btn btn-danger btn-sm d-inline-block ml-1"
                            onClick={() => {
                                this._deleteFrontMessage(row.id)
                            }}
                            style={{
                                height: 24,
                                width: 24,
                                padding: 0,
                                fontSize: "0.65rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </>
                },
                width: "100px"
            },
        ]

        return (<Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={this.nameMenu}/>
                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h5 className="m-0 text-dark">Message à afficher sur le front</h5>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item">Système</li>
                                            <li className="breadcrumb-item active">Message</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row" style={{justifyContent: "center"}}>
                                    <div className="col-lg-6 col-12">
                                        <form onSubmit={this._handleCreateFrontMessage}>
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <InputField className="bo-box-container" type="datetime-local"
                                                                name="startDate"
                                                                value={this.state.frontMessage.startDate}
                                                                onChange={this._handleChangeDate}
                                                                title="Plannifier une date d'envoi" context={this}>
                                                        Date de début
                                                    </InputField>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <InputField className="bo-box-container" type="datetime-local"
                                                                name="endDate"
                                                                value={this.state.frontMessage.endDate}
                                                                onChange={this._handleChangeDate}
                                                                title="Plannifier une date d'envoi" context={this}>
                                                        Date de fin
                                                    </InputField>
                                                </div>
                                            </div>

                                            <div className="text-input-container">
                                                <TextareaField
                                                    name="textInput" className="bo-box-container"
                                                    title="Message à afficher sur le front"
                                                    value={this.state.frontMessage.message}
                                                    context={this}
                                                    required
                                                    onChange={this._handleTextInputChange}>
                                                    Message à afficher sur le front
                                                </TextareaField>
                                            </div>

                                            <div className="text-center d-flex">
                                                <button disabled={this.state.onSave} type="submit"
                                                        className="btn btn-info w-100"
                                                        title="Enregistrer le message">
                                                    <i className="fa fa-save"/> Enregistrer
                                                </button>

                                                {this.state.frontMessage.id &&
                                                    <button disabled={this.state.onSave}
                                                            onClick={this._handleCancel}
                                                            className="btn btn-dark w-100 ml-2"
                                                            title="Enregistrer le message">
                                                        <i className="fa fa-cancel"/> Annuler
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                    </div>

                                    <div className="col-6">
                                        <div style={{position: "relative"}}>
                                            {this.state.loading && (
                                                <div className="loading-overlay"><i
                                                    className="fas fa-gear fa-spin loading-icon"></i></div>)}
                                            <DataTable
                                                columns={columns}
                                                data={this.state.frontMessages}
                                                noDataComponent={<p className={"py-3"}>Il n'y a aucun donné à afficher</p>}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Lignes par page:'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer/>
                </div>
            </div>
        </Router>)
    }
}
