import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {VictoryPie} from 'victory';
import {BeatLoader} from 'react-spinners';

// App
import {SectionTitle} from '../Composants';
import {Div, Column, Row} from '../../Mixed/Composants';
import {formatCurrency} from '../../../services/helpers/FrontHelper';
import {getCustomerInfo, setCustomerNotification} from '../../../services/helpers/FrontApiHelper';
import {Header, Footer, Content} from '../Composants/Template';
import _ from "lodash";

const Pie = styled.div`
  max-width: 320px;
  margin: 0 auto 0;
`;

const Card = styled.div`
  padding: 2em;
  margin: 0;
  border-radius: 0;
  box-shadow: inherit;
  border: 1px solid #e0dfd7;
`;

const Menu = styled(Card)`
  padding: 0;
`;

const Wrap = styled.div`
  margin-top: 2em;
`;

const NavLink = styled.a`
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 17px;
  padding: 13px 22px;
  line-height: 1.1;
  border-radius: 0 !important;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    color: #fff !important;
    background-color: #b3db11 !important;
  }
`;

const Input = styled.input`
  height: auto;
  padding: .8em 1em;
  border-radius: 0;
  background: #ffffff !important;
`;

const Value = styled.p`
  margin: 0;
  text-align: right;
`;

const Title = styled.h2`
  font-size: 40px;
  font-family: 'Covered By Your Grace', sans-serif;
  font-weight: 400;
  margin-bottom: .7em;
`;

const Description = styled.p`
  font-style: italic;
  font-size: 0.75em;
`;

const LabelInput = styled.label`
  vertical-align: top;
  font-weight: 400 !important;
`;
const InputTitle = styled.strong`
  display: block;
`;
const CheckboxWrapper = styled.div`
  position: relative;
  display: inline-block
`;
const Loader = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  text-align: center;
  top: 0;
  height: 100%;
`;

class InformationsPage extends React.Component {

    _isMounted = false

    state = {
        loading: false,
        customer: null,
        hasCustomer: false,
        loadingNotif: false,
        activeTab: "profile"
    }

    _renderNoInformation = () => {
        return (
            <center><p>Aucune information n'a été enregistrée</p></center>
        )
    }

    _isActiveTab(tabName) {
        return tabName === this.state.activeTab ? "active show" : ""
    }

    _isActive(tabName) {
        return tabName === this.state.activeTab
    }

    _renderTabInformations = () => {
        return (
            <React.Fragment>
                <SectionTitle tag="h1">Vos informations</SectionTitle>
                <Wrap>
                    <Row>
                        <Column lg={3}>
                            <Menu className="card">
                                <ul className="nav nav-pills flex-column">
                                    <li className="nav-item">
                                        <NavLink
                                            className={"nav-link " + (this._isActive("profile") ? "active" : "")}
                                            data-toggle="tab"
                                            onClick={() => {
                                                this.setState({
                                                    activeTab: "profile"
                                                })
                                            }}>
                                            Profile
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={"nav-link " + (this._isActive("address") ? "active" : "")}
                                            data-toggle="tab"
                                            onClick={() => {
                                                this.setState({
                                                    activeTab: "address"
                                                })
                                            }}
                                        >
                                            Adresse
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            className={"nav-link " + (this._isActive("encours") ? "active" : "")}
                                            data-toggle="tab"
                                            onClick={() => {
                                                this.setState({
                                                    activeTab: "encours"
                                                })
                                            }}
                                        >
                                            Encours
                                        </NavLink>
                                    </li>
                                </ul>
                            </Menu>
                        </Column>
                        <Column lg={9}>
                            <Card className="card">
                                <div className="tab-content" id="myTabContent">
                                    <div className={"tab-pane fade " + this._isActiveTab("profile")}
                                         id="profile"
                                         role="tabpanel"
                                         aria-labelledby="home-tab"
                                    >
                                        {this._renderTabProfile()}
                                    </div>
                                    <div className={"tab-pane fade " + this._isActiveTab("address")} id="adresse"
                                         role="tabpanel"
                                         aria-labelledby="profile-tab">
                                        {this._renderTabAdresse()}
                                    </div>
                                    <div className={"tab-pane fade " + this._isActiveTab("encours")} id="encours"
                                         role="tabpanel"
                                         aria-labelledby="contact-tab">
                                        {this._renderTabEncours()}
                                    </div>
                                    <div className="tab-pane fade" id="encours" role="tabpanel"
                                         aria-labelledby="contact-tab">
                                    </div>
                                </div>
                            </Card>
                        </Column>
                    </Row>
                </Wrap>
            </React.Fragment>
        )
    }

    _renderTabProfile = () => {
        let {customer, loadingNotif} = this.state;

        return (
            <Row>
                <Column lg={12}>
                    <div className="form-group">
                        <label htmlFor="nom">Nom</label>
                        <Input type="text" id="nom" placeholder="Nom" className="form-control"
                               value={customer.NomDuClient} readOnly/>
                    </div>
                </Column>
                <Column lg={6}>
                    <div className="form-group">
                        <label htmlFor="email">E-mail</label>
                        <Input type="text" id="email" placeholder="E-mail" className="form-control"
                               value={customer.Email} readOnly/>
                    </div>
                </Column>
                <Column lg={6}>
                    <div className="form-group">
                        <label htmlFor="telephone">Téléphone</label>
                        <Input type="text" id="telephone" placeholder="Téléphone" className="form-control"
                               value={customer.Telephone} readOnly/>
                    </div>
                </Column>
                <Column lg={6}>
                    <div className="form-group">
                        <label htmlFor="identifiant">Identifiant</label>
                        <Input type="text" id="identifiant" placeholder="Identifiant" className="form-control"
                               value={customer.Identifiant} readOnly/>
                    </div>
                </Column>
                <Column lg={6}>
                    <div className="form-group">
                        <label htmlFor="telnotif">Numéro(s) de téléphone associés aux notifications</label>

                        <div style={{minHeight: "55px", display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                            {_.map(customer.customerPhones, customerPhone =>
                                <span key={customerPhone.phone} style={{
                                    padding: '7px 15px',
                                    background: '#e0e0e0',
                                    borderRadius: '20px',
                                    whiteSpace: 'nowrap',
                                    marginRight: '3px',
                                    marginBottom: '5px'
                                }}>
                                    {customerPhone.phone}
                                </span>
                            )}
                        </div>
                        <Description>Numéro(s) de téléphone utilisé(s) pour la réception des notifications
                            WhatsApp/SMS</Description>
                    </div>
                </Column>
                <Column lg={12}>
                    <form>
                        <div className="form-group">
                            <InputTitle>Activer les notifications</InputTitle>
                            <CheckboxWrapper>
                                <input type="checkbox" name="acceptnotif" id="acceptnotif"
                                       checked={customer.NotificationAccepted} onChange={this._toggleNotif}/>&nbsp;
                                <LabelInput htmlFor="acceptnotif">J'accepte de recevoir les notifications de nouveaux
                                    produits</LabelInput>
                                {loadingNotif && <Loader><BeatLoader loading={loadingNotif} color="#ff5d1f"/></Loader>}
                            </CheckboxWrapper>
                        </div>
                    </form>
                </Column>
            </Row>
        )
    }

    _toggleNotif = e => {
        this.setState({loadingNotif: true});
        setCustomerNotification(this.state.customer.CodeClient, e.target.checked)
            .then(response => {
                this.setState({
                    customer: response
                })
            })
            .finally(() => this.setState({loadingNotif: false}))
            .catch(e => console.log(e))
    }

    _renderTabAdresse = () => {
        const {customer} = this.state;
        return (
            <Row>
                <Column lg={12}>
                    <div className="form-group">
                        <label htmlFor="codepostal">Code postal</label>
                        <Input type="text" id="codepostal" placeholder="Code postal" className="form-control"
                               value={customer.Codepostal} readOnly/>
                    </div>
                </Column>
                <Column lg={12}>
                    <div className="form-group">
                        <label htmlFor="ville">Ville</label>
                        <Input type="text" id="ville" placeholder="Ville" className="form-control"
                               value={customer.Ville} readOnly/>
                    </div>
                </Column>
                <Column lg={12}>
                    <div className="form-group">
                        <label htmlFor="adresse">Adresse</label>
                        <Input type="text" id="adresse" placeholder="Adresse" className="form-control"
                               value={customer.AdresseRue1} readOnly/>
                    </div>
                </Column>
                <Column lg={12}>
                    <div className="form-group">
                        <label htmlFor="pays">Pays</label>
                        <Input type="text" id="pays" placeholder="Adresse" className="form-control"
                               value={customer.Pays} readOnly/>
                    </div>
                </Column>
            </Row>
        )
    }

    _renderTabEncours = () => {
        const {customer} = this.state;
        let data = [];
        const encours_autorise = customer.MontantEncoursAutoriseFixe;
        const encours_utilise = customer.MontantEncoursUtilise;
        if (encours_utilise >= 0) {
            const utilise = ((encours_utilise / encours_autorise) * 100).toFixed(2);
            const restant = (100 - utilise).toFixed(2);
            if (restant < 0) {
                data = [
                    {x: 'Utilisé', y: parseFloat(utilise)},
                ];

            } else {
                data = [
                    {x: 'Restant', y: parseFloat(restant)},
                    {x: 'Utilisé', y: parseFloat(utilise)},
                ];
            }
        } else {
            data = [
                {x: 'Utilisé', y: customer.MontantEncoursUtilise},
            ]
        }
        return (
            <Row>
                <Column lg={4}>
                    <Pie>
                        <VictoryPie
                            data={data}
                            colorScale={["tomato", "orange"]}
                            labelPosition="centroid"
                            labels={({datum}) => `${datum.y}% ${datum.x} `}
                            padAngle={4}
                            innerRadius={8}
                            style={{
                                labels: {
                                    fontSize: 22, fill: "#404040"
                                }
                            }}
                        />
                    </Pie>
                </Column>
                <Column lg={8}>
                    <Title>Détail de votre encours</Title>
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Encours total autorisé</strong>
                                </td>
                                <td>
                                    <Value>{formatCurrency(customer.MontantEncoursAutoriseFixe)}</Value>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Encours utilisé</strong>
                                </td>
                                <td>
                                    <Value>{formatCurrency(customer.MontantEncoursUtilise)}</Value>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Encours restant</strong>
                                </td>
                                <td>
                                    <Value>{formatCurrency(customer.MontantEncoursAutoriseFixe - customer.MontantEncoursUtilise)}</Value>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Column>
            </Row>
        )
    }

    componentDidMount() {
        this._isMounted = true;
        const {customer} = this.props;
        if (this._isMounted) {
            if (customer && customer.hasOwnProperty('user')) {
                this.setState({loading: true});
                getCustomerInfo(customer.user.CodeClient, true)
                    .then(response => {
                        if (!response || !response.hasOwnProperty('CodeClient')) {
                            this.setState({customer: null, hasCustomer: false})
                            return false;
                        }
                        this.setState({
                            customer: response,
                            hasCustomer: true
                        })
                    })
                    .finally(() => this.setState({loading: false}))
                    .catch(e => console.log(e))
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {loading, hasCustomer} = this.state;
        return (
            <Div className="front__wrap-screen">
                <Header/>
                <Content loading={loading}>
                    {!loading && !hasCustomer && this._renderNoInformation()}
                    {!loading && hasCustomer && this._renderTabInformations()}
                </Content>
                <Footer/>
            </Div>
        )
    }

}

const mapStateToProps = state => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(InformationsPage);