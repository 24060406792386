import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames'

function Form(props){
    return (
        <form className={cls([props.className, 'form'])} method="post" onSubmit={ (event) => props.onSubmit(event) }>
            { props.children }
        </form>
    )
}

Form.propTypes = {
    onSubmit : PropTypes.func.isRequired
}

export default Form