import moment from "moment"
import connectApi from "../../../../services/helpers/connectApi"
import {envVars} from "../../../../env_var"

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Private functions ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
function initializePlaceholderProduct(selectedProduct) {
    return {
        ...selectedProduct,
        variantes: [
            {
                DateDLCDLUO: null,
                PrixVenteColis1: 0.01,
                PrixVenteColisRemise1: 0.01,
                PrixVentePalette: 0.01
            }
        ]
    }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Exported functions ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/**
 *
 * @param textInput
 * @param selectedVariant
 * @param freshData
 * @return {string}
 */
async function formatTextMessage(textInput, selectedVariant, freshData = true) {
    let selectedProduct = selectedVariant ? selectedVariant.product : null
    let alertMessage = ""
    let badgeClass = "bg-warning"
    const complementaryText = (textInput === "" || textInput === null) ? "" : `<br>${textInput.replace(/\n/g, "<br>")}<br>`
    if (selectedProduct !== null) {
        let product
        let pricePartMessage = ""

        if (freshData) {
            product = await connectApi(`products/${selectedProduct.CodeArticle}`, "GET", null)
        } else {
            product = initializePlaceholderProduct(selectedProduct)
        }

        if (selectedVariant !== null) {
            const variante = selectedVariant
            if (variante !== undefined) {
                const dlcDluo = (product.DlcOrDluo === 1) ? "DLC" : "DLUO"
                const dateDlcDluo = variante.DateDLCDLUO !== null ? moment(variante.DateDLCDLUO).format("DD/MM/YYYY") : "/invalid/"
                const lowestPrice = getMinPrice([
                    variante.PrixVenteColis1,
                    variante.PrixVenteColisRemise1,
                    variante.PrixVentePalette1
                ])
                pricePartMessage = `${dlcDluo} : ${dateDlcDluo}<br> Prix de vente à partir de : ${lowestPrice}`
                alertMessage = (!freshData) ? "Les données ci-dessous sont affichées à titre d'information. Celles-ci peuvent être différentes du message réellement envoyé au clients" : ""
            } else {
                alertMessage = "La variante du produit n'existe plus. Les données affichées ci-dessous ne sont plus complètes"
                badgeClass = "bg-danger"
            }
        }

        //TODO: remove hardcoded url ?
        const productLink = "https://destock.sedisalimentaire.com/home/frontoffice/fiche-produit/" + selectedProduct.CodeArticle

        return `<span class="badge ${badgeClass} history-alertBadge">${alertMessage}</span>
                ⭐SÉLECTION DU MOMENT⭐<br><br>
                ${product.Libelle}<br>
                ${pricePartMessage}<br>
                Nb colis/pal : ${product.NbrColisParPalette ?? "n/a"}<br><br>
                Retrouvez ce produit ici 👉 <a href="${productLink}" target="_blank">${productLink}</a><br>
                ${complementaryText}<br>
                SEDIS DESTOCK ALIMENTAIRE`
    }

    return complementaryText
}

function formatDlcDluoPriceText(product, variante) {
    if(!product || !variante) {
        return ''
    }

    const dlcDluo = (product.DlcOrDluo === 1) ? "DLC" : "DLUO"
    const dateDlcDluo = variante.DateDLCDLUO !== null ? moment(variante.DateDLCDLUO).format("DD/MM/YYYY") : "/invalid/"
    const lowestPrice = getMinPrice([variante.PrixVenteColis1, variante.PrixVenteColisRemise1])
    return `${dlcDluo} : ${dateDlcDluo} - ${lowestPrice}`
}

/**
 *
 * @param {number[]}priceArray
 * @return {string}
 */
function getMinPrice(priceArray = []) {
    priceArray = priceArray.filter((elt) => {
        return !!elt
    })
    const min = Math.min(...priceArray)
    return (min === Infinity) ? "n/a" : `${min}€ UHT `
}

function getPhotoBasePath() {
    let urlApi = envVars["url_shop_api"]
    const match = urlApi.match(/(.*)\/api\//)
    return match[1]
}

/**
 *
 * @param customerFilters
 * @param groupFilters
 * @return {Promise<{customerRecipient: *[], groupRecipient: *[]}>}
 */
async function getRecipients(customerFilters = null, groupFilters = null) {
    customerFilters = (customerFilters !== null) ? `?${customerFilters}` : ""
    groupFilters = (groupFilters !== null) ? `?${groupFilters}` : ""

    const resultCustomers = await connectApi(`customers${customerFilters}`, "GET", null)
    const resultGroups = await connectApi(`notification-groups${groupFilters}`, "GET", null)

    let groupRecipient = [], customerRecipient = []

    if (typeof (resultGroups) === "object" && resultCustomers["@type"] !== "hydra:Error") {
        resultGroups.forEach((group) => {
            groupRecipient.push({
                value: "group|$|" + group.id,
                label: `${group.name} (${group.customerList.length} clients)`
            })
        })
    }
    if (typeof (resultCustomers) === "object" && resultCustomers["@type"] !== "hydra:Error") {
        let customers = resultCustomers["hydra:member"]
        customers.forEach((customer) => {
            customerRecipient.push({
                value: "customer|$|" + customer.CodeClient,
                label: `${customer.CodeClient} : ${customer.NomDuClient}`
            })
        })
    }
    return {groupRecipient: groupRecipient, customerRecipient: customerRecipient}
}


const exported = {formatTextMessage, formatDlcDluoPriceText, getMinPrice, getPhotoBasePath, getRecipients}
export default exported
