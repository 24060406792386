import React from "react"
import styled from "styled-components"

// App
import {Branding, Search, Navbar, Filter, Account, MiniCart, Encours} from "../../Composants"
import {Container, Div, Row, Column} from "../../../Mixed/Composants"


const Wrap = styled(Div)`
  padding: 40px 0;
  @media screen and (max-width: 2000px) {
    padding: 25px 0;
  }
`

const Inner = styled.div`
  padding-left: 2em;
  @media (max-width: 820px) {
    padding-left: 0;
  }
`

const Block = styled.div`
  display: flex;
  padding-left: .4rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const MenuRow = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
  @media (max-width: 820px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`

const Widgets = styled(Block)`
  justify-content: center;
  border-radius: 6px;
  background-color: #ff0000;
`

const ColumnMiddle = styled(Column)`
  //position: static;
  @media screen and (min-width: 992px) and (max-width: 2000px) {
    -ms-flex: 0 0 60.333333%;
    flex: 0 0 60.333333%;
    max-width: 60.333333%;
  }
  //@media screen and (max-width: 1500px) {
  //  -ms-flex: 0 0 56.333333%;
  //  flex: 0 0 56.333333%;
  //  max-width: 56.333333%;
  //}
  @media (max-width: 820px) {
    width: 100%;
    flex: none;
  }
`

const ColumnWidgets = styled(Column)`
  @media screen and (min-width: 992px) and (max-width: 2000px) {
    -ms-flex: 0 0 31.333333%;
    flex: 0 0 31.333333%;
    max-width: 31.333333%;
  }
  //@media screen and (max-width: 1500px) {
  //  -ms-flex: 0 0 35.333333%;
  //  flex: 0 0 35.333333%;
  //  max-width: 35.333333%;
  //}
  @media (max-width: 820px) {
    width: 100%;
    flex: none;
    margin: 1rem 0;
  }
`

class TopHeader extends React.Component {

    render() {
        return (
            <Wrap className="front__top-header" {...this.props}>
                <Container fluid>
                    <Row>
                        <Column lg={1}>
                            <Branding/>
                        </Column>
                        <MenuRow>
                            <ColumnMiddle lg={7}>
                                <Inner>
                                    <Search/>
                                    <Navbar/>
                                </Inner>
                            </ColumnMiddle>
                            <ColumnWidgets lg={4}>
                                <Block>
                                    <Filter/>
                                    <Account/>
                                    <Widgets>
                                        <Encours/>
                                        <MiniCart/>
                                    </Widgets>
                                </Block>
                            </ColumnWidgets>
                        </MenuRow>
                    </Row>
                </Container>
            </Wrap>
        )
    }

}

export default TopHeader