import React, {Component} from "react";
import Header from "../../Mixed/Header/Header";
import Menu from "../../Mixed/Menu/Menu";
import {BrowserRouter as Router} from 'react-router-dom'
import connectApi from "../../../services/helpers/connectApi";
import {toast} from "react-toastify";

export default class Maintenance extends Component {
    nameMenu = 'Maintenance'

    state = {
        id: null,
        active: false,
        ips: "",
        onSave: false,
        loading: true
    }

    componentDidMount() {
        connectApi("maintenance", "GET", null).then(response => {
            if (response["@type"] === "hydra:Error") {
                this.setState({
                    loading: false
                }, () => {
                    toast(response["hydra:description"], {
                        type: "error",
                        autoClose: true
                    })
                })
                return
            }

            const configs = response["hydra:member"]
            if (configs && configs.length) {
                this.setState({
                    id: configs[0].id,
                    active: configs[0].active,
                    ips: configs[0].ips.trim(),
                })
            }

            this.setState({
                loading: false
            })
        })

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit() {
        this.setState({
            onSave: true
        })
        connectApi("save-maintenance", "POST", {
            id: this.state.id,
            active: this.state.active,
            ips: this.state.ips.trim()
        }).then((response) => {
            if (response["@type"] === "hydra:Error") {
                this.setState({
                    loading: false
                }, () => {
                    toast(response["hydra:description"], {
                        type: "error",
                        autoClose: true
                    })
                })
                return
            }

            toast("Configuration enregistrée avec succès", {
                type: "success",
                autoClose: true
            })
            this.setState({
                onSave: false,
                id: response.id,
                active: response.active,
                ips: response.ips.trim()
            })
        })
    }

    render() {
        return (
            <Router>
                <div className="wrapper">
                    <Header/>
                    <Menu nameMenu={this.nameMenu}/>
                    <div className={"container-fluid"}>
                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h5 className="m-0 text-dark">Mode Maintenance</h5>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">Système</li>
                                                <li className="breadcrumb-item active">Mode Maintenance</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="content ml-2">
                                {this.state.loading &&
                                    <div className="loading-overlay"><i
                                        className="fas fa-gear fa-spin loading-icon"></i></div>
                                }
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        checked={this.state.active}
                                        className="custom-control-input"
                                        id="customSwitch1"
                                        onChange={() => {
                                            this.setState({
                                                active: !this.state.active
                                            })
                                        }}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch1">
                                        Activer le mode maintenance
                                    </label>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className={"mt-4"}>
                                            <label htmlFor="" className={"mb-0"}>
                                                Adresses IPs whitelistées qui seront ignorées par le mode maintenance
                                            </label>
                                            <div>
                                                <small><i>(séparer par des entrées)</i></small>
                                            </div>

                                            <div>
                                                <textarea
                                                    className={"form-control"}
                                                    cols="30"
                                                    rows="10"
                                                    value={this.state.ips}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ips: e.target.value
                                                        })
                                                    }}></textarea>
                                            </div>

                                            <button
                                                onClick={this.handleSubmit}
                                                disabled={this.state.onSave}
                                                className={"mt-3 btn btn-info w-100"} type={"submit"}>
                                                <b>Enregistrer</b>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}
