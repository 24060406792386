import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import {toast} from "react-toastify"
import {ClipLoader} from "react-spinners"
import {connect} from "react-redux"

// App
import {envVars} from "../../../env_var"
import {Select, TextInput} from "./Forms"
import {Column, Div, Lightbox} from "../../Mixed/Composants"
import {UrlPrefixContext} from "../../../context"
import {
    createOrderLine,
    deleteOrderLine,
    getCustomerInfo,
    getProductStock,
    updateOrderLine
} from "../../../services/helpers/FrontApiHelper"
import {
    applyProductMaximumCommandale,
    formatCurrency,
    formatDateShort,
    getAllLocalProducts,
    getDefaultImage,
    getLocalOrderLine,
    getPiceGrid,
    getPiceGridNormal,
    getPiceGridRemise,
    getProductDetail,
    getProductFromStorage,
    getUnitPrice
} from "../../../services/helpers/FrontHelper"

const ProductInner = styled(Column)`
  padding: 2em 7.5px 0;
  ${({perline}) => perline === 5 && `
        @media screen and (min-width: 1500px){
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    `}
  ${({perline}) => perline === 6 && `
        @media screen and (min-width: 1200px){
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
        @media screen and (min-width: 1400px){
            -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
    `}
`

const Inner = styled.div`
  height: 100%;
  background: #fff;
  border: 2px solid #e0dfd7;
`

const Top = styled.div`
  padding: 12px 13px 10px;
  border-bottom: 2px solid #e0dfd7;
`
const Middle = styled.div`
  padding: 7px;
`

const Figure = styled.figure`
  position: relative;
  margin: 0;
  height: 170px;
  filter: ${props => props.disabled ? "grayscale(1)" : "none"};
`

const Image = styled.img`
  display: block;
  margin: 0 auto 15px;
  max-width: 90%;
  max-height: 90%;

  &.has-no-image {
    height: 80%;
    padding-top: 20px;
  }
`

const Name = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
  color: #404040;
  margin: 0 0 1em;
  @media screen and (max-width: 2000px) {
    font-size: 20px;
    line-height: 20px;
  }
`

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Label = styled.span`
  display: block;
  font-weight: 700;
  text-align: center;
  margin: 0 0 3px;
  background: ${props => props.selectionDuMoment ? "#ffcc00" : "#ffffff"};
  color: ${props => props.selectionDuMoment ? "#404040" : "#3F3F3F"};
  padding: 3px 12px;
  font-size: 11px;
  border-radius: 6px;
  position: relative;

  &:after {
    content: "";
    border-width: 6px 8px 0 8px;
    border-style: solid;
    border-color: transparent;
    border-top-color: ${props => props.selectionDuMoment ? "#ffcc00" : "#ffffff"};
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -4px;
  }
`

const Price = styled.span`
  display: block;
  font-size: 27px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #404040;
`

const Picto = styled.img`
  padding: 0;
  margin: 5px;
  max-width: 33px;
  display: block;

  &.remiseVolume {
    position: absolute;
    max-width: 20px;
    left: 6px;
  }
`

const Pictos = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 26px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 12px;
  max-width: 50px;
  flex-wrap: wrap;
`
const Livraison = styled.div`
  flex-shrink: 0;

  span {
    font-size: 9px;
    font-weight: 500;
    color: #404040;
    padding: 2px;
    display: block;
  }

  ${Picto} {
    max-width: 30px;
    height: 32px;
    margin: 0 auto;
  }
`
const DlcDluoTitle = styled.span``
const DlcDluoSingle = styled.span``
const DlcDluo = styled.label`
  display: block;
  position: relative;
  white-space: nowrap;
  font-size: 14px;

  .form-group {
    margin: 0;
    position: relative;
    display: inline-block;

    &:before, &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      pointer-events: none;
    }

    &:before {
      background: #404040;
      width: 28px;
      height: 30px;
    }

    &:after {
      border-width: 10px 10px 0 10px;
      border-style: solid;
      border-color: #ffffff transparent transparent transparent;
      margin: 10px 4px;
    }
  }

  .form-control {
    padding: 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    height: 30px;
    appearance: none;
    padding-right: 30px;
  }

  @media screen and (max-width: 2000px) {
    .form-group {
      &:before {
        width: 20px;
        height: 25px;
      }

      &:after {
        border-width: 8px 7px 0 7px;
        margin: 8px 3px;
      }
    }

    .form-control {
      height: 25px;
      padding-right: 20px;
    }
  }
`

const Prices = styled.div``

const Quantity = styled(TextInput)`
  border: none;
  border-radius: 0;
  text-align: center;
  font-size: 17px;
`

const Bloc = styled.div`
  display: flex;
  margin: 0 0 4px;
  background: ${props => props.added ? "#66b821" : "#ff5d1f"};
  padding: 2px 10px;
  align-items: center;
`

const Info = styled.span`
  display: block;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: #ffffff;
  margin-left: 6px;
  border-radius: 6px;
  @media screen and (max-width: 2000px) {
    font-size: 13px;
  }
`

const Flex = styled.div`
  flex: 1;
`

const Add = styled.button`
  background: ${props => props.added ? "#66b821" : (props.selectionDuMoment ? "#ffcc00" : "#606060")};
  color: ${props => props.added ? "#ffffff" : (props.selectionDuMoment ? "#404040" : "#ffffff")};
  display: block;
  width: 100%;
  font-size: 20px;
  padding: 10px;
  line-height: 1;
  font-weight: 800;
  border: none;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`
const Spinner = styled.span`
  margin-left: 10px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
`
const GoToFiche = styled(Link)`
  color: #404040;

  span {
    transition: all 0.3s ease;
  }

  &:hover,
  &:focus,
  &:active {
    span {
      color: #ff5d1f
    }

    img {
      opacity: .8;
    }
  }
`

const TipRemise = styled.div`
  display: ${props => props.displayed ? "block" : "none"};
  position: relative;

  span {
    position: absolute;
    bottom: 0;
    background: #66b821;
    color: #ffffff;
    padding: 5px;
    font-size: 14px;
    line-height: 1.2;
    left: 0;
    right: 0;
    opacity: 0.95;
  }
`

const Tags = styled.div`
  min-height: 23px;
`

const Tag = styled.span`
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  background: ${props => props.tag === "halal" ? "#117e15" : props.tag === "promo" || props.tag === "promotion" ? "#fd5b05" : props.tag === "derniere minute" ? "#fd0505" : props.tag === "bio" ? "#8BD600" : "#87a7a7"};
  display: inline-block;
  padding: .2rem .5rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const Notice = styled.span`
  color: #17a2b8;
  display: block;
  font-style: italic;
  font-size: .7rem;
  line-height: 1.2;
  margin: 0;
  text-align: justify;
`

class ProductItem extends React.Component {

    state = {
        product_filtered: this.props.product,
        price: 0,
        quantity: "",
        LeStock: 0,
        variant: false,
        added: false,
        loading: false,
        disabled: false,
        updated: false,
        isVenteColis: false,
        isVentePalette: false,
        dlc_dluo: false,
        select_dlc_dluo: [],
        texteRemise: "",
        priceNormal: 0,
        priceRemise: 0
    }

    qtyRef = React.createRef()

    _isCalled = false

    _getProductDetail = (quantite) => {
        let priceTotal
        const {variant} = this.state
        const {product, customer} = this.props
        const priceGrid = getPiceGrid(variant, customer, quantite)
        const unitPrice = getUnitPrice(priceGrid, this.props.product.PCB, true)
        if (product.UniteDeVente === "K" && product.PoidsColis > 0) {
            priceTotal = priceGrid * product.PoidsColis * quantite
        } else if (product.UniteDeVente === "K" && product.PoidsColis === 0) {
            priceTotal = 0.00
        } else {
            priceTotal = unitPrice * product.PCB * quantite
        }
        priceTotal = Number.isInteger(priceTotal) ? priceTotal : parseFloat(priceTotal).toFixed(2)
        const response = {
            PCB: product.PCB,
            Tags: product.Tags,
            Quantite: quantite,
            PU: unitPrice,
            PUsimple: parseFloat(unitPrice),
            SeuilRemiseVolume: variant.SeuilRemiseVolume,
            priceTotal: priceTotal,
            CodeTVA: product.CodeTVA,
            Libelle: product.Libelle,
            PriceGrid: priceGrid,
            PathPhoto: product.PathPhoto,
            FamilleID: product.FamilleID,
            DlcOrDluo: product.DlcOrDluo,
            PoidsColis: product.PoidsColis,
            CodeArticle: product.CodeArticle,
            UniteDeVente: product.UniteDeVente,
            PoidsUnitaire: product.PoidsUnitaire,
            PoidsVariable: product.PoidsVariable,
            MaxOrderEnabled: product.MaxOrderEnabled,
            NbrColisParPalette: product.NbrColisParPalette,
            ThresholdFullRelease: product.ThresholdFullRelease,
            MaxOrderPerDayPerCustomer: product.MaxOrderPerDayPerCustomer
        }
        if (variant.SeuilRemiseVolume > 0) {
            // remise au volume
            response.PUsimple = parseFloat(this.state.priceNormal)
            response.PUremise = parseFloat(this.state.priceRemise)
        }
        if (variant !== null) {
            response.EnPromo = variant.EnPromo
            response.LeStock = variant.LeStock
            response.DateDLCDLUO = variant.DateDLCDLUO
            response.DateArrivage = variant.DateArrivage
            response.VenteAuColis = variant.VenteAuColis
            response.ArriveEntrepot = variant.ArriveEntrepot
            response.VenteALaPalette = variant.VenteALaPalette
            response.DateArrivagePrev = variant.DateArrivagePrev
            response.CodeVarianteArticle = variant.CodeVarianteArticle
        }
        return response
    }

    async _addToCart() {
        const {product, customer, availability} = this.props
        const {variant, quantity, LeStock, added} = this.state
        if (this._isCalled) {
            return false
        }
        this._isCalled = true

        if (availability && availability.previousStateGeslotIsAvailable === false) {
            toast("Les commandes sont temporairement indisponibles.", {
                type: "error",
                autoDismiss: true
            })
            this._isCalled = false
            return false
        }

        let stockValue = LeStock

        if (!quantity && !added) {
            toast("Veuillez indiquer la quantité souhaitée", {
                type: "error",
                autoDismiss: true
            })
            this._isCalled = false
            return false
        }

        this.setState({loading: true})
        if (customer && customer.isAuthenticated && customer.user.role === "ROLE_CUSTOMER") {
            const status = await this._getCustomerInfo(customer, this._getProductDetail(parseInt(quantity)))
            if (!status) {
                this.setState({
                    loading: false
                })
                this._isCalled = false
                return false
            }
        }

        if (variant && variant.hasOwnProperty("CodeVarianteArticle")) {
            const stock = getProductStock(variant.CodeVarianteArticle)
            if (stock && stock.hasOwnProperty("LeStock")) {
                stockValue = stock.LeStock
            }
        }

        if (stockValue <= 0) {
            toast("Erreur technique dans la gestion du stock. Ce produit n'est pas disponible.", {
                type: "error",
                autoDismiss: true
            })
            this.setState({loading: false})
            this._isCalled = false
            return false
        }

        const data = getProductDetail(quantity, product, variant.CodeVarianteArticle, customer)
        const localProduct = getProductFromStorage(data)

        if (localProduct) {
            this._updateOrderLine(data)
        } else {
            this._createOrderLine(data)
        }

        this._isCalled = false
    }

    async _createOrderLine(data, stockAjuste = false) {
        const {product, customer} = this.props
        const {variant} = this.state
        const orderLine = getLocalOrderLine()
        const response = await createOrderLine(data, customer, orderLine)
        const qtMAxCom = applyProductMaximumCommandale(data)

        if (qtMAxCom) {
            data = getProductDetail(qtMAxCom, product, variant.CodeVarianteArticle, customer)
        }

        if (response.code === 0 || response.code === 400 || response.code === 401 || response.code === 403) {
            const message = response.code === 0 || response.code === 401 ? "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible." : response.message
            this._disableProductAndDisplayError(message)
            return false
        } else if (response.code === 480) {
            const LeStock = response.data.varianteArticle.LeStock
            if (LeStock === 0) {
                this._disableProductAndDisplayError()
            } else {
                const stock = getProductDetail(LeStock, product, variant.CodeVarianteArticle, customer)
                this._createOrderLine(stock, LeStock !== data.Quantite)
            }
            return false
        } else {
            const products = getAllLocalProducts()
            if (products.length === 0 || products.length === 1) {
                const orderLineData = {
                    "id": response.id,
                    "OrderId": response.OrderId,
                    "OrderLineId": response.OrderLineId,
                    "CodeBP": response.CodeBP
                }
                this._storeToStorage("frontOrderLine", orderLineData)
            }
            if (!response.hasOwnProperty("CodeArticle")) {
                this._disableProductAndDisplayError()
                return false
            }
            if (stockAjuste) {
                const msg_singulier = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${data.Quantite} colis a été ajouté.`
                const msg_pluriel = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${data.Quantite} colis ont été ajoutés.`
                const message = data.Quantite === 1 ? msg_singulier : msg_pluriel
                this.setState({quantity: data.Quantite})
                toast(message, {
                    type: "warning",
                    autoDismiss: true
                })
            }
            toast("Le produit est ajouté à votre panier", {
                type: "success",
                autoDismiss: true
            })
            this.setState({loading: false, added: true}, () => this._addProductToLocal({...data, orderLine: response}))
        }
    }

    async _updateOrderLine(data) {
        const {product, customer} = this.props
        const {variant} = this.state
        const localProduct = getProductFromStorage(data)
        const qtMAxCom = applyProductMaximumCommandale(data)

        if (qtMAxCom) {
            data = getProductDetail(qtMAxCom, product, variant.CodeVarianteArticle, customer)
        }

        if (localProduct && localProduct.hasOwnProperty("orderLine")) {
            const order = {...localProduct, ...data}
            if (order.Quantite > 0) {
                const response = await updateOrderLine(order.orderLine.id, order)

                if (response.code === 0 || response.code === 400 || response.code === 401 || response.code === 403) {
                    const message = response.code === 0 || response.code === 401 ? "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible." : response.message
                    this._deleteStorage("frontOrderProducts", data)
                    this._disableProductAndDisplayError(message)
                    return false
                } else if (response.code === 480) {
                    const LeStock = response.data.varianteArticle.LeStock
                    if (LeStock === 0) {
                        //this._deleteOrderLine(data);
                        this._deleteStorage("frontOrderProducts", data)
                        this._disableProductAndDisplayError()
                    } else {
                        const stock = getProductDetail(LeStock, product, variant.CodeVarianteArticle, customer)
                        const item = {...order, ...stock}
                        // const qColis = item.VenteAuColis ? LeStock : 0;
                        // const qPalette = item.VenteALaPalette ? LeStock : 0;
                        this.setState({
                            quantity: LeStock,
                            added: true,
                            updated: true,
                            isVenteColis: item.VenteAuColis,
                            isVentePalette: item.VenteALaPalette
                        }, () => {
                            this._reUpdateOrderAfterDissatisfiedStock(LeStock, item.orderLine)
                        })
                    }
                    return false
                } else {
                    if (!response.hasOwnProperty("CodeArticle")) {
                        this._disableProductAndDisplayError()
                        return false
                    }
                    toast("Le produit est ajouté à votre panier", {
                        type: "success",
                        autoDismiss: true
                    })
                    this.setState({
                        loading: false,
                        added: true
                    }, () => this._updateStorage("frontOrderProducts", {...order, orderLine: response}))
                }
            } else {
                this._deleteStorage("frontOrderProducts", data)
                deleteOrderLine(order.orderLine.id)
                toast("Le produit est supprimé de votre panier", {
                    type: "success",
                    autoDismiss: true
                })
                this.setState({
                    loading: false,
                    added: false
                })
            }

        }
    }

    _reUpdateOrderAfterDissatisfiedStock = (quantite, response) => {
        const {product, customer} = this.props
        const {variant} = this.state
        const detail = getProductDetail(quantite, product, variant.CodeVarianteArticle, customer)
        const item = {...detail, orderLine: response, geslotStockIs: quantite}
        this._updateStorage("frontOrderProducts", item)
        this._updateOrderLine(item)
        this.setState({
            quantity: item.Quantite
        })
    }

    _disableProductAndDisplayError = (message = null) => {
        const error = message ? message : "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible."
        this.setState({
            quantity: 0,
            loading: false,
            added: false,
            disabled: true
        })
        toast(error, {
            type: "error",
            autoDismiss: true
        })
    }

    _getCustomerInfo = (customer, dataProductDetailCommandee) => {
        return getCustomerInfo(customer.user.CodeClient)
            .then(response => {
                if (response) {
                    const info = {
                        MontantEncoursUtilise: response.MontantEncoursUtilise,
                        MontantEncoursAutoriseFixe: response.MontantEncoursAutoriseFixe,
                        MontantEncoursAutoriseFlexible: response.MontantEncoursAutoriseFlexible,
                        DelaiEchanceFacture: response.DelaiEchanceFacture
                    }

                    let dataCustomerWithProductCommandee = {...customer, ...dataProductDetailCommandee}
                    const data = {...dataCustomerWithProductCommandee, ...info}
                    const DelaiEchanceFacture = data.DelaiEchanceFacture
                    const encours_autorise = data.MontantEncoursAutoriseFixe
                    const encours_autorise_flexible = data.MontantEncoursAutoriseFlexible
                    const encours_utilise = data.MontantEncoursUtilise
                    const encours_restent = encours_autorise - encours_utilise
                    const encours_restent_flexible = encours_autorise_flexible - encours_utilise
                    // let montantHT = this._getOrderPriceTotal()
                    let MontantHTArticleCommandee = parseFloat(data.priceTotal)

                    let compteurDepassementEncoursFlexible = localStorage.getItem("compteurDepassementEncoursFlexible")
                    if (compteurDepassementEncoursFlexible == null && compteurDepassementEncoursFlexible === undefined) {
                        compteurDepassementEncoursFlexible = 0
                    }

                    // montantHT += MontantHTArticleCommandee
                    let diffAmountArticleCommandee = MontantHTArticleCommandee
                    const products = getAllLocalProducts()
                    if (products && products.length) {
                        products.forEach((item) => {
                            if (item.CodeArticle === data.CodeArticle) {
                                // montantHT -= parseFloat(item.priceTotal)
                                diffAmountArticleCommandee -= parseFloat(item.priceTotal)
                            }
                        })
                    }
                    diffAmountArticleCommandee = diffAmountArticleCommandee < 0 ? -1 * diffAmountArticleCommandee : diffAmountArticleCommandee
                    this.props.dispatch({type: "SET_CUSTOMER", value: data})

                    if (DelaiEchanceFacture > 0) {
                        toast("Vous avez des factures non-réglées ayant dépassé votre délai de paiement de x jours, merci de régulariser la situation pour pouvoir commander de nouveau !", {
                            type: "error",
                            autoDismiss: true
                        })
                        return false
                    }

                    if (encours_restent <= 0 || (diffAmountArticleCommandee > encours_restent && diffAmountArticleCommandee > encours_restent_flexible)) {
                        toast("Votre encours est insuffisant pour passer une commande !", {
                            type: "error",
                            autoDismiss: true
                        })
                        return false
                    } else if (diffAmountArticleCommandee < encours_restent && encours_autorise_flexible > 0 && diffAmountArticleCommandee > encours_restent_flexible) {
                        localStorage.setItem("compteurDepassementEncoursFlexible", parseInt(compteurDepassementEncoursFlexible) + 1)
                        compteurDepassementEncoursFlexible = localStorage.getItem("compteurDepassementEncoursFlexible")
                        if (compteurDepassementEncoursFlexible === 2) {
                            localStorage.setItem("firstMontantHTDepassantEncoursFlexible", diffAmountArticleCommandee)
                        }
                        if (compteurDepassementEncoursFlexible > 1) {
                            let firstMontantHTDepassantEncoursFlexible = parseFloat(localStorage.getItem("firstMontantHTDepassantEncoursFlexible"))
                            if (diffAmountArticleCommandee > firstMontantHTDepassantEncoursFlexible) {
                                toast("Votre encours est insuffisant pour passer une commande !", {
                                    type: "error",
                                    autoDismiss: true
                                })
                                return false
                            }
                            return true
                        }
                        return true
                    }
                    return true
                }
                return false
            })
    }

    _getOrderPriceTotal = () => {
        let total = 0
        const products = getAllLocalProducts()
        if (products && products.length) {
            products.forEach((item) => {
                total += parseFloat(item.priceTotal)
            })
        }
        return total
    }

    _persistDataToStore = (data) => {
        let value = []
        if (data.length) {
            data.forEach(item => {
                if (item.hasOwnProperty("orderLine")) {
                    value.push(item)
                }
            })
            this.props.dispatch({type: "SET_ORDER", value: value})
        } else {
            this.props.dispatch({type: "SET_ORDER", value: []})
        }
    }

    _storeToStorage = (name, data) => {
        const {onUpdate} = this.props
        if (!name || !data) {
            return false
        }
        localStorage.setItem(name, JSON.stringify(data))
        if (typeof (onUpdate) === "function") {
            onUpdate()
        }
        if (name === "frontOrderProducts") {
            this._persistDataToStore(data)
        }
    }

    _addProductToLocal = (data) => {
        let products = getAllLocalProducts()
        if (products) {
            products.push(data)
        }
        this._storeToStorage("frontOrderProducts", products)
    }

    _updateStorage = (name, value) => {
        if (!name || !value) {
            return false
        }
        const tmp = []
        const data = name === "frontOrderProducts" ? getAllLocalProducts() : getLocalOrderLine()
        data.forEach(item => {
            if (name === "frontOrderProducts" && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                item = value
            }
            if (name === "frontOrderLine" && item.OrderId === value.OrderId) {
                item = value
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp)
    }

    _deleteStorage = (name, value) => {
        if (!name || !value) {
            return false
        }
        const tmp = []
        const data = name === "frontOrderProducts" ? getAllLocalProducts() : getLocalOrderLine()
        data.forEach(item => {
            if (name === "frontOrderProducts" && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                // item = value;
                return false
            }
            if (name === "frontOrderLine" && item.OrderId === value.OrderId) {
                // item = value;
                return false
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp)

    }

    _renderProductTags = () => {
        const {product} = this.props
        let tags = [];

        product.Tags.forEach(tag => {
            tags = tags.concat(tag.split(',').map(t => t.trim()));
        });

        let isPromo = false
        product.variantes.forEach((variant) => {
            if (variant && variant.EnPromo === "1") {
                isPromo = true
            }
        })
        if (isPromo && !tags.includes("Derniere minute")) {
            tags = [...tags, "Promo"]
        }
        if (tags && tags.length) {
            return (
                <Tags>
                    {
                        tags.map((tag) => {
                            if (tag) {
                                return (
                                    <Tag tag={tag.toLowerCase()} key={tag}>{tag}</Tag>
                                )
                            }
                            return null
                        })
                    }
                </Tags>
            )
        }
    }

    _getDLCLDUOValue = (CodeVarianteArticle) => {
        const {product} = this.props
        if (product.variantes && CodeVarianteArticle) {
            const dlc_dluo = product.variantes.filter(item => item.CodeVarianteArticle === CodeVarianteArticle)
            return dlc_dluo.length ? dlc_dluo[0] : false
        }
        return false
    }

    _handleDlcDluoChange = (e) => {
        const variant = this._getDLCLDUOValue(e.target.value)
        const {customer} = this.props
        const {product_filtered, priceRemise} = this.state
        const price_grid_simple = getPiceGridNormal(variant, customer)
        const price_unit_simple = getUnitPrice(price_grid_simple, product_filtered.PCB, true)
        const price_grid_remise = getPiceGridRemise(variant, customer, variant.SeuilRemiseVolume)
        const price_unit_remise = getUnitPrice(price_grid_remise, product_filtered.PCB, true)
        this.setState({
            variant: this._getDLCLDUOValue(e.target.value),
            price: priceRemise,
            dlc_dluo: e.target.value,
            priceNormal: price_unit_simple,
            priceRemise: price_unit_remise
        })
    }

    _filterVariants() {
        const {product, special} = this.props
        let variantes = product.variantes
        let product_new = product
        if (special) {
            variantes = variantes.filter(variant => {
                if (special === "selmoment") {
                    return variant.SelectionDuMoment
                } else if (special === "promo") {
                    return parseInt(variant.EnPromo) === 1
                } else {
                    return true
                }
            })
            product_new["variantes"] = variantes
        }
        this.setState({
            product_filtered: product_new
        })
    }

    componentDidMount() {
        const {product, customer} = this.props
        const {product_filtered} = this.state
        if (product.hasOwnProperty("variantes")) {
            this._filterVariants()
            const variant = product.variantes[0]
            const data = getProductFromStorage(variant)

            const price_grid_simple = getPiceGridNormal(variant, customer)
            const price_unit_simple = getUnitPrice(price_grid_simple, product.PCB, true)
            const price_grid_remise = getPiceGrid(variant, customer, variant.SeuilRemiseVolume)
            const price_unit_remise = getUnitPrice(price_grid_remise, product.PCB, true)
            this.setState({
                variant: variant,
                price: price_unit_remise,
                dlc_dluo: variant.CodeVarianteArticle,
                LeStock: variant.LeStock,
                isVenteColis: variant.VenteAuColis,
                isVentePalette: variant.VenteALaPalette,
                disabled: !variant.VenteAuColis && !variant.VenteALaPalette,
                priceNormal: price_unit_simple,
                priceRemise: price_unit_remise
            })
            if (data) {
                this.setState({
                    added: true,
                    quantity: data.Quantite,
                    isVenteColis: data.VenteAuColis,
                    isVentePalette: data.VenteALaPalette
                })
                if (data.hasOwnProperty("geslotStockIs") && data.geslotStockIs === data.Quantite) {
                    this.setState({
                        quantity: data.geslotStockIs
                    })
                }
            }

            let dlcdluo = []
            product_filtered.variantes.forEach(item => {
                const label = `${formatDateShort(item.DateDLCDLUO)}`
                dlcdluo.push({value: item.CodeVarianteArticle, label: label})
            })
            this.setState({
                select_dlc_dluo: dlcdluo
            })
        } else {
            this.setState({
                price: 0,
                disabled: true,
                isVenteColis: false,
                isVentePalette: false
            })
        }

        let qtyInput = this.qtyRef.current.querySelector("input")
        let _this = this
        qtyInput.addEventListener("keyup", function (event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.keyCode === 13 && qtyInput.value > 0) {
                event.preventDefault()
                _this._addToCart()
            }
        })
    }

    _handleChangeQuantity = (e) => {
        const {variant, isVenteColis, priceNormal, priceRemise} = this.state
        const newQty = e.target.value
        this.setState({quantity: newQty})
        if (isVenteColis && variant.SeuilRemiseVolume > 0) {
            if (newQty < variant.SeuilRemiseVolume) {
                this.setState({texteRemise: `Commandez plus de ${variant.SeuilRemiseVolume} colis pour bénéficier du tarif préférentiel avec remise au volume (${formatCurrency(priceRemise)} au lieu de ${formatCurrency(priceNormal)}), plus que ${variant.SeuilRemiseVolume - newQty} colis pour y arriver`})
            } else {
                this.setState({texteRemise: `Félicitations, vous bénéficiez désormais du tarif avec remise au volume !`})
            }
        }

    }

    render() {

        const {product, perLine, rayons} = this.props
        const {
            price,
            quantity,
            loading,
            isVenteColis,
            isVentePalette,
            added,
            updated,
            disabled,
            dlc_dluo,
            select_dlc_dluo,
            variant,
            texteRemise,
            priceRemise,
            priceNormal
        } = this.state
        const api_path = envVars.url_shop_api.replace("/api/", "")
        const img_path = product.PathPhoto ? api_path + product.PathPhoto : getDefaultImage(rayons, product)
        const single_dlc_dluo = typeof (select_dlc_dluo[0]) === "object" ? select_dlc_dluo[0].label : false
        const stock = variant.ArriveEntrepot ?
            <>
                <Picto src="/img/i-stock.svg"/>
                <span>EN STOCK</span>
            </> :
            <>
                <Picto src="/img/i-camion.svg"/>
                <span>{formatDateShort(variant.DateArrivage)}</span>
            </>
        const titre = product.Libelle.replace(/([A-Za-z])\/([A-Za-z])/gi, "$1 / $2")
        // console.log(variant.LeStock, product.CodeArticle, titre)
        return (
            <ProductInner md={4} lg={3} xxl={2} perline={perLine}>
                {this._renderProductTags()}
                <Inner>
                    <Top>
                        <Lightbox>
                            <Figure disabled={disabled}>
                                {isVenteColis && updated &&
                                <Notice>Le stock disponible ne nous permet pas de satisfaire votre
                                    demande, <b>{quantity}</b> {quantity === 1 ? "colis a été ajouté." : "colis ont été ajoutés"}
                                </Notice>}
                                <a href={img_path} title={product.Libelle}>
                                    <Image src={img_path} alt={product.Libelle}
                                           className={product.PathPhoto ? "has-image" : "has-no-image"}/>
                                </a>
                            </Figure>
                        </Lightbox>
                        <Name className="front__product-libelle">
                            <GoToFiche
                                to={`${this.context.prefix}/fiche-produit/${product.CodeArticle}`}><span>{titre}</span></GoToFiche>
                        </Name>
                        <Wrap>
                            <Prices>
                                <Label
                                    selectionDuMoment={variant.SelectionDuMoment}>Prix {product.UniteDeVente === "K" ? " Kg " : ""} à
                                    partir de :</Label>
                                <Price>{formatCurrency(price)}</Price>
                            </Prices>
                            <Pictos>
                                {isVenteColis && <Picto src="/img/icons-colis.svg"/>}
                                {isVentePalette && variant.SeuilRemiseVolume === 0 &&
                                <Picto src="/img/icons-palette.svg"/>}
                                {isVenteColis && variant.SeuilRemiseVolume > 0 &&
                                <Picto src="/img/icon-pourcentage.png" className="remiseVolume"
                                       title={`Commandez plus de ${variant.SeuilRemiseVolume} colis et bénéficiez d'un tarif de ${formatCurrency(priceRemise)} au lieu de ${formatCurrency(priceNormal)}`}/>}
                            </Pictos>
                        </Wrap>
                    </Top>
                    <Middle>
                        <Wrap>
                            <Livraison>
                                {stock}
                            </Livraison>
                            <DlcDluo className="front__select-dlcdluo">
                                <DlcDluoTitle>{product.DlcOrDluo === 1 ? "DLC" : "DLUO"} : </DlcDluoTitle>
                                {select_dlc_dluo.length > 1 ?
                                    <Select fieldname="dlc_dluo"
                                            placeholder={!isVenteColis && !isVentePalette ? "DLC/DLUO" : false}
                                            value={dlc_dluo} data={select_dlc_dluo}
                                            disabled={!isVenteColis && !isVentePalette ? "disabled" : dlc_dluo.length === 0 ? "disabled" : ""}
                                            onChange={this._handleDlcDluoChange}/> :
                                    <DlcDluoSingle>{single_dlc_dluo}</DlcDluoSingle>
                                }
                            </DlcDluo>
                        </Wrap>
                    </Middle>
                    <TipRemise displayed={texteRemise !== ""}><span>{texteRemise}</span></TipRemise>
                    <Bloc className={added ? "front__qt-product product-added" : "front__qt-product"} ref={this.qtyRef}
                          added={added}>
                        <Quantity type="number" fieldname="qtColis" value={quantity} placeholder="0" disabled={disabled}
                                  min={0} max={500} onChange={this._handleChangeQuantity}
                                  onBlur={() => this.setState({texteRemise: ""})} onFocus={(e) => e.target.select()}/>
                        <Flex>
                            <Info>{`${isVenteColis ? "Colis" : isVentePalette ? "Palette" : ""} ${!added ? "à commander" : "commandé"}`}</Info>
                        </Flex>
                    </Bloc>
                    <Div>
                        <Add type="button" disabled={disabled} contain="outlined"
                             selectionDuMoment={variant.SelectionDuMoment} added={added}
                             onClick={() => this._addToCart()}>
                            {added ? "MODIFIER" : "AJOUTER!"}
                            <Spinner>
                                <ClipLoader loading={loading} size={15}
                                            color={variant.SelectionDuMoment ? "#404040" : "#ffffff"}/>
                            </Spinner>
                        </Add>
                    </Div>
                </Inner>
            </ProductInner>
        )
    }

}

ProductItem.contextType = UrlPrefixContext

ProductItem.defaultProps = {
    special: false
}

ProductItem.propTypes = {
    product: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    special: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ])
}

const mapStateToProps = state => {
    return {
        rayons: state.rayons.rayons
    }
}

export default connect(mapStateToProps)(ProductItem)
