import React from 'react';

// App
import Div from './Div';

function Modal(props){
    const { title } = props;
    return (
        <React.Fragment>
            <button type="button" id="triggerModal" className="btn btn-primary d-none" data-toggle="modal" data-target="#reactBootstrapModal">Open modal</button>
            <Div className="modal fade" id="reactBootstrapModal" role="dialog" aria-hidden="true">
                <Div className="modal-dialog modal-dialog-centered" role="document">
                    <Div className="modal-content">
                        <Div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{ title || 'Modal title' }</h5>
                            <button type="button" className="close" id="closeThisModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </Div>
                        <Div className="modal-body">
                            {props.children}
                        </Div>
                    </Div>
                </Div>
            </Div>
        </React.Fragment>
    )
}

export default Modal