import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import formateDatetime from '../../../../services/helpers/formateDatetime'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, prefixFront, PROPOSITION_TEMPORAIRE } from '../../../../constants'
import Loading from '../../Loading'

const newHistory = createBrowserHistory()

export default class ActivePanier extends Component {

    state = {
      isAuthenticated: false,
      listActivePanier: []
    };

    nameMenu = 'activePanier'

    componentDidMount() {
        this.renderListActivePanier();
    }

    renderListActivePanier = async () => {
        const data = await connectApi('orders?OrdersStatus=' + PROPOSITION_TEMPORAIRE, 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                // this will re render the view with new data
                let dataFormated = []
                for (let k = 0; k < data.length; k++) {
                    let createdAt = data[k].orderLines[0].createdAt
                    let updatedAt = null
                    let CodeClient = data[k].orderLines[0].CodeClient
                    let amountTotalHT = 0
                    let amountTotalTVA = 0
                    let amountTotalTTC = 0
                    for (let i = 0; i < data[k].orderLines.length; i++) {
                        amountTotalHT += data[k].orderLines[i].MontantHT
                        amountTotalTVA += data[k].orderLines[i].MontantTVA
                        amountTotalTTC += data[k].orderLines[i].MontantTTC
                        updatedAt = data[k].orderLines[i].updatedAt
                    }
                    let item = {
                        id: data[k].id,
                        CodeBP: data[k].CodeBP,
                        Statut: data[k].Statut,
                        CodeBL: data[k].CodeBL,
                        CodeFacture: data[k].CodeFacture,
                        ObjectLastUpdate: data[k].ObjectLastUpdate,
                        customersEncours: data[k].customersEncours,
                        orderLines: data[k].orderLines,
                        CodeClient: CodeClient,
                        createdAt: createdAt,
                        updatedAt: updatedAt,
                        amountTotalHT: amountTotalHT,
                        amountTotalTVA: amountTotalTVA,
                        amountTotalTTC: amountTotalTTC
                    }
                    dataFormated.push(item)
                }
                this.setState({
                    listActivePanier: dataFormated
                });
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    render() {
        const listActivePanier = this.state.listActivePanier?.map((activePanier, i) => (
            <tr key={
                `activePanier${
                    activePanier.id }`
                }>
                <td className="date">
                    { formateDatetime(activePanier.updatedAt, 'd/m/Y', true) }
                </td>
                <td className="CodeClient">{ activePanier.CodeClient }</td>
                <td className="CodeBP">{ activePanier.CodeBP }</td>
                <td className="amountTotalTTC">{ parseFloat(activePanier.amountTotalTTC).toLocaleString('fr') + ' €' }</td>
                <td className="orderLines">
                    { 
                        <ol><b>Liste des produits</b>
                        { 
                            activePanier.orderLines.map(item => (
                            <li key={
                                `orderline${
                                    item.id }`
                                }>
                              { 
                                'Libellé Article: ' + item.libelleArticle + ', ' +
                                'CodeVarianteArticle: ' + item.CodeVarianteArticle + ', ' +
                                'DateArrivagePrev: ' + item.dateArrivagePrev + ', ' +
                                'Quantite: ' + item.Quantite + ', ' +
                                'UniteVente: ' + item.UniteVente + ', ' +
                                'PrixVenteUnitaireHT: ' + item.PrixVenteUnitaireHT + ' €'
                              }
                            </li>
                            ))
                        }
                        </ol>
                    }
                </td>
            </tr>
        ));
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Liste des paniers actifs</h5>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                            <li className="breadcrumb-item">Administration</li>
                            <li className="breadcrumb-item active">Paniers actifs</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="table-responsive">
                                    <table id="activePanier" className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="table-dark">
                                            <th className="date">Date et heure</th>
                                            <th className="CodeClient">Code Client</th>
                                            <th className="CodeBP">CodeBP</th>
                                            <th className="amountTotalTTC">Montant Total TTC</th>
                                            <th className="orderLines">Détail</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listActivePanier }
                                        </tbody>
                                        <tfoot>
                                            <tr className="table-dark">
                                            <th className="date">Date et heure</th>
                                            <th className="CodeClient">Code Client</th>
                                            <th className="CodeBP">CodeBP</th>
                                            <th className="amountTotalTTC">Montant Total TTC</th>
                                            <th className="orderLines">Détail</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Loading/>
                    </div>
                </section>
                <br/>
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}