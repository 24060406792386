import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {toast} from "react-toastify"
import styled from "styled-components"
import Countdown from "react-countdown"

// App
import {CART_DELETE_DELAY, ROLE_USER_BO} from "../../../constants"
import {UrlPrefixContext} from "../../../context"
import {formatCurrency} from "../../../services/helpers/FrontHelper"
import {deleteOrder} from "../../../services/helpers/FrontApiHelper"
import {isGranted} from "../../../services/helpers/RolesHelper"

const Button = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 119px;
  height: 120px;
  border-radius: 6px;
  background-color: #ff0000;
  @media screen and (max-width: 2000px) {
    width: 102px;
    height: 104px;
  }
`

const Img = styled.img`
  display: block;
  margin: ${props => props.with === "counter" ? "0 auto 5px" : "0 auto 15px"};
  @media screen and (max-width: 2000px) {
    max-width: 32px;
  }
`

const Text = styled.span`
  display: block;
  font-size: 23px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  @media screen and (max-width: 2000px) {
    font-size: 19px;
  }
`

const Counter = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`

class MiniCart extends React.Component {

    _isMounted = false

    state = {
        prices: 0,
        timer: 0,
        displayCounter: false
    }

    _calculatePriceTotal = (order) => {
        let prices = 0
        if (order && order.length) {
            order.forEach(item => {
                prices += parseFloat(item.priceTotal)
            })
        }
        return prices
    }

    _caculateOrderTimeout = () => {
        return Date.now() + CART_DELETE_DELAY
    }

    _renderCountdown = ({minutes}) => {
        const time = minutes + 1
        return (
            <Counter><img src="/img/hourglass-half.svg" alt="Hour" width={12}/> {`${time > 30 ? 30 : time} mn`}</Counter>
        )
    }

    _deleteCartOnTimeout = () => {
        const {customer} = this.props
        const orderLine = this._getLocalOrderLine()
        toast("Vous n'avez pas encore passé de commande depuis 30mn, votre panier sera supprimé !", {
            type: "error",
            autoDismiss: true
        })
        if (orderLine && orderLine.id && customer && customer.hasOwnProperty("user") && !isGranted(customer.user.role, ROLE_USER_BO)) {
            this._deleteCart()
            deleteOrder(orderLine.OrderId)
            this.setState({timer: 0, displayCounter: false})
        }
    }

    _deleteCart = () => {
        const orderLine = this._getLocalOrderLine()

        localStorage.removeItem("frontOrderLine")
        localStorage.removeItem("frontOrderProducts")
        localStorage.removeItem("errorsDuplicationPanier")
        this._removeMiniCartStatus()
        this.props.dispatch({type: "RESET_ORDER", value: []})
        console.info(`Order ${orderLine.id} deleted`)
    }

    _updateStatusOnTick = (value) => {
        const {minutes} = value
        if (minutes) {
            const timer = Date.now() + (minutes * 60000)
            this._updateMiniCartStatus({timer: timer})
        }
    }

    _getMiniCartStatus = () => {
        const storage = localStorage.getItem("frontMiniCartStatus")
        return storage != null ? JSON.parse(storage) : null
    }

    _updateMiniCartStatus = (data) => {
        localStorage.setItem("frontMiniCartStatus", JSON.stringify(data))
    }

    _removeMiniCartStatus = () => {
        localStorage.removeItem("frontMiniCartStatus")
    }

    _getLocalOrderLine = () => {
        const storage = localStorage.getItem("frontOrderLine")
        return storage != null ? JSON.parse(storage) : null
    }

    componentDidUpdate(prevProps) {
        const prevPrices = this._calculatePriceTotal(prevProps.order)
        const prices = this._calculatePriceTotal(this.props.order)
        const timer = this._caculateOrderTimeout()
        if (prices !== prevPrices) {
            this.setState({
                prices: prices,
                timer: timer,
                displayCounter: true
            }, () => {
                this._updateMiniCartStatus({timer: timer})
            })
        }
        if (prices === 0 && prevPrices === 0 && this.state.displayCounter) {
            this.setState({
                timer: 0,
                displayCounter: false
            }, () => {
                this._removeMiniCartStatus()
            })
        }
    }

    componentDidMount() {
        this._isMounted = true
        const {order} = this.props
        const status = this._getMiniCartStatus()
        if (this._isMounted) {
            const timer = status === null ? this._caculateOrderTimeout() : status.timer
            const prices = this._calculatePriceTotal(order)
            if (order && order.length) {
                this.setState({
                    prices: prices,
                    timer: timer,
                    displayCounter: true
                })
            } else {
                this.setState({
                    displayCounter: false,
                    timer: 0
                })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {prices, timer, displayCounter} = this.state
        return (
            <Button to={`${this.context.prefix}/panier`}>
                {
                    displayCounter &&
                    <Countdown
                        date={timer}
                        intervalDelay={60000}
                        renderer={this._renderCountdown}
                        onTick={this._updateStatusOnTick}
                        onComplete={this._deleteCartOnTimeout}
                    />
                }
                <Img src="/img/i-cart.svg" with={displayCounter ? "counter" : "no-counter"}/>
                <Text>{formatCurrency(prices)}</Text>
            </Button>
        )
    }

}

MiniCart.contextType = UrlPrefixContext

const mapStateToProps = (state) => {
    return {
        order: state.order.order,
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(MiniCart)