import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import formateDatetime from '../../../../services/helpers/formateDatetime'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, MAX_RESULT_ERROR, prefixBack, prefixFront } from '../../../../constants'
import Loading from '../../Loading'

const newHistory = createBrowserHistory()

export default class ProductOrVariante extends Component {

    state = {
      isAuthenticated: false,
      listProductOrVariante: []
    };

    nameMenu = 'ProduitVarianteInvalid'

    componentDidMount() {
        this.renderListProductOrVariante();
    }

    renderListProductOrVariante = async () => {
        const data = await connectApi('products-variantes-failure?maxResultError=' + MAX_RESULT_ERROR, 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                // this will re render the view with new data
                this.setState({
                    listProductOrVariante: data
                });
                localStorage.setItem('totalProductOrVariante', JSON.stringify(data.length))
                localStorage.setItem('totalUnreadProductOrVariante', 0)
                localStorage.setItem('gardeFouProductIsClicked', true)
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    render() {
        const listProductOrVariante = this.state.listProductOrVariante?.map((productsOrVariante, i) => (
            <tr key={
                `productsOrVariante${
                    productsOrVariante.CodeVarianteArticle 
                    ? 
                    productsOrVariante.CodeVarianteArticle 
                    : 
                    productsOrVariante.CodeArticle }`
                }>
                <td className="date">
                    { formateDatetime(productsOrVariante.createdAt, 'd/m/Y', true) }
                </td>
                <td className="CodeArticle">{productsOrVariante.CodeArticle}</td>
                <td className="LibelleArticle">{productsOrVariante.LibelleArticle}</td>
                <td className="CodeVarianteArticle">{productsOrVariante.CodeVarianteArticle}</td>
                <td className="Motif">{productsOrVariante.Motif}</td>
            </tr>
        ));
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Historique des erreurs de produits/variantes</h5>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                            <li className="breadcrumb-item">Système</li>
                            <li className="breadcrumb-item"><a href={ prefixBack + '/totalgardefou' }>Garde-fou</a></li>
                            <li className="breadcrumb-item active">Produits/variants invalides</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="table-responsive">
                                    <table id="productVarianteInvalid" className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="table-dark">
                                            <th className="date">Date et heure</th>
                                            <th className="CodeArticle">Code Article</th>
                                            <th className="LibelleArticle">Libellé Article</th>
                                            <th className="CodeVarianteArticle">Code Variante Article</th>
                                            <th className="Motif">Motif</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listProductOrVariante }
                                        </tbody>
                                        <tfoot>
                                            <tr className="table-dark">
                                            <th className="date">Date et heure</th>
                                            <th className="CodeArticle">Code Article</th>
                                            <th className="LibelleArticle">Libellé Article</th>
                                            <th className="CodeVarianteArticle">Code Variante Article</th>
                                            <th className="Motif">Motif</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Loading/>
                    </div>
                </section>
                
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}