import React from "react"
import {connect} from "react-redux"

// App
import {Div} from "../../Mixed/Composants"
import {Products} from "../Composants"
import {Header, Content, Footer} from "../Composants/Template"
import {getRayons} from "../../../services/helpers/FrontApiHelper"

class BioPage extends React.Component {

    state={
        loader: false
    }

    componentDidUpdate(prevProps) {
        if(prevProps.tag !== this.props.tag || prevProps.filter !== this.props.filter){
            this.setState({loader: true})
            window.scrollTo(0, 0)
            const {filter} = this.props;
            let filters = ["Frais", "Sec", "DPH", "PetsFood"]
            if (filter && filter.length) {
                filters = filter
            }
            getRayons(filters)
                .then(rayons => {
                    this.props.dispatch({type: "SET_RAYONS", value: rayons})
                })
                .finally(() => {
                    this.setState({loader: false})
                })
        }
    }

    _renderProducts() {
        return (

            <Products
                paged = {1}
                special = 'bio'
            />
        )
    }

    render(){
        return (
            <Div className="front__wrap-screen">
                <Header/>
                <Content loading={this.state.loader}>
                    {this._renderProducts()}
                </Content>
                <Footer/>
            </Div>
        )
    }
}

const mapStateToProps = state => {
    return {
        rayons: state.rayons.rayons,
        filter: state.filter.filter,
        tag: state.tag.tag
    }
}

export default connect(mapStateToProps)(BioPage)
