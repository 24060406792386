import React, {useContext, useEffect, useState} from "react"
import {connect} from "react-redux"
import {Link, useLocation, useParams} from "react-router-dom"
import styled from "styled-components"
import parse from "html-react-parser"

// App
import {envVars} from "../../../../env_var"
import RayonsFixtures from "../../../../fixture_rayons"
import {strToSlug} from "../../../../services/helpers/FrontHelper"
import {getCustomerInfo} from "../../../../services/helpers/FrontApiHelper"
import ProductContext from "../../../../context/ProductContext"
import {UrlPrefixContext} from "../../../../context"
import {Container, Div, Row, Column, List, ListItem} from "../../../Mixed/Composants"
import useCustomerStore from "../../../../reducers/CustomerStore";

const Breadcrumb = styled(Div)`
  background: #fff;
`

const BreadList = styled(List)`
  margin: 0;
  padding: 1rem 0;
`

const BreadText = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  font-weight: 500;
  color: #404040;
  display: inline-block;
  vertical-align: middle;
  @media screen and (max-width: 2000px) {
    font-size: 19px;
  }
`

const Home = styled.img`
  display: inline-block;
  margin-right: 18px;
  @media screen and (max-width: 2000px) {
    max-width: 22px;
    margin-right: 8px;
  }
`

const Interlocuteur = styled(Div)`
  float: right;
  position: relative;
  max-width: 340px;
  background: #ff5d1f;
  padding: 7.5px 25px 7.5px 95px;

  img {
    position: absolute;
    max-height: 58px;
    left: 30px;
    bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    border: solid;
    border-width: 30px 0 30px 15px;
    border-color: transparent transparent transparent #ffffff;
    height: 100%;
    top: 0;
  }
`

const InterlocuteurTooltip = styled.div`
  position: absolute;
  background: #ff5d1f;
  padding: 10px;
  top: 100%;
  right: 7.5px;
  z-index: 1500;
  color: #ffffff;
  margin-top: 15px;

  p {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0;
    width: 100px;
  }

  &:before {
    content: '';
    position: absolute;
    border: solid;
    border-width: 0 8px 15px 8px;
    border-color: transparent transparent #ff5d1f;
    top: -15px;
    left: 50%;
    margin-left: -8px;
  }
`

const Label = styled.span`
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.04;
  color: #ffffff;
`

const Name = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: block;
  color: #ffff;
`

function BottomHeader(props) {

    const context = useContext(ProductContext)
    const contextUrl = useContext(UrlPrefixContext)
    const location = useLocation()
    const params = useParams()
    const {customer} = props
    const [interlocutor, setInterlocutor] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const customerStore = useCustomerStore()

    // N'appeler l'api qu'une fois sauf si le customer change (n'arrivera jamais ?)
    useEffect(() => {
        if (customerStore.customer) {
            setInterlocutor(customerStore.customer.interlocutor)
        } else {
            getCustomerInfo(customer.user.CodeClient)
                .then(response => {
                    customerStore.setCustomer(response)
                    if (response && response.interlocutor !== null) {
                        setInterlocutor(response.interlocutor)
                    }
                })
        }

        // Fonction de retour utilisée lors du démontage du composant
        return () => {
            setInterlocutor(false)
        }
    }, [customerStore, customer.user.CodeClient])


    const _renderBreadCrumbLocationName = () => {
        let breadName = "Accueil"
        const pathname = location.pathname
        if (pathname.includes("panier")) {
            breadName = "Panier"
        } else if (pathname.includes("nous-contacter")) {
            breadName = "Nous contacter"
        } else if (pathname.includes("mes-factures")) {
            breadName = "Mes factures"
        } else if (pathname.includes("detail-commande")) {
            breadName = "Mes commandes > Détail de la commande"
        } else if (pathname.includes("mes-informations")) {
            breadName = "Mes informations"
        } else if (pathname.includes("mes-commandes")) {
            breadName = "Mes commandes"
        } else if (pathname.includes("nos-produits")) {
            const {termName} = location
            const {categorie} = params
            breadName = "Nos rayons > Tous nos produits"
            if (termName) {
                breadName += ` > <b>${termName}</b>`
            } else {
                switch (categorie) {
                    case "boisson":
                        breadName += ` > <b>Boissons</b>`
                        break
                    default:
                        breadName += ` > <b>${_capitalizeString(categorie)}</b>`
                }
            }
        } else if (pathname.includes("fiche-produit")) {
            breadName = "Nos rayons > Tous nos produits"
            const categorie = _getProductCategorie(context.product)
            if (categorie) {
                breadName += ` > ${categorie.Nomcategorie}`
            }
            if (context.product) {
                breadName += ` > <b>${context.product.Libelle}</b>`
            }
        } else if (pathname.includes("selection-du-moment")) {
            breadName = `<a href="${contextUrl.prefix}/selection-du-moment" className="breadcrumb_link">Accueil</a>`
        } else if (pathname.includes("promotions")) {
            breadName = `<a href="${contextUrl.prefix}/selection-du-moment" className="breadcrumb_link">Accueil</a>&nbsp;&gt;&nbsp;<strong>Promotions</strong>`
        }
        return parse(breadName)
    }

    const _capitalizeString = (txt) => {
        return txt.charAt(0).toLocaleUpperCase() + txt.slice(1)
    }

    const _getProductCategorie = (product) => {
        const {rayons} = props
        if (rayons && product) {
            const categorie = rayons.filter(item => parseInt(item.Identifiant) === parseInt(product.FamilleID))
            if (categorie.length) {
                const rayon = RayonsFixtures.filter(r => r.Slugcategorie === strToSlug(categorie[0].Nomcategorie))
                return rayon.length ? rayon[0] : null
            }
        }
        return null
    }

    return (
        <Breadcrumb className="front__bottom-header">
            <Container fluid>
                <Row className="align-items-center">
                    <Column lg={9}>
                        <BreadList className="list-inline">
                            <ListItem className="list-inline-item">
                                <Link to={`${contextUrl.prefix}/selection-du-moment`}>
                                    <Home src="/img/home.svg"/>
                                </Link>
                            </ListItem>
                            <ListItem className="list-inline-item">
                                <BreadText>{_renderBreadCrumbLocationName()}</BreadText>
                            </ListItem>
                        </BreadList>
                    </Column>
                    {interlocutor &&
                        <Column lg={3} onMouseLeave={e => {
                            e.stopPropagation()
                            setShowTooltip(false)
                        }}>
                            <Interlocuteur onMouseEnter={e => {
                                e.stopPropagation()
                                setShowTooltip(true)
                            }}>
                                <img src={envVars["scheme"] + window.location.host + "/img/interlocuteur.svg"} alt=""/>
                                <Label>Votre interlocuteur :</Label>
                                <Name>{interlocutor.name}</Name>
                            </Interlocuteur>
                            {showTooltip && (interlocutor.phone || interlocutor.email) &&
                                <InterlocuteurTooltip>
                                    <p><label>Téléphone:</label>{interlocutor.phone}</p>
                                    <p><label>Email:</label>{interlocutor.email}</p>
                                </InterlocuteurTooltip>
                            }
                        </Column>
                    }
                </Row>
            </Container>
        </Breadcrumb>
    )

}

const mapStateToProps = state => {
    return {
        rayons: state.rayons.rayons,
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(BottomHeader)