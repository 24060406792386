import React from "react"
import styled from "styled-components"
import Proptypes from "prop-types"

// App
import CartDetailItem from "./CartDetailItem"
import {Div} from "../../Mixed/Composants"
import {Button} from "../Composants/Forms"
import {formatCurrency} from "../../../services/helpers/FrontHelper"
import {UploadOrderBase64} from "./index"

const Bold = styled.b`
  font-weight: 700;
`

const Inner = styled.div`
  background: #ffffff;
`

const Total = styled.p`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  color: #ff0000;
  margin: 0;
  text-align: center;
  padding-bottom: 38px;
  @media screen and (max-width: 2000px) {
    font-size: 18px;
    padding-bottom: 27px;
  }
`

const AddToCart = styled(Button)`
  display: block;
  width: 100%;
  border-radius: 0;
  background: #e82424 !important;
  padding: 8px 20px !important;
  @media screen and (max-width: 2000px) {
    padding: 0 20px !important;
  }
`

const Note = styled.div`
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin: 20px 0 0;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    line-height: 1.5;
    margin: 18px 0 0;
  }
`

const Paragraphe = styled.div`
  text-align: center;
  margin: 2rem 0;
  padding-bottom: 20px !important;
`

class CartDetail extends React.Component {

    state = {
        loading: false
    }

    _validateOrder = () => {
        const {onValidate} = this.props
        if (typeof (onValidate) === "function") {
            onValidate()
        }
    }

    _renderCartDetailInStock = (products) => {
        return (
            <CartDetailItem title="Produits en stock" data={products}/>
        )
    }

    _renderCartDetailArrivage = (products) => {
        return (
            <CartDetailItem title="Produits en arrivage" data={products}/>
        )
    }

    _renderCartDetail = () => {
        const {data} = this.props
        let priceTotal = 0
        const productInStock = []
        const productArrivage = []
        if (data && data.length) {
            data.forEach(item => {
                if (item.ArriveEntrepot) {
                    productInStock.push(item)
                } else {
                    productArrivage.push(item)
                }
                priceTotal += parseFloat(item.priceTotal)
            })
        }
        return (
            <>
                {this._renderCartDetailInStock(productInStock)}
                {this._renderCartDetailArrivage(productArrivage)}
                <Total>Montant total de la commande : <Bold>{formatCurrency(priceTotal)}</Bold></Total>
            </>
        )
    }

    _renderNotePoidsVariable = () => {
        const {data} = this.props
        let hasPoidsVariable = false
        if (data && data.length) {
            data.forEach(item => {
                if (item.UniteDeVente === "K") {
                    hasPoidsVariable = true
                }
            })
        }
        if (hasPoidsVariable) {
            return (
                <Note>
                    ** Poids variable : Produit dont le poids à l'unité indiqué sur le bon de commande correspond au
                    poids moyen communiqué par le fournisseur. Conformément à nos conditions générales de vente, le
                    poids exact sera indiqué sur le bon de livraison accompagnant le colis et sera celui faisant foi
                    pour calculer le montant facturé.
                </Note>
            )
        }
    }

    render() {
        const {loading} = this.state
        return (
            <Div className="front__cart-detail">
                <Inner>
                    {this._renderCartDetail()}
                    <AddToCart type="button" variant="secondary" contain="filled" loader={loading}
                               onClick={this._validateOrder}>Je passe la commande</AddToCart>
                    <Paragraphe>
                        <UploadOrderBase64/>
                    </Paragraphe>
                </Inner>
                {this._renderNotePoidsVariable()}
            </Div>
        )
    }

}

CartDetail.defaultProps = {
    data: []
}

CartDetail.propTypes = {
    data: Proptypes.array.isRequired
}

export default CartDetail
