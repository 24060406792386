import React, {Component, useEffect} from "react"
import {prefixFront, ROLE_ADMIN, ROLE_CUSTOMER, ROLE_MAINTAINER_SEDIS} from "../../../constants"
import {createBrowserHistory} from "history"
import {getRoleStrings} from "../../../services/helpers/RolesHelper"
import WhatsAppStatus from "../../Backoffice/Administration/Notifications/WhatsAppStatus";
import useMeStore from "../../../reducers/MeStore";
import _ from "lodash";


const newHistory = createBrowserHistory()

const ShowWhatsApp = () => {
    const {info} = useMeStore()
    return (_.intersection(info.roles, [ROLE_ADMIN, ROLE_MAINTAINER_SEDIS]).length > 0 &&
        <WhatsAppStatus/>)
}

const ShowPushMenu = () => {
    const body = document.getElementsByTagName('body')[0];
    const menuConfig = window.localStorage.getItem("menuConfig") ?? "large"

    useEffect(() => {
        if (menuConfig === "large") {
            body.className = body.className.replace(" wrapper-sm sidebar-collapse", "")
        } else {
            body.className += " wrapper-sm sidebar-collapse"
        }
        // eslint-disable-next-line
    }, [])

    return <button
        className="nav-link menu-bars"
        data-widget="pushmenu"
        onClick={(e) => {
            e.stopPropagation()
            if (body.className.includes("wrapper-sm")) {
                body.className = body.className.replace(" wrapper-sm sidebar-collapse", "")
                window.localStorage.setItem("menuConfig", "large")
            } else {
                body.className += " wrapper-sm sidebar-collapse"
                window.localStorage.setItem("menuConfig", "small")
            }
        }}
    >
        <i className="fas fa-bars"/>
    </button>
}

export default class Header extends Component {
    currentState = JSON.parse(localStorage.getItem("state"));

    user = this.currentState?.user
    state = {
        isAuthenticated: this.currentState?.isAuthenticated,
        token: this.currentState?.token,
        user: {
            id: this.user.id,
            email: this.user.email,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            role: this.user.role,
            isCustomer: this.user.isCustomer,
            CodeClient: this.user.CodeClient
        }
    }

    componentDidMount() {
        const scripts = [
            "/dist/js/adminlte.js",
        ]

        scripts.map(s => {
            const adminScript = document.createElement("script")
            adminScript.src = s
            adminScript.async = false
            document.body.appendChild(adminScript)
            return true
        })
    }


    logout() {
        localStorage.removeItem("state")
        localStorage.removeItem("errorsDuplicationPanier")
        newHistory.push(`${prefixFront}/login`)
        document.location.reload()
    }

    render() {
        return (
            <div>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <ShowPushMenu/>
                        </li>
                        {this.state.user.role === ROLE_CUSTOMER &&
                            <li className="nav-item d-none d-sm-inline-block">
                                <a href="/" className="nav-link">Accueil</a>
                            </li>
                        }

                    </ul>
                    <div className="ml-3">
                        <span className="d-block bo-user-name">
                            {`${this.state.user.firstname} ${this.state.user.lastname} (${getRoleStrings(this.state.user.role).role})`}
                        </span>
                    </div>

                    <div className="navbar-nav ml-auto" style={{display: 'flex', alignItems: 'center'}}>
                        <ShowWhatsApp/>

                        <button onClick={this.logout} className="bo-logout bo-card" title="Déconnexion">
                            <i className="fas fa-power-off nav-icon"/> Déconnexion
                        </button>
                    </div>
                </nav>
            </div>

        )
    }
}
