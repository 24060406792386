function formateDatetime(datetime, format = 'Y/m/d', withHourMinSec = false) {
    let formatedDatetime = datetime
    if (format === 'd/m/Y') {
        formatedDatetime = datetime.substring(8, 10) + '/' +
        datetime.substring(5, 7) + '/' +
        datetime.substring(0, 4)
    } else if (format === 'd-m-Y') {
        formatedDatetime = datetime.substring(8, 10) + '-' +
        datetime.substring(5, 7) + '-' +
        datetime.substring(0, 4)
    } else if (format === 'Y-m-d') {
        formatedDatetime = datetime.substring(0, 4) + '-' +
        datetime.substring(5, 7) + '-' +
        datetime.substring(8, 10)
    } else {
        formatedDatetime = datetime.substring(0, 4) + '/' +
        datetime.substring(5, 7) + '/' +
        datetime.substring(8, 10)
    }
    if (withHourMinSec === true) {
        formatedDatetime += ' ' + datetime.substring(11, 19)
    }

    return formatedDatetime
}

export default formateDatetime