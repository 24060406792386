import React, { useContext } from 'react';
import styled from 'styled-components';

// App
import { Div, List, ListItem } from '../../../Mixed/Composants';
import { UrlPrefixContext } from '../../../../context';

const Text = styled.span`
    font-family: 'Covered By Your Grace', sans-serif;
    font-size: 40px;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #404040;
    @media screen and (max-width:2000px){
        font-size: 33px;
    }
`;

const Item = styled(ListItem)`
    vertical-align: middle;
    &:not(:last-child){
        margin-right: 15px;
    }
    @media screen and (max-width:2000px){
        &:not(:last-child){
            margin-right: 12px;
        }
    }
`;

const PhoneText = styled.a`
    font-family: 'Raleway', sans-serif;
    font-size: 35px;
    font-weight: 600;
    line-height: 1;
    color: #404040;
    @media screen and (max-width:2000px){
        font-size: 26px;
    }
`;

const Wrap = styled(Div)`
    border-bottom: 1px solid #404040;
    padding-bottom: 21px;
    margin-bottom: 34px;
    @media screen and (max-width:2000px){
        padding-bottom: 18px;
        margin-bottom: 30px;
    }
`;

const Email = styled.a`
    display: inline-block;
    text-decoration: none;
`;

const Phone = styled.a`
    display: inline-block;
    text-decoration: none;
    margin-left: 60px;
    @media screen and (max-width:2000px){
        margin-left: 50px;
    }
`;

const Icone = styled.img`
    display: inline-block;
    @media screen and (max-width:2000px){
        max-width: 46px;
    }
`;

function TopFooter(props){
    const contextUrl = useContext(UrlPrefixContext);
    return (
        <Wrap className="front__top-footer" {...props}>
            <List className="list-inline mb-0">
                <Item className="list-inline-item">
                    <Email href={`${contextUrl.prefix}/nous-contacter`}>
                        <Text>Besoin d’aide ?</Text>
                    </Email>
                </Item>
                <Item className="list-inline-item">
                    <Email href={`${contextUrl.prefix}/nous-contacter`}>
                        <Icone src="/img/i-mail.webp" />
                    </Email>
                </Item>
                <Item className="list-inline-item">
                    <Phone href="tel:0954456788">
                        <Icone src="/img/i-phone.webp" />
                    </Phone>
                </Item>
                <Item className="list-inline-item">
                    <PhoneText href="tel:0954456788">09 54 45 67 88</PhoneText>
                </Item>
            </List>
        </Wrap>
    )
}

export default TopFooter;