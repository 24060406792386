import React, {Component} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import connectApi from '../../../services/helpers/connectApi'
import Header from '../../Mixed/Header/Header'
import Menu from '../../Mixed/Menu/Menu'
import Footer from '../../Mixed/Footer/Footer'
import formateDatetime from '../../../services/helpers/formateDatetime'
import {createBrowserHistory} from 'history'
import {
    ALL,
    AUTHENTICATION,
    HTTP_UNAUTHORIZED,
    MAX_RESULT_ERROR,
    prefixFront,
    SHOP_API,
    SHOP_BOT,
    SHOP_MOBILE,
    SHOP_OFFICE
} from '../../../constants'
import Loading from '../Loading'

const newHistory = createBrowserHistory()

export default class LogErreur extends Component {

    state = {
        isAuthenticated: false,
        listError: []
    };

    nameMenu = 'LogErreur'

    componentDidMount() {
        this.renderListError();
    }

    renderListError = async () => {
        const data = await connectApi('log-error?maxResultError=' + MAX_RESULT_ERROR + '&Type=' + ALL, 'GET', null)
        if (typeof (data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                // this will re render the view with new data
                this.setState({
                    listError: data
                });
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    render() {
        const listError = this.state.listError?.map((error, i) => (
            <tr key={
                `error${
                    error.id}`
            }>
                <td className="date">
                    {formateDatetime(error.createdAt, 'd/m/Y', true)}
                </td>
                <td className="Motif">
                    {error.Motif}
                </td>
                <td className="Type">
                    {
                        (error.Type === SHOP_API && error.SousType === AUTHENTICATION) &&
                        <span className="btn-info">{error.SousType}</span>
                    }
                    {
                        (error.Type === SHOP_API && error.SousType !== AUTHENTICATION) &&
                        <span className="btn-success">{error.Type}</span>
                    }
                    {
                        error.Type === SHOP_BOT &&
                        <span className="btn-warning">{error.Type}</span>
                    }
                    {
                        error.Type === SHOP_MOBILE &&
                        <span className="btn-danger">{error.Type}</span>
                    }
                    {
                        error.Type === SHOP_OFFICE &&
                        <span className="btn-secondary">{error.Type}</span>
                    }
                </td>
                <td className="ip">
                    {error.adressIp}
                </td>
                <td className="detail">
                    {
                        (error.Type === SHOP_API && error.SousType === AUTHENTICATION)
                            ?
                            'Email: ' + error.detail['email'] + ', Mot de passe: **********'
                            :
                            JSON.stringify(error.detail)
                    }
                </td>
            </tr>
        ));
        return (
            <Router>
                <div className="wrapper">
                    <Header/>
                    <Menu nameMenu={this.nameMenu}/>
                    <div>
                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h5 className="m-0 text-dark">Log des erreurs</h5>
                                        </div>
                                        {/* /.col */}
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                                                <li className="breadcrumb-item">Système</li>
                                                <li className="breadcrumb-item active">Erreurs</li>
                                            </ol>
                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="table-responsive">
                                                <table id="listError" className="table table-bordered table-hover">
                                                    <thead>
                                                    <tr className="table-dark">
                                                        <th className="date">Date et heure</th>
                                                        <th className="Motif">Motif</th>
                                                        <th className="type">Type</th>
                                                        <th className="ip">Adresse IP</th>
                                                        <th className="detail">Détail</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listError}
                                                    </tbody>
                                                    <tfoot>
                                                    <tr className="table-dark">
                                                        <th className="date">Date et heure</th>
                                                        <th className="Motif">Motif</th>
                                                        <th className="type">Type</th>
                                                        <th className="ip">Adresse IP</th>
                                                        <th className="detail">Détail</th>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Loading/>
                                </div>
                            </section>

                        </div>
                        <Footer/>
                    </div>
                </div>
            </Router>
        )
    }
}