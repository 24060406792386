import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"

// App
import {Branding, Forms, LoginForm} from "../Composants"
import {Column, Container, Div, Row} from "../../Mixed/Composants"
import {
    prefixBack,
    prefixFront,
    ROLE_MAINTAINER_SEDIS, ROLE_REDACTOR_BO, ROLE_SALESMANAGER_BO,
    ROLE_USER_BO
} from "../../../constants"
import {isGranted} from "../../../services/helpers/RolesHelper"
import {isJWTExpired} from "../../../services/helpers/FrontApiHelper"

// Fields
const {Button} = Forms

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  background-color: #eeede4;
  @media screen and (max-width: 991px) {
    height: auto;
    overflow: visible;
  }
`

const Content = styled(Div)`
  margin: auto;
`

const WrapForm = styled(Div)`
  margin-left: 3em;
  @media screen and (max-width: 1199px) {
    margin-left: 0;
  }
  @media screen and (max-width: 991px) {
    margin: 3em 0;
  }
`

const NavBar = styled.div`
  padding: 15px 0 5em;
  @media screen and (max-width: 1400px) {
    padding-bottom: 2em;
  }
  @media screen and (max-width: 1199px) {
    padding-bottom: 0;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 3em;
  }
`

const Title = styled.h1`
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 120px;
  font-weight: 400;
  line-height: 0.92;
  color: #66b821;
  text-transform: capitalize;
  @media screen and (max-width: 2000px) {
    font-size: 75px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 65px;
  }
  @media screen and (max-width: 767px) {
    font-size: 55px;
  }
`

const Paragraphe = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.6;
  color: #404040;
  margin: 3em 0;
  @media screen and (max-width: 2000px) {
    font-size: 19px;
    margin: 2em 0;
  }
  @media screen and (max-width: 1200px) {
    font-size: 17px;
  }

`

const Esp = styled(Button)`
  float: left;
`

class ConnexionPage extends React.Component {
    state = {showlogin: false}

    componentDidMount() {
        const {customer} = this.props;
        const state = localStorage.getItem('state');
        const jsonState = JSON.parse(state);

        if (customer && customer.hasOwnProperty('isAuthenticated') && state != null && !isJWTExpired(jsonState["token"])) {
            const hasMaintainerRole = isGranted(customer.user.role, ROLE_MAINTAINER_SEDIS)
            const hasRedactorRole = isGranted(customer.user.role, ROLE_REDACTOR_BO)
            const hasUserRole = isGranted(customer.user.role, ROLE_USER_BO)

            if (isGranted(customer.user.role, ROLE_SALESMANAGER_BO)) {
                window.location.href = prefixBack + '/suivi-achats'
            } else {
                if (hasMaintainerRole || hasRedactorRole || hasUserRole) {
                    window.location.href = prefixBack + '/dashboard'
                } else {
                    window.location.href = prefixFront + '/selection-du-moment';
                }
            }
        } else {
            this.setState({showlogin: true})
        }
    }

    render() {

        return (<>{this.state.showlogin &&
            <Wrapper>
                <NavBar>
                    <Container fluid>
                        <Branding/>
                    </Container>
                </NavBar>
                <Content className="d-flex justify-content-center align-items-center">
                    <Container fluid>
                        <Row>
                            <Column lg={5}>
                                <Title>
                                    BIENVENUE<br/>
                                    CHEZ VOUS !
                                </Title>
                                <Paragraphe>
                                    Sedis alimentaire, c’est plus de 30 ans d’expérience
                                    dans l’agroalimentaire et le de stockage.
                                    Nous réalisons des achats de sur stocks,
                                    produits frais et secs hors contrat date que nous
                                    revendons sur un réseau de magasins.
                                </Paragraphe>
                                <Esp type="link">EN SAVOIR +</Esp>
                            </Column>
                            <Column lg={7}>
                                <WrapForm>
                                    <LoginForm history={this.props.history}/>
                                </WrapForm>
                            </Column>
                        </Row>
                    </Container>
                </Content>
            </Wrapper>
        }</>)
    }

}

const mapStateToProps = state => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(ConnexionPage)