import React from 'react';
import styled from 'styled-components';

// App
import OrderDetailItem from "./OrderDetailItem";
import { Div } from '../../Mixed/Composants';
import { UrlPrefixContext } from '../../../context';
import { SectionTitle, Forms } from '../Composants';
import {formatCurrency} from "../../../services/helpers/FrontHelper";
import { Link } from 'react-router-dom';
import { prefixFront } from '../../../constants';

const { Button } = Forms;

const Table = styled.table`
    margin: 0;
    width: 100%;
`;

const Thead = styled.thead`
    border: 0;
`;

const TBody = styled.tbody`
    padding: 0;
`;

const Tr = styled.tr`
    position: relative;
`;

const Th = styled.th`
    position: relative;
`;

const Td = styled.td`
    position: relative;
    text-align: center;
    background: ${ props => props.disabled ? '#f2d2c1' : '#dedddd' };
    border-bottom: 3px solid #eeede4;
    padding: 24px 15px;
    &:nth-child(2){
        text-align:left;
        padding-left: 0;
    }
    &:not(:first-child):before{
        content: "";
        display: block;
        width: 1px;
        height: 72%;
        position: absolute;
        left: -1px;
        z-index: 99;
        background: #fff;
        top: 14%;
    }
    &:nth-child(2):before{
        display: none;
    }
    @media screen and (max-width:2000px){
        padding: 15px;
        &:not(:first-child):before{
            height: 68%;
            top: 16%;
        }
    }
`;

const Tf = styled(Td)`
    background: none;
    &:before{
        display: none !important
    }
`;

const TTotal = styled(Tf)`
    padding-top: 34px;
`;

const TFoot = styled.tfoot`
    padding: 0;
`;
const Total = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;
`;

const TotalText = styled.span`
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.17;
    color: #404040;
    @media screen and (max-width:2000px){
        font-size: 24px;
    }
`;

const Label = styled.span`
    font-family: 'Covered By Your Grace', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    color: #404040;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    margin:0 auto;
    background: #fff;
    margin-bottom: -13px;
    z-index: 99;
    position: relative;
    border-radius : 50%;
    @media screen and (max-width:2000px){
        width: 65px;
        height: 65px;
        font-size: 18px;
        margin-bottom: -10px;
    }
`;

const TotalField = styled.div`
    flex: 1;
    height: 50px;
    margin: 0 0 0 31px;
    opacity: 0.8;
    border-radius: 6px;
    border: solid 2px #404040;
    background-color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.2;
    color: #404040;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:2000px){
        font-size: 24px;
    }
`;

const GoToHome = styled(Button)`
    float: left;
    border-radius: 50px;
`;

const Confirmation = styled.p`
    text-align: center;
    margin: 0 0 3rem;
`;

const Enlevement = styled.div`
    margin-top: 5px;
    a{
        font-weight: 600;
        color: #ffffff;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
`

class OrderDetail extends  React.Component{

    _displayPriceTotal = () => {
        let prices = 0;
        const { data } = this.props;
        data.forEach( item => {
            prices += parseFloat(item.priceTotal);
        } )
        return formatCurrency(prices);
    }

    render(){
        const { data } = this.props;
        return (
            <Div className="front__cart-detail">
                <Confirmation className="alert alert-success">
                    Merci pour votre commande, cette dernière sera traitée dans les meilleurs délais.
                    <Enlevement>
                        Si vous ne l'avez pas déjà fait, merci de <Link to={prefixFront + '/demande-enlevement'}>planifier votre prochain enlèvement de marchandises</Link>
                    </Enlevement>
                </Confirmation>
                <SectionTitle>Résumé de votre commande</SectionTitle>
                <Table>
                    <Thead>
                        <Tr>
                            <Th width="6%">&nbsp;</Th>
                            <Th width="38%">&nbsp;</Th>
                            <Th><Label>Pièces/<br />Colis</Label></Th>
                            <Th><Label>Poids<br/>(Kg)</Label></Th>
                            <Th><Label>U/ <br/>Kg</Label></Th>
                            <Th><Label>Prix unitaire</Label></Th>
                            <Th><Label>Date d’arrivage</Label></Th>
                            <Th><Label>DLC<br/>DLUO</Label></Th>
                            <Th><Label>Quantité</Label></Th>
                            <Th width="8%"><Label>Prix Total</Label></Th>
                        </Tr>
                    </Thead>
                    <TBody>
                        { data && data.map( (product, key)  => <OrderDetailItem key={key} product={product} /> ) }
                    </TBody>
                    <TFoot>
                        <Tr>
                            <TTotal colSpan={6}>
                                <GoToHome type="link" variant="secondary" contain="filled" to={`${this.context.prefix}/commande-rapide`} >Vers la page d'accueil </GoToHome>
                            </TTotal>
                            <TTotal colSpan={3}>
                                <Total>
                                    <TotalText>Total</TotalText>
                                    <TotalField>{ this._displayPriceTotal() }</TotalField>
                                </Total>
                            </TTotal>
                        </Tr>
                    </TFoot>
                </Table>
            </Div>
        )
    }

}

OrderDetail.contextType =  UrlPrefixContext

export default OrderDetail;