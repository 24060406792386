import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

// Css
import './Button.css';

const Spinner = styled.span`
    margin-top: 5px;
    margin-left: 8px;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
`;

function Button(props){
    
    let { type, variant, contain, to, value, disabled, loader, tabIndex, className, onClick } = props;
    let customProps = {};
    const variants = ['primary', 'secondary'];
    const contains = ['filled', 'outlined'];

    if( onClick && typeof(onClick) == 'function' ){
        customProps.onClick = onClick;
    }

    if( tabIndex ){
        customProps.tabIndex = tabIndex;
    }

    if(disabled){
        customProps.disabled = disabled
    }
    
    if( !variants.includes(variant) ){
        variant = 'primary'
    }
    if( !contains.includes(contain) ){
        contain = 'filled'
    }
    
    let btnClass = `btn ${variant} ${contain}`; 

    if(loader){
        btnClass += ' active';
    }

    const _renderButtonLink = () => {
        return (
            <Link
                to={to} 
                className={ classNames(btnClass, className) }
            >
                { value ? value : props.children}
            </Link>
        )
    }

    const _renderButtonType = () => {
        if( type === "link" ){
            return _renderButtonLink();
        }
        return (
            <button 
                type={type} 
                {...customProps}
                className={ classNames(btnClass, className) }
            >
                { value ? value : props.children}
                <Spinner>
                    <ClipLoader loading={loader} size={25} color={ contain === 'field' ? '#ffffff' : variant === 'primary' ? '#66b821' : loader ? '#fffff'  : '#ff5d1f' } />
                </Spinner>
            </button>
        )
    }

    return  _renderButtonType();
    
}

Button.defaultProps = {
    to : "/",
    button : "button",
    variant : "primary",
    contain : "filled",
    loader : false
}

export default Button;