import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, prefixFront } from '../../../../constants'
import styled from "styled-components";
import { toast } from 'react-toastify';
import {connect} from "react-redux";

const Alert = styled.span`
    color: red;
    display: block;
    font-style: italic;
    font-size: .8rem;
`;

const LinkReset = styled.a`
    background-color: #778899 !important;
    border-color: #778899 !important;
    margin-left: 50px;
    line-height: 1.5;
    &:hover,
    &:focus,
    &.active{
        color: #fff !important;
        background-color: #A9A9A9 !important;
        border-color: #A9A9A9 !important;
    }
`;

const DivTextSelectionDuMoment = styled.div`
    background-color: #FFFACD;
    line-height: 2;
`;

const newHistory = createBrowserHistory()

class SelectionDuMoment extends Component {

    state = {
        isAuthenticated: false,
        idTextDuMoment: null,
        textDuMoment: '',
        initialTextDuMoment: ''
    };

    nameMenu = 'SelectionDuMoment'

    componentDidMount() {
        document.getElementById('errortextDuMoment').style.display = 'none'
        document.getElementById('blocTextForm').style.display = 'none'
        this.renderTexteDuMoment()
    }

    renderTexteDuMoment = async () => {
        const data = await connectApi('text-selection-du-moment', 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                let listTextSelectionDuMoment = data['hydra:member'];
                if (listTextSelectionDuMoment.length > 0) {
                    this.setState({
                        idTextDuMoment: listTextSelectionDuMoment[0].id,
                        textDuMoment: listTextSelectionDuMoment[0].textDuMoment,
                        initialTextDuMoment: listTextSelectionDuMoment[0].textDuMoment
                    });
                }
            }
        } else {
            console.log(data)
        }
    }

    enregistrer = async (event) => {
        event.preventDefault();
        // Sauvegarder dans le SHOP API
        document.getElementById('errortextDuMoment').style.display = 'none';
        if (this.state.textDuMoment === '') {
            document.getElementById('errortextDuMoment').style.display = 'block';
        } else {
            document.body.style.cursor = "wait";
            let uri = 'text-selection-du-moment'
            let method = 'POST'
            let dataObject = { textDuMoment: this.state.textDuMoment }
            if (this.state.idTextDuMoment) {
                uri = 'text-selection-du-moment/' + this.state.idTextDuMoment
                method = 'PUT'
            }
            const data = await connectApi(uri, method, dataObject)
            if (typeof(data) === 'object') {
                if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                    newHistory.push(prefixFront + '/login')
                } else {
                    this.setState({
                        idTextDuMoment: data.id,
                        textDuMoment: data.textDuMoment,
                        initialTextDuMoment: data.textDuMoment
                    });
                    toast("Le texte de sélection du moment a été enregistré avec succès", {
                        type: 'success',
                        autoDismiss : true
                    });
                    document.body.style.cursor = "default";
                    this.props.dispatch({ type : 'SET_ID_TEXT_MOMENT', value : this.state.idTextDuMoment })
                    this.props.dispatch({ type : 'SET_TEXT_MOMENT', value : this.state.textDuMoment })
                    document.getElementById('blocTextForm').style.display = 'none'
                    document.getElementById('blocTextDisplay').style.display = ''
                }
            } else {
                console.log(data)
            }
        }
    }

    createUpdateText = (event) => {
        event.preventDefault()
        document.getElementById('errortextDuMoment').style.display = 'none';
        document.getElementById('blocTextDisplay').style.display = 'none'
        document.getElementById('blocTextForm').style.display = ''
    }

    resetCreateUpdateText = (event) => {
        event.preventDefault()
        const { dispatch } = this.props;
        this.setState({
            textDuMoment: this.state.initialTextDuMoment
        });
        document.getElementById('blocTextForm').style.display = 'none'
        document.getElementById('blocTextDisplay').style.display = ''
        dispatch({ type : 'SET_TEXT_MOMENT', value : this.state.textDuMoment })
    }

    _handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Texte de Sélection du Moment</h5>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">Administration</li>
                            <li className="breadcrumb-item active">Sélection du moment</li>
                            </ol>
                        </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row" id={ 'blocTextDisplay' }>
                            <div className="col-lg-3 col-3">
                                Texte actuel de sélection du moment
                            </div>
                            <div className="col-lg-9 col-9">
                                <DivTextSelectionDuMoment id={ 'DivTextSelectionDuMoment' }>
                                    { this.state.idTextDuMoment ? this.state.textDuMoment : 'aucune' }
                                </DivTextSelectionDuMoment>
                                <br />
                                <div>
                                    <button onClick={ (event) => { this.createUpdateText(event) } } className="btn btn-info btn-login">
                                        { this.state.idTextDuMoment ? 'Modifier' : 'Créer un texte' }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row" id={ 'blocTextForm' }>
                            <div className="col-lg-3 col-3">
                                Saisir ici le texte de sélection du moment
                            </div>
                            <div className="col-lg-9 col-9">
                                <input
                                    id={'textDuMoment'}
                                    name={'textDuMoment'}
                                    type={'text'}
                                    size={ 50 }
                                    onChange={ (e) => this._handleTextChange(e) }
                                    value={ this.state.textDuMoment ? this.state.textDuMoment : '' }
                                >
                                </input>
                                <br />
                                <Alert id={'errortextDuMoment'}>Le texte de sélection du moment ne doit pas être vide</Alert>
                                <br />
                                <div>
                                    <button onClick={ (event) => { this.enregistrer(event) } } className="btn btn-info btn-login">Enregistrer</button>
                                    <LinkReset href="#" onClick={ (event) => { this.resetCreateUpdateText(event) } } className="btn btn-info btn-login">Annuler</LinkReset>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}

const mapStateToProps = state => {
    return {
        textMoment : state.textMoment.textDuMoment,
    }
}

export default connect(mapStateToProps)(SelectionDuMoment);
