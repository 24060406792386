import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import formateDatetime from '../../../../services/helpers/formateDatetime'
import { createBrowserHistory } from 'history'
import {AUTHENTICATION_SUCCESS, HTTP_UNAUTHORIZED, MAX_RESULT_ERROR, prefixFront} from '../../../../constants'
import Loading from '../../Loading'

const newHistory = createBrowserHistory()

export default class LastActivityUsers extends Component {

    state = {
      isAuthenticated: false,
      lastActivityUsers: []
    };

    nameMenu = 'lastActivityUsers'

    componentDidMount() {
        this.renderLastActivityUsers();
    }

    renderLastActivityUsers = async () => {
        const data = await connectApi('log-error?maxResultError=' + MAX_RESULT_ERROR + '&SousType=' + AUTHENTICATION_SUCCESS + '&lastActivityUser=yes', 'GET', null)
        if (typeof(data) === 'object') {
            if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + '/login')
            } else {
                // this will re render the view with new data
                this.setState({
                    lastActivityUsers: data
                });
                let listCodeClient = [];
                for(let i = 0; i < this.state.lastActivityUsers.length; i++) {
                    let lastActivity = this.state.lastActivityUsers[i];
                    let CodeClient = lastActivity.detail['CodeClient'];
                    if (CodeClient && !listCodeClient.includes(CodeClient)) {
                        listCodeClient.push(CodeClient);
                    }
                }
                let lastActivityUsers = [];
                for(let i = 0; i < listCodeClient.length; i++) {
                    let codeClient = listCodeClient[i];
                    for(let j = 0; j < this.state.lastActivityUsers.length; j++) {
                        let lastActivity = this.state.lastActivityUsers[j];
                        if (codeClient === lastActivity.detail['CodeClient']) {
                            lastActivityUsers.push(lastActivity);
                            break;
                        }
                    }
                }
                this.setState({
                    lastActivityUsers: lastActivityUsers
                });
                const script = document.createElement('script')
                script.src = '../../../../../custom-code/js/datatable-custom.js'
                script.async = true
                document.body.appendChild(script)
            }
        } else {
            console.log(data)
        }
    }

    render() {
        const lastActivityUsers = this.state.lastActivityUsers?.map((lastActivity) => (
            <tr key={
                `lastActivity${
                    lastActivity.id }`
                }>
                <td className="date">
                    { formateDatetime(lastActivity.createdAt, 'd/m/Y')  }
                </td>
                <td className="CodeClient">{ lastActivity.detail['CodeClient'] }</td>
                <td className="ip">{ lastActivity.adressIp }</td>
            </tr>
        ));
        return (
          <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={ this.nameMenu } />
                <div>
                <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h5 className="m-0 text-dark">Activité des utilisateurs</h5>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                            <li className="breadcrumb-item">Administration</li>
                            <li className="breadcrumb-item active">Date de dernière activité</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="table-responsive">
                                    <table id="lastActivityUsers" className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="table-dark">
                                                <th className="date">Date</th>
                                                <th className="CodeClient">Code Client</th>
                                                <th className="ip">Adresse IP</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { lastActivityUsers }
                                        </tbody>
                                        <tfoot>
                                            <tr className="table-dark">
                                                <th className="date">Date</th>
                                                <th className="CodeClient">Code Client</th>
                                                <th className="ip">Adresse IP</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Loading/>
                    </div>
                </section>
                
                </div>
                <Footer/>
                </div>
            </div>
          </Router>
        )
    }
}