import React, {Component} from "react"
import { BrowserRouter as Router } from 'react-router-dom'
import connectApi from "../../../../services/helpers/connectApi"
import Header from "../../../Mixed/Header/Header"
import Menu from "../../../Mixed/Menu/Menu"
import Footer from "../../../Mixed/Footer/Footer"
import {createBrowserHistory} from "history"
import {HTTP_UNAUTHORIZED, prefixFront} from "../../../../constants"
import styled from "styled-components"
import {toast} from "react-toastify"
import {connect} from "react-redux"

const Alert = styled.span`
  color: red;
  display: block;
  font-style: italic;
  font-size: .8rem;
`

const LinkReset = styled.a`
  background-color: #778899 !important;
  border-color: #778899 !important;
  margin-left: 50px;
  line-height: 1.5;

  &:hover,
  &:focus,
  &.active {
    color: #fff !important;
    background-color: #A9A9A9 !important;
    border-color: #A9A9A9 !important;
  }
`

const DivMinimumCommandable = styled.div`
  background-color: #FFFACD;
  line-height: 2;
`

const newHistory = createBrowserHistory()

class MinimumCommandable extends Component {

    state = {
        isAuthenticated: false,
        idminimumCommandable: null,
        minimumCommandable: "",
        initialminimumCommandable: ""
    }

    nameMenu = "MinimumCommandable"

    componentDidMount() {
        document.getElementById("errorminimumCommandable").style.display = "none"
        document.getElementById("blocTextForm").style.display = "none"
        this.renderMinimumCommandable()
    }

    renderMinimumCommandable = async () => {
        const data = await connectApi("min-commandable", "GET", null)
        if (typeof (data) === "object") {
            if (data.hasOwnProperty("code") && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + "/login")
            } else {
                let listMinimumCommandable = data["hydra:member"]
                if (listMinimumCommandable.length > 0) {
                    this.setState({
                        idminimumCommandable: listMinimumCommandable[0].id,
                        minimumCommandable: listMinimumCommandable[0].minCommandable,
                        initialminimumCommandable: listMinimumCommandable[0].minCommandable
                    })
                }
            }
        } else {
            console.log(data)
        }
    }

    enregistrer = async (event) => {
        event.preventDefault()
        // Sauvegarder dans le SHOP API
        document.getElementById("errorminimumCommandable").style.display = "none"
        if (this.state.minimumCommandable === "") {
            document.getElementById("errorminimumCommandable").style.display = "block"
        } else {
            document.body.style.cursor = "wait"
            let uri = "min-commandable"
            let method = "POST"
            let dataObject = {minCommandable: parseFloat(this.state.minimumCommandable)}
            if (this.state.idminimumCommandable) {
                uri = "min-commandable/" + this.state.idminimumCommandable
                method = "PUT"
            }
            const data = await connectApi(uri, method, dataObject)
            if (typeof (data) === "object") {
                if (data.hasOwnProperty("code") && data.code === HTTP_UNAUTHORIZED) {
                    newHistory.push(prefixFront + "/login")
                } else {
                    this.setState({
                        idminimumCommandable: data.id,
                        minimumCommandable: data.minCommandable,
                        initialminimumCommandable: data.minCommandable
                    })
                    toast("Le montant minimum de commande a été enregistré avec succès", {
                        type: "success",
                        autoDismiss: true
                    })
                    document.body.style.cursor = "default"
                    this.props.dispatch({type: "SET_ID_MIN_COMMANDABLE", value: this.state.idminimumCommandable})
                    this.props.dispatch({type: "SET_MIN_COMMANDABLE", value: this.state.minimumCommandable})
                    document.getElementById("blocTextForm").style.display = "none"
                    document.getElementById("blocTextDisplay").style.display = ""
                }
            } else {
                console.log(data)
            }
        }
    }

    createUpdateText = (event) => {
        event.preventDefault()
        document.getElementById("errorminimumCommandable").style.display = "none"
        document.getElementById("blocTextDisplay").style.display = "none"
        document.getElementById("blocTextForm").style.display = ""
    }

    resetCreateUpdateText = (event) => {
        event.preventDefault()
        const {dispatch} = this.props
        this.setState({
            minimumCommandable: this.state.initialminimumCommandable
        })
        document.getElementById("blocTextForm").style.display = "none"
        document.getElementById("blocTextDisplay").style.display = ""
        dispatch({type: "SET_MIN_COMMANDABLE", value: this.state.minimumCommandable})
    }

    _handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <Router>
                <div className="wrapper">
                    <Header/>
                    <Menu nameMenu={this.nameMenu}/>
                    <div>
                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h5 className="m-0 text-dark">Minimum commandable</h5>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">Administration</li>
                                                <li className="breadcrumb-item active">Minimum commandable</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row" id={"blocTextDisplay"}>
                                        <div className="col-lg-3 col-3">
                                            Valeur actuelle du Minimum commandable
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <DivMinimumCommandable id={"DivMinimumCommandable"}>
                                                {this.state.idminimumCommandable ? this.state.minimumCommandable : "aucune"}
                                            </DivMinimumCommandable>
                                            <br/>
                                            <div>
                                                <button onClick={(event) => {
                                                    this.createUpdateText(event)
                                                }} className="btn btn-info btn-login">
                                                    {this.state.idminimumCommandable ? "Modifier" : "Créer un minimum commandable"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" id={"blocTextForm"}>
                                        <div className="col-lg-3 col-3">
                                            Saisir ici le montant total minimum commandable
                                        </div>
                                        <div className="col-lg-9 col-9">
                                            <input
                                                id={"minimumCommandable"}
                                                name={"minimumCommandable"}
                                                type={"number"}
                                                size={50}
                                                onChange={(e) => this._handleTextChange(e)}
                                                value={this.state.minimumCommandable ? this.state.minimumCommandable : ""}
                                            >
                                            </input>
                                            <br/>
                                            <Alert id={"errorminimumCommandable"}>Le Montant minimum commandable ne doit
                                                pas être vide</Alert>
                                            <br/>
                                            <div>
                                                <button onClick={(event) => {
                                                    this.enregistrer(event)
                                                }} className="btn btn-info btn-login">Enregistrer
                                                </button>
                                                <LinkReset href="#" onClick={(event) => {
                                                    this.resetCreateUpdateText(event)
                                                }} className="btn btn-info btn-login">Annuler</LinkReset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <Footer/>
                    </div>
                </div>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return {
        minimumCommandable: state.minimumCommandable.minimumCommandable
    }
}

export default connect(mapStateToProps)(MinimumCommandable)
