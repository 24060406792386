import React from "react"
import HtmlStructure from "./NotificationsHtmlStructure"
import {DOCUMENT_TITLE_BACKOFFICE} from "../../../../constants"
import NotificationsHistoryComponents from "./NotificationsHistoryComponents"


export default function NotificationsHistory() {
    document.title = "Historique des envois de message - " + DOCUMENT_TITLE_BACKOFFICE
    return <HtmlStructure titleText={"Historique des envois"} breadcrumActiveText={"Historique des envois"}
                          menuName={"NotificationHistory"}>

        <section className="notifications-history">
            <NotificationsHistoryComponents.Table/>
        </section>

    </HtmlStructure>
}
