const initialCustomer = {};

export default function CustomReducer(state = initialCustomer, action){
    let nextState;
    switch(action.type){
        case "SET_CUSTOMER" :
            nextState = {
                ...state,
                customer : action.value
            }
            return nextState;
        // break;
        
        case "DELETE_CUSTOMER":
            nextState = {
                ...state,
                customer : initialCustomer
            }
            return nextState;
        // break;

        default :
            return nextState || state
    }
}