import React from "react"
import connectApi from "../../../../services/helpers/connectApi"
import {InputField, SelectField, TextareaField} from "./FormComponents"
import NotificationsHelper from "./NotificationsHelper"

const _ = require("lodash")

class Form extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        /* used to populate select options */
        this.recipientOptions = [
            {
                label: "Groupes",
                type: "groups",
                icon: "fa-users-line",
                options: []
            },
            {
                label: "Clients",
                type: "customers",
                icon: "fa-user",
                options: []
            }
        ]
        this.productOptions = [
            {
                label: "Produits",
                type: "product",
                icon: "fa-box-open",
                options: []
            }
        ]
        this.state = {
            optionsRecipients: [],
            optionsProducts: [],
            selectedProduct: null,
            selectedVariant: null,
            textInput: "",
            imageLink: "",
            planningDate: "",
            imageDisabled: true, //TODO: default false when the feature will be add
            planningDateDisabled: false,
            submitDisabled: false,
            textPreview: "",
            customTextPreview: "",
            customImagePreview: "",
            imagePreview: "",
            previewLoading: false,
            recipientLoading: true,
            submitLoading: false,
            productLoading: true,
            displayFeedback: false,
            currentProductNumber: 0,
            totalProducts: 0
        }

        this.errorMessage = ""
        this.hasError = false
        this.imageBasePath = ""

        this.handleRecipientSelectChange = this.handleRecipientSelectChange.bind(this)
        this.handleProductSelectChange = this.handleProductSelectChange.bind(this)
        this.handleTextInputChange = this.handleTextInputChange.bind(this)
        this.handleDateInputChange = this.handleDateInputChange.bind(this)
        this.handleImageInputChange = this.handleImageInputChange.bind(this)
        this.updatePreview = this.updatePreview.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.formatTextPreview = this.formatTextPreview.bind(this)
        this.handlePreviousPreviewClick = this.handlePreviousPreviewClick.bind(this)
        this.handleNextPreviewClick = this.handleNextPreviewClick.bind(this)
        this.formatProductArray = this.formatProductArray.bind(this)
    }

    componentDidMount() {
        (async function () {
            this.imageBasePath = NotificationsHelper.getPhotoBasePath()
            const urlParams = new URLSearchParams(window.location.search)

            /* ---------------------- Load message ---------------------- */
            if (urlParams.get("message") !== "" && urlParams.get("message") !== null) {
                const message = decodeURI(urlParams.get("message"))
                this.setState({textInput: message === "null" ? "" : message})
            }

            /* ---------------------- Load Recipients ---------------------- */
            const {
                groupRecipient,
                customerRecipient
            } = await NotificationsHelper.getRecipients("NotificationAccepted=1")
            this.recipientOptions[0].options = groupRecipient
            this.recipientOptions[1].options = customerRecipient

            if (urlParams.get("recipients") !== "" && urlParams.get("recipients") !== "null") {
                let recipients = decodeURI(urlParams.get("recipients"))
                let recipientsList = recipients.split(",")

                recipientsList = _.map(recipientsList, (r) => {
                    let result = null
                    if (r.split("|$|")[0] === "group") {
                        result = _.find(this.recipientOptions[0].options, function (o) {
                            return o.value === r
                        })
                    }
                    if (r.split("|$|")[0] === "customer") {
                        result = _.find(this.recipientOptions[1].options, function (o) {
                            return o.value === r
                        })
                    }
                    return result
                })

                this.setState({optionsRecipients: recipientsList})
            }

            this.setState({recipientLoading: false})

            /* ---------------------- Load variants ---------------------- */
            const resultVariants = await connectApi("stocks?isSalable=true", "GET", null)

            resultVariants.forEach((variant) => {
                const dlcDluoPriceText = NotificationsHelper.formatDlcDluoPriceText(variant.product, variant)
                const product = variant.product

                if (product) {
                    this.productOptions[0].options.push({
                        value: variant.CodeVarianteArticle,
                        CodeArticle: product.CodeArticle,
                        codeVariantArticle: variant.CodeVarianteArticle,
                        label: `${product.Libelle} - ${dlcDluoPriceText} (${product.CodeArticle})`
                    })
                }
            })

            if (urlParams.get("product") !== "" && urlParams.get("product") !== null) {
                const codes = decodeURI(urlParams.get("product"))
                let productsCodes = codes.split(",")
                productsCodes = _.map(productsCodes, (code) => {
                    return _.find(this.productOptions[0].options, function (o) {
                        return o.value === code
                    })
                })
                this.setState({optionsProducts: productsCodes})
                this.handleProductSelectChange(productsCodes)
            } else {
                this.setState({productLoading: false})
            }
        }).bind(this)()
    }

    handleRecipientSelectChange(selectedOptions) {
        this.setState({
            optionsRecipients: selectedOptions
        })
    }

    handleProductSelectChange(selectedOptions) {
        (async function () {
            this.setState({productLoading: true})
            let variant = null
            // let customImageDisabled = false
            if (selectedOptions !== undefined && selectedOptions.length !== 0) {
                variant = await connectApi(`stocks/${selectedOptions[0].value}`, "GET", null)
                variant.product = await connectApi(`products/${selectedOptions[0].CodeArticle}`, "GET", null)
                // customImageDisabled = true //TODO: set to true when the feature will be add
            }
            this.setState({
                optionsProducts: selectedOptions,
                selectedProduct: variant ? variant.product : null,
                selectedVariant: variant,
                productLoading: false,
                totalProducts: selectedOptions?.length ?? 0,
                currentProductNumber: (selectedOptions !== undefined && selectedOptions.length !== 0) ? 1 : 0
                // imageDisabled: customImageDisabled
            }, () => {
                this.updatePreview()
            })
        }).bind(this)()
    }

    handleTextInputChange(e) {
        this.setState({textInput: e.target.value}, () => {
            this.updatePreview()
        })
    }

    handleDateInputChange(e) {
        this.setState({planningDate: e.target.value})
    }

    handleImageInputChange(e) {
        const imageName = e.target.value
        const imageLink = (imageName === "") ? null : `${this.imageBasePath}/files/photos/2e08cac4312ea118580b65710.jpg`

        //TODO: verify image integrity + size + upload it to server and serve it

        this.setState({imageLink: imageLink}, () => {
            this.updatePreview()
        })
    }

    handlePreviousPreviewClick() {
        (async function () {
            const nextNumber = this.state.currentProductNumber - 1
            const variant = await connectApi(`stocks/${this.state.optionsProducts[nextNumber - 1].value}`, "GET", null)
            variant.product = await connectApi(`products/${this.state.optionsProducts[nextNumber - 1].CodeArticle}`, "GET", null)
            this.setState({
                selectedProduct: variant.product,
                selectedVariant: variant,
                currentProductNumber: nextNumber
            }, () => {
                this.updatePreview()
            })
        }).bind(this)()
    }

    handleNextPreviewClick() {
        (async function () {
            const nextNumber = this.state.currentProductNumber + 1
            const variant = await connectApi(`stocks/${this.state.optionsProducts[nextNumber - 1].value}`, "GET", null)
            variant.product = await connectApi(`products/${this.state.optionsProducts[nextNumber - 1].CodeArticle}`, "GET", null)
            this.setState({
                selectedVariant: variant,
                selectedProduct: variant.product,
                currentProductNumber: nextNumber
            }, () => {
                this.updatePreview()
            })
        }).bind(this)()
    }

    updatePreview() {
        (async function () {
            this.setState({previewLoading: true})
            let stateObject = {}

            if (this.state.selectedProduct !== null) {
                //TODO: remove hardcoded url
                stateObject.imagePreview = (this.state.selectedProduct.PathPhoto === null) ? "" : this.imageBasePath + this.state.selectedProduct.PathPhoto
            } else {
                // stateObject.imagePreview = this.state.imageLink
                stateObject.imagePreview = null
            }

            stateObject.textPreview = await this.formatTextPreview()

            this.setState(stateObject, () => {
                this.setState({previewLoading: false})
            })
        }).bind(this)()
    }

    async formatTextPreview() {
        return NotificationsHelper.formatTextMessage(this.state.textInput, this.state.selectedVariant)
    }

    formatProductArray() {
        return _.map(this.state.optionsProducts, (p) => {
            return {
                productsGeslot: `/api/products/${p.CodeArticle}`,
                stocksGeslot: `/api/stocks/${p.codeVariantArticle}`,
            }
        })
    }

    handleSubmit(e) {
        (async function () {
            this.setState({submitLoading: true, submitDisabled: true})

            if (!checkForm.bind(this)()) {
                this.hasError = true
                this.errorMessage = "Veuillez remplir le formulaire correctement : Au moins un destinataire et un contenu (texte ou produit)"
                displayFeedback.bind(this)(2000, "submitLoading")
                this.setState({submitDisabled: false})
                return
            }

            let customersRecipients = []
            let groupsRecipients = []
            this.state.optionsRecipients.forEach((recipient) => {
                if (recipient.value.split("|$|")[0] === "group") {
                    groupsRecipients.push("/api/notification-groups/" + recipient.value.split("|$|")[1])
                }
                if (recipient.value.split("|$|")[0] === "customer") {
                    customersRecipients.push("/api/customers/" + recipient.value.split("|$|")[1])
                }
            })

            const productArray = (this.state.optionsProducts.length === 0) ? [] : this.formatProductArray(this.state.optionsProducts)

            const dataObject = {
                message: this.state.textInput,
                // imageLink: this.state.imageLink,
                imageLink: null,
                notificationProducts: productArray,
                customersRecipients: customersRecipients,
                groupsRecipients: groupsRecipients,
                userEditor: "/api/user-sedis/" + JSON.parse(localStorage.getItem("state")).user.id
            }
            //Only add it if date is set
            if (this.state.planningDate !== null && this.state.planningDate !== "") {
                dataObject.scheduledAt = this.state.planningDate
            }

            const result = await connectApi("notification", "POST", dataObject)
            checkApiResult.bind(this)(result, "Les demandes de notifications ont été envoyées. Vérifiez les logs d'envois dans la page 'historique des envois' pour plus de détails", () => {
                this.setState({
                    submitDisabled: false,
                    textInput: "",
                    imageLink: null,
                    planningDate: "",
                    selectedProduct: null,
                    selectedVariant: null,
                    optionsRecipients: [],
                    optionsProducts: []
                }, () => {
                    this.updatePreview()
                })
            }, true, "submitLoading", 5000)
        }).bind(this)()
    }

    render() {
        const displayPreviousIcon = this.state.currentProductNumber > 1
        const displayNextIcon = this.state.currentProductNumber < this.state.totalProducts

        return <>
            <article className="message-form" style={{maxWidth: '50%'}}>
                <div className="mandatory-field"></div>
                <div className="notification-recipient-selector">
                    {this.state.recipientLoading && (
                        <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i>
                        </div>)}
                    <SelectField
                        className="formField reactSelect-custom"
                        classNamePrefix="reactSelect-custom"
                        options={this.recipientOptions}
                        value={this.state.optionsRecipients}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        placeholder="Selectionnez un ou plusieurs destinataire(s)"
                        onChange={this.handleRecipientSelectChange}
                        optionFocusedBackground={"#66B82126"}
                        optionActiveBackground={"#66B82133"}
                        context={this}
                    />
                </div>
                <div className="notification-product-selector">
                    {this.state.productLoading && (
                        <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i>
                        </div>)}
                    <SelectField
                        className="formField reactSelect-custom"
                        classNamePrefix="reactSelect-custom"
                        options={this.productOptions}
                        value={this.state.optionsProducts}
                        isMulti={true}
                        isClearable={true}
                        closeMenuOnSelect={true}
                        placeholder="Selectionnez une variante produit à mettre en avant"
                        onChange={this.handleProductSelectChange}
                        optionFocusedBackground={"#66B82126"}
                        optionActiveBackground={"#66B82133"}
                        context={this}
                    />
                </div>

                <div className="text-input-container">
                    <TextareaField name="textInput" value={this.state.textInput} className="bo-box-container"
                                   title="Un texte complémentaire qui sera ajouté à la fin du message." context={this}
                                   onChange={this.handleTextInputChange}>Texte complémentaire
                        (facultatif)</TextareaField>
                </div>

                <div className="date-input-container mt-0">
                    <InputField className="bo-box-container" type="datetime-local" name="planningDate"
                                title="Plannifier une date d'envoi" value={this.state.planningDate} context={this}
                                disabled={this.state.planningDateDisabled} onChange={this.handleDateInputChange}>
                        Date d'envoi (facultatif)
                    </InputField>
                </div>

                <div className="image-input-container helper-not-available-container">
                    <InputField type="file" name="image" value={undefined} accept="image/png, image/jpeg"
                                title="Une image à envoyer avec le message" context={this}
                                disabled={this.state.imageDisabled}
                                onChange={this.handleImageInputChange}>Image personnalisée (facultatif)</InputField>
                    <span className="notification-form-helper helper-not-available"><i
                        className="fas fa-arrow-circle-up"/> À venir dans une prochaine version</span>
                </div>

                <aside className="groups-buttons">
                    {this.state.submitLoading && (
                        <div className="notification-form-loadingMessage">
                            Demande créée, les envois sont en cours... <i className="fas fa-circle-notch fa-spin"></i>
                            <br/>Vous pouvez suivre l'évolution des envois via la page "Historique"
                        </div>
                    )}
                    <button type="button" className="btn btn-info"
                            title="Cliquez ici pour envoyer le message"
                            disabled={this.state.submitDisabled}
                            onClick={this.handleSubmit}>
                        <i className="fab fa-whatsapp"/> Envoyer
                    </button>
                </aside>

                {this.state.displayFeedback && (
                    <div
                        className={`alert notification-form-feedback ${this.hasError ? "alert-danger" : "alert-success"}`}
                        role="alert">
                        {this.errorMessage}
                    </div>
                )}
            </article>

            <article className="notification-preview">
                <div className="bo-box-container">
                    <div className="preview-bar">
                        <h6 className="preview-title">Preview</h6>
                        <span
                            className="preview-count">{this.state.currentProductNumber}/{this.state.totalProducts} </span>
                    </div>
                    <hr/>
                    <div className="text-preview-container"
                         style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/img/placeholderWhatsapp.jpg"})`}}>

                        {displayPreviousIcon &&
                            <div className="preview-previous" title="Produit précédent"
                                 onClick={this.handlePreviousPreviewClick}>
                                <i className="fas fa-chevron-circle-left"></i>
                            </div>
                        }

                        {this.state.previewLoading && (
                            <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i>
                            </div>)}
                        {this.state.imagePreview && (
                            <div className="notification-image-preview">
                                <img src={this.state.imagePreview} alt="Featured product"/>
                            </div>
                        )}
                        {this.state.textPreview && (
                            <div className="notification-text-preview"
                                 dangerouslySetInnerHTML={{__html: this.state.textPreview}}></div>
                        )}

                        {displayNextIcon &&
                            <div className="preview-next" title="Produit suivant" onClick={this.handleNextPreviewClick}>
                                <i className="fas fa-chevron-circle-right"></i>
                            </div>
                        }
                    </div>
                </div>
            </article>
        </>

    }
}

/* ================================== EXPORTED COMPONENTS ================================== */
const exported = {Form}
export default exported

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 *
 * @return {boolean}
 */
function checkForm() {
    return !((Array.isArray(this.state.optionsRecipients) && !this.state.optionsRecipients.length) || (this.state.textInput === "" && this.state.selectedProduct === null))
}

/**
 * Check if Api result contains an error and display a feedback message.
 * You can additionally set a message to display and a callback function that will be executed if the result does not contains any error
 *
 * @param result {Object}
 * @param defaultMessage {string}
 * @param callback {?function}
 * @param callFeedback
 * @param loadingStateName
 * @param feedbackTimeout
 */
function checkApiResult(result, defaultMessage = "updated", callback = null, callFeedback = true, loadingStateName = "loading", feedbackTimeout = 2000) {
    if (result.hasOwnProperty("@type") && result["@type"] === "hydra:Error") {
        this.hasError = true
        this.errorMessage = result["hydra:description"]
    } else {
        this.errorMessage = defaultMessage
        this.hasError = false
        if (callback !== null && typeof callback === "function") {
            callback.bind(this)()
        }
    }
    if (callFeedback) {
        displayFeedback.bind(this)(feedbackTimeout, loadingStateName)
    }
}

/**
 * Display an alert for 2 sec
 */
function displayFeedback(timeout = 2000, loadingStateName = "loading") {
    //End of the script : display feedback
    this.setState({
        displayFeedback: true,
        [loadingStateName]: false
    })

    // Hide Feedback message ater 2sec
    window.setTimeout(() => {
        this.setState({
            displayFeedback: false
        })
    }, timeout)
}