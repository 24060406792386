import {envVars} from "../../env_var"

// format date
export function formatDate(date) {
    if (date) {
        const dateToFornat = new Date(date);
        return new Intl.DateTimeFormat('fr-FR').format(dateToFornat)
    }
    return null
}

// format date short
export function formatDateShort(date) {
    if (date) {
        function pad(s) {
            return (s < 10) ? '0' + s : s;
        }

        var d = new Date(date)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear().toString().substr(-2)].join('/')
    }
    return null
}

// format currency
export function formatCurrency(price) {
    return new Intl.NumberFormat('fr-FR', {style: "currency", currency: "EUR"}).format(price);
}

// format number
export function formatNumber(number) {
    return new Intl.NumberFormat('fr-FR').format(number);
}

export function addSubClass(element, subClass) {
    element.className += ' ' + subClass;
}

export function removeSubClass(element, subClass) {
    element.className = element.className.replace(' ' + subClass, '');
}

// get tva
export function getTVA(codeTVA) {
    switch (codeTVA) {
        case 0 :
            return 0;
        //break;

        case 55 :
            return 5.5;
        // break;

        case 10 :
            return 10;
        // break;

        default :
            return 20
    }
}


// get customer price grid
export function getVarianHasLowPrice(product, customer) {
    let variant = null;
    let grille_price = 0;
    let stocks = product.variantes;
    if (stocks && stocks.length) {
        stocks.forEach((stock) => {
            const grille_name = stock.VenteAuColis ? `PrixVenteColis` : `PrixVentePalette`;
            const grille = stock[grille_name];
            if (grille_price === 0) {
                variant = stock;
                grille_price = grille;
            }
            if (grille <= grille_price) {
                variant = stock;
                grille_price = grille;
            }
        });
        return variant;
    }
    return null;
}

// get customer price grid
export function getCustomerPriceGrid(product, customer) {
    let grille_price = 0;
    let stocks = product.variantes;
    if (stocks && stocks.length) {
        stocks.forEach((stock) => {
            const grille_name = stock.VenteAuColis ? `PrixVenteColis` : `PrixVentePalette`;
            const grille = stock[grille_name];
            if (grille_price === 0) {
                grille_price = grille;
            }
            if (grille <= grille_price) {
                grille_price = grille;
            }
        });
        return grille_price;
    }
    return 0;
}

// get prix unitaire
export function getUnitPrice(prixColis, pcb, returnPrixUnitaire = false) {
    let pu = parseFloat(prixColis) / parseFloat(pcb);
    return returnPrixUnitaire ?
        Number.isInteger(prixColis) ? prixColis : parseFloat(prixColis).toFixed(2).toString() :
        Number.isInteger(pu) ? pu.toString() : pu.toFixed(2).toString()
        ;
}

// str to slug
export function strToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}


// get orderLine from localStorage
export function getLocalOrderLine() {
    const storage = localStorage.getItem('frontOrderLine');
    return storage != null ? JSON.parse(storage) : null;
}

// get all products from localStorage
export function getAllLocalProducts() {
    const storage = localStorage.getItem('frontOrderProducts');
    return storage != null ? JSON.parse(storage) : [];
}

// get product from localStorage
export function getProductFromStorage(data) {
    let product = null;
    const products = getAllLocalProducts();
    products.forEach(item => {
        if (item.CodeVarianteArticle === data.CodeVarianteArticle) {
            product = item;
        }
    })
    return product;
}

// get dlc_dluo value
export function getDLCLDUOValue(product, CodeVarianteArticle) {
    if (product.variantes && CodeVarianteArticle) {
        const dlc_dluo = product.variantes.filter(item => item.CodeVarianteArticle === CodeVarianteArticle);
        return dlc_dluo.length ? dlc_dluo[0] : false;
    }
    return false;
}

// get pricegrid 
export function getPiceGridNormal(variant, customer, ventePalette = false) {
    if (variant) {
        let grid = '';
        if (variant.VenteALaPalette && ventePalette) {
            grid = `PrixVentePalette`;
        } else if (variant.VenteAuColis) {
            grid = `PrixVenteColis`;
        } else {
            grid = `PrixVentePalette`;
        }
        return variant[grid];
    }
    return null;
}

// get pricegrid 
export function getPiceGrid(variant, customer, quantite, ventePalette = false) {
    if (variant) {
        let grid;
        if (variant.SeuilRemiseVolume > 0 && quantite >= variant.SeuilRemiseVolume) {
            grid = variant.VenteALaPalette && ventePalette ? `PrixVentePalette` : `PrixVenteColisRemise`;
        } else {
            grid = variant.VenteALaPalette && ventePalette ? `PrixVentePalette` : `PrixVenteColis`;
        }
        // console.log('gridname', grid);
        return variant[grid];
    }
    return null;
}

// get pricegrid 
export function getPiceGridRemise(variant, customer) {
    return getPiceGrid(variant, customer, variant.SeuilRemiseVolume);
}

// get or create product detail
export function getProductDetail(quantite, product, dlc_dluo, customer) {

    let priceTotal;
    const dlc_dluo_value = getDLCLDUOValue(product, dlc_dluo);
    const price_grid = getPiceGrid(dlc_dluo_value, customer, quantite);
    const price_unit = getUnitPrice(price_grid, product.PCB, true);


    if (product.UniteDeVente === 'K' && product.PoidsColis > 0) {
        priceTotal = price_grid * product.PoidsColis * quantite;
    } else if (product.UniteDeVente === 'K' && product.PoidsColis === 0) {
        priceTotal = 0.00;
    } else {
        priceTotal = price_unit * product.PCB * quantite;
    }
    priceTotal = Number.isInteger(priceTotal) ? priceTotal : parseFloat(priceTotal).toFixed(2);
    const response = {
        PCB: product.PCB,
        Tags: product.Tags,
        Quantite: quantite,
        PU: parseFloat(price_unit),
        PUsimple: parseFloat(price_unit),
        SeuilRemiseVolume: dlc_dluo_value.SeuilRemiseVolume,
        priceTotal: priceTotal,
        CodeTVA: product.CodeTVA,
        Libelle: product.Libelle,
        PriceGrid: price_grid,
        PathPhoto: product.PathPhoto,
        FamilleID: product.FamilleID,
        DlcOrDluo: product.DlcOrDluo,
        PoidsColis: product.PoidsColis,
        CodeArticle: product.CodeArticle,
        UniteDeVente: product.UniteDeVente,
        PoidsUnitaire: product.PoidsUnitaire,
        PoidsVariable: product.PoidsVariable,
        MaxOrderEnabled: product.MaxOrderEnabled,
        NbrColisParPalette: product.NbrColisParPalette,
        ThresholdFullRelease: product.ThresholdFullRelease,
        MaxOrderPerDayPerCustomer: product.MaxOrderPerDayPerCustomer
    }
    if (dlc_dluo_value.SeuilRemiseVolume > 0) {
        // remise au volume
        const price_grid_simple = getPiceGrid(dlc_dluo_value, customer, dlc_dluo_value.SeuilRemiseVolume - 1);
        const price_unit_simple = getUnitPrice(price_grid_simple, product.PCB, true);
        const price_grid_remise = getPiceGrid(dlc_dluo_value, customer, dlc_dluo_value.SeuilRemiseVolume);
        const price_unit_remise = getUnitPrice(price_grid_remise, product.PCB, true);
        response.PUsimple = parseFloat(price_unit_simple);
        response.PUremise = parseFloat(price_unit_remise);
    }
    if (dlc_dluo_value) {
        response.EnPromo = dlc_dluo_value.EnPromo;
        response.LeStock = dlc_dluo_value.LeStock;
        response.DateDLCDLUO = dlc_dluo_value.DateDLCDLUO;
        response.DateArrivage = dlc_dluo_value.DateArrivage;
        response.VenteAuColis = dlc_dluo_value.VenteAuColis;
        response.ArriveEntrepot = dlc_dluo_value.ArriveEntrepot;
        response.VenteALaPalette = dlc_dluo_value.VenteALaPalette;
        response.DateArrivagePrev = dlc_dluo_value.DateArrivagePrev;
        response.CodeVarianteArticle = dlc_dluo_value.CodeVarianteArticle;
    }
    return response;
}

export function applyProductMaximumCommandale(product) {
    let quantite = false;
    const qt = product.Quantite;
    const stockValue = product.LeStock;
    const applyStatus = product.MaxOrderEnabled;
    const qtRelease = product.ThresholdFullRelease;
    const qtMaxPerDayPerCustomer = product.MaxOrderPerDayPerCustomer;
    const qtColisPerPalette = product.NbrColisParPalette;

    if (applyStatus) {

        if ((stockValue < qt) && (stockValue < qtRelease)) {
            quantite = stockValue;
        } else if (qtColisPerPalette) {
            const qtMC = parseInt(stockValue * (qtMaxPerDayPerCustomer / 100));
            quantite = Math.min(qt, Math.ceil(qtMC / qtColisPerPalette) * qtColisPerPalette);
        } else {
            quantite = Math.min(qt, parseInt(stockValue * (qtMaxPerDayPerCustomer / 100)));
        }

    }

    return quantite;
}

export function getDefaultImage(rayons, product) {
    if (product && product.FamilleID) {
        const rayon_produit = rayons.filter(item => item.Identifiant === product.FamilleID);
        const slug = strToSlug(rayon_produit[0].Nomcategorie)
        const pictoName = `icons-8-${slug}.svg`;
        return envVars['scheme'] + window.location.host + '/img/default/' + pictoName;
    } else {
        return envVars['scheme'] + window.location.host + '/img/default-product-img.png';
    }
}
