import React, { useState} from 'react';
import ConnectApi from "../../../../services/helpers/connectApi";

const WhatsAppStatus = () => {
    const [status, setStatus] = useState('online');

    useState(() => {
        checkState()
    });

    function checkState() {
        ConnectApi('whatsapp-status', 'GET', null, 5000).then(result => {
            setStatus(result && result.statusInfo
            && result.statusInfo.state
            && result.statusInfo.state.state === "CONNECTED" ? "online" : "offline")

            setTimeout(() => {
                checkState()
            }, 60000)
        });
    }

    const getStatusColor = () => {
        switch (status) {
            case 'online':
                return 'green';
            case 'offline':
                return 'red';
            default:
                return 'gray';
        }
    }

    const getStatusMessage = () => {
        let message = ''
        switch (status) {
            case 'online':
                message = <div>API WhatsApp accessible, service disponible et téléphone SEDIS connecté. <br/>
                    Envoi des messages en attente possible.</div>;
                break
            case 'offline':
                message = <div>API WhatsApp inaccessible, hors-service ou téléphone SEDIS déconnecté. <br/>
                    Envoi des messages en attente impossible.</div>;
                break
            default:
                message = '';
                break
        }

        return status.length ? <div className="tooltip">
            {message}
        </div> : ''
    }

    return (
        <>
            <span style={{fontSize: "13px"}}>API WhatsApp:</span>
            <div className="whatsapp-status">
                <i className="fa-brands fa-whatsapp " style={{
                    marginRight: "10px",
                    marginLeft: "5px", color: getStatusColor(),
                    fontSize: "18px",
                    cursor: "default",
                    animation: status === 'offline' ? 'blink 1s infinite' : '',
                }}/>

                {getStatusMessage()}
            </div>
        </>
    );
};

export default WhatsAppStatus;

