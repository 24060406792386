import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {BeatLoader} from "react-spinners"

// App
import SectionTitle from "./SectionTitle"
import {Div} from "../../Mixed/Composants"
import CommandeRapideItem from "./CommandeRapideItem"
import {formatCurrency} from "../../../services/helpers/FrontHelper"
import {getProducts} from "../../../services/helpers/FrontApiHelper"

const Wrapper = styled(Div)`
    &:not(:first-child){
        padding-top: 60px;
    }
`

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .7rem 0;
`

const Label = styled.span`
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background: #fff;
  margin: 0 auto -13px;
  z-index: 99;
  position: relative;
  border-radius: 50%;
  @media screen and (max-width: 2000px) {
    width: 65px;
    height: 65px;
    font-size: 18px;
    margin-bottom: -10px;
  }
`

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-style: italic;
`

const Table = styled.table`
  margin: 0;
  width: 100%;
`

const Thead = styled.thead`
  border: 0;
`

const TBody = styled.tbody`
  padding: 0;
`

const Tr = styled.tr`
  position: relative;
`
const Td = styled.td``;
const Th = styled.th`
    position: relative;
`

const WrapLoader = styled.div`
    padding: 9px 0 0;
`

const HeadImage = styled(Th)`
  width: 7%;
`

const HeadTitle = styled(Th)`
  width: 33%;
`
const HeadPm = styled(Th)`
  width: 5%;
`

const HeadMm = styled(Th)`
  width: 10%;
`

const HeadPrice = styled(Th)`
  width: 10%;
`

const HeadQuantity = styled(Th)`
  width: 10%;
`

const TableResponsive = styled.div`
  padding-bottom: 10px;
  @media only screen and (min-width: 1000px){
    overflow: visible;
  }
`

const NoProduct = styled.p`
  text-align: center;
  margin: 2em 0 0;
`

const LoadMore = styled.button`
    margin: 20px auto 5px;
    border: 0;
    display: block;
    font-family: 'Covered By Your Grace', sans-serif;
    font-size: 30px;
    &:hover{
        color: #66b821;
    }
    &:active, &:focus{
        border: 0;
        outline: 0;
    }
`

class CommandeRapideGroup extends React.Component {

    _paged = 1
    _isCalled = false
    _isMounted = false

    state = {
        products: [],
        loading: false,
        isEndOfLists: false,
        hasmore: true
    }

    _handleOnUpdate = () => {
        const products = this._getLocalProducts()
        this._renderPriceTotal(products)
    }

    _getLocalProducts = () => {
        const storage = localStorage.getItem("frontOrderProducts")
        return storage != null ? JSON.parse(storage) : []
    }

    _getProducts = (appendNewProducts = false) => {
        const {rayonId, onContentLoaded} = this.props
        getProducts(rayonId, this._paged)
            .then(products => {
                if (products && products.length) {
                    const data = appendNewProducts ? [...this.state.products, ...products] : products
                    this.setState({products: data}, () => {
                        if (typeof (onContentLoaded) === "function" && !this._isCalled) {
                            onContentLoaded()
                        }
                        this._isCalled = true
                    })
                }
                if ((products && products.length === 0) || (products.length === 1 && this.state.products.length === 1) || (products.length < 30)) {
                    this.setState({hasmore: false});
                }
            })
            .finally(() => this.setState({loading: false}))
            .catch(e => console.log(e))
    }

    _getMoreProducts = () => {
        this.setState({loading: true}, () => {
            this._paged += 1
            this._getProducts(true)
        })
    }

    _renderPriceTotal = (tmp_data) => {
        let total = 0
        const elements = document.getElementsByClassName("front__order-total")
        if (tmp_data && tmp_data.length) {
            tmp_data.forEach((item) => {
                total += parseFloat(item.priceTotal)
            })
        }
        if (elements) {
            Array.from(elements).forEach(element => {
                element.innerHTML = formatCurrency(total)
            })
        }
    }

    _renderLoader = () => {
        return (
            <WrapLoader>
                <Loader>
                    <Text>Chargement de la liste en cours...</Text>
                </Loader>
            </WrapLoader>
        )
    }

    _renderNoProducts = () => {
        const {rayonName} = this.props
        const {products, loading} = this.state
        if (products && !products.length && !loading) {
            return (
                <Tr>
                    <Td colSpan={10}>
                        <NoProduct>Aucun produit
                            : <b>{rayonName === "BOISSON" ? "BOISSONS" : rayonName}</b> correspondant à vos
                            filtres</NoProduct>
                    </Td>
                </Tr>
            )
        }
    }

    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.setState({loading: true}, () => {
                const products = this._getLocalProducts()
                this._renderPriceTotal(products)
                this._getProducts()
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {products, hasmore, loading} = this.state;
        const {rayonName, customer} = this.props;

        return (
            <Wrapper id={"rayon-" + this.props.rayonId}>
                <SectionTitle tag="h2">{rayonName === "BOISSON" ? "BOISSONS" : rayonName}</SectionTitle>
                {products.length === 0 &&
                    this._renderLoader()
                }
                {products.length > 0 &&
                    <TableResponsive className="table-responsive">
                        <Table>
                            <Thead>
                                <Tr>
                                    <HeadImage>&nbsp;</HeadImage>
                                    <HeadTitle>&nbsp;</HeadTitle>
                                    <HeadPm><Label>Pièces/<br/>Colis</Label></HeadPm>
                                    <HeadPm><Label>Poids<br/>(Kg)</Label></HeadPm>
                                    <HeadPm><Label>U/ <br/>Kg</Label></HeadPm>
                                    <HeadPm><Label>Prix unitaire</Label></HeadPm>
                                    <HeadMm><Label>Date d’arrivage</Label></HeadMm>
                                    <HeadMm><Label>DLC<br/>DLUO</Label></HeadMm>
                                    <HeadPrice><Label>Prix Total</Label></HeadPrice>
                                    <HeadQuantity><Label>Quantité</Label></HeadQuantity>
                                </Tr>
                            </Thead>
                            <TBody>
                                {products.map((product, key) => {
                                    if (product.variantes.length > 1) {
                                        return product.variantes.map((variant, k) => {
                                            return (
                                                <CommandeRapideItem
                                                    key={key + '-' + k}
                                                    tabIndex={1}
                                                    product={product}
                                                    variant={variant}
                                                    customer={customer}
                                                    onUpdate={this._handleOnUpdate}
                                                />
                                            )
                                        })
                                    }
                                    return (
                                        <CommandeRapideItem
                                            key={key}
                                            tabIndex={1}
                                            product={product}
                                            customer={customer}
                                            variant={product.variantes[0]}
                                            onUpdate={this._handleOnUpdate}
                                        />
                                    )
                                })}
                                {hasmore &&
                                    <tr>
                                        <td colSpan={10}>
                                            <LoadMore onClick={this._getMoreProducts}>Cliquer pour charger plus...</LoadMore>
                                        </td>
                                    </tr>
                                }
                                {loading &&
                                    <tr>
                                        <td colSpan={10} align="center">
                                            <BeatLoader loading={loading} color="#ff5d1f"/>
                                        </td>
                                    </tr>
                                }
                            </TBody>
                        </Table>
                    </TableResponsive>
                }
            </Wrapper>
        )
    }

}

CommandeRapideGroup.propTypes = {
    rayonId: PropTypes.string.isRequired,
    rayonName: PropTypes.string.isRequired,
    customer: PropTypes.object.isRequired,
    onContentLoaded: PropTypes.func
}

export default CommandeRapideGroup
