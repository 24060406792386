import './App.css';
import React from 'react';
import interval from 'interval-promise';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

// Front
import {AppStore} from './reducers';
import {UrlPrefixContext} from './context';
import {SecureFrontRoute} from './components/Mixed/Composants';
import SimpleReactLightbox from 'simple-react-lightbox';
// End - Front

// App
import {AVAILABILITY_DELAY} from './constants';
import {checkApiAvailability} from './services/helpers/FrontApiHelper';

// redux
const {store, persistor} = AppStore;


class App extends React.Component {
    componentDidMount() {
        let counterCheckAvailabilityGeslot = 0;
        interval(async () => {
            const {customer, availability} = store.getState();
            if (customer && customer.customer && customer.customer.user && customer.customer.user.isCustomer) {
                const body = {
                    "email": customer.customer.user.email,
                    "counterCheckAvailabilityGeslot": counterCheckAvailabilityGeslot,
                    "previousStateGeslotIsAvailable": availability && availability.availability && availability.availability.previousStateGeslotIsAvailable === undefined ?
                        counterCheckAvailabilityGeslot === 0
                        :
                        true
                };
                const status = await checkApiAvailability(body);
                store.dispatch([
                    {
                        type: 'UPDATE_AVAILABILITY',
                        value: {
                            counterCheckAvailabilityGeslot: counterCheckAvailabilityGeslot,
                            previousStateGeslotIsAvailable: status
                        }
                    },
                ]);
            }

        }, AVAILABILITY_DELAY).then()
    }

    render() {
        return (
            <div className="application">
                <SimpleReactLightbox>
                    <div className="wrapper">
                        <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <UrlPrefixContext.Provider
                                    value={{prefix: '/home/frontoffice', prefixBack: '/home/backoffice'}}>
                                    <SecureFrontRoute/>
                                </UrlPrefixContext.Provider>
                            </PersistGate>
                        </Provider>
                    </div>
                </SimpleReactLightbox>
            </div>
        );
    }
}

export default App;
