import React from "react";
import {Container} from "../../Mixed/Composants";
import styled from "styled-components";
import connectApi from "../../../services/helpers/connectApi";

const Alert = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin: 0;
  text-align: center;
`

export default class FrontMessage extends React.Component {
    _isMounted = false
    _timout;

    constructor(props) {
        super(props);
        this.state = {
            message: ""
        }

        this._getFrontMessage = this._getFrontMessage.bind(this)
    }

    componentDidMount() {
        this._isMounted = true;
        this._getFrontMessage()
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this._timout)
    }

    _getFrontMessage() {
        connectApi("current-front-messages", "GET", null).then(result => {
            const frontMessages = result["hydra:member"]

            if (this._isMounted) {
                if (frontMessages.length) {
                    this.setState({
                        message: frontMessages[0].message
                    })
                } else {
                    this.setState({
                        message: ""
                    })
                }
            }

            this._timout = setTimeout(() => {
                this._getFrontMessage()
            }, 60000)
        })
    }

    render() {
        return (
            <div className={this.state.message ? "" : "d-none"}>
                <Alert className="alert alert-danger alert-dismissible">
                    <Container fluid>
                        {this.state.message}
                    </Container>
                </Alert>
            </div>
        );
    }
}
