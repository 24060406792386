import React, { Component } from 'react'
import connectApi from '../../../../services/helpers/connectApi'
import Header from '../../../Mixed/Header/Header'
import Menu from '../../../Mixed/Menu/Menu'
import Footer from '../../../Mixed/Footer/Footer'
import { createBrowserHistory } from 'history'
import { HTTP_UNAUTHORIZED, MAX_RESULT_ERROR, prefixBack, prefixFront } from '../../../../constants'
import Loading from '../../Loading'
import './TotalGardeFou.css'

const newHistory = createBrowserHistory()

export default class TotalGardeFou extends Component {
    currentState = JSON.parse(localStorage.getItem('state'))
    user = this.currentState.user
    state = {
      isAuthenticated: this.currentState.isAuthenticated,
      listCustomer: [],
      listProductOrVariante: [],
      listFamily: [],
      totalUnreadCustomer: 0,
      totalUnreadProductOrVariante: 0,
      totalUnreadFamily: 0,
      token: this.currentState.token,
      user: {
        id: this.user.id,
        email: this.user.email,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        role: this.user.role,
        isCustomer: this.user.isCustomer,
        CodeClient: this.user.CodeClient,
      }
    };

    nameMenu = 'TotalGardeFou'
    
    componentDidMount() {
      this.renderListCustomer()
      this.renderListProductOrVariante()
      this.renderListFamily()
    }

    renderListCustomer = async () => {
      const data = await connectApi('customers-failure', 'GET', null)
      if (typeof(data) === 'object') {
          if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
              newHistory.push(prefixFront + '/login')
          } else {
              // this will re render the view with new data
              this.setState({
                  listCustomer: data,
              })
              let totalUnreadCustomer = JSON.stringify(data.length) - localStorage.getItem('totalCustomer')
              let gardeFouCustomerIsClicked = localStorage.getItem('gardeFouCustomerIsClicked')
              this.setState({
                totalUnreadCustomer: gardeFouCustomerIsClicked === true ? 0 : totalUnreadCustomer
              })
              localStorage.setItem('gardeFouCustomerIsClicked', false)
          }
      } else {
          console.log(data)
      }
    }

    renderListProductOrVariante = async () => {
      const data = await connectApi('products-variantes-failure?maxResultError=' + MAX_RESULT_ERROR, 'GET', null)
      if (typeof(data) === 'object') {
          if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
              newHistory.push(prefixFront + '/login')
          } else {
              // this will re render the view with new data
              this.setState({
                  listProductOrVariante: data,
              });
              if (document.getElementById('div-loading')) {
                document.getElementById('div-loading').style.display = 'none';
              }
              let totalUnreadProductOrVariante = JSON.stringify(data.length) - localStorage.getItem('totalProductOrVariante')
              let gardeFouProductIsClicked = localStorage.getItem('gardeFouProductIsClicked')
              this.setState({
                totalUnreadProductOrVariante: gardeFouProductIsClicked === true ? 0 : totalUnreadProductOrVariante
              })
              localStorage.setItem('gardeFouProductIsClicked', false)
          }
      } else {
          console.log(data)
      }
    }

    renderListFamily = async () => {
      const data = await connectApi('incoherent-familles', 'GET', null)
      if (typeof(data) === 'object') {
          if (data.hasOwnProperty('code') && data.code === HTTP_UNAUTHORIZED) {
              newHistory.push(prefixFront + '/login')
          } else {
              // this will re render the view with new data
              this.setState({
                  listFamily: data,
              });
              let totalUnreadFamily = JSON.stringify(data.length) - localStorage.getItem('totalFamily')
              let gardeFouFamilyIsClicked = localStorage.getItem('gardeFouFamilyIsClicked')
              this.setState({
                totalUnreadFamily: gardeFouFamilyIsClicked === true ? 0 : totalUnreadFamily
              })
              localStorage.setItem('gardeFouFamilyIsClicked', false)
          }
      } else {
          console.log(data)
      }
    }

    render() {
        return (
          <div className="wrapper">
            <Header/>
            <Menu nameMenu={ this.nameMenu } />
            <div>
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                  <div className="container-fluid">
                      <div className="row mb-2">
                      <div className="col-sm-6">
                          <h5 className="m-0 text-dark">Garde-fou</h5>
                      </div>{/* /.col */}
                      <div className="col-sm-6">
                          <ol className="breadcrumb float-sm-right">
                          {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                          <li className="breadcrumb-item">Système</li>
                          <li className="breadcrumb-item active">Garde-fou</li>
                          </ol>
                      </div>{/* /.col */}
                      </div>{/* /.row */}
                  </div>{/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <section className="content">
                <div className="container-fluid">
                  {/* Small boxes (Stat box) */}
                  <div className="row">
                    <div className="col-lg-3 col-6">
                        <div className="small-box bg-info">
                          <div className="inner">
                            <h3>{ this.state.listCustomer.length }</h3>
                            <p>Clients invalides</p>
                            { 
                              this.state.totalUnreadCustomer > 0
                              &&
                              <p>
                                <i className="far fa-bell image-clignote"> { this.state.totalUnreadCustomer } </i> nouveaux Clients invalides
                              </p>
                            }
                          </div>
                          <div className="icon">
                            <i className="ion " />
                          </div>
                            <a href={ prefixBack + '/invalidcustomer' } className="small-box-footer">Plus d'informations <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                      <div className="small-box bg-success">
                        <div className="inner">
                          <h3>{ this.state.listProductOrVariante.length }</h3>
                          <p>Produits/variants invalides</p>
                          { 
                            this.state.totalUnreadProductOrVariante > 0
                            &&
                            <p>
                              <i className="far fa-bell image-clignote"> { this.state.totalUnreadProductOrVariante } </i> nouveaux produits/variants invalides
                            </p>
                          }
                        </div>
                        <div className="icon">
                          <i className="ion " />
                        </div>
                        <a href={ prefixBack + '/productorvariante' } className="small-box-footer">Plus d'informations <i className="fas fa-arrow-circle-right" /></a>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="small-box bg-warning">
                          <div className="inner">
                            <h3>{ this.state.listFamily.length }</h3>
                            <p>Familles invalides</p>
                            { 
                              this.state.totalUnreadFamily > 0
                              &&
                              <p>
                                <i className="far fa-bell image-clignote"> { this.state.totalUnreadFamily } </i> nouvelles familles invalides
                              </p>
                            }
                          </div>
                          <div className="icon">
                            <i className="ion " />
                          </div>
                           <a href={ prefixBack + '/invalidfamily' } className="small-box-footer">Plus d'informations <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                    {/* ./col */}
                    {/* <div className="col-lg-3 col-6">
                      <div className="small-box bg-danger">
                        <div className="inner">
                          <h3>65</h3>
                          <p>Unique Visitors</p>
                        </div>
                        <div className="icon">
                          <i className="ion ion-pie-graph" />
                        </div>
                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                      </div>
                    </div> */}
                    {/* ./col */}
                  </div>
                  <Loading/>
                  {/* /.row */}
                </div>{/* /.container-fluid */}
              </section>
              {/* /.content */}
            </div>
            </div>
            <Footer/>
          </div>

        )
    }
}
