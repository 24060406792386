import React from "react"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"
import {connect} from "react-redux"

// App
import {Div} from "../../Mixed/Composants/"
import {UrlPrefixContext} from "../../../context"
import {DownloadInvoice, SectionTitle} from "../Composants"
import {Content, Footer, Header} from "../Composants/Template"
import {getCustomerInvoices} from "../../../services/helpers/FrontApiHelper"
import {formatCurrency, formatDate} from "../../../services/helpers/FrontHelper"

const Wrap = styled.div`
  padding: 2em;
  margin-top: 2em;
  background: #fff;
  box-shadow: 0 0 8px #f3e6e6;
`

const Alert = styled.span`
  padding: .3rem 1rem;
  margin-bottom: 0;
`

const WrapLoader = styled.div`
  padding: 9px 0 0;
`

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .7rem 0;
`

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-style: italic;
`

class MesFacturesPage extends React.Component {

    static contextType = UrlPrefixContext
    _isMounted = false

    state = {
        paged: 1,
        invoices: [],
        loader: false,
        loading: false,
        downloading: false,
        hasInvoices: false
    }

    _getInvoices(reset = false) {
        const tmp_invoices = []
        const {paged} = this.state
        const {customer} = this.props
        if (customer && customer.hasOwnProperty("user")) {
            getCustomerInvoices(customer.user.CodeClient, paged)
                .then(response => {
                    if (!response || response.code === 0 || response.code === 200) {
                        this.setState({hasInvoices: false, invoices: []})
                        return false
                    }
                    response.forEach(invoice => {
                        if (invoice.CodeFacture) {
                            tmp_invoices.push(invoice)
                        }
                    })
                    if (!tmp_invoices.length && !this.state.invoices.length) {
                        this.setState({hasInvoices: false, invoices: []})
                        return false
                    }
                    if (tmp_invoices.length) {
                        const data = reset ? tmp_invoices : [...this.state.invoices, ...tmp_invoices]
                        this.setState({invoices: data, hasInvoices: true})
                    }
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                        loader: false
                    })
                })
                .catch(e => console.log(e))
        }
    }

    _getMoreInvoices = () => {
        this.setState({loading: true, paged: this.state.paged + 1}, () => {
            this._getInvoices()
        })
    }

    _renderLoader = () => {
        const {loading} = this.state
        return (
            <WrapLoader className={loading ? "d-block" : "d-none"}>
                <Loader>
                    <Text>Chargement de la liste en cours...</Text>
                </Loader>
            </WrapLoader>
        )
    }

    _renderNoInvoices = () => {
        return (
            <center><p>Aucune facture n'a été enregistrée</p></center>
        )
    }

    _renderInvoices = () => {
        const {invoices} = this.state
        return (
            <React.Fragment>
                <SectionTitle tag="h1">Vos factures</SectionTitle>
                <Wrap className="table-responsive">
                    <InfiniteScroll
                        hasMore={true}
                        dataLength={invoices.length}
                        loader={this._renderLoader()}
                        next={this._getMoreInvoices}
                    >
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th>Facture N°</th>
                                <th>Date facture</th>
                                <th>Montant HT</th>
                                <th>Etat facture</th>
                                <th width="15%">Téléchargement</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                invoices.map((invoice, index) => {
                                    let cls = "alert "
                                    let status
                                    const echeance = new Date(invoice.DateEchEanceFacture)
                                    const now = new Date()

                                    if (invoice.StatutFacture.toLowerCase() === "paye") {
                                        status = "Soldée"
                                        cls += "alert-success"
                                    } else if (invoice.StatutFacture.toLowerCase() === "a payer") {
                                        status = "A payer"
                                        cls += "alert-info"
                                    } else {
                                        if (echeance < now) {
                                            status = "Échue"
                                            cls += "alert-danger"
                                        } else {
                                            status = "Due"
                                            cls += "alert-warning"
                                        }
                                    }
                                    return (
                                        <tr key={index}>
                                            <td className="v-align-center"><strong># {invoice.CodeFacture}</strong></td>
                                            <td className="v-align-center">{formatDate(invoice.DateFacture)}</td>
                                            <td className="v-align-center">{formatCurrency(invoice.MontantHTFacture)}</td>
                                            <td className="v-align-center"><Alert className={cls}>{status}</Alert></td>
                                            <td className="v-align-center">
                                                <DownloadInvoice codeFacture={invoice.CodeFacture}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </Wrap>
            </React.Fragment>
        )
    }

    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.setState({loader: true})
            this._getInvoices()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {loader, hasInvoices} = this.state

        return (
            <Div className="front__wrap-screen">
                <Header/>
                <Content loading={loader}>
                    {!loader && !hasInvoices && this._renderNoInvoices()}
                    {!loader && hasInvoices && this._renderInvoices()}
                </Content>
                <Footer/>
            </Div>
        )
    }

}

const mapStateToProps = state => {
    return {
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(MesFacturesPage)