import React from "react"
import ReactSelect from "react-select"
import "./formComponents.css"
import ReactDOM from "react-dom"

class SelectField extends React.Component {

    constructor(props) {
        super(props)
        this.formatGroupLabel = this.formatGroupLabel.bind(this)
    }

    formatGroupLabel(data) {
        let icon = data.icon ?? "fa-user"
        return <div className={"reactSelect-custom__groupe"}>
            <span><i className={`fas ${icon}`}/> {data.label}</span>
            <span className={"reactSelect-custom__groupeBadge"}>{data.options.length}</span>
        </div>
    }

    handleSelectChange(selectedOptions) {
        this.setState({
            data: selectedOptions
        })
    }

    render() {
        const {
            className,
            classNamePrefix,
            options,
            isMulti,
            isClearable,
            closeMenuOnSelect,
            placeholder,
            context,
            value
        } = this.props
        const handleSelectChange = this.props.onChange ?? this.handleSelectChange.bind(context)
        const formatGroupLabel = this.props.formatGroupLabel ?? this.formatGroupLabel
        const isInputClearable = isClearable ?? true

        const optionFocusedBackground = this.props.optionFocusedBackground ?? "#efefef"
        const optionActiveBackground = this.props.optionActiveBackground ?? "#c5c5c5"

        const selectStyle = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                backgroundColor: state.isSelected ? optionActiveBackground : (state.isFocused ? optionFocusedBackground : undefined),
                ":active": {backgroundColor: optionActiveBackground}
            })
        }

        return <ReactSelect
            // key={`unique_select_key__${JSON.stringify(value)}`}
            className={className}
            classNamePrefix={classNamePrefix}
            onChange={handleSelectChange}
            options={options}
            value={value || ''}
            isMulti={isMulti}
            isClearable={isInputClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            formatGroupLabel={formatGroupLabel}
            placeholder={placeholder}
            styles={selectStyle}
            aria-label="Selector"
        />
    }
}

class CheckboxField extends React.Component {

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    getUniqueId() {
        return generateUUID(5)
    }

    render() {
        const {children, name, checked, value, title, context, onChange} = this.props
        const id = `${name}-${this.getUniqueId()}`
        const onCheckChange = onChange ?? this.handleChange.bind(context)

        return <div className={"formField formField-checkbox"}>
            <input id={id} name={name} title={title} type={"checkbox"} checked={checked}
                   value={value}
                   onChange={onCheckChange}/>
            <label htmlFor={id}>{children}</label>
            <span className={"checkboxIndicator"}></span>
        </div>
    }
}

class InputField extends React.Component {
    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getFocus(e) {
        e.target.classList.add("formField--focused")
    }

    looseFocus(e) {
        const element = e.target
        if (element.value === "") {
            element.classList.remove("formField--focused")
        }
    }

    render() {
        const {
            type,
            className,
            children,
            name,
            value,
            title,
            context,
            onChange,
            onKeyDown,
            forwardedRef,
            required,
            disabled,
            accept,
            min
        } = this.props
        const currentContext = context ?? this
        const inputType = type ?? "text"
        // eslint-disable-next-line no-unused-vars
        const isRequired = (typeof required === "boolean") ? required : false //Todo: find a way to use this
        const isDisabled = (typeof disabled === "boolean") ? disabled : false //Todo: find a way to use this
        const onInputChange = onChange ?? this.handleChange.bind(currentContext)
        return <div className={`formField formField-input ${className}`}>
            <input type={inputType} id={name} name={name} title={title}
                   min={min}
                   ref={forwardedRef} value={value}
                   onChange={onInputChange} onFocus={this.getFocus.bind(currentContext)}
                   onBlur={this.looseFocus.bind(currentContext)}
                   className={value ? 'formField--focused' : ''}
                   onKeyDown={onKeyDown} required={isRequired} disabled={isDisabled} accept={accept}></input>
            <label htmlFor={name}>{children}</label>
            <span className="formField-underline"></span>
        </div>
    }
}

class TextareaField extends React.Component {

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getFocus(e) {
        e.target.classList.add("formField--focused")
    }

    looseFocus(e) {
        const element = e.target
        if (element.value === "") {
            element.classList.remove("formField--focused")
        }
    }

    render() {
        const {
            children,
            className,
            name,
            value,
            title,
            rows,
            cols,
            context,
            onChange,
            onKeyDown,
            forwardedRef,
            required
        } = this.props
        const currentContext = context ?? this
        // eslint-disable-next-line no-unused-vars
        const isRequired = (typeof required === "boolean") ? "required" : "" //Todo: find a way to use this
        const onInputChange = onChange ?? this.handleChange.bind(currentContext)

        return <div className={`formField formField-textarea ${className}`}>
            <textarea
                id={name} name={name} title={title} rows={rows} cols={cols}
                ref={forwardedRef} value={value} onChange={onInputChange}
                onFocus={this.getFocus.bind(currentContext)}
                onBlur={this.looseFocus.bind(currentContext)} onKeyDown={onKeyDown}
                className={value ? 'formField--focused' : ''}
            ></textarea>
            <label htmlFor={name}>{children}</label>
            <span className="formField-underline"></span>
        </div>
    }
}

const Modal = ({isShowing, hide, title, ...props}) => {
    return (!isShowing) ? null : ReactDOM.createPortal(
        <>
            <div className={`custom-modal-overlay ${props.className}`}>
                <div className="custom-modal-wrapper">
                    <div className="custom-modal">
                        <div className="custom-modal-header">
                            <h4>{title}</h4>
                            <button type="button" className="custom-modal-close-button btn btn-light" onClick={hide}>
                                <span><i className="fas fa-times"></i></span>
                            </button>
                        </div>
                        <div className="custom-modal-body">{props.children}</div>
                    </div>
                </div>
            </div>
        </>,
        document.body
    )
}

/**
 *
 * @param length {number}
 * @return {string}
 */
function generateUUID(length = 5) {
    const uuid = crypto.randomUUID()
    return uuid.slice(uuid.length - 5)
}

export {
    InputField,
    TextareaField,
    SelectField,
    CheckboxField,
    Modal
}
