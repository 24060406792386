import React from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"

// App 
import {UrlPrefixContext} from "../../../../context"
import {Div, Row, Column, List, ListItem} from "../../../Mixed/Composants"

const Title = styled.span`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.54;
  color: #404040;
  margin-bottom: 25px;
  @media screen and (max-width: 2000px) {
    font-size: 20px;
  }
`

const AppTitle = styled(Title)`
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 35px;
  line-height: 1.14;
  font-weight: normal;
  max-width: 161px;
  @media screen and (max-width: 2000px) {
    font-size: 28px;
  }
`

const Img = styled.img`
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
`

const Menu = styled(List)`
  list-style: none;
  margin: 0;
  padding: 0;
`

const MenuItem = styled(ListItem)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 2000px) {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`

const MenuLink = styled(Link)`
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #404040;
  pointer-events: none;

  &:hover,
  &:focus,
  &:active {
    color: #66b821;
    text-decoration: none;
  }

  @media screen and (max-width: 2000px) {
    font-size: 17px;
  }
`

const Apps = styled(Menu)`
  text-align: left;
`

const App = styled(ListItem)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Version = styled.span`
  font-family: 'Raleway', sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
`

class BottomFooter extends React.Component {

    render() {

        return (
            <Div className="front__bottom-footer position-relative" {...this.props}>
                <Row>
                    <Column lg={3}>
                        <Title>A propos de Sedis :</Title>
                        <Menu className="mb-0">
                            <MenuItem>
                                <MenuLink to={`${this.context.prefix}/commande-rapide`}>Plan du site</MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuLink to={`${this.context.prefix}/commande-rapide`}>Nos tarifs de
                                    livraison</MenuLink>
                            </MenuItem>
                        </Menu>
                    </Column>
                    <Column lg={3}>
                        <Title>Infos légales</Title>
                        <Menu className="mb-0">
                            <MenuItem>
                                <MenuLink to={`${this.context.prefix}/commande-rapide`}>Contact</MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuLink to={`${this.context.prefix}/commande-rapide`}>CGV & Mentions
                                    légales</MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuLink to={`${this.context.prefix}/commande-rapide`}>Données personnelles &
                                    Cookies</MenuLink>
                            </MenuItem>
                        </Menu>
                    </Column>
                    <Column lg={3}>
                        <Title>Paiement</Title>
                    </Column>
                    <Column lg={3}>
                        <AppTitle>
                            Sedis proche
                            de vous :
                        </AppTitle>
                        <Apps>
                            <App>
                                <Link to={{pathname: "https://apps.apple.com/us/app/sedis/id1535809005"}}
                                      target="_blank">
                                    <Img src="/img/appstore.webp"/>
                                </Link>
                            </App>
                            <App>
                                <Link
                                    to={{pathname: "https://play.google.com/store/apps/details?id=com.nextaura.sedisdestock&gl=FR"}}
                                    target="_blank">
                                    <Img src="/img/google-play.webp"/>
                                </Link>
                            </App>
                        </Apps>
                    </Column>
                </Row>
                <Version>Version {process.env.REACT_APP_VERSION}</Version>
            </Div>
        )
    }

}

BottomFooter.contextType = UrlPrefixContext

export default BottomFooter