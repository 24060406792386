import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// App
import { envVars } from '../../../env_var';
import { formatCurrency, formatDate, getDefaultImage } from '../../../services/helpers/FrontHelper';
import { Lightbox } from '../../Mixed/Composants';

const Image = styled.img`
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    max-width: 89px;
    max-height: 95px;
    @media screen and (max-width:2000px){
        max-width: 68px;
        max-height: 75px;
    }
`;

const Text = styled.span`
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: #404040;
    @media screen and (max-width:2000px){
        font-size: 16px;
    }
`;

const Total = styled.span`
    flex: 1;
    height: 50px;
    border-radius: 6px;
    border: solid 2px #404040;
    background-color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: #404040;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin: 0 auto;
    @media screen and (max-width:2000px){
        font-size: 16px;
        height: 43px;
    }
`;

const Tr = styled.tr`
    position: relative;
`;

const Td = styled.td`
    position: relative;
    text-align: center;
    background: ${ props => props.disabled ? '#f2d2c1' : '#dedddd' };
    border-bottom: 3px solid #eeede4;
    padding: 24px 15px;
    &:nth-child(2){
        text-align:left;
        padding-left: 0;
    }
    &:not(:first-child):before{
        content: "";
        display: block;
        width: 1px;
        height: 72%;
        position: absolute;
        left: -1px;
        z-index: 99;
        background: #fff;
        top: 14%;
    }
    &:nth-child(2):before{
        display: none;
    }
    @media screen and (max-width:2000px){
        padding: 15px;
        &:not(:first-child):before{
            height: 68%;
            top: 16%;
        }
    }
`;

const Thumbnail = styled.figure`
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    margin: 0;
    &.has-no-image{
    background: #ffffff;
    padding: 5px;
    }
`;

class OrderDetailItem extends React.Component{

    render(){

        const { product, rayons } = this.props;
        const api_path = envVars.url_shop_api.replace('/api/', '');
        const img_path = product.PathPhoto ? api_path+product.PathPhoto : getDefaultImage(rayons, product);

        return (
            <Tr data-product-id={product.CodeArticle} data-variant-id={product.CodeVarianteArticle}>
                <Td>
                    <Lightbox>
                        <Thumbnail className={product.PathPhoto?'has-image':'has-no-image'}>
                            <Image src={img_path} alt={product.Libelle} />
                        </Thumbnail>
                    </Lightbox>
                </Td>
                <Td>
                    <Text>{ product.Libelle }</Text>
                </Td>
                <Td><Text>{ product.PCB }</Text></Td>
                <Td><Text>{ product.PoidsColis }</Text></Td>
                <Td><Text>{ product.UniteDeVente }</Text></Td>
                <Td><Text>{ product.PU } €</Text></Td>
                <Td><Text>{ formatDate(product.DateArrivagePrev) }</Text></Td>
                <Td><Text>{ formatDate(product.DateDLCDLUO) }</Text></Td>
                <Td><Text>{ product.Quantite }</Text></Td>
                <Td>
                    <Total className="front__order-item-total">{ formatCurrency(product.priceTotal) }</Total>
                </Td>
            </Tr>
        )
    }

}

OrderDetailItem.propTypes = {
    product : PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        rayons : state.rayons.rayons
    }
}

export default connect(mapStateToProps)(OrderDetailItem);