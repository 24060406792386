import React, {useEffect, useState} from "react"
import styled from "styled-components"
import useCustomerStore from "../../../reducers/CustomerStore";

const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 119px;
  height: 120px;
  border-radius: 6px;
  background-color: #ff0000;
  @media screen and (max-width: 2000px) {
    width: 102px;
    height: 104px;
  }
`

const Label = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
  text-align: left;
  color: #ffffff;
  margin: 0 0 .4rem;
  display: inline-block;
  @media screen and (max-width: 2000px) {
    font-size: 15px;
    margin: 0 0 .3rem;
  }
`

const Text = styled.span`
  position: relative;
  display: block;
  font-size: 28px;
  line-height: 1;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  font-family: 'Raleway', sans-serif;

  &::before {
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 10px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    bottom: 0;
    right: 10px;
  }

  @media screen and (max-width: 2000px) {
    font-size: 22px;
    &::before {
      border-top: 8px solid #fff;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      right: 8px;
    }
  }
`

const Inner = styled.div`
  display: inline-block;
  padding: 0 25px;
`

export default function Encours() {
    const [value, setValue] = useState(0)
    const [show, setShow] = useState(false)
    const customerStore = useCustomerStore()

    useEffect(() => {
        const customer = customerStore.customer

        if (!customer) {
            return
        }

        const encours_autorise = customer.MontantEncoursAutoriseFixe
        const encours_utilise = customer.MontantEncoursUtilise

        if (encours_utilise >= 0) {
            const utilise = ((encours_utilise / encours_autorise) * 100).toFixed(2)
            const restant = (100 - utilise)
            let value
            let show_encours
            switch (true) {
                case restant > 10 && restant <= 25:
                    value = 25
                    show_encours = true
                    break
                case restant > 5 && restant <= 10 :
                    value = 10
                    show_encours = true
                    break
                case restant > 0 && restant <= 5 :
                    value = 5
                    show_encours = true
                    break
                case restant <= 0 :
                    value = 0
                    show_encours = true
                    break
                default:
                    value = 5
                    show_encours = false
                    break
            }

            setValue(value)
            setShow(show_encours)
        } else {
            setValue(0)
            setShow(true)
        }

    }, [customerStore.customer])

    return (<>
        {show &&
            <Button className="front__encours-client">
                <Inner>
                    <Label>
                        Encours restant :
                    </Label>
                    <Text>{`${value}%`}</Text>
                </Inner>
            </Button>
        }
    </>)
}
