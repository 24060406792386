function getDateFullTime(day, separator, lang = null) {
    var date = day.getDate() < 10 ? '0' + day.getDate() : day.getDate();
    var mounth = day.getMonth() + 1;
    var month = mounth < 10 ? '0' + mounth : mounth;
  
    return lang === 'en' 
    ? day.getFullYear() + separator + month + separator + date
    : 
    date + separator + month + separator + day.getFullYear()
  }

export default getDateFullTime