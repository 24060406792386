import connectApi from "./connectApi"
import {getTVA} from "./FrontHelper"
import axios from "axios"
import {envVars} from "../../env_var"
import useCustomerStore from "../../reducers/CustomerStore";

function updateUserInfoStore() {
    const customerState = useCustomerStore.getState()
    const customer = customerState.customer
    if (customer && customer.CodeClient) {
        getCustomerInfo(customer.CodeClient).then(response => {
            if (response) {
                customerState.setCustomer(response)
            }
        })
    }
}

// check geslot api availability
export function checkApiAvailability(body) {
    const url = "check-geslot"
    return connectApi(url, "POST", body, 11000)
        .then(availability => {
            if (availability && availability.code === "ECONNABORTED") {
                return false
            }

            return !(availability && availability.code === 400);
        })
}

// createOrderLine
export function createOrderLine(cartItem, customer, order = null) {
    const url = "ordersproducts"
    const tva = getTVA(cartItem.CodeTVA)
    const montantHT = parseFloat(cartItem.priceTotal)
    const montantTVA = parseFloat((montantHT * tva) / 100).toFixed(2)
    const montantTTC = parseFloat(montantHT) + parseFloat(montantTVA)
    let body = {
        "OrderId": "",
        "OrderLineId": "",
        "CodeVarianteArticle": cartItem.CodeVarianteArticle,
        "CodeClient": customer.user.CodeClient,
        "CodeBP": "",
        "MontantHT": montantHT,
        "MontantTTC": parseFloat(montantTTC),
        "StatutReglementFacture": "total",
        "CodeArticle": cartItem.CodeArticle,
        "Quantite": cartItem.Quantite,
        "UniteVente": cartItem.UniteDeVente,
        "PrixVenteUnitaireHT": cartItem.PU,
        "PrixVenteTotalHT": montantHT,
        "MontantTVA": parseFloat(montantTVA),
        "ObjectLastUpdate": null
    }

    if (order && order.CodeBP) {
        body.CodeBP = order.CodeBP
    }

    if (order && order.OrderId) {
        body.OrderId = order.OrderId
    }

    return connectApi(url, "POST", body)
        .then(response => response)
        .finally(() => {
            updateUserInfoStore()
        })
}

// updateOrderLine
export function updateOrderLine(orderId, order) {
    const url = `ordersproducts/${orderId}`
    const tva = getTVA(order.CodeTVA)
    const montantHT = parseFloat(order.priceTotal)
    const montantTVA = parseFloat((montantHT * tva) / 100).toFixed(2)
    const montantTTC = parseFloat(montantHT) + parseFloat(montantTVA)
    const body = {
        "id": order.orderLine.id,
        "OrderId": order.orderLine.OrderId,
        "OrderLineId": order.orderLine.OrderLineId,
        "CodeBP": order.orderLine.CodeBP,
        "CodeArticle": order.orderLine.CodeArticle,
        "CodeVarianteArticle": order.orderLine.CodeVarianteArticle,
        "CodeClient": order.orderLine.CodeClient,
        "MontantHT": parseFloat(montantHT),
        "MontantTTC": parseFloat(montantTTC),
        "MontantTVA": parseFloat(montantTVA),
        "ObjectLastUpdate": null,
        "PrixVenteTotalHT": montantHT,
        "PrixVenteUnitaireHT": order.orderLine.PrixVenteUnitaireHT,
        "Quantite": parseInt(order.Quantite),
        "StatutReglementFacture": order.orderLine.StatutReglementFacture,
        "UniteVente": order.orderLine.UniteVente,
        "createdAt": order.orderLine.createdAt,
        "updatedAt": order.orderLine.updatedAt
    }

    return connectApi(url, "PUT", body)
        .then(response => response)
        .finally(() => {
            updateUserInfoStore()
        })
}

// deleteOrderLine
export function deleteOrderLine(orderId) {
    const url = `ordersproducts/${orderId}`
    return connectApi(url, "DELETE")
        .then(response => response)
        .finally(() => {
            updateUserInfoStore()
        })
}

// get order
export function getOrder(orderId) {
    const url = `orders/${orderId}`
    return connectApi(url, "GET")
        .then(response => response)
        .finally(() => {
            updateUserInfoStore()
        })

}

// validate cart
export async function validateOrder(orderId, codeBP, montantHT, montantTTC) {
    const url = `orders/${orderId}/validate`
    const body = {
        "id": orderId,
        "MontantFactureHT": montantHT,
        "MontantFactureTTC": montantTTC,
        "CodeBP": codeBP,
        "Statut": "Proposition temporaire",
        "CodeBL": null,
        "CodeFacture": null,
        "ObjectLastUpdate": null,
        "createdAt": null,
        "updatedAt": null,
        "orderLines": null
    }
    return connectApi(url, "PUT", body)
        .then(response => response)
}

// delete order
export async function deleteOrder(OrderId) {
    const url = `orders/${OrderId}`
    return connectApi(url, "DELETE")
        .then(response => response)
}

// get customer info
export function getCustomerInfo(customerId, verbose = false) {
    return getCustomerEncours(customerId, verbose)
        .then(response => {
            if (response && !response.hasOwnProperty("CodeClient")) {
                return getMagasinsCustomerEncours(customerId)
                    .then(data => {
                        if (data && !data.hasOwnProperty("CodeClient")) {
                            return false
                        }
                        return data
                    })
            }
            return response
        })
        .catch(e => console.error(e))
}

// get customer info
export function getCustomerEncours(customerId, verbose = false) {
    let url = `customers/${customerId}`
    url = (verbose) ? `${url}?verbose=true` : url
    return connectApi(url, "GET").then(response => response)
}

// toggle customer notification
export function setCustomerNotification(customerId, notificationAccepted) {
    const url = `customers/update-notification-state/${customerId}?state=${notificationAccepted}`
    return connectApi(url, "GET", null).then(response => response)
}

// get customer token
export function getCustomerToken(customerId) {
    const url = `customers/token/${customerId}`
    return connectApi(url, "GET")
        .then(response => response)
}

// get customer info
export function getMagasinsCustomerEncours(customerId) {
    const url = `magasinscustomer/${customerId}`
    return connectApi(url, "GET")
        .then(response => response)
}

// get customer invoices
export function getCustomerInvoices(CodeClient, paged = 1) {
    const url = `invoices/customer/${CodeClient}/?page=${paged}`
    return connectApi(url, "GET")
        .then(response => response)
}

// get customer orders
export function getCustomerOrders(CodeClient, paged = 1) {
    const url = `orders/customer/${CodeClient}/?page=${paged}`
    return connectApi(url, "GET")
        .then(response => response)
}

// get product
export function getProduct(CodeArticle) {
    const url = `products/${CodeArticle}`
    return connectApi(url, "GET")
        .then(response => response)
}

// get product stock
export function getProductStock(codeVarianteArticle) {
    const url = `stocks/${codeVarianteArticle}`
    return connectApi(url, "GET")
        .then(response => response)
}

// dowload customer invoice
export function downloadCustomerInvoice(CodeFacture) {
    const url = `invoices/download/${CodeFacture}`
    return connectApi(url, "BLOB")
        .then(response => response)

}

// dowload customer order
export function downloadCustomerOrder(CodeFacture) {
    const url = `orders/download/${CodeFacture}`
    return connectApi(url, "BLOB")
        .then(response => response)

}

// dowload base64 order
export function downloadBase64Order(id) {
    const url = `orders/download-base64/${id}`
    return connectApi(url, "BLOB")
        .then(response => response)

}

// upload base64 order
export async function uploadBase64Order(file) {
    let url = envVars["url_shop_api"] + `parse-base64-order`
    const fd = new FormData()
    fd.append("file", file)
    let currentState = JSON.parse(localStorage.getItem("state"))
    let headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + currentState.token
    }
    let config = {
        headers: headers
    }
    const res = await axios.post(url, fd, config)
    return res.data
}

// search console
export function searchProductConsole(libelle, grandFamilleID = null) {
    let url = `suggest-products?libelle=${libelle}`
    if (grandFamilleID) {
        url += `&GrandeFamilleID=${grandFamilleID}`
    }
    return connectApi(url, "GET")
        .then(response => response)
}

// get rayons
export function getRayons() {
    let url = "salable-products?isSalable=true&GrandeFamilleID=ALL"

    return connectApi(url, "GET", null)
        .then(rayons => {
            if (rayons && rayons["@type"] === "hydra:Error") {
                return []
            }

            return rayons
        })
}

export function getProducts(familyId, paged = null) {
    let url = `salable-products?isSalable=true&familyId=${familyId}`
    let urlFirst = url

    const storageFilters = localStorage.getItem("persist:filter")
    const storageTags = localStorage.getItem("persist:tag")

    // Apply filters
    if (storageFilters != null) {
        const jsonFilters = JSON.parse(storageFilters)
        if (jsonFilters && jsonFilters.filter) {
            const filters = JSON.parse(jsonFilters.filter)
            // console.log(filters)
            if (filters && filters.length) {
                let filter_id = 500001
                if (familyId === 44) {
                    url += "&GrandeFamilleID1=" + 500006
                } else {
                    filters.forEach((filter, index) => {
                        switch (filter.toLowerCase()) {
                            case "petsfood":
                                filter_id = 500007
                                break
                            case "sec":
                                filter_id = 500002
                                break
                            case "dph":
                                filter_id = 500003
                                break
                            default :
                                filter_id = 500001
                        }
                        url += "&GrandeFamilleID" + (index + 1) + "=" + filter_id
                    })
                    if (url.includes(500007) && url.includes(500002) && url.includes(500003) && url.includes(500001) && url.includes(familyId)) {
                        url = urlFirst
                    }
                }
            }
        }
    }

    // Apply tags
    if (storageTags != null) {
        const jsonTags = JSON.parse(storageTags)
        if (jsonTags && jsonTags.tag) {
            const tags = JSON.parse(jsonTags.tag)
            let ext = ""
            tags.forEach((tag, index) => {
                const tagIndex = index + 1
                const tagName = tag === "Promotion" ? "Promo" : tag === "Derniere" ? "Derni" : tag
                ext += "&tag" + tagIndex + "=" + tagName
            })
            url += ext
        }
    }

    if (paged) {
        url += "&page=" + paged
    }

    return connectApi(url, "GET", null)
        .then(products => products)
}

export function getPromos(paged) {
    let url = `salable-products?isSalable=true&tag1=Promo`

    if (paged) {
        url += "&page=" + paged
    }

    // console.log('url promos', url);

    return connectApi(url, "GET", null)
        .then(products => products)
}

export function getDerniereMinute(paged) {
    let url = `salable-products?isSalable=true&tag1=Derni`

    if (paged) {
        url += "&page=" + paged
    }

    return connectApi(url, "GET", null)
        .then(products => products)
}

export function getDluoDepassee(paged) {
    let url = `salable-products?isSalable=true&tag1=Dluo`

    if (paged) {
        url += "&page=" + paged
    }

    return connectApi(url, "GET", null)
        .then(products => products)
}

export function getHalal(paged) {
    let url = `salable-products?isSalable=true&tag1=Halal`

    if (paged) {
        url += "&page=" + paged
    }

    return connectApi(url, "GET", null)
        .then(products => products)
}

export function getBio(paged) {
    let url = `salable-products?isSalable=true&tag1=Bio`

    if (paged) {
        url += "&page=" + paged
    }

    return connectApi(url, "GET", null)
        .then(products => products)
}

export function getSelectionDuMoment(paged) {
    let url = `salable-products?isSalable=true&SelectionDuMoment=true`

    if (paged) {
        url += "&page=" + paged
    }

    // console.log('url selection du moment', url);

    return connectApi(url, "GET", null)
        .then(products => products)
}


export function getTitreSelection() {
    let url = "text-selection-du-moment"
    return connectApi(url, "GET", null)
        .then(response => response)
}

export function getSuivi(CodeClient) {
    let url = "orders-notinvoiced/customer/" + CodeClient
    return connectApi(url, "GET", null)
        .then(response => response)
}


export function isJWTExpired(token) {
    try {
        const jwt = JSON.parse(atob(token.split(".")[1]))
        return (new Date()).getTime() > jwt.exp * 1000
    } catch (e) {
        return false
    }
}
