import React from "react"
import HtmlStructure from "./NotificationsHtmlStructure"
import "./notifications.css"
import {DOCUMENT_TITLE_BACKOFFICE} from "../../../../constants"
import NotificationsGroupComponents from "./NotificationsGroupComponents"

// Default function : call
export default function NotificationsGroup() {
    document.title = "Gestion des groupes de notification - " + DOCUMENT_TITLE_BACKOFFICE
    return <HtmlStructure titleText="Gérer les groupes" breadcrumActiveText="Gérer les groupes"
                          menuName="NotificationGroupsForm">
        <section className="notifications-groups-form">

            <article className="groups-managment">
                <NotificationsGroupComponents.GroupList/>
                <NotificationsGroupComponents.CustomerList/>
            </article>

            <aside className="groups-customers-selector">
                <NotificationsGroupComponents.CustomerSelector/>
                <aside className="groups-helpers">
                    <div className="group-helper"><i className="fas fa-reply"></i> 1. Sélectionne un groupe ou
                        créé en un nouveau
                    </div>
                    <div className="customer-helper"><i className="fas fa-hand-point-up"></i> 2. Ajoute un/des
                        client(s) au groupe sélectionné
                    </div>
                </aside>
            </aside>
        </section>
    </HtmlStructure>
}
