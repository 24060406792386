import {create} from 'zustand'
import dayjs from "dayjs";
import {DEFAULT_DIFF_DAYS_SALES} from "../constants";

const useSalesFilterStore = create((set) => ({
    filters: {
        currentStore: "",
        beginDate: (new dayjs()).subtract(DEFAULT_DIFF_DAYS_SALES, "day"),
        endDate: new dayjs(),
        shippingDate: undefined
    },
    setFilters: (filters) => set({filters: filters})
}))

export default useSalesFilterStore
