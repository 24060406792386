import React, {Component} from "react";
import Header from "../../../Mixed/Header/Header";
import Menu from "../../../Mixed/Menu/Menu";
import styled from "styled-components"
import connectApi from "../../../../services/helpers/connectApi";
import {checkEmail} from "./userHelper";
import {toast} from "react-toastify";

const Alert = styled.span`
  color: red;
  display: block;
  font-style: italic;
  font-size: .8rem;
`

class ProfileUser extends Component {
    nameMenu = "userProfile"

    state = {
        user: null,
        onSave: false
    }

    roleOptions = [{
        label: "Roles", type: "role", icon: "fa-user", options: []
    }]

    async componentDidMount() {
        this.displayFieldsError()
        let user = null
        const state = window.localStorage.getItem("state")

        if (state) {
            user = JSON.parse(state).user
        } else {
            user = await connectApi('me', 'GET', null);
        }

        this.setState({
            user: user
        })
    }

    checkUserForm() {
        let formatFieldsAreOK = true
        const state = this.state.user

        if (state.email.length > 0) {
            if (checkEmail(state.email)) {
                document.getElementById('errorFormatEmail').style.display = 'block';
                formatFieldsAreOK = false
            }
            if (state.email.trim() === "") {
                document.getElementById("errorEmail").style.display = "block"
                formatFieldsAreOK = false
            }
        } else {
            document.getElementById("errorEmail").style.display = "block"
            formatFieldsAreOK = false
        }

        if (state.password && state.password.length > 0) {
            if (state.password.trim().length < 6) {
                document.getElementById("errorFormatPassword").style.display = "block"
                formatFieldsAreOK = false
            }
        }
        if (state.firstname.length > 0) {
            if (state.firstname.trim() === "") {
                document.getElementById("errorfirstName").style.display = "block"
                formatFieldsAreOK = false
            }
        } else {
            document.getElementById("errorfirstName").style.display = "block"
            formatFieldsAreOK = false
        }
        if (state.lastname.length > 0) {
            if (state.lastname.trim() === "") {
                document.getElementById("errorlastName").style.display = "block"
                formatFieldsAreOK = false
            }
        } else {
            document.getElementById("errorlastName").style.display = "block"
            formatFieldsAreOK = false
        }
        if (state.role === null) {
            document.getElementById("errorRole").style.display = "block"
            formatFieldsAreOK = false
        }
        return formatFieldsAreOK
    }

    displayFieldsError = () => {
        document.getElementById("errorEmail").style.display = "none"
        document.getElementById('errorFormatEmail').style.display = 'none';
        document.getElementById("errorPassword").style.display = "none"
        document.getElementById("errorFormatPassword").style.display = "none"
        document.getElementById("errorfirstName").style.display = "none"
        document.getElementById("errorlastName").style.display = "none"
    }

    _handleTextChange(e) {
        this.setState({
            user: {
                ...this.state.user,
                [e.target.name]: e.target.value
            }
        })
    }

    enregistrer = async (event) => {
        event.preventDefault()
        this.displayFieldsError()
        let formatFieldsAreOK = this.checkUserForm(this.state.user)
        if (formatFieldsAreOK) {
            this.setState({
                onSave: true
            })
            const user = this.state.user
            delete user["id"]

            connectApi('update-bo-profil', 'POST', user).then((result) => {
                this.setState({
                    onSave: false
                })

                if (result.error) {
                    toast(result.message, {
                        type: "error", autoDismiss: true
                    })

                    return
                }

                const state = JSON.parse(window.localStorage.getItem("state"))
                state.user.email = result.user.email
                state.user.firstname = result.user.firstName
                state.user.lastname = result.user.lastName

                window.localStorage.setItem("state", JSON.stringify(state))
                toast("Modification enregistré avec succès", {
                    type: "success", autoDismiss: true
                })
            })
        }
    }


    render() {
        return (
            <div className="wrapper">
                <Header/>
                <Menu nameMenu={this.nameMenu}/>

                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h5 className="m-0 text-dark">Modification mon profil</h5>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item">Administration</li>
                                            <li className="breadcrumb-item active">Mon compte</li>
                                            <li className="breadcrumb-item active">Profile</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="form col-lg-3 col-3">
                                        Nom
                                    </div>
                                    <div className="col-lg-9 col-9">
                                        <input
                                            id={"lastName"}
                                            name={"lastname"}
                                            type={"text"}
                                            size={50}
                                            onChange={(e) => this._handleTextChange(e)}
                                            value={this.state.user ? this.state.user.lastname : ""}
                                        >
                                        </input>
                                        <br/>
                                        <Alert id={"errorlastName"}>(*) champ obligatoire</Alert>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form col-lg-3 col-3">
                                        Prénom
                                    </div>
                                    <div className="col-lg-9 col-9">
                                        <input
                                            id={"firstName"}
                                            name={"firstname"}
                                            type={"text"}
                                            size={50}
                                            onChange={(e) => this._handleTextChange(e)}
                                            value={this.state.user ? this.state.user.firstname : ""}
                                        >
                                        </input>
                                        <br/>
                                        <Alert id={"errorfirstName"}>(*) champ obligatoire</Alert>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form col-lg-3 col-3">
                                        Email
                                    </div>
                                    <div className="col-lg-9 col-9">
                                        <input
                                            id={"email"}
                                            name={"email"}
                                            type={"text"}
                                            size={50}
                                            onChange={(e) => this._handleTextChange(e)}
                                            value={this.state.user ? this.state.user.email : ""}
                                        >
                                        </input>
                                        <br/>
                                        <Alert id={"errorEmail"}>(*) champ obligatoire</Alert>
                                        <Alert id={"errorFormatEmail"}>Adresse email invalide</Alert>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form col-lg-3 col-3">
                                        Mot de passe
                                    </div>
                                    <div className="col-lg-9 col-9">
                                        <input
                                            id={"password"}
                                            name={"password"}
                                            type={"password"}
                                            placeholder={"*************"}
                                            size={50}
                                            onChange={(e) => this._handleTextChange(e)}
                                        >
                                        </input>
                                        <br/>
                                        <Alert id={"errorPassword"}>(*) champ obligatoire</Alert>
                                        <Alert id={"errorFormatPassword"}>Le mot de passe doit faire au moins 6
                                            caractères</Alert>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button onClick={(event) => {
                                    this.enregistrer(event).then(r => {
                                    })
                                }} className="btn btn-info btn-login" disabled={this.state.onSave}>
                                    Enregistrer
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfileUser
