import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"

// App
import MenuRayons from "./MenuRayons"
import {UrlPrefixContext} from "../../../context"
import {Div, List, ListItem, Nav} from "../../Mixed/Composants"

const NavMenu = styled(Nav)`
  position: static;
  padding: 0;
  margin: 13px 0 0;
  @media screen and (max-width: 2000px) {
    margin: 12px 0 0;
  }
`

const Menu = styled(List)`
  width: 100%;
  justify-content: space-between;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`

const MenuItem = styled(ListItem)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover > .main-sub-menu {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

const MenuLink = styled.a`
  font-family: 'Raleway', sans-serif;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: 0.21px;
  color: #404040;
  padding: 10px 0 !important;

  &:hover {
    color: #66b821
  }

  &.disabled {
    pointer-events: none;
    color: #847f7f;
  }

  @media screen and (max-width: 2000px) {
    font-size: 17px;
    padding: 10px 0 !important;
  }
  @media screen and (max-width: 1500px) {
    font-size: 16px;
  }
`

const RayonsLink = styled.a`
  position: relative;
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 30px;
  font-weight: normal;
  letter-spacing: normal;
  color: #fff;
  background: #66b821;
  padding-left: 15px !important;
  padding-right: 36px !important;

  &:hover,
  &:active,
  &:focus {
    color: #fff
  }

  &:before {
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 14px solid #fff;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 14px;
  }

  @media screen and (max-width: 2000px) {
    font-size: 24px;
    padding-right: 34px !important;
    &:before {
      border-top: 8px solid #fff;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      margin-top: -5px;
    }
  }
  @media screen and (max-width: 1500px) {
    font-size: 22px;
  }
`

const MegaMenu = styled.div`
  position: absolute;
  top: 100%;
  width: 150%;
  left: 0;
  z-index: -1;
  padding: 0 7.5px;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease-in;
  @media (max-width: 820px) {
    width: 100%;
  }
`

const InnerMegaMenu = styled.div`
  position: relative;
  background: #66b821;
  margin: 17px 0 0;
  padding: 20px;

  &:before {
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #66b821;
    position: absolute;
    bottom: 100%;
    left: 60px;
    @media (max-width: 820px) {
      left: 45%;
    }
  }
`

const MenuTitle = styled.span`
  display: block;
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 30px;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 0 .5em;
`

class Navbar extends React.Component {

    render() {
        const {rayons} = this.props
        return (
            <NavMenu className="navbar navbar-expand-lg front__main-menu">
                <Div className="navbar-collapse">
                    <Menu className="navbar-nav">
                        <MenuItem className="nav-item">
                            <RayonsLink href="#" className="nav-link" onClick={(e) => e.preventDefault()}>NOS
                                RAYONS</RayonsLink>
                            <MegaMenu className="main-sub-menu">
                                <InnerMegaMenu>
                                    <MenuTitle>TOUS NOS PRODUITS :</MenuTitle>
                                    <MenuRayons rayons={rayons}/>
                                </InnerMegaMenu>
                            </MegaMenu>
                        </MenuItem>
                        {/*<MenuItem className="nav-item">
                            <MenuLink href={`${this.context.prefix}/promotions`}
                                      className="nav-link">PROMOTIONS</MenuLink>
                        </MenuItem>*/}
                        <MenuItem className="nav-item">
                            <MenuLink href={`${this.context.prefix}/halal`} className="nav-link">HALAL</MenuLink>
                        </MenuItem>
                        <MenuItem className="nav-item">
                            <MenuLink href={`${this.context.prefix}/dluo-depassee`} className="nav-link">DLUO
                                DEPASSEE</MenuLink>
                        </MenuItem>
                        <MenuItem className="nav-item">
                            <MenuLink href={`${this.context.prefix}/derniere-minute`} className="nav-link">DERNIERE
                                MINUTE</MenuLink>
                        </MenuItem>
                    </Menu>
                </Div>
            </NavMenu>
        )
    }

}

Navbar.contextType = UrlPrefixContext

const mapStateToProps = (state) => {
    return {
        rayons: state.rayons.rayons
    }
}

export default connect(mapStateToProps)(Navbar)
