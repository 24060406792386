const initialRayons = [];

export default function CustomReducer(state = initialRayons, action){
    let nextState;
    switch(action.type){
        case "SET_RAYONS" :
            nextState = {
                ...state,
                rayons : action.value
            }
            return nextState;
        // break;
        
        case "RESET_RAYONS":
            nextState = {
                ...state,
                rayons : initialRayons
            }
            return nextState;
        // break;

        default :
            return nextState || state
    }
}