import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"
import {toast} from "react-toastify"

// App
import {Column, Container, Div, Row} from "../../Mixed/Composants"
import {UrlPrefixContext} from "../../../context"
import {getRayons} from "../../../services/helpers/FrontApiHelper"
import {formatCurrency} from "../../../services/helpers/FrontHelper"
import {CommandeRapideGroup, Forms} from "../Composants"
import {Content, Footer, Header} from "../Composants/Template"

const {Button} = Forms

const Total = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
`

const AddToCart = styled(Button)`
  width: 100%;
`

const TotalText = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.17;
  color: #404040;
  @media screen and (max-width: 2000px) {
    font-size: 24px;
  }
`

const TotalField = styled.div`
  flex: 1;
  height: 50px;
  margin: 0 0 0 31px;
  opacity: 0.8;
  border-radius: 6px;
  border: solid 2px #404040;
  background-color: #ffffff;
  font-family: 'Raleway', sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 2000px) {
    font-size: 24px;
  }
`

const Table = styled.table`
  margin: 0;
  width: 100%;
`

const Tr = styled.tr`
  position: relative;
`

const Td = styled.td`
  position: relative;
  text-align: center;
  background: #dedddd;
  border-bottom: 3px solid #eeede4;
  padding: 24px 15px;

  &:first-child {
    text-align: left;
  }

  &:not(:first-child):before {
    content: "";
    display: block;
    width: 1px;
    height: 72%;
    position: absolute;
    left: -1px;
    z-index: 99;
    background: #fff;
    top: 14%;
  }

  @media screen and (max-width: 2000px) {
    padding: 22px 15px;
    &:not(:first-child):before {
      height: 68%;
      top: 16%;
    }
  }
`

const Th = styled.th`
  position: relative;
`

const Tf = styled(Td)`
  background: none;

  &:before {
    display: none !important
  }
`

const TTotal = styled(Tf)`
  padding-top: 34px;
`

const TButton = styled(Tf)`
  padding: 10px 15px 0;
`

const Thead = styled.thead`
  border: 0;
`

const TFoot = styled.tfoot`
  padding: 0;
`

const Recap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0d9ca;
  z-index: 9999;
  padding: 20px 0;
  box-shadow: 0 0 10px rgb(107 107 107 / 55%);
`

const TotalFixed = styled(TotalField)`
  width: auto;
  flex: 0;
  padding: 0 4rem;
`

const AddToCartFixed = styled(AddToCart)`
  width: auto;
  margin: 0 auto 0 2rem;
  border-radius: 50px;
`

const NoProduct = styled.p`
  text-align: center;
`
const RayonLinkWrap = styled.div`
  z-index: 1000;
  position: relative;

  &.sticky {
    padding-top: 34px;

    .rayons-link {
      position: fixed;
      top: 0;
      width: 100%;
      background: #66b821;

      a {
        color: #ffffff;

        &:hover {
          color: rgba(255, 255, 255, 0.5)
        }
      }
    }
  }
`;
const RayonLink = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background: #ffffff;
  width: 100%;
  transition: all 0.3s ease;
`;
const RayonLinkItem = styled.a`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  padding: 10px;
  transition: all 0.3s ease;

  &:hover {
    color: #66b821;
  }
`;

const TableResponsive = styled.div`
  @media only screen and (min-width: 1000px) {
    overflow: visible;
  }
`

const HeadImage = styled(Th)`
  width: 7%;
`

const HeadTitle = styled(Th)`
  width: 33%;
`

const HeadPm = styled(Th)`
  width: 5%;
`

const HeadMm = styled(Th)`
  width: 10%;
`

const HeadPrice = styled(Th)`
  width: 10%;
`

const HeadQuantity = styled(Th)`
  width: 10%;
`

const Label = styled.span`
  font-family: 'Covered By Your Grace', sans-serif;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 13px;
  background: #fff;
  margin: 0 auto;
  z-index: 99;
  position: relative;
  border-radius: 50%;
  box-shadow: 1px 2px 5px #8d8d8d;
`

const TableHeaders = styled.div`
  opacity: 0;
  z-index: 800;
  width: 100%;
  transition: all 0.3s ease;
  position: absolute;
  transform: translateY(-100%);
  @media screen and (min-width: 1000px) {
    &.sticky {
      opacity: 1;
      position: fixed;
      top: 40px;
      background: rgba(255, 255, 255, 0.5);
      padding-top: 5px;
      padding-bottom: 5px;
      transform: translateY(0);
    }
  }
`

class CommandeRapidePage extends React.Component {

    _isMounted = false

    state = {
        prices: 0,
        rayons: [],
        lists: [],
        loader: false,
        showFooterBlock: false,
        showFooterRecapFixed: false,
        stickyMenu: false
    }

    _getNextRayon = () => {
        var _indexed = localStorage.getItem("index_rayon") ? localStorage.getItem("index_rayon") : 0
        _indexed = parseInt(_indexed) + 1
        localStorage.setItem("index_rayon", _indexed)
        const {rayons} = this.state
        const next = rayons[_indexed]
        if (next) {
            const data = [...this.state.lists, ...[next]]
            this.setState({lists: data})
        }
    }

    _getLocalProducts = () => {
        const storage = localStorage.getItem("frontOrderProducts")
        return storage != null ? JSON.parse(storage) : []
    }

    _addTocart = () => {
        const {history, dispatch} = this.props
        const products = this._getLocalProducts()
        let value = []
        if (products.length === 0) {
            toast("Aucun produit n'a été ajouté. Veuillez ajouter des produits avant de commander", {
                type: "error",
                autoDismiss: true
            })
            return false
        }
        products.forEach(item => {
            if (item.hasOwnProperty("orderLine")) {
                value.push(item)
            }
        })
        dispatch({type: "SET_ORDER", value: value})
        setTimeout(() => {
            history.push(`${this.context.prefix}/panier`)
        }, 200)
    }

    _getRayons = () => {

        const {filter} = this.props
        let filters = ["Frais", "Sec", "DPH", "PetsFood"]
        if (filter && filter.length) {
            filters = filter
        }
        getRayons(filters)
            .then(rayons => {
                this.setState({
                    rayons: rayons,
                    lists: rayons && rayons.length ? rayons : [],
                    showFooterBlock: !!(rayons && rayons.length),
                    showFooterRecapFixed: !!(rayons && rayons.length)
                })
                this.props.dispatch({type: "SET_RAYONS", value: rayons})
            })
            .finally(() => this.setState({loader: false}))

    }

    _renderNoContent = () => {
        const {rayons} = this.state
        if (rayons && rayons.length === 0) {
            return (
                <NoProduct>Aucun produit trouvé</NoProduct>
            )
        }
    }

    _renderRayonsLinks = () => {
        return (
            <RayonLinkWrap className={'rayons-link-wrap' + (this.state.stickyMenu ? ' sticky' : '')}>
                <RayonLink className="rayons-link">
                    {this.state.lists.map((rayon, index) =>
                        <RayonLinkItem key={rayon.Identifiant}
                                       href={"#rayon-" + rayon.Identifiant}>{rayon.Nomcategorie}</RayonLinkItem>
                    )}
                </RayonLink>
            </RayonLinkWrap>
        )
    }

    _renderHeaders = () => {
        return (
            <TableHeaders className={"front__fluid" + (this.state.stickyMenu ? ' sticky' : '')}>
                <TableResponsive className="table-responsive">
                    <Table>
                        <Thead>
                            <Tr>
                                <HeadImage>&nbsp;</HeadImage>
                                <HeadTitle>&nbsp;</HeadTitle>
                                <HeadPm><Label>Pièces/<br/>Colis</Label></HeadPm>
                                <HeadPm><Label>Poids<br/>(Kg)</Label></HeadPm>
                                <HeadPm><Label>U/ <br/>Kg</Label></HeadPm>
                                <HeadPm><Label>Prix unitaire</Label></HeadPm>
                                <HeadMm><Label>Date d’arrivage</Label></HeadMm>
                                <HeadMm><Label>DLC<br/>DLUO</Label></HeadMm>
                                <HeadPrice><Label>Prix Total</Label></HeadPrice>
                                <HeadQuantity><Label>Quantité</Label></HeadQuantity>
                            </Tr>
                        </Thead>
                    </Table>
                </TableResponsive>
            </TableHeaders>
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter) {
            this.setState({loader: true}, () => {
                this._getRayons()
            })
        }
    }

    componentDidMount() {
        localStorage.setItem("index_rayon", 0)
        this._isMounted = true
        const products = this._getLocalProducts()
        if (this._isMounted) {
            if (products.length) {
                let total = 0
                products.forEach(product => {
                    total += parseFloat(product.priceTotal)
                })
                this.setState({prices: total})
            }
            this.setState({loader: true}, () => {
                this._getRayons()
            })
            const _this = this;

            window.addEventListener('scroll', _this.handleScroll);
        }
    }

    componentWillUnmount() {
        this._isMounted = false
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const el_top = document.querySelector('.rayons-link-wrap');
        const el_bottom = document.querySelector('.front__main-footer');

        if (el_top && el_bottom) {
            const el_position_top = el_top.getBoundingClientRect().top + window.pageYOffset;
            const el_position_bottom = el_bottom.getBoundingClientRect().top + window.pageYOffset;

            this.setState({
                stickyMenu: el_position_top < window.pageYOffset && window.pageYOffset < el_position_bottom
            })
        }
    }

    render() {
        const {customer} = this.props
        const {loader, lists, rayons, prices, showFooterBlock, showFooterRecapFixed} = this.state
        return (
            <Div className="front__wrap-screen" data-version="1.1.17">
                <Header/>
                {rayons && this._renderRayonsLinks()}
                {rayons && this._renderHeaders()}
                <Content loading={loader}>
                    {this._renderNoContent()}
                    {
                        rayons &&
                        lists.map((rayon, index) => (
                            <CommandeRapideGroup
                                key={index}
                                customer={customer}
                                rayonId={rayon.Identifiant}
                                rayonName={rayon.Nomcategorie}
                                onContentLoaded={() => this.setState({showFooterBlock: true})}
                            />
                        ))
                    }
                    {
                        showFooterBlock &&
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th width="6%">&nbsp;</Th>
                                    <Th width="68%">&nbsp;</Th>
                                    <Th>&nbsp;</Th>
                                    <Th>&nbsp;</Th>
                                    <Th>&nbsp;</Th>
                                    <Th>&nbsp;</Th>
                                    <Th>&nbsp;</Th>
                                    <Th>&nbsp;</Th>
                                    <Th width="8%">&nbsp;</Th>
                                    <Th width="7%">&nbsp;</Th>
                                </Tr>
                            </Thead>
                            <TFoot>
                                <Tr>
                                    <TTotal colSpan={7}>&nbsp;</TTotal>
                                    <TTotal colSpan={3}>
                                        <Total>
                                            <TotalText>Total</TotalText>
                                            <TotalField
                                                className="front__order-total">{formatCurrency(prices)}</TotalField>
                                        </Total>
                                    </TTotal>
                                </Tr>
                                <Tr>
                                    <TButton colSpan={7}>&nbsp;</TButton>
                                    <TButton colSpan={3}>
                                        <AddToCart type="button" variant="secondary" contain="outlined"
                                                   onClick={() => this._addTocart()}>Ajouter au panier</AddToCart>
                                    </TButton>
                                </Tr>
                            </TFoot>
                        </Table>
                    }
                    <Recap className={showFooterRecapFixed ? "d-block" : "d-none"}>
                        <Container>
                            <Row className="justify-content-center align-items-center">
                                <Column lg={6} className="d-flex justify-content-end align-items-center">
                                    <TotalText>Total</TotalText>
                                    <TotalFixed className="front__order-total">{formatCurrency(prices)}</TotalFixed>
                                </Column>
                                <Column lg={6}>
                                    <AddToCartFixed type="button" variant="secondary" contain="contained"
                                                    onClick={() => this._addTocart()}>Ajouter au panier</AddToCartFixed>
                                </Column>
                            </Row>
                        </Container>
                    </Recap>
                </Content>
                <Footer/>
            </Div>
        )
    }

}

CommandeRapidePage.contextType = UrlPrefixContext

const mapStateToProps = (state) => {
    return {
        filter: state.filter.filter,
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(CommandeRapidePage)
