function listVars() {
    let tableau = [];
    let url_shop_api = '';
    let scheme = '';
    if (window.location.host === 'localhost:3000') {
        url_shop_api = process.env.REACT_APP_SHOP_API_DEV_URL ? process.env.REACT_APP_SHOP_API_DEV_URL : 'http://sedis.local/api/';
        scheme = 'http://'
    } else if (window.location.host === 'destock-dev37.nextaura.eu') {
         url_shop_api = 'https://destock-dev35.nextaura.eu/api/'
         scheme = 'https://'
    } else if (window.location.host === 'destock-dev31.nextaura.eu') {
        url_shop_api = 'https://destock-dev35.nextaura.eu/api/'
        scheme = 'https://'
    } else if (window.location.host === 'destock-stag34.nextaura.eu') {
         url_shop_api = 'https://destock-stag32.nextaura.eu/api/'
         scheme = 'https://'
    } else if (window.location.host === 'destock.sedisalimentaire.com') {
         url_shop_api = 'https://shop-api.sedisalimentaire.com/api/'
         scheme = 'https://'
    }
    tableau['url_shop_api'] = url_shop_api
    tableau['scheme'] = scheme

    return tableau
}

export const envVars = listVars()