import Header from "../../../Mixed/Header/Header";
import Menu from "../../../Mixed/Menu/Menu";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {BrowserRouter as Router} from 'react-router-dom'
import Footer from "../../../Mixed/Footer/Footer";
import SedisDateGrid from "../../../Mixed/Composants/SedisDateGrid";
import {Box, createTheme, ThemeProvider, Tooltip} from "@mui/material";
import connectApi from "../../../../services/helpers/connectApi";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/fr';
import useSalesFilterStore from "../../../../reducers/SalesFilterStore";
import useMeStore from "../../../../reducers/MeStore";
import _ from "lodash";
import {useGridApiRef} from "@mui/x-data-grid";
import {Check, Error} from "@mui/icons-material";
import {DEFAULT_DATAGRID_PAGE_SIZE} from "../../../../constants";
import dayjs from "dayjs";

const theme = createTheme({
    palette: {
        primary: {
            main: "#66b821",
            contrastText: "#fff"
        },
    },
    typography: {
        fontSize: 13,
        button: {
            textTransform: "normal"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                sizeMedium: {
                    height: 38
                }
            }
        }
    }
});

export default function SaleItem() {
    const apiRef = useGridApiRef();
    const sales = useRef([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: DEFAULT_DATAGRID_PAGE_SIZE
    })
    const columns = [
        {
            field: 'exist',
            headerName: 'AST',
            type: "singleSelect",
            headerAlign: "right",
            align: "right",
            width: 100,
            valueOptions: [
                {
                    value: true,
                    label: (<><Check sx={{mr: 1, position: "relative", top: -0.5}} fontSize="small"
                                     color={"success"}/> Référencé</>)
                },
                {
                    value: false,
                    label: (<><Error sx={{mr: 1, position: "relative", top: -0.5}} fontSize="small"
                                     color={"error"}/> Non référencé</>)
                },
            ],
            renderCell: (params) => {
                if (params.value === true) {
                    return <Tooltip title={"Article référencé dans la base Asten"}>
                        <Check sx={{mr: 1, position: "relative", top: -0.5}} fontSize="small" color={"success"}/>
                    </Tooltip>
                } else if (params.value === false) {
                    return <Tooltip title={"Article non référencé dans la base Asten"}>
                        <Error sx={{mr: 1, position: "relative", top: -0.5}} fontSize="small" color={"error"}/>
                    </Tooltip>
                } else {
                    return ""
                }
            },
        },
        {
            field: 'CodeArticle',
            headerName: 'Code Article',
            width: 100,
            type: "number",
            renderCell: (params) => {
                return <>
                    {/*{params.row.exist === false &&
                        <Tooltip title={"Article non référencé dans la base Asten"}>
                            <Error sx={{mr: 1, position: "relative", top: -0.5}} fontSize="small" color={"error"}/>
                        </Tooltip>
                    }*/}
                    {params.value}
                </>
            }
        },
        {field: 'LibelleArticle', headerName: 'Libellé', flex: 1},
        {
            field: 'DateDLC_DLUO',
            headerName: 'DLC/DLUO',
            width: 95,
            disableColumnMenu: true,
            getApplyQuickFilterFn: null,
            renderCell: (params) => {
                return <>
                    {dayjs(params.value).format("DD/MM/YYYY")}
                </>
            },
        },
        {field: 'PUNet', headerName: 'PU Net', type: "number", width: 80, getApplyQuickFilterFn: null},
        {field: 'NbPieces', headerName: 'Nb Pièces', type: "number", width: 80, getApplyQuickFilterFn: null},
        {field: 'NbColis', headerName: 'Nb Colis', type: "number", width: 80, getApplyQuickFilterFn: null},
        {field: 'Fournisseur', headerName: 'Fournisseur', width: 150, getApplyQuickFilterFn: null},
        {
            field: 'DateLivraison',
            headerName: 'Livraison',
            width: 125,
            disableColumnMenu: true,
            getApplyQuickFilterFn: null,
            renderCell: (params) => {
                return <>
                    {dayjs(params.value).format("DD/MM/YYYY")}
                </>
            },
        },
        {field: 'EAN', headerName: 'EAN', width: 125},
        {field: 'BL', headerName: 'BL', width: 135},
        {field: 'Famille', headerName: 'Famille', flex: 1, filterable: false, getApplyQuickFilterFn: null},
        {field: 'Gamme', headerName: 'Gamme', flex: 1, filterable: false, getApplyQuickFilterFn: null},
    ];

    const {filters, setFilters} = useSalesFilterStore()
    const firstLoad = useRef(true)
    const {info} = useMeStore()

    useEffect(() => {
        if (!window.localStorage.getItem("menuConfig")) {
            const body = document.getElementsByTagName('body')[0];
            body.className += " wrapper-sm sidebar-collapse"
        }

        if (filters.shippingDate) {
            setRows(sales.current.filter(r => r.DateLivraison === filters.shippingDate.format("YYYY/MM/DD")))
            setTimeout(() => {
                handleFilterModelChange()
            }, 250)
        } else {
            setRows(sales.current)
        }
        // eslint-disable-next-line
    }, [filters.shippingDate])

    const getSales = (params) => {
        setLoading(true)
        connectApi(`stores-sales?${params.join("&")}`, "GET", null)
            .then(response => {
                if (response.sales) {
                    sales.current = response.sales
                    setRows(response.sales)
                    firstLoad.current = false
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        const currentStore = info.userStores.length ? info.userStores.map(us => us.store)[0].code : ""

        setFilters({
            ...filters,
            currentStore: currentStore
        })
        if (currentStore) {
            getSales([])
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [])

    const getRows = useCallback(() => {
        if (filters.shippingDate) {
            return rows.filter(r => r.DateLivraison === filters.shippingDate.format("YYYY/MM/DD"))
        }

        return rows
    }, [rows, filters.shippingDate])

    const handleFilter = (code = null, begin = null, end = null) => {
        getSales([
            `store=${code ?? filters.currentStore}`,
            `beginDate=${begin ?? filters.beginDate.format("YYYYMMDD")}`,
            `endDate=${end ?? filters.endDate.format("YYYYMMDD")}`,
        ])
    }

    const handlePaginationModelChange = (newPaginationModel) => {
        setPaginationModel(newPaginationModel)
        const startIndex = newPaginationModel.page * newPaginationModel.pageSize;
        const endIndex = startIndex + newPaginationModel.pageSize;
        checkProduct(apiRef.current.getSortedRows().slice(startIndex, endIndex))
    };

    const handleFilterModelChange = () => {
        if (apiRef.current.state && apiRef.current.state.filter && apiRef.current.state.filter.filteredRowsLookup) {
            const dataGridRowIDs = new Set(
                Object.entries(apiRef.current.state.filter.filteredRowsLookup)
                    .filter(([_, value]) => value === true)
                    .map(([key, _]) => key)
            );
            const filteredDataRows = sales.current.filter((item) => dataGridRowIDs.has(item.id));
            const startIndex = paginationModel.page * paginationModel.pageSize;
            const endIndex = startIndex + paginationModel.pageSize;

            checkProduct(filteredDataRows.slice(startIndex, endIndex))
        }
    }

    const checkProduct = (products) => {
        const _filteredProducts = products.filter(p => !p.checked)

        if (_filteredProducts.length) {
            const labels = _filteredProducts.map(r => r.CodeArticle).join(",")
            const eans = _filteredProducts.filter(r => r.EAN).map(r => r.EAN).join(",")

            const params = [
                `code_articles=${labels}`,
                `eans=${eans}`
            ]

            setLoading(true)
            connectApi(`check-article-availability?${params.join("&")}`, "GET", null)
                .then(response => {
                    const productsCheckedCodeArticles = _filteredProducts.map(pr => {
                        return pr.CodeArticle
                    })

                    const _rows = _.cloneDeep(sales.current).map(r => {
                        if (productsCheckedCodeArticles.includes(r.CodeArticle)) {
                            r.checked = true
                            r.exist = response['codeArticles'].includes(r.CodeArticle)
                                || response['eans'].includes(r.EAN)
                        }

                        return r
                    })

                    setLoading(false)
                    sales.current = _rows
                    setRows(_rows)
                })
        }
    }

    return (
        <Router>
            <div className="wrapper">
                <Header/>
                <Menu nameMenu="saleItem"/>

                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h5 className="m-0 text-dark">Suivi achats</h5>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item">Magasins</li>
                                            <li className="breadcrumb-item active">Suivi achats</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Box sx={{height: "78vh"}}>
                                    <ThemeProvider theme={theme}>
                                        {info.userStores.length === 0 &&
                                            <Box sx={{
                                                background: "white",
                                                p: 3,
                                                textAlign: "center",
                                                borderRadius: 5,
                                                overflow: "hidden"
                                            }}>
                                                <p style={{fontWeight: "bold"}}>
                                                    Fonctionnalité non disponible
                                                </p>
                                                <p style={{fontWeight: "bold"}}>
                                                    Votre compte utilisateur n'est associé à aucun magasin SEDIS
                                                </p>
                                            </Box>
                                        }
                                        {info.userStores.length > 0 &&
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                                <SedisDateGrid
                                                    apiRef={apiRef}
                                                    columns={columns}
                                                    rows={getRows()}
                                                    loading={loading}
                                                    onFilterChange={handleFilter}
                                                    columnVisibilityModel={JSON.parse(info.salesColumnVisibility ?? "{}")}
                                                    onColumnVisibilityModelChange={model => {
                                                        connectApi(`update-sales-column-visibility`, "POST", {
                                                            salesColumnVisibility: JSON.stringify(model)
                                                        }).then()
                                                    }}
                                                    onPaginationModelChange={handlePaginationModelChange}
                                                    onFilterModelChange={_.debounce(() => {
                                                        handleFilterModelChange()
                                                    }, 500)}
                                                />
                                            </LocalizationProvider>
                                        }
                                    </ThemeProvider>
                                </Box>
                            </div>
                        </section>
                    </div>
                    <Footer/>
                </div>
            </div>
        </Router>
    )
}