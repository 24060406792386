const initialTag = [];

export default function TagReducer(state = initialTag, action){
    let nextState;
    switch(action.type){
        case "SET_TAG" :
            nextState = {
                ...state,
                tag : action.value
            };
            return nextState;
        // break;
        case "RESET_TAG" :
            nextState = {
                ...state,
                tag : initialTag
            };
            return nextState;
        // break;
        default :
            return nextState || state;
    }
}