import React from "react"
import {createBrowserHistory} from "history"
import "./notifications.css"
import connectApi from "../../../../services/helpers/connectApi"
import {
    HTTP_UNAUTHORIZED,
    NOTIFICATION_HASERROR,
    NOTIFICATION_INPROGRESS,
    NOTIFICATION_SENT, prefixBack,
    prefixFront
} from "../../../../constants"
import DataTable from "react-data-table-component"
import NotificationsHelper from "./NotificationsHelper"
import moment from "moment"
import {InputField, SelectField} from "./FormComponents"

const _ = require("lodash")

class Filters extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        this.state = {
            optionsRecipients: [],
            optionsUsers: [],
            dateFrom: "",
            dateTo: "",
            totalRows: 0,
            perPage: 10,
            loading: false,
            recipientLoading: true,
            userLoading: true,
            displayFeedback: false
        }
        this.recipientOptions = [
            {
                label: "Groupes",
                type: "groups",
                icon: "fa-users-line",
                options: []
            },
            {
                label: "Clients",
                type: "customers",
                icon: "fa-user",
                options: []
            }
        ]
        this.userOptions = [
            {
                label: "Utilisateurs",
                type: "user",
                icon: "fa-user",
                options: []
            }
        ]
        this.errorMessage = ""
        this.hasError = false
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
        this.handleUserSelectChange = this.handleUserSelectChange.bind(this)
        this.handleRecipientSelectChange = this.handleRecipientSelectChange.bind(this)
        this.loadRecipients = this.loadRecipients.bind(this)
        this.loadUsers = this.loadUsers.bind(this)
        this.updateButtonState = this.updateButtonState.bind(this)
        // eslint-disable-next-line no-func-assign
        getFilters = getFilters.bind(this)
    }

    componentDidMount() {
        (async function () {
            await Promise.all([
                this.loadRecipients(),
                this.loadUsers()
            ])
        }).bind(this)()
    }

    async loadRecipients() {
        /* ---------------------- Load Recipients ---------------------- */
        const {groupRecipient, customerRecipient} = await NotificationsHelper.getRecipients()
        this.recipientOptions[0].options = groupRecipient
        this.recipientOptions[1].options = customerRecipient

        this.setState({recipientLoading: false})
    }

    async loadUsers() {
        /* ---------------------- Load Users ---------------------- */
        const resultUsers = await connectApi("user-sedis", "GET", null)

        resultUsers.forEach((user) => {
            this.userOptions[0].options.push({
                value: user.id,
                label: `${user.firstName} ${user.lastName} <${user.email}>`
            })
        })

        this.setState({userLoading: false})
    }

    handleFilterSubmit() {
        this.props.onFilter(getFilters(), this.updateButtonState)
    }

    updateButtonState(isLoading = false) {
        this.setState({loading: isLoading})
    }

    handleRecipientSelectChange(selectedOptions) {
        this.setState({
            optionsRecipients: selectedOptions
        })
    }

    handleUserSelectChange(selectedOptions) {
        this.setState({
            optionsUsers: selectedOptions
        })
    }

    render() {
        return <div className="history-filters">
            <InputField className="history-dateFrom" type="date" name="dateFrom" value={this.state.dateFrom}
                        min={getMaxHistoryDate(true)}
                        title="A partir du" context={this}>
                Date de début
            </InputField>
            <InputField className="history-dateTo" type="date" name="dateTo"
                        value={this.state.dateTo} title="Jusqu'au"
                        context={this}>
                Date de fin
            </InputField>
            <div className="history-user-selector">
                {this.state.userLoading && (
                    <div className={"loading-overlay"}><i className={"fas fa-gear fa-spin loading-icon"}></i>
                    </div>)}
                <SelectField
                    className={"formField reactSelect-userSelector"}
                    classNamePrefix={"reactSelect-custom"}
                    options={this.userOptions}
                    value={this.state.optionsUsers}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    placeholder={"Selectionnez un ou plusieurs utilisateur(s)"}
                    onChange={this.handleUserSelectChange}
                    optionFocusedBackground={"#66B82126"}
                    optionActiveBackground={"#66B82133"}
                    context={this}
                />
            </div>
            <div className="history-recipient-selector">
                {this.state.recipientLoading && (
                    <div className={"loading-overlay"}><i className={"fas fa-gear fa-spin loading-icon"}></i>
                    </div>)}
                <SelectField
                    className={"formField reactSelect-recipientSelector"}
                    classNamePrefix={"reactSelect-custom"}
                    options={this.recipientOptions}
                    value={this.state.optionsRecipients}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    placeholder={"Selectionnez un ou plusieurs destinataire(s)"}
                    onChange={this.handleRecipientSelectChange}
                    optionFocusedBackground={"#66B82126"}
                    optionActiveBackground={"#66B82133"}
                    context={this}
                />
            </div>
            <button type="button" className="btn btn-info filterButton"
                    title="Cliquer pour filtrer les résultats"
                    disabled={this.state.loading}
                    onClick={this.handleFilterSubmit}>
                <i className="fas fa-arrow-down"/> Filtrer
            </button>
            {this.state.displayFeedback && (
                <div
                    className={`alert notification-group-feedback ${this.hasError ? "alert-danger" : "alert-success"}`}
                    role="alert">
                    {this.errorMessage}
                </div>
            )}
        </div>
    }
}

class Table extends React.Component {
    static defaultProps = {}

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalRows: 0,
            perPage: 10,
            loading: true,
            displayFeedback: false
        }

        this.errorMessage = ""
        this.hasError = false
        this.imageBasePath = ""

        this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
    }

    componentDidMount() {
        (async function () {
            this.imageBasePath = NotificationsHelper.getPhotoBasePath()
            const count = await connectApi(`notification-count?createdAt[after]=${getMaxHistoryDate()}`, "GET", null)
            this.setState({totalRows: count.count})

            await getHistoryLines.bind(this)(1)
        }).bind(this)()
    }

    handlePerRowsChange(newPerPage, page) {
        (async function () {
            this.setState({perPage: newPerPage, loading: true}, () => {
                getHistoryLines.bind(this)(page, getFilters())
            })
        }).bind(this)()
    }

    handlePageChange(page) {
        (async function () {
            this.setState({loading: true}, () => {
                getHistoryLines.bind(this)(page, getFilters())
            })
        }).bind(this)()
    }

    handleFilter(filters, updateButtonStateFunction) {
        (async function () {
            this.setState({loading: true})
            updateButtonStateFunction(true)

            const urlCount = `notification-count?${getFiltersUrlParams(filters)}`
            connectApi(urlCount, "GET", null).then((response) => {
                this.setState({totalRows: response.count})
            })

            getHistoryLines.bind(this)(1, filters, updateButtonStateFunction)
        }).bind(this)()
    }


    render() {
        const columns = [
            {name: "Date", selector: row => row.date, sortable: true, width: "7vw"},
            {name: "Utilisateur", selector: row => row.user, sortable: true, width: "7vw"},
            {name: "Texte complémentaire", selector: row => row.message, width: "10vw"},
            // {name: "Image", selector: row => row.image, width: "100},
            {name: "Produit", selector: row => row.product, width: "18vw"},
            {name: "Destinataires", selector: row => row.formatedRecipients, width: "14vw"},
            {name: "Etat", selector: row => row.state, width: "6vw"},
            {name: "Programmation", selector: row => row.scheduledAt, width: "9vw"},
            {name: "Requête d'envoi", selector: row => row.sendRequested, width: "7vw"},
            {name: "Envoi complété", selector: row => row.sendCompleted, width: "7vw"},
            // {name: "Retours/erreurs", selector: row => row.apiReturnStatus, width: "15vw"},
            {name: "Actions", selector: row => row.actions, width: "6vw"}
        ]

        return <>
            <Filters onFilter={this.handleFilter}/>
            <div className="history-table">
                {this.state.loading && (
                    <div className="loading-overlay"><i className="fas fa-gear fa-spin loading-icon"></i></div>)}
                <DataTable
                    title="Historique"
                    columns={columns}
                    data={this.state.data}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    fixedHeader
                    fixedHeaderScrollHeight="65vh"
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    responsive
                    className="historyTable"
                    subHeaderAlign="center"
                    subHeaderWrap
                    noDataComponent={<p className={"py-3"}>Il n'y a aucun donné à afficher</p>}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Lignes par page:'
                    }}
                />

                {this.state.displayFeedback && (
                    <div
                        className={`alert notification-group-feedback ${this.hasError ? "alert-danger" : "alert-success"}`}
                        role="alert">
                        {this.errorMessage}
                    </div>
                )}
            </div>
        </>
    }
}

class ExpandedComponent extends React.Component {
    render() {
        return <div className="expanded-table-preview">
            <div className="message-preview">
                <h6>Image & message</h6>
                <div>
                    {this.props.data.productsCount > 1 &&
                        <span className="badge bg-info">+ {this.props.data.productsCount - 1} autres produits</span>}
                    {this.props.data.productsCount === 0 && <div>Aucun produit disponible</div>}
                    {this.props.data.productsCount !== 0 &&
                        <div className="message-preview-content">
                            {this.props.data.image ? this.props.data.image : <div>Pas d'image</div>}
                            <div className="" dangerouslySetInnerHTML={{__html: this.props.data.formatedText}}>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="recipients-preview">
                <h6>Destinataires</h6>
                {this.props.data.recipients}
            </div>
            <div className="apiStatus-preview">
                <h6>Retour / erreurs</h6>
                {this.props.data.apiReturnStatus}
            </div>
        </div>
    }
}

/* ================================== EXPORTED COMPONENTS ================================== */
const exported = {
    Table, Filters
}
export default exported

/* ================================== GLOBAL FUNCTIONS ================================== */

function getFilters() {
    let filters = {}
    if (this.state.dateFrom !== "") {
        filters.dateFrom = this.state.dateFrom + " 00:00:00"
    }
    if (this.state.dateTo !== "") {
        filters.dateTo = this.state.dateTo + " 23:59:59"
    }
    if (this.state.optionsUsers.length !== 0) {
        let userIdArray = this.state.optionsUsers
        userIdArray = userIdArray.map((user) => {
            return user.value
        })
        filters.users = userIdArray
    }

    if (this.state.optionsRecipients.length !== 0) {
        let groupRecipientsArray = []
        let customersArray = []
        this.state.optionsRecipients.forEach((rec) => {
            const type = rec.value.split("|$|")[0]
            const id = parseInt(rec.value.split("|$|")[1])
            if (type === "group") {
                groupRecipientsArray.push(id)
            } else if (type === "customer") {
                customersArray.push(id)
            }

        })

        if (groupRecipientsArray.length !== 0) {
            filters.groupRecipients = groupRecipientsArray
        }
        if (customersArray.length !== 0) {
            filters.customerRecipients = customersArray
        }
    }

    return filters
}

function getFiltersUrlParams(filters) {
    let urlParams = ""
    if (filters.hasOwnProperty("dateFrom")) {
        urlParams += `&createdAt[after]=${filters.dateFrom}`
    }
    if (filters.hasOwnProperty("dateTo")) {
        urlParams += `&createdAt[before]=${filters.dateTo}`
    }
    if (filters.hasOwnProperty("users")) {
        urlParams += `&users=${_.join(filters.users, ",")}`
    }
    if (filters.hasOwnProperty("groupRecipients")) {
        urlParams += `&groups=${_.join(filters.groupRecipients, ",")}`
    }
    if (filters.hasOwnProperty("customerRecipients")) {
        urlParams += `&customers=${_.join(filters.customerRecipients, ",")}`
    }
    return urlParams
}

async function getHistoryLines(page = 1, filters = null, callback = null) {
    const dateStr = getMaxHistoryDate()

    let url = `notification-history?order[createdAt]=desc&page=${page}&per_page=${this.state.perPage}`
    if (filters === null || (filters && !filters.dateFrom)) {
        filters = {};
        filters["dateFrom"] = dateStr;
    }

    if (filters !== null) {
        url += getFiltersUrlParams(filters)
    }

    const result = await connectApi(url, "GET", null)

    if (typeof (result) !== "object") {
        return
    }
    if (result.hasOwnProperty("code") && result.code === HTTP_UNAUTHORIZED) {
        const history = createBrowserHistory()
        history.push(prefixFront + "/login")
        return
    }
    let data = []
    for (const history of result) {
        data.push(await formatHistoryRow.bind(this)(history))
    }
    this.setState({data: data, loading: false}, () => {
        if (callback !== null && callback instanceof Function) {
            callback()
        }
    })
}


async function formatHistoryRow(history) {
    let recipients = []
    let recipientsList = []
    history.groupsRecipients.forEach((group) => {
        recipients.push(<div key={group.id} className="recipient-item">{`GROUPE : ${group.name}`}</div>)
        recipientsList.push(`group|$|${group.id}`)
    })
    history.customersRecipients.forEach((customer) => {
        recipients.push(<div key={customer.CodeClient}
                             className="recipient-item">{`${customer.CodeClient} : ${customer.NomDuClient}`}</div>)
        recipientsList.push(`customer|$|${customer.CodeClient}`)
    })

    let apiReturnStatus = []
    if (history.apiReturnStatus) {
        history.apiReturnStatus.forEach((status) => {
            apiReturnStatus.push(<div key={status} className="apiStatus-item">{status}</div>)
        })
    }

    let imageLink = history.imageLink
    let formatedText = ""

    const product = history.products.length ? history.products[0] : null
    const variant = history.variants.length ? history.variants[0] : null
    if (product) {
        imageLink = product.PathPhoto ? this.imageBasePath + product.PathPhoto : '/img/default-product-img.png'
        variant.product = product
        formatedText = await NotificationsHelper.formatTextMessage(history.message ?? '', variant, false)
    }

    return {
        id: history.id,
        date: formatDate(history.createdAt),
        user: formatText(`${history.user}`),
        message: formatText(history.message),
        image: formatImage(imageLink),
        formatedText: formatedText,
        product: formatProduct(history),
        productsCount: history.products.length,
        recipients: <div className="recipients">{recipients}</div>,
        formatedRecipients: formatRecipients(recipients),
        state: getState(history.state),
        scheduledAt: formatDate(history.scheduledAt, false),
        sendRequested: formatDate(history.sendRequested, true),
        sendCompleted: formatDate(history.sendCompleted, true),
        apiReturnStatus: <div className="apiReturnStatus">{apiReturnStatus}</div>,
        hasError: (history.state === 2),
        actions: <CloneButton recipientsList={recipientsList} history={history}/>
    }
}

function CloneButton(props) {
    const searchParams = new URLSearchParams()
    searchParams.append("product", getUrlProductsCodes(props.history.variants))
    searchParams.append("message", props.history.message)
    searchParams.append("recipients", _.join(props.recipientsList, ","))

    return <>
        <a href={prefixBack + "/notifications?" + searchParams.toString()} className="btn btn-light clone-button"
           title="Réutiliser ce message">
            <i className="fas fa-clone"></i>
        </a>
    </>

}

function getUrlProductsCodes(products) {
    if (products.length === 0) {
        return ""
    }

    return _.join(_.map(products, (p) => {
        return p ? p.CodeVarianteArticle : ''
    }))
}

function getState(state) {
    switch (state) {
        case NOTIFICATION_INPROGRESS:
            return <span className="notification-status-badge badge bg-primary">En cours</span>
        case NOTIFICATION_SENT:
            return <span className="notification-status-badge badge bg-success">Envoyés</span>
        case NOTIFICATION_HASERROR:
            return <span className="notification-status-badge badge bg-danger">Erreurs</span>
        default:
            return <span className="notification-status-badge badge bg-secondary"> ? </span>
    }
}

function formatText(text) {
    return <div className="text-display" title={text}>
        <span>{text}</span>
    </div>
}


function formatImage(imageLink, title = "") {
    return <div className="image-display" title={title}>
        <img src={imageLink} alt={"product illustration"}/>
    </div>
}


function formatDate(datetime, withSeconds = false) {
    const date = moment(datetime)
    const timeFormat = (withSeconds) ? "HH:mm:ss" : "HH:mm"
    return (!datetime) ? '-' : <div className="date-display" title={date.format(`DD/MM/YYYY ${timeFormat}`)}>
        <span>{date.format("DD/MM/YYYY")}</span><span>{date.format(timeFormat)}</span>
    </div>
}

function formatProduct(history) {
    let product = "/"
    let title = "/"

    if (history.products.length && history.variants.length) {
        const productsGeslot = history.products[0]
        const stocksGeslot = history.variants[0]

        if (!productsGeslot || !stocksGeslot) {
            return ''
        }

        product = productsGeslot.Libelle
        title = `${stocksGeslot ? stocksGeslot.CodeArticle : ''} : ${product}`
    }

    return <div className="product-display" title={title}> {product} </div>
}

function formatRecipients(recipients) {
    let firstRecipient = recipients.slice(0, 1);
    if (firstRecipient.length) {
        return firstRecipient;
    } else {
        return "N/A";
    }
}

function getMaxHistoryDate(dateOnly = false) {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return moment(date).format("YYYY-MM-DD" + (!dateOnly ? " 00:00:00" : ""))
}