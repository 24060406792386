import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import styled from 'styled-components';

// App
import { Button } from './Forms';
import { downloadCustomerOrder } from '../../../services/helpers/FrontApiHelper';

const Download = styled(Button)`
    font-size: 16px !important;
    line-height: 46px !important;
    padding: 0px 40px !important;
`;

function DownloadInvoice(props){

    const [loader, setLoader] = useState(false);

    const _downloadInvoice = () => {
        const { codeFacture } = props;
        setLoader(true);
        downloadCustomerOrder(codeFacture)
            .then( response => {
                fileDownload(response, `Commande-${codeFacture}.pdf`);
            } )
            .finally( () => setLoader(false) )
            .catch( e => console.log(e) )
    }

    return (
        <Download type="button" variant="secondary" loader={loader} contain="outlined" onClick={ () => _downloadInvoice() }>Télécharger</Download>
    )
}

export default DownloadInvoice;