import React from "react"
import styled from "styled-components"
import {toast} from "react-toastify"
import {Link} from "react-router-dom"
import {connect} from "react-redux"
import {createBrowserHistory} from "history"

// App
import {UrlPrefixContext} from "../../../context"
import {List, ListItem} from "../../Mixed/Composants"
import {deleteOrder} from "../../../services/helpers/FrontApiHelper"
import {ROLE_USER_BO} from "../../../constants"
import {isGranted} from "../../../services/helpers/RolesHelper"

const history = createBrowserHistory()

const Button = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 119px;
  height: 120px;
  border-radius: 6px;
  background-color: #ffcc00;
  /* background: #847f7f; */

  &:hover > ul {
    visibility: visible;
    transform: translateY(0px);
  }

  @media screen and (max-width: 2000px) {
    width: 102px;
    height: 104px;
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto 11px;
  @media screen and (max-width: 2000px) {
    max-width: 26px;
  }
  @media screen and (max-width: 1500px) {
    max-width: 24px;
  }
`

const Text = styled.span`
  display: block;
  font-size: 30px;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  color: #404040;
  position: relative;
  /* color: #ffffff; */
  font-family: 'Covered By Your Grace', sans-serif;
  text-transform: uppercase;
  @media screen and (max-width: 2000px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1500px) {
    font-size: 20px;
  }
`

const AccountId = styled.span`
    position: absolute;
    display: block;
    width: 100%;
    bottom: -17px;
    font-size: 1.1rem;
`

const Submenu = styled(List)`
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
  z-index: 9999;
  border-radius: 4px;
  visibility: hidden;
  transform: translateY(40px);
  transition: all 400ms ease-in;

  ::before {
    content: '';
    display: inline-block;
    border-top: 20px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    bottom: 100%;
    transform: rotate(180deg);
    position: absolute;
    z-index: -1;
    left: 38px;
    margin-bottom: -7px;
    @media (max-width: 820px) {
      left: 90px;
    }
  }

  @media (max-width: 820px) {
    left: -50px;
  }
`

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  color: #909090;
  font-size: 19px;
  font-weight: 400;
  padding: .8rem 2rem;

  &:hover {
    background: #dedede;
    color: #909090;
  }

  @media screen and (max-width: 2000px) {
    font-size: 1.1rem;
  }
`
const IconWrapper = styled.div`
  width: 52px;
  flex-shrink: 0;
`
const Icon = styled.img`
  display: inline-block;
  @media screen and (max-width: 2000px) {
    max-width: 26px;
  }
`

const IconCamion = styled(Icon)`
  max-width: 41px;
  margin-left: -8px;
`

const IconInformation = styled(Icon)`
  @media screen and (max-width: 2000px) {
    max-width: 32px;
    margin-right: 16px;
    margin-left: -3px;
  }
`

class Account extends React.Component {

    _getLocalOrderLine = () => {
        const storage = localStorage.getItem("frontOrderLine")
        return storage != null ? JSON.parse(storage) : null
    }

    _checkIfEncoursExists = () => {
        const compte = document.getElementsByClassName("front__mon-compte-link")[0]
        const size = this._getMenuSize()
        if (compte) {
            compte.style.width = `${size}px`
        }
    }

    _getMenuSize = () => {
        let sizeWithEncours = 330
        let sizeWithoutEncours = 280
        const w = window.innerWidth
        const encours = document.getElementsByClassName("front__encours-client")
        if (w > 1900) {
            sizeWithEncours = 360
            sizeWithoutEncours = 310
        } else if (w > 1401 && w <= 1500) {
            sizeWithEncours = 320
            sizeWithoutEncours = 270
        } else if (w <= 1400) {
            sizeWithEncours = 310
            sizeWithoutEncours = 260
        }
        return encours.length ? sizeWithEncours : sizeWithoutEncours
    }

    async _logoutCustomer(event) {
        event.preventDefault()
        const {order, customer} = this.props
        const orderLine = this._getLocalOrderLine()
        if (order && order.length) {
            toast("Votre panier sera supprimé après votre déconnexion", {
                type: "success",
                autoDismiss: true
            })
            if (orderLine && orderLine.id && customer && customer.hasOwnProperty("user") && !isGranted(customer.user.role, ROLE_USER_BO)) {
                await deleteOrder(orderLine.OrderId)
            }
        }
        localStorage.removeItem("state")
        localStorage.removeItem("frontOrderProducts")
        localStorage.removeItem("frontMiniCartStatus")
        localStorage.removeItem("frontOrderLine")
        localStorage.removeItem("errorsDuplicationPanier")
        this.props.dispatch([
            {type: "RESET_ORDER", value: []},
            {type: "DELETE_CUSTOMER", value: []}
        ])
        setTimeout(() => {
            history.push(`${this.context.prefix}/login`)
        }, 200)
    }

    componentDidMount() {
        this._checkIfEncoursExists()
        window.addEventListener("resize", () => {
            this._checkIfEncoursExists()
        })
    }

    componentDidUpdate() {
        this._checkIfEncoursExists()
    }

    render() {
        const {customer} = this.props

        return (
            <Button to="/" {...this.props} className="front__mon-compte">
                <Img src="/img/i-account.svg"/>
                <Text>COMPTE <AccountId>({customer.user.CodeClient})</AccountId></Text>
                <Submenu className="front__mon-compte-link">
                    <ListItem>
                        <MenuItem to={`${this.context.prefix}/mes-commandes`} title="Mes commandes">
                            <IconWrapper>
                                <Icon src="/img/commandes.svg" alt="Mes commandes"/>
                            </IconWrapper>
                            <span>Mes commandes</span>
                        </MenuItem>
                    </ListItem>
                    <ListItem>
                        <MenuItem to={`${this.context.prefix}/suivi-des-commandes`} title="Suivi des commandes">
                            <IconWrapper>
                                <IconCamion src="/img/picto-camion-livraison.svg" alt="Suivi des commandes"/>
                            </IconWrapper>
                            <span>Suivi des commandes</span>
                        </MenuItem>
                    </ListItem>
                    <ListItem>
                        <MenuItem to={`${this.context.prefix}/mes-informations`} title="Mes informations">
                            <IconWrapper>
                                <IconInformation src="/img/information.svg" alt="Mes informations"/>
                            </IconWrapper>
                            <span>Mes informations</span>
                        </MenuItem>
                    </ListItem>
                    <ListItem>
                        <MenuItem to={`${this.context.prefix}/mes-factures`} title="Mes factures">
                            <IconWrapper>
                                <Icon src="/img/facture.svg" alt="Mes factures"/>
                            </IconWrapper>
                            <span>Mes factures</span>
                        </MenuItem>
                    </ListItem>
                    <ListItem>
                        <MenuItem to={`${this.context.prefix}/nous-contacter`} title="Nous contacter">
                            <IconWrapper>
                                <Icon src="/img/contact.svg" alt="Nous contacter"/>
                            </IconWrapper>
                            <span>Nous contacter</span>
                        </MenuItem>
                    </ListItem>
                    <ListItem>
                        <MenuItem to={`${this.context.prefix}/demande-enlevement`} title="Demande d'enlèvement marchandise">
                            <IconWrapper>
                                <IconCamion src="/img/picto-camion-livraison.svg" alt="Demande d'enlèvement marchandise"/>
                            </IconWrapper>
                            <span>Demande d'enlèvement marchandise</span>
                        </MenuItem>
                    </ListItem>
                    <ListItem>
                        <MenuItem to="/" onClick={(event) => this._logoutCustomer(event)} title="Déconnexion">
                            <IconWrapper>
                                <Icon src="/img/deconnexion.svg" alt="Déconnexion"/>
                            </IconWrapper>
                            <span>Déconnexion</span>
                        </MenuItem>
                    </ListItem>
                </Submenu>
            </Button>
        )
    }

}

const mapStateToProps = state => {
    return {
        order: state.order.order,
        customer: state.customer.customer
    }
}

Account.contextType = UrlPrefixContext

export default connect(mapStateToProps)(Account)