import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage'
import Multi from 'redux-multi';

// App
import TagReducer from './TagReducer';
import OrderReducer from './OrderReducer';
import FilterReducer from './FilterReducer';
import RayonsReducer from './RayonsReducer';
import CustomerReducer from './CustomerReducer';
import AvailabilityReducer from './AvailabilityReducer';
import TextMomentReducer from './TextMomentReducer';
import UserBackReducer from './UserBackReducer';
import MinimumCommandableReducer from "./MinimumCommandableReducer";

// Persist
const tagPersistConfig = {
    key : 'tag',
    storage : localStorage,
    timeout : 0
};
const orderPersistConfig = {
    key : 'order',
    storage : localStorage,
    timeout : 0
};
const filterPersistConfig = {
    key : 'filter',
    storage : localStorage,
    timeout : 0
};
const rayonsPersistConfig = {
    key : 'rayons',
    storage : localStorage,
    timeout : 0
};
const customerPersistConfig = {
    key : 'customer',
    storage : localStorage,
    timeout : 0
};
const availabilityPersistConfig = {
    key : 'availability',
    storage : localStorage,
    timeout : 0
};
const textMomentPersistConfig = {
    key : 'textMoment',
    storage : localStorage,
    timeout : 0
};
const minimumCommandablePersistConfig = {
    key : 'minimumCommandable',
    storage : localStorage,
    timeout : 0
};
const userBackPersistConfig = {
    key : 'userBack',
    storage : localStorage,
    timeout : 0
};

const Reducers = combineReducers({ 
    tag : persistReducer(tagPersistConfig, TagReducer),
    order : persistReducer(orderPersistConfig, OrderReducer),
    filter: persistReducer(filterPersistConfig, FilterReducer),
    rayons : persistReducer(rayonsPersistConfig, RayonsReducer),
    customer : persistReducer(customerPersistConfig, CustomerReducer),
    availability : persistReducer(availabilityPersistConfig, AvailabilityReducer),
    textMoment : persistReducer(textMomentPersistConfig, TextMomentReducer),
    minimumCommandable : persistReducer(minimumCommandablePersistConfig, MinimumCommandableReducer),
    userback : persistReducer(userBackPersistConfig, UserBackReducer)
});

const Stores = createStore(Reducers, applyMiddleware(Multi));

const exported = {
    store : Stores,
    persistor : persistStore(Stores)
}
export default exported
