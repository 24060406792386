import React from "react"
import HtmlStructure from "./NotificationsHtmlStructure"
import {DOCUMENT_TITLE_BACKOFFICE} from "../../../../constants"
import {Table, SendingConditions, NotificationStats} from "./NotificationSedisComponents"

export default function NotificationSedis() {
    document.title = "Notifications SEDISDESTOCK - " + DOCUMENT_TITLE_BACKOFFICE

    return <HtmlStructure titleText="Notifications SEDISDESTOCK" breadcrumActiveText="Notifications SEDISDESTOCK"
                          menuName="NotificationSedis">

        <section className="notifications-sedis">
            <NotificationStats/>
            <SendingConditions/>
            <Table/>
        </section>

    </HtmlStructure>
}
