import React, {Component} from "react"
import {BrowserRouter as Router} from "react-router-dom"
import connectApi from "../../../../services/helpers/connectApi"
import Header from "../../../Mixed/Header/Header"
import Menu from "../../../Mixed/Menu/Menu"
import Footer from "../../../Mixed/Footer/Footer"
import {createBrowserHistory} from "history"
import {HTTP_UNAUTHORIZED, prefixBack, prefixFront} from "../../../../constants"
import {toast} from "react-toastify"
import {connect} from "react-redux"
import {envVars} from "../../../../env_var"

import "./user.css"
import {getRoleStrings} from "../../../../services/helpers/RolesHelper"

const newHistory = createBrowserHistory()

class UtilisateurSEDIS extends Component {

    state = {
        userId: null,
        listUsers: []
    }

    nameMenu = "UtilisateurSEDIS"

    componentDidMount() {
        if (localStorage.getItem("state")) {
            this.setState({
                userId: JSON.parse(localStorage.getItem("state")).user.id
            })
        }

        this.renderListUsers()
    }

    renderListUsers = async () => {
        const data = await connectApi("user-sedis", "GET", null)
        if (typeof (data) === "object") {
            if (data.hasOwnProperty("code") && data.code === HTTP_UNAUTHORIZED) {
                newHistory.push(prefixFront + "/login")
            } else {
                this.setState({
                    listUsers: data
                })
            }
        } else {
            console.log(data)
        }
    }

    _handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    openCreate = () => {
        window.location.href = envVars["scheme"] + window.location.host + `${prefixBack}/create-user-sedis`
    }

    openUpdate = (e, id) => {
        window.location.href = envVars["scheme"] + window.location.host + `${prefixBack}/update-user-sedis/` + id
    }

    deleteUser = async (e, id) => {
        e.preventDefault()
        if (window.confirm("Attention! Cette opération est irreverssible. Êtes vous sûr de vouloir supprimer cet utilisateur?") === true) {
            await connectApi("user-sedis/" + id, "DELETE", null)
            toast("Utilisateur supprimé avec succès", {
                type: "success",
                autoDismiss: true
            })
            window.location.reload()
        }
    }

    render() {
        const users = this.state.listUsers?.filter(user => {
            return !user.roles.includes('ROLE_API') && !user.roles.includes('ROLE_ADMIN');
        })

        const listUsers = users?.map((user) => (
            <tr key={`user${user.id}`}>
                <td className="fullname">{user.firstName + " " + user.lastName}</td>
                <td className="Identifiant">{user.email}</td>
                <td className="Identifiant">{getRoleStrings(user.roles[0]).role}</td>
                <td>
                    {user.userStores.length ? user.userStores.map(us => us.store.name).join(", ") : "-"}
                </td>
                <td className="actions">
                    <button className="link user-action-button" title="Modifier"
                            onClick={(event) => {
                                this.openUpdate(event, user.id)
                            }}>
                        <i className="fas fa-edit"/>
                    </button>
                    {this.state.userId && (this.state.userId === user.id) ? null :
                        <button className="link link-danger user-action-button" title="Supprimer"
                                onClick={(event) => {
                                    this.deleteUser(event, user.id)
                                }}>
                            <i className="fas fa-trash"/>
                        </button>
                    }
                </td>
            </tr>
        ))
        return (
            <Router>
                <div className="wrapper">
                    <Header/>
                    <Menu nameMenu={this.nameMenu}/>
                    <div>
                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-8 user-title">
                                            <h5 className="m-0 text-dark">Liste des utilisateurs SEDIS</h5>

                                            <button className="btn btn-info user-create-button" onClick={(event) => {
                                                this.openCreate(event)
                                            }}>
                                                Créer un utilisateur SEDIS
                                            </button>
                                        </div>
                                        <div className="col-sm-4">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">Administration</li>
                                                <li className="breadcrumb-item active">Utilisateurs SEDIS</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="content">

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="table-responsive">
                                                <table id="listUsers" className="table table-bordered table-hover">
                                                    <thead>
                                                    <tr className="table-dark">
                                                        <th className="fullname">Prénom et Nom</th>
                                                        <th className="Identifiant">Identifiant</th>
                                                        <th className="role">Role</th>
                                                        <th className="action">Magasins</th>
                                                        <th className="action">Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listUsers}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return {
        textMoment: state.textMoment.textDuMoment
    }
}

export default connect(mapStateToProps)(UtilisateurSEDIS)
