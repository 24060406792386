import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Div, Container, Row, Column } from '../../Mixed/Composants';
import {SectionTitle} from '../Composants';
import { Header, Footer, Content } from '../Composants/Template';
import { getSuivi } from '../../../services/helpers/FrontApiHelper';
import { formatDate, formatCurrency } from '../../../services/helpers/FrontHelper';

const Wrapper = styled.section`
    padding: 0 400px 250px 0;
    position: relative;
    min-height: 600px;
`;
const Camion = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 1300px;
    pointer-events: none;
`;
const ColTitle = styled.div`
    display: flex;
    height: 100px;
    align-items: center;
`;
const Title = styled.h2`
    background: #ff5d1f;
    padding: 10px 12px;
    font-family: 'Covered By Your Grace', sans-serif;
    color: #ffffff;
    font-size: 25px;
    margin-right: 10px;
`;
const PictoTitle = styled.img`
    max-width: 20%;
    height: 70px;
`;
const ListWrap = styled.div``;
const ListHeader = styled.div`
    display: flex;
    border: 1px solid #707070;
    justify-content: space-between;
    padding: 10px 15px;
    font-weight: 700;
`;
const ListBody = styled.div`
    display: flex;
    background: #ffffff;
    justify-content: space-between;
    padding: 10px 15px;
    margin-bottom: 3px;
`;
const Noproduct = styled.div`
    font-style: italic;
    text-align: center;
    margin: 10px 0;
`;
const Details = styled.div`
    position: fixed;
    background: #ffffff;
    border: 2px solid #eeede4;
    z-index: 3000;
    .container{
        width: 620px;
        text-align: center;
        font-size: 0.9em;
    }
`;
const RowDetailsHeader = styled(Row)`
    background: #ff5d1f;
    color: #ffffff;
    font-weight: 600;
    border: 2px solid #eeede4;
    padding: 10px 0;
    flex-wrap: nowrap;
`;
const ColumnDetails = styled.div`
    width: 20%;
`;
const RowDetailsBody = styled(Row)`
    padding: 10px 0;
`;
const Overlay = styled.div`
    background: #eeede4;
    opacity: 0.5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
`;

class SuiviCommandesPage extends React.Component{
    state = {
        loader : true,
        showdetails: false,
        detailsTop: 0,
        detailsLeft: 0,
        elements: false,
        elementDetail: {
            CodeBL: '',
            CodeBP: '',
            DateDLCDLUO: '',
            PrixVenteUnitaireHT: '',
            PrixVenteTotalHT: ''
        }
    }
    componentDidMount(){
        getSuivi(this.props.customer.user.CodeClient).then((response) =>{
            this.setState({
                elements: response,
                loader: false
            });
        })
    }
    _getElements(col){
        return this.state.elements.length ? this.state.elements[col] : false;
    }
    _renderSuivi(){
        return(
            <Wrapper>
                <Camion src="/img/picto-camion.svg"/>
                <Container>
                    <Row>
                        <Column md={4}>
                            {this._renderColumn('En attente livraison chez SEDIS', 'picto-camion-livraison.svg', 'enAttenteLivraisonGeslot')}
                        </Column>
                        <Column md={4}>
                            {this._renderColumn('Arrivé entrepôt SEDIS', 'picto-entrepot.svg', 'arriveEntrepot')}
                        </Column>
                        <Column md={4}>
                            {this._renderColumn('Préparation effectuée', 'picto-preparation.svg', 'preparationEffectuee')}
                        </Column>
                    </Row>
                </Container>
                <Details style={{
                    top: this.state.detailsTop,
                    left: this.state.detailsLeft,
                    display: this.state.showdetails?'block':'none'}}
                    onMouseLeave={e => this._hideDetails()}
                >
                    <Container>
                        <RowDetailsHeader>
                            <ColumnDetails>Numéro BL</ColumnDetails>
                            <ColumnDetails>Numéro BP</ColumnDetails>
                            <ColumnDetails>DLC/DLUO</ColumnDetails>
                            <ColumnDetails>PU €HT</ColumnDetails>
                            <ColumnDetails>Montant €HT</ColumnDetails>
                        </RowDetailsHeader>
                        <RowDetailsBody>
                            <ColumnDetails>{this.state.elementDetail.CodeBL}</ColumnDetails>
                            <ColumnDetails>{this.state.elementDetail.CodeBP}</ColumnDetails>
                            <ColumnDetails>{formatDate(this.state.elementDetail.DateDLCDLUO)}</ColumnDetails>
                            <ColumnDetails>{formatCurrency(this.state.elementDetail.PrixVenteUnitaireHT)}</ColumnDetails>
                            <ColumnDetails>{formatCurrency(this.state.elementDetail.PrixVenteTotalHT)}</ColumnDetails>
                        </RowDetailsBody>
                    </Container>
                </Details>
                <Overlay style={{display: this.state.showdetails?'block':'none'}}/>
            </Wrapper>
        );
    }
    _renderColumn(titre, icon, col){
        const elements = this.state.elements[col];
        if(elements){
            let total = elements.length ? elements.reduce((previousValue, elt) => previousValue + elt.Quantite, 0) : 0;
            return(
                <Div>
                    <ColTitle>
                        <Title>{titre} ({total} colis)</Title>
                        <PictoTitle src={"/img/" + icon}/>
                    </ColTitle>
                    {elements.length>0 && 
                        <ListWrap>
                            <ListHeader>
                                <span>Article</span>
                                <span>Qté</span>
                            </ListHeader>
                            {elements.map((elt, id) => 
                                <ListBody key={id} onMouseOver={e => this._showDetails(e, elt)}>
                                    <span>{elt.LibelleArticle}</span>
                                    <span>{elt.Quantite}</span>
                                </ListBody>
                            )}
                        </ListWrap>
                    }
                    {elements.length===0 && <Noproduct>Aucun produit disponible</Noproduct>}
                </Div>
            )
        }
    }
    _showDetails(e, elt){
        const dim = e.currentTarget.getBoundingClientRect();
        this.setState({
            detailsTop: dim.y,
            detailsLeft: dim.x,
            showdetails: true,
            elementDetail: elt
        })
    }
    _hideDetails(e){
        this.setState({
            showdetails: false
        })
    }
    render(){
        const { loader } = this.state;
        return(
            <Div className="front__wrap-screen">
                
                <Header />
                    <Content loading={loader}>
                        <SectionTitle tag="h1">Suivi des commandes</SectionTitle>
                        {this._renderSuivi()}
                    </Content>
                <Footer />
            </Div>
        );
    }
}
const mapStateToProps = state => {
    return {
        customer : state.customer.customer
    }
}

export default connect(mapStateToProps)(SuiviCommandesPage);