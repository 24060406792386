import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {connect} from "react-redux"
import {BeatLoader} from "react-spinners"

// App
import InfiniteScroll from "react-infinite-scroll-component"
import {
    getDerniereMinute,
    getProducts,
    getPromos,
    getSelectionDuMoment,
    getDluoDepassee,
    getHalal,
    getBio
} from "../../../services/helpers/FrontApiHelper"
import {strToSlug} from "../../../services/helpers/FrontHelper"
import {ProductItem, Rayons, SectionTitle} from "../Composants"
import {Column, Div, Row} from "../../Mixed/Composants"

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`

const WrapLoader = styled.div`
  padding: 9px 0 0;
  width: 100%;
`

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-style: italic;
`

const NoContent = styled.p`
  margin: 1rem 0;
  text-align: center;
`

const ColSidebar = styled(Column)`
  @media screen and (min-width: 1200px) {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
`

const ColContent = styled(Column)`
  @media screen and (min-width: 1200px) {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
`


class Products extends React.Component {

    _isMounted = false

    state = {
        paged: 1,
        loading: true,
        products: [],
        hasmore: true,
        totalItemsCount: 0,
        cb_getproducts: false
    }

    cb_getproductsArray = {
        promo: getPromos,
        selmoment: getSelectionDuMoment,
        dmin: getDerniereMinute,
        dluod: getDluoDepassee,
        halal: getHalal,
        bio: getBio
    }

    section_title = {
        promo: "Promotions",
        dmin: "Dernière minute",
        dluod: "DLUO dépassée",
        halal: "Halal",
        bio: "Bio"
    }

    _matchEqualHeights = () => {
        let max = 0
        const elements = document.querySelectorAll(".front__product-libelle")
        Array.from(elements).forEach(element => {
            const height = element.clientHeight
            if (height > max) {
                max = height
            }
        })
        Array.from(elements).forEach(element => {
            element.style.height = `${max}px`
        })
    }

    _setEqualHeights = () => {
        setTimeout(() => {
            this._matchEqualHeights()
        }, 0)
    }

    _getRayonInfo = () => {
        const {categorie, rayons} = this.props
        if (rayons && rayons.length) {
            return rayons.filter(item => strToSlug(item.Nomcategorie) === categorie)
        }
        return []
    }

    _setTotalItemsCount = () => {
        const {cb_getproducts} = this.state
        const {special} = this.props
        let productsFromApi = false
        if (special && typeof (cb_getproducts) === "function") {
            productsFromApi = cb_getproducts()
        } else {
            const rayon = this._getRayonInfo()
            if (rayon && rayon.length) {
                productsFromApi = getProducts(rayon[0].Identifiant)
            }
        }

        if (productsFromApi) {
            productsFromApi
                .then(response => {
                    this.setState({
                        totalItemsCount: response.length
                    })
                })
        }
    }

    _getProducts = () => {
        const {paged, products, cb_getproducts} = this.state
        const {special} = this.props
        let productsFromApi = false
        if (special && typeof (cb_getproducts) === "function") {
            productsFromApi = cb_getproducts(paged)
        } else {
            const rayon = this._getRayonInfo()
            if (rayon && rayon.length) {
                productsFromApi = getProducts(rayon[0].Identifiant, paged)
            }
        }

        if (productsFromApi) {
            productsFromApi
                .then(response => {
                    if (response && response.length) {
                        this.setState({
                            products: [...products, ...response]
                        }, () => this._setEqualHeights())
                    } else if (!products.length) {
                        this.setState({
                            products: []
                        })
                    }
                })
                .finally(() => this.setState({
                    loading: false,
                    hasmore: (this.state.totalItemsCount !== this.state.products.length)
                }))
        }
    }

    _renderProductLists = () => {
        const {customer, availability} = this.props
        const {loading, products, hasmore} = this.state

        if (loading) {
            return (
                <Loader>
                    <BeatLoader loading={loading} color="#ff5d1f"/>
                </Loader>
            )
        }

        if (!products || (products && !products.length)) {
            return (
                <Div>
                    <NoContent>Aucun produit ne correspond à vos critères de filtres </NoContent>
                </Div>
            )
        }
        return (
            <Div className="wrap-poduct-lists">
                <InfiniteScroll
                    hasMore={hasmore}
                    dataLength={this.state.products.length}
                    className="row align-items-end"
                    next={() => {
                        this.setState({paged: this.state.paged + 1})
                        this._getProducts()
                    }}
                    loader={
                        <WrapLoader>
                            <Loader>
                                <Text>Chargement de la liste en cours...</Text>
                            </Loader>
                        </WrapLoader>
                    }
                >
                    {products.map((product, index) => <ProductItem key={product.CodeArticle}
                                                                   dispatch={this.props.dispatch} product={product}
                                                                   customer={customer} availability={availability}
                                                                   perLine={this.props.special === "selmoment" ? 6 : 5}
                                                                   special={this.props.special}/>)}
                </InfiniteScroll>
            </Div>
        )
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.categorie !== this.props.categorie)) {
            this._setTotalItemsCount()
            this.setState({
                paged: 1,
                loading: true,
                products: [],
                hasmore: true
            }, () => this._getProducts())
        }
    }

    componentDidMount() {
        const {paged} = this.state
        const {special} = this.props
        this._isMounted = true
        if (this._isMounted) {
            this.setState({
                    paged: paged,
                    cb_getproducts: special ? this.cb_getproductsArray[special] : false
                }, () => {
                    this._setTotalItemsCount()
                    this._getProducts()
                }
            )
        }
    }


    render() {
        // const { paged } = this.state;
        const {categorie, special} = this.props;
        const title = special && this.section_title[special];
        return (
            <Div className="front__our-produts">
                {!special &&
                <Row>
                    <ColSidebar lg={3}>
                        <Rayons/>
                    </ColSidebar>
                    <ColContent lg={9}>
                        <SectionTitle tag="h1"
                                      className="second-title">{categorie === "boisson" ? "BOISSONS" : categorie}</SectionTitle>
                        {this._renderProductLists()}
                    </ColContent>
                </Row>
                }
                {special && special !== "selmoment" &&
                <Row>
                    <ColSidebar lg={3}>
                        <Rayons/>
                    </ColSidebar>
                    <ColContent lg={9}>
                        <SectionTitle tag="h1" className="second-title">{title}</SectionTitle>
                        {this._renderProductLists()}
                    </ColContent>
                </Row>
                }
                {special && special === "selmoment" &&
                this._renderProductLists()
                }
            </Div>
        )
    }

}

Products.defaultProps = {
    special: false
}

Products.propTypes = {
    paged: PropTypes.number.isRequired,
    categorie: PropTypes.string,
    special: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ])
}

const mapStateToProps = state => {
    return {
        rayons: state.rayons.rayons,
        customer: state.customer.customer,
        availability: state.availability.availability
    }
}

export default connect(mapStateToProps)(Products)
