import React from "react"
import styled from "styled-components"
// import { Link } from 'react-router-dom';
// App
import {Content, Footer, Header} from "../Composants/Template"
import {Column, Div, Row} from "../../Mixed/Composants/"

const Block = styled.div`
  text-align: center;
  background: #ffcc00;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 7px;
`

const Title = styled.h2`
  font-size: 42px;
  font-family: 'Covered By Your Grace', sans-serif;
  font-weight: 400;
  line-height: 1.1;
  text-align: center;
  margin-bottom: .7em;
`

const Button = styled.a`
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  color: #404040;
  display: inline-block;
  background: #fff;
  border: 2px solid #404040;
  padding: .8em 2em;
  border-radius: 60px;

  &:hover,
  &:active,
  &:focus {
    background: #404040;
    color: #fff
  }
`

function ContacterPage() {
    return (
        <Div className="front__wrap-screen">
            <Header/>
            <Content>
                <Row className="justify-content-center">
                    <Column lg={5}>
                        <Block>
                            <Title>Par email</Title>
                            <Button href="mailto:contact@sedisalimentaire.com">Contactez-nous !</Button>
                        </Block>
                    </Column>
                    <Column lg={5}>
                        <Block>
                            <Title>Par téléphone</Title>
                            <Button href="tel:0954456788">09 54 45 67 88</Button>
                        </Block>
                    </Column>
                </Row>
            </Content>
            <Footer/>
        </Div>
    )
}

export default ContacterPage
