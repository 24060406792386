import React, { Component } from 'react'
import { envVars } from '../../env_var'
import './Backoffice.css'

export default class Loading extends Component {

    render() {
        return (
            <div className="row" id="div-loading">
                <div className="loading">
                    <div className="loading-gif">
                        <img width="150px" height="150px" src={ envVars['scheme'] + window.location.host + '/img/loading.gif' } alt="Chargement"></img>
                    </div>
                </div>
            </div>
        )
    }
}