import React from "react"
import {connect} from "react-redux"
import {ToastContainer, toast} from "react-toastify"
import {Switch, Route, BrowserRouter} from "react-router-dom"

// App
import "react-toastify/dist/ReactToastify.css"
import {
    ListeSelectionDuMoment,
    Panier,
    MesInformations,
    MesCommandes,
    DetailCommand,
    Factures,
    Contacter,
    Connexion,
    NosProduits,
    Promotions,
    FicheProduit,
    CommandeRapide,
    DerniereMinute,
    SuiviCommandes,
    DluoDepassee,
    Halal,
    Bio,
    DemandeEnlevement,
} from "../../Frontoffice/Pages"
import Dashboard from "../Dashboard/Dashboard"
import {
    prefixBack,
    prefixFront,
    ROLE_MAINTAINER_SEDIS,
    ROLE_REDACTOR_BO, ROLE_SALESMANAGER_BO,
    ROLE_USER_BO
} from "../../../constants"
import {getOrder} from "../../../services/helpers/FrontApiHelper"
import Customer from "../../Backoffice/GardeFou/Customer/Customer"
import Family from "../../Backoffice/GardeFou/Family/Family"
import ProductOrVariante from "../../Backoffice/GardeFou/ProductOrVariante/ProductOrVariante"
import SecretRoute from "../SecretRoute/SecretRoute"
import HistoriqueConnexion from "../../Backoffice/Administration/Connexion/HistoriqueConnexion"
import LastActivityUsers from "../../Backoffice/Administration/Connexion/LastActivityUsers"
import ActivePanier from "../../Backoffice/Administration/Panier/ActivePanier"
import HistoriquePanier from "../../Backoffice/Administration/Panier/HistoriquePanier"
import MaximumCommandable from "../../Backoffice/Administration/Produit/MaximumCommandable"
import MinimumCommandable from "../../Backoffice/Administration/Produit/MinimumCommandable"
import LogErreur from "../../Backoffice/LogErreur/LogErreur"
import TotalGardeFou from "../../Backoffice/GardeFou/TotalGardeFou/TotalGardeFou"
import CompareGeslotShopAPI from "../../Backoffice/Administration/Produit/CompareGeslotShopAPI"
import SelectionDuMoment from "../../Backoffice/Administration/Produit/SelectionDuMoment"
import UtilisateurSEDIS from "../../Backoffice/Administration/Users/UtilisateurSEDIS"
import CreateUserSEDIS from "../../Backoffice/Administration/Users/CreateUserSEDIS"
import UpdateUserSEDIS from "../../Backoffice/Administration/Users/UpdateUserSEDIS"
import Notifications from "../../Backoffice/Administration/Notifications/Notifications"
import NotificationsGroup from "../../Backoffice/Administration/Notifications/NotificationsGroup"
import NotificationsHistory from "../../Backoffice/Administration/Notifications/NotificationsHistory"
import NotificationSedis from "../../Backoffice/Administration/Notifications/NotificationSedis"
import {isGranted} from "../../../services/helpers/RolesHelper"
import ErrorPage from "../../Backoffice/ErrorPage"
import ProfileUser from "../../Backoffice/Administration/Users/ProfileUser";
import Message from "../../Backoffice/Message/Message";
import Maintenance from "../../Backoffice/Maintenance/Maintenance";
import SaleItem from "../../Backoffice/Administration/Sale/SaleItem";
class SecureFrontRoute extends React.Component {

    _timeout = null
    _isMounted = false

    _getLocalOrderLine = () => {
        const storage = localStorage.getItem("frontOrderLine")
        return storage != null ? JSON.parse(storage) : null
    }

    _getPriceTotal = (data) => {
        let priceTotal = 0
        if (data && data.length) {
            data.forEach(item => {
                priceTotal += parseFloat(item.priceTotal)
            })
        }
        return priceTotal
    }

    _deleteCart = () => {
        const orderLine = this._getLocalOrderLine()
        localStorage.removeItem("frontOrderLine")
        localStorage.removeItem("frontOrderProducts")
        localStorage.removeItem("errorsDuplicationPanier")
        this.props.dispatch({type: "RESET_ORDER", value: []})
        console.info(`Order ${orderLine.id} deleted`)
    }

    componentDidMount() {
        this._isMounted = true
        const {order, customer} = this.props
        const orderLine = this._getLocalOrderLine()
        if (this._isMounted && order && order.length && orderLine && orderLine.OrderId && customer && customer.hasOwnProperty("user") && !isGranted(customer.user.role, ROLE_USER_BO)) {
            getOrder(orderLine.OrderId)
                .then(response => {
                    if (response && response.hasOwnProperty("trace")) {
                        toast("Vous n'avez pas encore passé de commande, votre panier a été supprimé !", {
                            type: "error",
                            autoDismiss: true
                        })
                        this._deleteCart()
                        console.warn("Panier déjà supprimé")
                    }
                })

        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={`${prefixFront}/login`} component={Connexion}/>
                    <Route path={`${prefixFront}/reset-password`} component={Connexion}/>
                    <Route path={`${prefixFront}/demande-enlevement`} component={DemandeEnlevement}/>

                    <SecretRoute exact path="/" component={Connexion}/>
                    <SecretRoute exact path={prefixFront} component={Connexion}/>
                    <SecretRoute path={`${prefixFront}/selection-du-moment`} component={ListeSelectionDuMoment}/>
                    <SecretRoute path={`${prefixFront}/panier`} component={Panier}/>
                    <SecretRoute path={`${prefixFront}/mes-factures`} component={Factures}/>
                    <SecretRoute path={`${prefixFront}/nous-contacter`} component={Contacter}/>
                    <SecretRoute path={`${prefixFront}/mes-commandes`} component={MesCommandes}/>
                    <SecretRoute path={`${prefixFront}/detail-commande/:id`} component={DetailCommand}/>
                    <SecretRoute path={`${prefixFront}/commande-rapide`} component={CommandeRapide}/>
                    <SecretRoute path={`${prefixFront}/fiche-produit/:id`} component={FicheProduit}/>
                    <SecretRoute path={`${prefixFront}/mes-informations`} component={MesInformations}/>
                    <SecretRoute path={`${prefixFront}/nos-produits/:categorie/:paged?`} component={NosProduits}/>
                    <SecretRoute path={`${prefixFront}/promotions`} component={Promotions}/>
                    <SecretRoute path={`${prefixFront}/derniere-minute`} component={DerniereMinute}/>
                    <SecretRoute path={`${prefixFront}/suivi-des-commandes`} component={SuiviCommandes}/>
                    <SecretRoute path={`${prefixFront}/dluo-depassee`} component={DluoDepassee}/>
                    <SecretRoute path={`${prefixFront}/halal`} component={Halal}/>
                    <SecretRoute path={`${prefixFront}/bio`} component={Bio}/>

                    <SecretRoute path={`${prefixFront}/dashboard`} component={Dashboard}/>

                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/dashboard`} component={Dashboard}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/totalgardefou`}
                                 component={TotalGardeFou}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/productorvariante`}
                                 component={ProductOrVariante}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/invalidfamily`}
                                 component={Family}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/invalidcustomer`}
                                 component={Customer}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/historiqueconnexion`}
                                 component={HistoriqueConnexion}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/lastactivityusers`}
                                 component={LastActivityUsers}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/selection-du-moment`}
                                 component={SelectionDuMoment}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/montantmincommandable`}
                                 component={MinimumCommandable}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/users-sedis`}
                                 component={UtilisateurSEDIS}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/create-user-sedis`}
                                 component={CreateUserSEDIS}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/update-user-sedis/:id`}
                                 component={UpdateUserSEDIS}/>
                    <SecretRoute minRole={ROLE_USER_BO} path={`${prefixBack}/profile`}
                                 component={ProfileUser}/>
                    <SecretRoute minRole={ROLE_REDACTOR_BO} path={`${prefixBack}/notifications`}
                                 component={Notifications}/>
                    <SecretRoute minRole={ROLE_REDACTOR_BO} path={`${prefixBack}/notifications-sedis`}
                                 component={NotificationSedis}/>
                    <SecretRoute minRole={ROLE_REDACTOR_BO} path={`${prefixBack}/notifications-groups`}
                                 component={NotificationsGroup}/>
                    <SecretRoute minRole={ROLE_REDACTOR_BO} path={`${prefixBack}/notifications-history`}
                                 component={NotificationsHistory}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/activpanier`}
                                 component={ActivePanier}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/historiquepanier`}
                                 component={HistoriquePanier}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/maximumcommandable`}
                                 component={MaximumCommandable}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/products-geslot-shopapi`}
                                 component={CompareGeslotShopAPI}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/logerror`} component={LogErreur}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/message`} component={Message}/>
                    <SecretRoute minRole={ROLE_MAINTAINER_SEDIS} path={`${prefixBack}/maintenance`}
                                 component={Maintenance}/>
                    <SecretRoute minRole={ROLE_SALESMANAGER_BO} path={`${prefixBack}/suivi-achats`}
                                 component={SaleItem}/>
                    <Route render={() => <ErrorPage code="404" title="Page non trouvée"
                                                    message="Désolé, la page que vous essayez de charger n'existe pas"/>}/>
                </Switch>
                <ToastContainer/>
            </BrowserRouter>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        order: state.order.order,
        customer: state.customer.customer
    }
}

export default connect(mapStateToProps)(SecureFrontRoute)
