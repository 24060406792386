export default function TextMomentReducer(state = {}, action){
    let nextState;
    switch(action.type){
        case "SET_TEXT_MOMENT" :
            nextState = {
                ...state,
                textDuMoment : action.value
            }
            return nextState;
            // break;

        case "SET_ID_TEXT_MOMENT" :
            nextState = {
                ...state,
                idTextDuMoment : action.value
            }
            return nextState;
            // break;

        case "SET_LIST_TEXT_MOMENT":
            nextState = {
                ...state,
                listTextDuMoment : action.value
            }
            return nextState;
            // break;

        default :
            return nextState || state
    }
}
