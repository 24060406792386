const initialUser = {};

export default function UserBackReducer(state = {}, action){
    let nextState;
    switch(action.type){
        case "SET_USER_SEDIS" :
            nextState = {
                ...state,
                user : action.value
            }
            return nextState;
            // break;

        case "RESET_ORDER":
            nextState = {
                ...state,
                order : initialUser
            }
            return nextState;
            // break;

        case "SET_LIST_USER_SEDIS":
            nextState = {
                ...state,
                listUser : action.value
            }
            return nextState;
            // break;

        default :
            return nextState || state
    }
}
