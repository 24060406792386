import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"
import {BeatLoader} from "react-spinners"
import {Link} from "react-router-dom"
import {toast} from "react-toastify"

// App
import InputDebounce from "./InputDebounce"
import {UrlPrefixContext} from "../../../context"
import {envVars} from "../../../env_var"
import {Column, Div, Lightbox, Row} from "../../Mixed/Composants"
import {deleteOrderLine, getCustomerInfo, updateOrderLine} from "../../../services/helpers/FrontApiHelper"
import {
    applyProductMaximumCommandale,
    formatCurrency,
    formatDate,
    getDefaultImage,
    getPiceGridNormal,
    getPiceGridRemise,
    getUnitPrice
} from "../../../services/helpers/FrontHelper"

const GoToFiche = styled(Link)`
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    span {
      color: #ff5d1f
    }

    img {
      opacity: .8;
    }
  }
`

const Thumbnail = styled.figure`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin: 0;

  &.has-no-image {
    height: 100px;
  }
`

const Img = styled.img`
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 303px;
  max-height: 200px;
  @media screen and (max-width: 2000px) {
    max-width: 260px;
  }
`

const Title = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-size: 31px;
  font-weight: 700;
  line-height: 1.39;
  letter-spacing: normal;
  color: #404040;
  margin: 0 0 .4rem;
  @media screen and (max-width: 2000px) {
    font-size: 21px;
  }
`

const Info = styled.div`
  padding: 10px;
`

const Item = styled(Div)`
  background: ${props => props.disabled === "desactivate" ? "#f1f1f1" : "#ffffff"};;
`

const Meta = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 1;
  color: #272727;
  @media screen and (max-width: 2000px) {
    font-size: 18px;
  }
`

const Options = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 1.1rem 0 1.3rem;
`

const Colis = styled(Img)`
  max-width: 67px;
  @media screen and (max-width: 2000px) {
    max-width: 40px;
  }
`

const Quanity = styled.div`
  padding: 0 40px;
  @media screen and (max-width: 2000px) {
    padding: 0 30px;
  }
`

const Label = styled(Meta)`
  margin: 0 0 .3rem;
`

const Input = styled(InputDebounce)`
  font-size: 18px;
  font-weight: 600;
  color: #75314e;
  border: 1px solid #75314e;
  height: auto;
  line-height: 1;
  padding: 10px 1.1rem;
  border-radius: 0;
`

const Prices = styled.div`
  margin: 0;
`

const Total = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 1;
  color: #e82424;
  @media screen and (max-width: 2000px) {
    font-size: 26px;
  }
`

const Index = styled.sup`
  font-family: 'Raleway', sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  color: #e82424;
  margin-left: 2px;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
  }
`

const Delete = styled.a`
  display: inline-flex;
  cursor: pointer;
`

const Text = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 1.89;
  letter-spacing: 0.19px;
  color: #272727;
  text-decoration: underline;
  @media screen and (max-width: 2000px) {
    font-size: 14px;
  }
`

const Icon = styled(Img)`
  max-width: 27px;
  margin-right: 13px;
  @media screen and (max-width: 2000px) {
    max-width: 20px;
    margin-right: 10px;
  }
`

const Alert = styled.span`
  color: red;
  display: block;
  font-style: italic;
  font-size: .8rem;
`

const Notice = styled.span`
  color: #17a2b8;
  display: block;
  font-style: italic;
  font-size: .8rem;
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .8);
  display: ${props => props.loading === "true" ? "flex" : "none"};
  justify-content: center;
  align-items: center;
`

class CartItem extends React.Component {

    _isMounted = false

    state = {
        error: false,
        message: "",
        quantite: 0,
        quantiteDisplay: 0,
        priceTotal: 0,
        loading: false,
        disabled: false,
        updatedStock: false
    }

    _getCustomerInfo = (customer, dataProductDetailCommandee) => {
        return getCustomerInfo(customer.user.CodeClient)
            .then(response => {
                if (response) {
                    const info = {
                        MontantEncoursUtilise: response.MontantEncoursUtilise,
                        MontantEncoursAutoriseFixe: response.MontantEncoursAutoriseFixe,
                        MontantEncoursAutoriseFlexible: response.MontantEncoursAutoriseFlexible,
                        DelaiEchanceFacture: response.DelaiEchanceFacture
                    }
                    let dataCustomerWithProductCommandee = {...customer, ...dataProductDetailCommandee}
                    const data = {...dataCustomerWithProductCommandee, ...info}
                    const DelaiEchanceFacture = data.DelaiEchanceFacture
                    const encours_autorise = data.MontantEncoursAutoriseFixe
                    const encours_autorise_flexible = data.MontantEncoursAutoriseFlexible
                    const encours_utilise = data.MontantEncoursUtilise
                    const encours_restent = encours_autorise - encours_utilise
                    const encours_restent_flexible = encours_autorise_flexible - encours_utilise
                    // let montantHT = this._getOrderPriceTotal()
                    let MontantHTArticleCommandee = parseFloat(data.priceTotal)

                    let compteurDepassementEncoursFlexible = localStorage.getItem("compteurDepassementEncoursFlexible")
                    if (compteurDepassementEncoursFlexible == null && compteurDepassementEncoursFlexible === undefined) {
                        compteurDepassementEncoursFlexible = 0
                    }

                    // montantHT += MontantHTArticleCommandee
                    let diffAmountArticleCommandee = MontantHTArticleCommandee
                    const products = this._getLocalProducts()
                    if (products && products.length) {
                        products.forEach((item) => {
                            if (item.CodeArticle === data.CodeArticle) {
                                // montantHT -= parseFloat(item.priceTotal)
                                diffAmountArticleCommandee -= parseFloat(item.priceTotal)
                            }
                        })
                    }

                    diffAmountArticleCommandee = diffAmountArticleCommandee < 0 ? -1 * diffAmountArticleCommandee : diffAmountArticleCommandee
                    this.props.dispatch({type: "SET_CUSTOMER", value: data})

                    if (DelaiEchanceFacture > 0) {
                        toast("Vous avez des factures non-réglées ayant dépassé votre délai de paiement de x jours, merci de régulariser la situation pour pouvoir commander de nouveau !", {
                            type: "error",
                            autoDismiss: true
                        })
                        return false
                    }

                    if (encours_restent <= 0 || (diffAmountArticleCommandee > encours_restent && diffAmountArticleCommandee > encours_restent_flexible)) {
                        toast("Votre encours est insuffisant pour passer une commande !", {
                            type: "error",
                            autoDismiss: true
                        })
                        return false
                    } else if (diffAmountArticleCommandee < encours_restent && encours_autorise_flexible > 0 && diffAmountArticleCommandee > encours_restent_flexible) {
                        localStorage.setItem("compteurDepassementEncoursFlexible", parseInt(compteurDepassementEncoursFlexible) + 1)
                        compteurDepassementEncoursFlexible = localStorage.getItem("compteurDepassementEncoursFlexible")
                        if (compteurDepassementEncoursFlexible === 2) {
                            localStorage.setItem("firstMontantHTDepassantEncoursFlexible", diffAmountArticleCommandee)
                        }
                        if (compteurDepassementEncoursFlexible > 1) {
                            let firstMontantHTDepassantEncoursFlexible = parseFloat(localStorage.getItem("firstMontantHTDepassantEncoursFlexible"))
                            if (diffAmountArticleCommandee > firstMontantHTDepassantEncoursFlexible) {
                                toast("Votre encours est insuffisant pour passer une commande !", {
                                    type: "error",
                                    autoDismiss: true
                                })
                                return false
                            }
                            return true
                        }
                        return true
                    }
                    return true
                }
                return false
            })
    }

    async _updateCart(quantite, data) {
        // const quantity = event.target.value;
        const product = this._getProductFromStorage(data)
        const qtMAxCom = applyProductMaximumCommandale(data)

        if (qtMAxCom) {
            data = this._getProductDetail(qtMAxCom)
        }

        if (product && product.hasOwnProperty("orderLine")) {
            const order = {...product, ...data}
            const response = await updateOrderLine(order.orderLine.id, order)

            if (response.code === 0) {
                const message = "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible."
                this.setState({
                    error: true,
                    disabled: true,
                    quantite: 0,
                    priceTotal: 0,
                    message: message,
                    loading: false
                })
                setTimeout(() => {
                    this._deletCart()
                }, 5000)
                console.error(message)
                return false
            } else if (response.code === 400 || response.code === 403) {
                const message = response.message
                this.setState({error: true, message: message, loading: false})
                console.error(message)
                return false
            } else if (response.code === 401) {
                const message = "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible."
                this.setState({error: true, message: message, loading: false})
                console.error(message)
                return false
            } else if (response.code === 480) {
                const LeStock = response.data.varianteArticle.LeStock
                if (LeStock === 0) {
                    const message = "Erreur technique dans la gestion du stock. Ce produit n'est pas disponible."
                    this.setState({
                        error: true,
                        disabled: true,
                        quantite: 0,
                        priceTotal: 0,
                        message: message,
                        loading: false
                    })
                    setTimeout(() => {
                        this._deletCart()
                    }, 5000)
                    console.error(message)
                } else {
                    const stock = this._getProductDetail(LeStock)
                    const msg_single = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${LeStock} colis a été ajouté.`
                    const msg_pluriel = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${LeStock} colis ont été ajoutés.`
                    const message = LeStock === 1 ? msg_single : msg_pluriel
                    this.setState({
                        updatedStock: true,
                        message: message,
                        loading: false
                    }, () => {
                        this._reUpdateOrderAfterDissatisfiedStock(LeStock, stock.orderLine)
                    })
                    console.warn(message)
                }
                return false
            } else if (!response.hasOwnProperty("detail")) {
                this.setState({loading: false}, () => this._updateStorage("frontOrderProducts", {
                    ...order,
                    orderLine: response
                }))
            }
        }
    }

    _getOrderPriceTotal = () => {
        let total = 0
        const products = this._getLocalProducts()
        if (products && products.length) {
            products.forEach((item) => {
                total += parseFloat(item.priceTotal)
            })
        }
        return total
    }

    _deletCart = () => {
        const {data} = this.props
        if (data && data.hasOwnProperty("orderLine")) {
            deleteOrderLine(data.orderLine.id)
                .then((response) => {
                    if (!response.hasOwnProperty("trace")) {
                        this._deleteStorage("frontOrderProducts", data)
                    }
                })
                .finally(
                    () => {
                        this.setState({loading: false})
                    }
                )
        }
    }

    _reUpdateOrderAfterDissatisfiedStock = (quantite, response) => {
        const detail = this._getProductDetail(quantite)
        const item = {...detail, orderLine: response, geslotStockIs: quantite}
        this._updateStorage("frontOrderProducts", item)
        this._updateCart(quantite, item)
        this.setState({
            loading: false,
            quantite: item.Quantite,
            priceTotal: item.priceTotal
        })
    }

    async _handleUpdateCart(value) {
        const {data, customer, availability} = this.props
        const quantite = parseInt(data.VenteALaPalette ? (value * data.NbrColisParPalette) : value);
        const priceTotal = this._getPriceTotal(quantite, data)
        const order = {...data, ...{Quantite: quantite, priceTotal: priceTotal}}

        if (!quantite) {
            const message = "La quantité doit être supérieure à 0"
            this.setState({
                error: true,
                message: message
            })
            return false
        }

        if (availability && availability.previousStateGeslotIsAvailable === false) {
            toast("Les commandes sont temporairement indisponibles.", {
                type: "error",
                autoDismiss: true
            })
            return false
        }

        this.setState({loading: true})
        if (customer && customer.isAuthenticated && customer.user.role === "ROLE_CUSTOMER") {
            const status = await this._getCustomerInfo(customer, this._getProductDetail(quantite))
            if (!status) {
                this.setState({
                    loading: false
                })
                return false
            }
        }

        this.setState({
            // loading: true,
            error: false,
            disabled: false,
            updatedStock: false,
            // quantite : quantite,
            priceTotal: priceTotal
        }, () => this._updateCart(quantite, order))
        return false
    }

    _handleChangeQuantite = (quantite) => {
        const {data} = this.props;
        this.setState({
            quantite: parseInt(data.VenteALaPalette ? (quantite * data.NbrColisParPalette) : quantite),
            quantiteDisplay: quantite
        });
    }

    _handleDeleteCart = (event) => {
        event.preventDefault()
        const {availability} = this.props
        if (availability && availability.previousStateGeslotIsAvailable === false) {
            toast("Les commandes sont temporairement indisponibles.", {
                type: "error",
                autoDismiss: true
            })
            return false
        }
        this.setState({loading: true}, this._deletCart)
    }

    _getPriceTotal = (quantite, product) => {
        let priceTotal
        const unitPrice = this._getPriceUnit(quantite, product)
        if (product.UniteDeVente === "K" && product.PoidsColis > 0) {
            priceTotal = unitPrice * product.PoidsColis * quantite
        } else if (product.UniteDeVente === "K" && product.PoidsColis === 0) {
            priceTotal = 0.00
        } else {
            priceTotal = unitPrice * product.PCB * quantite
        }
        return priceTotal
    }

    _getPriceUnit = (quantite, product) => {
        let priceUnit
        const {customer} = this.props
        if (!product.PUsimple) {
            const price_grid_remise = getPiceGridRemise(product, customer, product.SeuilRemiseVolume)
            product.PUremise = getUnitPrice(price_grid_remise, product.PCB, true)
            const price_grid_simple = getPiceGridNormal(product, customer)
            product.PUsimple = getUnitPrice(price_grid_simple, product.PCB, true)
        }
        if (product.SeuilRemiseVolume && product.SeuilRemiseVolume > 0 && quantite >= product.SeuilRemiseVolume) {
            priceUnit = product.PUremise
        } else {
            priceUnit = product.PUsimple
        }
        return priceUnit
    }

    _storeToStorage = (name, data) => {
        // const {onUpdate} = this.props
        if (!name || !data) {
            return false
        }
        localStorage.setItem(name, JSON.stringify(data))
        this.props.dispatch({type: "SET_ORDER", value: data})
    }

    _deleteStorage = (name, value) => {
        if (!name || !value) {
            return false
        }
        const tmp = []
        const data = this._getLocalProducts()
        data.forEach(item => {
            if (name === "frontOrderProducts" && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                return false
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp)
        if (data.length === 1) {
            localStorage.removeItem("errorsDuplicationPanier")
            localStorage.removeItem("frontOrderLine")
        }
    }

    _updateStorage = (name, value) => {
        if (!name || !value) {
            return false
        }
        const tmp = []
        const data = this._getLocalProducts()
        data.forEach(item => {
            if (name === "frontOrderProducts" && item.CodeVarianteArticle === value.CodeVarianteArticle) {
                item = value
            }
            tmp.push(item)
        })
        this._storeToStorage(name, tmp)
    }

    _getProductDetail = (quantite) => {
        const {data} = this.props
        const priceTotal = this._getPriceTotal(quantite, data)
        return {...data, ...{Quantite: quantite, priceTotal: priceTotal}}
    }

    _getProductFromStorage = (data) => {
        let product = null
        const products = this._getLocalProducts()
        products.forEach(item => {
            if (item.CodeVarianteArticle === data.CodeVarianteArticle) {
                product = item
            }
        })
        return product
    }

    _getLocalProducts = () => {
        const storage = localStorage.getItem("frontOrderProducts")
        return storage != null ? JSON.parse(storage) : []
    }

    _renderMetaInfo = () => {
        const {data} = this.props
        const {quantite} = this.state
        const stock_label = `Stock - ${this._getPriceUnit(quantite, data)} €`
        const dlc_label = `DLC ${formatDate(data.DateDLCDLUO)}`
        const dluo_label = `DLUO ${formatDate(data.DateDLCDLUO)}`
        const arrivage_label = `Arrivage ${formatDate(data.DateArrivagePrev)} - ${this._getPriceUnit(quantite, data)} €`
        return `${data.DlcOrDluo === 1 ? dlc_label : dluo_label} - ${data.ArriveEntrepot ? stock_label : arrivage_label}`
    }

    _renderTotalPrice = (data) => {
        const priceTotal = parseFloat(data.priceTotal)
        return (
            <Total>
                {formatCurrency(priceTotal)}
                <Index>HT</Index>
            </Total>
        )
    }

    componentDidMount() {
        this._isMounted = true
        const {data} = this.props
        if (this._isMounted && data) {
            const quantiteDisp = data.VenteALaPalette ? parseInt(data.Quantite/data.NbrColisParPalette) : data.Quantite;
            this.setState({
                quantiteDisplay: quantiteDisp,
                quantite: data.Quantite,
                priceTotal: data.priceTotal
            })

            if (data.hasOwnProperty("geslotStockIs") && data.geslotStockIs === data.Quantite) {
                const msg_single = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${data.geslotStockIs} colis a été ajouté.`
                const msg_pluriel = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${data.geslotStockIs} colis ont été ajoutés.`
                const message = data.geslotStockIs === 1 ? msg_single : msg_pluriel
                this.setState({
                    updatedStock: true,
                    message: message
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {data} = this.props
        if (prevProps.data.Quantite !== data.Quantite && prevProps.data.priceTotal !== data.priceTotal) {
            const quantiteDisp = data.VenteALaPalette ? parseInt(data.Quantite/data.NbrColisParPalette) : data.Quantite;
            this.setState({
                quantiteDisplay: quantiteDisp,
                quantite: data.Quantite,
                priceTotal: data.priceTotal
            })
            if (data.hasOwnProperty("geslotStockIs") && data.geslotStockIs === data.Quantite) {
                const msg_single = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${data.geslotStockIs} colis a été ajouté.`
                const msg_pluriel = `Le stock disponible ne nous permet pas de satisfaire votre demande, ${data.geslotStockIs} colis ont été ajoutés.`
                const message = data.geslotStockIs === 1 ? msg_single : msg_pluriel
                this.setState({
                    updatedStock: true,
                    message: message
                })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const {data, rayons} = this.props
        const {loading, disabled, quantiteDisplay, error, message, updatedStock} = this.state
        const api_path = envVars.url_shop_api.replace("/api/", "")
        const img_path = data.PathPhoto ? api_path + data.PathPhoto : getDefaultImage(rayons, data);

        return (
            <Item className="front__cart-item" disabled={disabled ? "desactivate" : "active"}>
                <Row className="align-items-center">
                    <Column lg={4}>
                        <Lightbox>
                            <a href={img_path} title={data.Libelle}>
                                <Thumbnail className={data.PathPhoto ? "has-image" : "has-no-image"}>
                                    <Img src={img_path} alt={data.Libelle}/>
                                    <Loader loading={loading.toString()}><BeatLoader loading={loading} color="#ff5d1f"
                                                                                     size={18}/></Loader>
                                </Thumbnail>
                            </a>
                        </Lightbox>
                    </Column>
                    <Column lg={8}>
                        <Info>
                            <Title>
                                <GoToFiche to={`${this.context.prefix}/fiche-produit/${data.CodeArticle}`}>
                                    <span>{data.Libelle}</span>
                                </GoToFiche>
                            </Title>
                            <Meta>{this._renderMetaInfo()}</Meta>
                            <Options>
                                {data.VenteALaPalette && <Colis src="/img/icons-palette.svg"/>}
                                {data.VenteAuColis && <Colis src="/img/i-colis.svg"/>}
                                <Quanity>
                                    <Label>Quantité :</Label>
                                    <Input type="number" disabled={disabled} min={0} max={500} defaultValue={quantiteDisplay}
                                           onInputChange={(q) => this._handleChangeQuantite(q)}
                                           onEndChanged={(value) => this._handleUpdateCart(value)}
                                           onFocus={(e) => e.target.select()}/>
                                </Quanity>
                                <Prices>
                                    {this._renderTotalPrice(data)}
                                </Prices>
                            </Options>
                            <Delete title="Supprimer" onClick={event => this._handleDeleteCart(event)}>
                                <Icon src="/img/i-delete.svg" alt="Supprimer"/>
                                <Text>Supprimer</Text>
                            </Delete>
                            {error && <Alert>{message}</Alert>}
                            {updatedStock && <Notice>{message}</Notice>}
                            {data.UniteDeVente === "K" && <Alert>** Poids variable</Alert>}
                        </Info>
                    </Column>
                </Row>
            </Item>
        )
    }

}

CartItem.contextType = UrlPrefixContext

const mapStateToProps = state => {
    return {
        order: state.order.order,
        customer: state.customer.customer,
        availability: state.availability.availability,
        rayons: state.rayons.rayons
    }
}

export default connect(mapStateToProps)(CartItem)
