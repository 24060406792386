import React from "react"
import * as yup from "yup"
import styled from "styled-components"
import {connect} from "react-redux"
import {toast} from "react-toastify"
import {Redirect} from 'react-router-dom';

// App
import connectApi from "../../../services/helpers/connectApi"
import {envVars} from "../../../env_var"
import {Button, Form, TextInput} from "./Forms"
import {UrlPrefixContext} from "../../../context"
import {Column, Modal, Row} from "../../Mixed/Composants"
import {
    HTTP_BAD_REQUEST,
    HTTP_INVALID_PASSWORD,
    HTTP_PARAM_REQUIRED,
    HTTP_UNAUTHORIZED, HTTP_USER_LOCKED,
    HTTP_USER_NOT_FOUND,
    prefixBack,
    prefixFront, ROLE_REDACTOR_BO, ROLE_SALESMANAGER_BO,
    ROLE_USER_BO
} from "../../../constants"
import {getRayons} from "../../../services/helpers/FrontApiHelper"
import {isGranted} from "../../../services/helpers/RolesHelper"
import {ClipLoader} from "react-spinners";

const Wrapper = styled.div`
  position: relative;
`

const Error = styled.p`
  margin: 0 0 1rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  color: red;
  text-align: center;
`

const ErrorTip = styled.p`
  margin: -15px 0 1rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  color: red;
  font-size: 0.9em;
`

const Inner = styled.div`
  position: relative;
  z-index: 2;
  padding: 45px 40px 60px 40px;
  border-radius: 30px;
  background-color: #ffcc00;
  @media screen and (max-width: 1200px) {
    padding: 35px 30px 45px 30px
  }
  @media screen and (max-width: 600px) {
    padding: 30px 20px
  }
`

const Title = styled.h2`
  font-family: 'Covered By Your Grace', sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 .3rem;
  color: #404040;
  text-transform: uppercase;
  @media screen and (max-width: 2000px) {
    font-size: 55px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 50px;
  }
`

const SubTitle = styled.span`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 2;
  color: #404040;
  margin-bottom: .7rem;
  @media screen and (max-width: 2000px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 0.85rem;
  }
`

const Input = styled(TextInput)`
  opacity: 0.8;
  font-family: 'Raleway', sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.19;
  letter-spacing: 0.21px;
  color: #727272;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 22px 15px;
  height: auto;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    padding: 15px 15px;
  }
`

const LostPassword = styled.span`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: center;
  color: #404040;
  margin: .9rem 0 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #000;
    text-decoration: underline;
  }

  @media screen and (max-width: 2000px) {
    font-size: 17px;
  }
`

const Img = styled.img`
  position: absolute;
  right: -12%;
  top: -80%;
  z-index: 1;
  @media screen and (max-width: 2000px) {
    top: -344px;
    right: -13%;
    max-width: 72%;
  }
  @media screen and (max-width: 1500px) {
    top: -322px;
    max-width: 550px
  }
  @media screen and (max-width: 1400px) {
    top: -258px;
    max-width: 440px;
    right: -12%;
  }
  @media screen and (max-width: 1300px) {
    top: -260px;
    max-width: 440px;
  }
  @media screen and (max-width: 1199px) {
    display: none
  }
`

const IconeEmail = styled.img`
  display: inline-block;
  max-width: 27px;
  @media screen and (max-width: 2000px) {
    max-width: 20px;
  }
`

const IconePassword = styled(IconeEmail)`
  @media screen and (max-width: 2000px) {
    max-width: 16px;
  }
`

const Div = styled.div`
  margin: 0 0 2.3em;
`

const InnerLogin = styled(Div)`
  padding: 0 1em 0 0;
  @media screen and (max-width: 1200px) {
    padding: 0 .5em 0 0;;
  }
`

const InnerSignUp = styled(Div)`
  padding: 0 0 0 1em;
  @media screen and (max-width: 1200px) {
    padding: 0 0 0 .5em;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    margin: 2em 0 0;
  }
`

const Submit = styled(Button)`
  width: 100%;
  background: #fff !important;

  &:hover {
    background: #ff5d1f !important;
  }
`

const SignUpInput = styled(Input)`
  background-color: #fff5d3 !important;
`

const InnerModal = styled.div`
  padding: 0;
`

const Magasins = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const MagasinItem = styled.li`
  padding: 1em 0;

  & ~ li {
    border-top: 1px solid #e9ecef
  }
`

const MagasinChoice = styled.input`
  height: 25px;
  width: 100%;
  display: inline-block;
`

const MagasinTitle = styled.span`
  display: block;
  font-weight: 500;
  color: #404040;
  font-size: 1rem;
  font-style: normal;
  text-transform: uppercase;
`

const MagasinLabel = styled.label`
  display: flex;
  margin: 0;
  flex-direction: row;
  align-items: center;
`

const MagasinWrap = styled.div`
  width: 25px;
  flex: 0 0 25px;
  margin: 0 1em 0 0;
`

const MagasinMeta = styled.div`
  font-size: .8rem;
  font-weight: 200;
  font-style: italic;
`

const LoginButton = styled(Button)`
  line-height: 50px !important;
  font-size: 16px !important;
  padding: 0 56px !important;
  border-radius: 50px !important;
`

const WrapButton = styled.div`
  margin: 1.1em 0 1em;
  display: flex;
  justify-content: center;
`

const Alert = styled.span`
  color: red;
  display: block;
  font-style: italic;
  font-size: .8rem;
  position: relative;
  top: -0.75rem;

  @media screen and (min-width: 767px) {
    position: absolute;
    top: -3.5rem;
    font-size: 0.90rem;
  }
`

// schema validation
const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().min(3).required()
})

const schemaReset = yup.object().shape({
    password: yup.string().min(3).required()
})

class LoginForm extends React.Component {
    // states
    state = {
        email: "",
        password: "",
        message: "",
        register: "",
        magasins: null,
        secure: true,
        loader: false,
        loading: false,
        codeMagasin: null,
        emailHasError: false,
        passwordHasError: false,
        displayMagasins: false,
        capsLock: false,
        onResetPassword: false,
        resetPassword: true,
        passwordToken: "",
        errorTokenUser: false,
        newPassword: "",
        confirmationPassword: "",
        redirect: ""
    }

    // refs
    emailInputRef = React.createRef()
    pwdInputRef = React.createRef()
    pwdInputResetRef = React.createRef()
    pwdConfirmationInputRef = React.createRef()

    constructor(props) {
        super(props);

        this._handleForgotPassword = this._handleForgotPassword.bind(this)
    }

    _redirectToLogin() {
        window.location.href = "/home/frontoffice/login"
    }

    _loginUser = (modalConnect = false) => {

        const {dispatch} = this.props

        const {email, password, codeMagasin} = this.state
        let credentials = {
            email: email,
            password: password
        }

        if (codeMagasin !== null) {
            credentials.email = `${email}/${codeMagasin}`
        }


        connectApi("connect", "POST", credentials)
            .then(response => {
                // form erros
                if (response.hasOwnProperty("code")) {
                    let message
                    switch (response.code) {
                        case HTTP_PARAM_REQUIRED :
                        case HTTP_USER_NOT_FOUND :
                        case  HTTP_INVALID_PASSWORD :
                            message = "Mot de passe ou adresse e-mail incorrecte."
                            break
                        case HTTP_BAD_REQUEST :
                            message = "Votre compte client est désactivé"
                            break
                        case HTTP_UNAUTHORIZED :
                            message = "Vous n'avez pas les droits suffisants pour accéder au back office"
                            break
                        case HTTP_USER_LOCKED :
                            message = response.message
                            break
                        default:
                            message = "Une erreur s'est produite"
                    }
                    if (!modalConnect) {
                        this.setState({message: message})
                    } else {
                        toast(message, {
                            type: "error",
                            autoDismiss: true
                        })
                    }
                }

                if (Array.isArray(response)) {
                    this.setState({
                        magasins: response,
                        displayMagasins: true
                    }, () => {
                        document.getElementById("triggerModal").click()
                    })
                    return false
                }

                if (!response.user) {
                    return false
                }

                if (response && response.user && response.user.isCustomer) {

                    if (response.user.CodeClient === null) {
                        const msg = "Votre compte client est desactivé"
                        if (!modalConnect) {
                            this.setState({message: msg})
                        } else {
                            toast(msg, {
                                type: "error",
                                autoDismiss: true
                            })
                        }
                        return false
                    }

                    if (!response.user.GrilleTarifaire) {
                        toast("Désolé votre compte n'a pas été complètement configuré, merci de contacter votre interlocuteur habituel pour lui signaler", {
                            type: "error",
                            autoDismiss: true
                        })
                        return false
                    }

                }

                const customer = {
                    isAuthenticated: true,
                    token: response.token,
                    user: {
                        id: response.user.id,
                        email: response.user.email,
                        firstname: response.user.firstname,
                        lastname: response.user.lastname,
                        role: response.user.role,
                        isCustomer: response.user.isCustomer,
                        CodeClient: response.user.CodeClient,
                        GrilleTarifaire: parseInt(response.user.GrilleTarifaire),
                        interlocutor: response.user.interlocutor
                    }
                }

                localStorage.setItem("state", JSON.stringify(customer))

                dispatch({type: "SET_CUSTOMER", value: customer})

                setTimeout(() => {
                    switch (customer.user.role) {
                        case ROLE_SALESMANAGER_BO:
                            window.location.href = prefixBack + '/suivi-achats'
                            break

                        case ROLE_REDACTOR_BO:
                            window.location.href = prefixBack + '/notifications-sedis'
                            break

                        default:
                            // redirection
                            if (isGranted(response.user.role, ROLE_USER_BO)) {
                                //this.props.history.push(`${prefixBack}/dashboard`)
                                window.location.href = envVars["scheme"] + window.location.host + `${prefixBack}/dashboard`
                            } else {
                                localStorage.removeItem("frontOrderLine")
                                localStorage.removeItem("persist:order")
                                localStorage.removeItem("frontOrderProducts")
                                localStorage.removeItem("errorsDuplicationPanier")

                                const {filter} = this.props
                                let filters = ["Frais", "Sec", "DPH", "PetsFood"]
                                if (filter && filter.length) {
                                    filters = filter
                                }
                                getRayons(filters)
                                    .then(rayons => {
                                        this.props.dispatch({type: "SET_RAYONS", value: rayons})
                                        let urlRedirection = localStorage.getItem("redirectUrl") ?? `${prefixFront}/selection-du-moment`
                                        localStorage.removeItem("redirectUrl")

                                        window.location.href = envVars["scheme"] + window.location.host + urlRedirection
                                    })
                                    .finally(
                                        //() => this.setLoader(false)
                                    )

                            }
                            break
                    }

                }, 400)

            })
            .finally(() => this.setState({loading: false, loader: false}))
            .catch((e) => console.log(e))
    }

    _handleFormSubmit = (event) => {
        if (event) {
            event.preventDefault()
        }

        const {email, password, newPassword, confirmationPassword} = this.state

        if (this.state.resetPassword) {
            this.pwdInputResetRef.current.classList.remove("validation-error")
            this.pwdConfirmationInputRef.current.classList.remove("validation-error")

            schemaReset.validate({password: newPassword, confirmationPassword}, {abortEarly: false})
                .then(() => {
                    if (newPassword !== confirmationPassword) {
                        this.setState({
                            message: "Les deux mots de passe ne sont pas identiques"
                        })
                        return;
                    }

                    this.setState({
                        message: "",
                        loading: true
                    })

                    connectApi("change-pwd", "POST", {
                        token: this.state.passwordToken,
                        password: newPassword
                    }).then(response => {
                        toast("Le changement de votre mot de passe a bien été pris en compte.", {
                            type: "success",
                            autoDismiss: true
                        })

                        setTimeout(() => {
                            this._redirectToLogin()
                        }, 5000);

                    }).finally(() => {
                        this.setState({
                            loading: false
                        })
                    })
                })
                .catch(e => {
                    e.errors.forEach(error => {
                        if (error === "password must be at least 3 characters" || error === "password is a required field") {
                            const message = (error === "password must be at least 3 characters")
                                ? "Le mot de passe doit contenir au moins 3 caractères" : "Le Mot de passe est obligatoire"

                            this.setState({
                                passwordHasError: true,
                                message: message
                            }, () => this.pwdInputResetRef.current.classList.add("validation-error"))
                        }
                    })
                })
        } else {
            this.emailInputRef.current.classList.remove("validation-error")
            this.pwdInputRef.current.classList.remove("validation-error")

            schema.validate({email: email, password: password}, {abortEarly: false})
                .then(() => {

                    this.setState({
                        loading: true,
                        message: "",
                        magasins: null,
                        codeMagasin: null,
                        displayMagasins: false,
                        redirectToHome: false,
                        redirectToAdmin: false
                    })
                    this._loginUser()

                })
                .catch(e => {
                    e.errors.forEach(error => {
                        if (error === "email must be a valid email" || error === "email is a required field") {
                            this.setState({emailHasError: true}, () => this.emailInputRef.current.classList.add("validation-error"))
                        }
                        if (error === "password must be at least 3 characters" || error === "password is a required field") {
                            this.setState({passwordHasError: true}, () => this.pwdInputRef.current.classList.add("validation-error"))
                        }
                    })

                })
        }
    }

    _handleInputChange = (event) => {
        const input = event.target.name
        const value = event.target.value
        if (input === "email") this.setState({email: value})
        if (input === "password") this.setState({password: value})
        if (input === "newPassword") this.setState({newPassword: value})
        if (input === "confirmationPassword") this.setState({confirmationPassword: value})
    }

    _handleLogin = () => {
        const {codeMagasin} = this.state
        if (!codeMagasin) {
            toast("Veuillez sélectionner votre magasin", {
                type: "error",
                autoDismiss: true
            })
            return false
        }
        this.setState({loader: true})
        this._loginUser(true)
    }

    _handleForgotPassword = () => {
        const email = this.state.email

        if (!email) {
            this.setState({
                message: 'Vous devez renseigner l\'adresse email'
            })

            return
        }

        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!expression.test(email)) {
            this.setState({
                message: 'Adresse email invalide'
            })

            return
        }

        this.setState({
            message: '',
            onResetPassword: true
        }, () => {
            connectApi("reset-pwd", "POST", {
                email: email,
            }).then((response) => {
                this.setState({
                    onResetPassword: false
                })

                if (response.error || response.code === 0 || !response.data) {
                    toast(response.error ?? response.message, {
                        type: "error",
                        autoDismiss: true
                    })
                    return
                }

                toast("Un email contenant un lien de réinitialisation vous a été envoyé", {
                    type: "success",
                    autoDismiss: true
                })
            })
        })
    }

    _renderEmailAccessory = () => {
        return (
            <IconeEmail src="/img/i-user.svg" aria-label="Accessory email"/>
        )
    }

    _renderPasswordAccessory = () => {
        const {secure} = this.state
        return (
            <button type="button" onClick={() => this.setState({secure: !secure})}>
                <IconePassword src="/img/i-pwd.svg" aria-label="Accessory password"/>
            </button>
        )
    }

    _renderModalMagasin = () => {
        const {displayMagasins, codeMagasin, magasins, loader} = this.state
        if (!displayMagasins) {
            return null
        }
        return (
            <Modal title="Vos magasins" show={displayMagasins}>
                <InnerModal>
                    <Magasins>
                        {magasins.map((magasin, index) => (
                            <MagasinItem key={index}>
                                <MagasinLabel>
                                    <MagasinWrap>
                                        <MagasinChoice type="radio" name="magasin" value={magasin.CodeMagasin}
                                                       selected={magasin.CodeMagasin === codeMagasin ? "selected" : ""}
                                                       onChange={event => this.setState({codeMagasin: event.target.value})}/>
                                    </MagasinWrap>
                                    <MagasinMeta>
                                        <MagasinTitle>{magasin.NomDuClient}</MagasinTitle>
                                        <span>#{magasin.CodeMagasin} - {`${magasin.AdresseRue1} ${magasin.AdresseRue2}`} - {`${magasin.Codepostal} ${magasin.Pays}`}</span>
                                    </MagasinMeta>
                                </MagasinLabel>
                            </MagasinItem>
                        ))}
                    </Magasins>
                    <WrapButton>
                        <LoginButton type="button" variant="secondary" loader={loader} contain="filled"
                                     onClick={this._handleLogin}>
                            {this.state.resetPassword ? 'Réinitialiser' : 'Se connecter'}
                        </LoginButton>
                    </WrapButton>
                </InnerModal>
            </Modal>
        )
    }

    componentDidMount() {
        let emailInput = this.emailInputRef.current
        let pwdInput = this.pwdInputRef.current
        let pwdInputReset = this.pwdInputResetRef.current
        let pwdConfirmationInput = this.pwdConfirmationInputRef.current

        let _this = this
        // Placer le focus sur le champ "Identifiant" après le chargement initial
        if (emailInput && typeof (emailInput) === "object") {
            emailInput.querySelector("input").focus()
        }

        if (pwdInput) {
            // Envoyer au clic sur Enter
            pwdInput.querySelector("input").addEventListener("keyup", function (event) {
                // Number 13 is the "Enter" key on the keyboard
                if (event.keyCode === 13) {
                    event.preventDefault()
                    _this._handleFormSubmit()
                }
            })
            // Avertir si sa touche "Caps Lock" est activée
            pwdInput.addEventListener("keyup", (event) => {
                if (event.getModifierState) {
                    _this.setState({capsLock: event.getModifierState("CapsLock")})
                }
            })
        }

        if (pwdInputReset) {
            pwdInputReset.addEventListener("keyup", (event) => {
                if (event.getModifierState) {
                    _this.setState({capsLock: event.getModifierState("CapsLock")})
                }
            })
        }

        if (pwdConfirmationInput) {
            pwdConfirmationInput.querySelector("input").addEventListener("keyup", function (event) {
                // Number 13 is the "Enter" key on the keyboard
                if (event.keyCode === 13) {
                    event.preventDefault()
                    _this._handleFormSubmit()
                }
            })

            pwdConfirmationInput.addEventListener("keyup", (event) => {
                if (event.getModifierState) {
                    _this.setState({capsLock: event.getModifierState("CapsLock")})
                }
            })
        }

        const queryString = window.location.search
        const token = new URLSearchParams(queryString).get("token")

        this.setState({
            resetPassword: this.props.history.location.pathname.includes("reset-password"),
            passwordToken: token
        }, () => {
            if (this.state.resetPassword) {
                connectApi("user-token", "POST", {token}).then(response => {
                    if (response.code !== 200) {
                        toast(response.message, {
                            type: "error",
                            autoDismiss: true
                        })

                        this.setState({
                            errorTokenUser: true
                        }, () => {
                        })
                    }
                })
            }
        })
    }

    render() {
        const {
            secure,
            email,
            password,
            register,
            message,
            loading,
            capsLock,
            newPassword,
            confirmationPassword
        } = this.state

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect}/>;
        }

        return (
            <Wrapper className="front__form_login">
                <Inner>
                    <Title>PAR ICI !</Title>
                    <Row>
                        <Column>
                            <Form onSubmit={this._handleFormSubmit}>
                                <Row>
                                    <Column md={6}>
                                        <InnerLogin>
                                            <SubTitle>
                                                {this.state.resetPassword ? 'Réinitialiser votre mot de passe' : 'Déjà client ?'}
                                            </SubTitle>
                                            <div className={!this.state.resetPassword ? "d-none" : ""}>
                                                <Input
                                                    required
                                                    tabIndex={1}
                                                    type="password"
                                                    fieldname="newPassword"
                                                    placeholder="Nouveau mot de passe"
                                                    value={newPassword}
                                                    ref={this.pwdInputResetRef}
                                                    onChange={this._handleInputChange}
                                                    disabled={this.state.errorTokenUser}
                                                    accessoryLeft={this._renderPasswordAccessory}
                                                />

                                                <Input
                                                    required
                                                    tabIndex={1}
                                                    type="password"
                                                    fieldname="confirmationPassword"
                                                    placeholder="Confirmation"
                                                    value={confirmationPassword}
                                                    ref={this.pwdConfirmationInputRef}
                                                    onChange={this._handleInputChange}
                                                    disabled={this.state.errorTokenUser}
                                                    accessoryLeft={this._renderPasswordAccessory}
                                                />
                                            </div>

                                            <div className={this.state.resetPassword ? "d-none" : ""}>
                                                <Input
                                                    required
                                                    tabIndex={1}
                                                    type="email"
                                                    fieldname="email"
                                                    placeholder="Identifiant"
                                                    value={email}
                                                    ref={this.emailInputRef}
                                                    onChange={this._handleInputChange}
                                                    accessoryLeft={this._renderEmailAccessory}
                                                />
                                                <Input
                                                    required
                                                    tabIndex={2}
                                                    type={secure ? "password" : "text"}
                                                    fieldname="password"
                                                    placeholder="Mot de passe"
                                                    value={password}
                                                    ref={this.pwdInputRef}
                                                    onChange={this._handleInputChange}
                                                    accessoryLeft={this._renderPasswordAccessory}
                                                />
                                            </div>

                                            {capsLock &&
                                                <ErrorTip>Attention, touche "Verrouil. Majuscules"
                                                    active</ErrorTip>}
                                        </InnerLogin>
                                        {message && <Error>{message}</Error>}
                                        <Submit type="button" tabIndex={3}
                                                disabled={this.state.errorTokenUser}
                                                variant="secondary" contain="outlined"
                                                loader={loading} onClick={this._handleFormSubmit}>
                                            {this.state.resetPassword ? 'Réinitialiser' : 'Se connecter'}
                                        </Submit>

                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            {!this.state.resetPassword &&
                                                <LostPassword onClick={this._handleForgotPassword} tabIndex={4}>
                                                    Mot de passe oublié ?
                                                </LostPassword>
                                            }

                                            {this.state.resetPassword &&
                                                <LostPassword tabIndex={4} onClick={() => this._redirectToLogin()}>
                                                    Se connecter
                                                </LostPassword>
                                            }

                                            {this.state.onResetPassword &&
                                                <div style={{marginLeft: "15px", position: "relative", top: "10px"}}>
                                                    <ClipLoader size={25}
                                                                color={'#fff'}/>
                                                </div>}
                                        </div>

                                    </Column>
                                    <Column md={6} style={{position: "relative"}}>
                                        <InnerSignUp>
                                            {this.state.resetPassword &&
                                                <Alert>
                                                    <b>Veuillez saisir et confirmer votre nouveau mot de passe pour
                                                        réinitialiser celui-ci</b>
                                                </Alert>
                                            }
                                            <SubTitle>Nouveau chez Sedis ?</SubTitle>
                                            <SignUpInput
                                                value={register}
                                                tabIndex={6}
                                                disabled={true}
                                                type="email"
                                                fieldname="signup"
                                                placeholder="Se créer un compte"
                                                accessoryLeft={this._renderEmailAccessory}
                                                onChange={(event) => this.setState({register: event.target.value})}
                                            />
                                        </InnerSignUp>
                                    </Column>
                                </Row>
                            </Form>
                        </Column>
                    </Row>
                </Inner>
                <Img src="/img/panier.webp"/>
                {this._renderModalMagasin()}
            </Wrapper>
        )
    }

}

LoginForm.contextType = UrlPrefixContext

const mapStateToProps = (state) => {
    return {
        customer: state.customer.customer
    }
}


export default connect(mapStateToProps)(LoginForm)
