import React from 'react';

// App 
import SearchForm from './SearchForm';
import { Div } from '../../Mixed/Composants';

class Search extends React.Component{

    render(){
        return(
            <Div className="front__wrap-search-form">
                <SearchForm />
            </Div>
        )
    }

}

export default Search;