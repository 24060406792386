import {BrowserRouter as Router} from "react-router-dom"
import Header from "../../../Mixed/Header/Header"
import Menu from "../../../Mixed/Menu/Menu"
import Footer from "../../../Mixed/Footer/Footer"
import React from "react"


export default function HtmlStructure({children, titleText, breadcrumActiveText, menuName}) {
    return <Router>
        <div className="wrapper">
            <Header/>
            <Menu nameMenu={menuName}/>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h5 className="m-0 text-dark">{titleText}</h5>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">Notifications</li>
                                        <li className="breadcrumb-item active">{breadcrumActiveText}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Footer/>
            </div>
        </div>
    </Router>
}