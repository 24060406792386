import React, {useCallback, useContext, useState} from "react"
import styled, {keyframes} from "styled-components"
import debounce from "lodash.debounce"
import {ClipLoader} from "react-spinners"
import {Link} from "react-router-dom"
import {fadeIn} from "react-animations"
import {useSelector} from "react-redux"

// App
import {Div} from "../../Mixed/Composants"
import {envVars} from "../../../env_var"
import {Form, Select, TextInput} from "./Forms"
import {UrlPrefixContext} from "../../../context"
import {searchProductConsole} from "../../../services/helpers/FrontApiHelper"
import {getDefaultImage} from "../../../services/helpers/FrontHelper"

const SearchFormWrap = styled(Form)`
  position: relative;
`

const Input = styled(TextInput)`
  border: 0;
  height: auto;
  font-size: 18px;
  font-weight: 500;
  color: #acacac;
  font-family: 'Raleway', sans-serif;
  border-radius: 0;
  padding: 12px 20px;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    padding: 10px 15px;
  }
`

const Dropdown = styled(Select)`
  height: auto;
  border: 0;
  background: #d6d6d6;
  padding: 15px 31px 15px 15px;
  border-radius: 0;
  appearance: none;
  font-family: 'Raleway', sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: 0.17px;
  color: #727272;

  &:focus {
    background: #d6d6d6;
  }

  @media screen and (max-width: 2000px) {
    font-size: 16px;
    padding: 13px 31px 13px 15px;
  }
`

const SearchIcon = styled.img`
  max-width: 35px;
  @media screen and (max-width: 2000px) {
    max-width: 26px;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`

const Results = styled.div`
  position: absolute;
  top: 100%;
  z-index: 999999;
  background: #fff;
  width: 100%;
  margin-top: 1px;
  box-shadow: 0 4px 8px #e4e4e4;
  border-top: 1px solid #e9ecef;
  animation: 400ms ${keyframes`${fadeIn}`};
`

const NoResults = styled.p`
  text-align: center;
  margin: 0;
  color: #727272;
  padding: 1.4em;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const Spinner = styled.div`
  padding: 0 6px;
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 70px;
  padding: .5em 1.5em;

  & a {
    display: block;
  }

  &:hover a span,
  & a:hover span,
  & a:focus span,
  & a:active span {
    color: #ff5d1f;
  }

  & ~ li {
    border-top: 1px solid #dee1e4;
  }
`

const Figure = styled.figure`
  margin: 0;
  flex: 0 0 68px;
  max-width: 68px;
`

const LinkBlock = styled.div`
  margin-left: 1em;
`

const LinkTexte = styled.span`
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #404040;
  @media screen and (max-width: 2000px) {
    font-size: 16px;
  }
`

function SearchForm() {

    const data = [
        {label: "Toutes nos catégories", value: ""},
        {label: "Frais", value: 500001},
        {label: "Sec", value: 500002},
        {label: "DPH", value: 500003},
        {label: "PetsFood", value: 500007}
    ]

    const context = useContext(UrlPrefixContext)
    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState("")
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState(null)
    const [displayResults, setDisplayResults] = useState(false)

    //TODO: React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead
    //eslint-disable-next-line
    const inputDebounced = useCallback(
        debounce((query, familyId) => {
            _handleSubmitForm(query, familyId)
        }, 1000),
        []
    )

    const _handleSubmitForm = (param, familyId = null) => {
        let query = param
        if (param && typeof (param) == "object") {
            param.preventDefault()
            query = search
        }
        if (!query) {
            setDisplayResults(false)
            return false
        }
        setLoading(true)
        searchProductConsole(query, familyId)
            .then(response => {
                if ((response && response.hasOwnProperty("code")) || (response && response.length === 0)) {
                    setResults(null)
                    setDisplayResults(true)
                    return false
                }
                setResults(response)
                setDisplayResults(true)
            })
            .finally(() => setLoading(false))
            .catch(e => {
                console.log(e)
            })
    }

    const _handleInputChange = (event) => {
        const input = event.target.name
        const value = event.target.value
        if (input === "filter") {
            setFilter(value)
            inputDebounced(search, value)
        }
        if (input === "search") {
            setSearch(value)
            inputDebounced(value, filter)
        }

    }


    const rayons = useSelector((state) => state.rayons.rayons)

    const _renderResults = () => {
        if (!loading && results === null && displayResults) {
            return (
                <Results className="wrap-list-results">
                    <NoResults>Aucun résultat</NoResults>
                </Results>
            )
        }
        if (!loading && displayResults) {
            return (
                <Results className="wrap-list-results">
                    <List>
                        {
                            results.map((result, index) => {
                                const api_path = envVars.url_shop_api.replace("/api/", "")
                                const img_path = result.PathPhoto ? api_path + result.PathPhoto : getDefaultImage(rayons, result)
                                return (
                                    <ListItem key={index}>
                                        <Figure>
                                            <img src={img_path} className="img-responsive" alt={result.CodeArticle}/>
                                        </Figure>
                                        <LinkBlock>
                                            <Link to={`${context.prefix}/fiche-produit/${result.CodeArticle}`}
                                                  onClick={() => setDisplayResults(false)}>
                                                <LinkTexte>{result.Libelle}</LinkTexte>
                                            </Link>
                                        </LinkBlock>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Results>
            )
        }
    }

    const _renderSearchAccessory = () => {
        if (loading) {
            return (
                <Spinner>
                    <ClipLoader loading={loading} size={25} color="#ff5d1f"/>
                </Spinner>
            )
        }
        return (
            <button type="button" onClick={(event) => _handleSubmitForm(event)}>
                <SearchIcon src="/img/search.svg" aria-label="Search"/>
            </button>
        )
    }

    return (
        <Div className="front__search-form" onBlur={event => event.relatedTarget == null && setDisplayResults(false)}>
            <SearchFormWrap onSubmit={(event) => _handleSubmitForm(event)}>
                <Flex>
                    <Dropdown
                        data={data}
                        value={filter}
                        fieldname="filter"
                        // placeholder = "Toutes nos catégories"
                        onChange={(event) => _handleInputChange(event)}
                    />
                    <Input
                        required
                        type="text"
                        fieldname="search"
                        placeholder="Trouvez votre produit…"
                        value={search}
                        autoComplete="off"
                        onFocus={(event) => !displayResults && _handleInputChange(event)}
                        onChange={(event) => _handleInputChange(event)}
                        accessoryRight={() => _renderSearchAccessory()}
                    />
                </Flex>
                {_renderResults()}
            </SearchFormWrap>
        </Div>
    )

}

export default SearchForm