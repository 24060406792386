import axios from "axios"
import {prefixFront} from "../../constants"
import {envVars} from "../../env_var"

async function connectApi(endPoint, method, dataObject, timeout = null) {
    let url = envVars["url_shop_api"] + endPoint
    const json = JSON.stringify(dataObject)
    try {
        let currentState = JSON.parse(localStorage.getItem("state"))
        let headers = {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json"
        }

        // if is authenticated
        if (
            endPoint !== "connect"
            && endPoint !== "demande-enlevement"
            && endPoint !== "server-datetime/1"
            && endPoint !== "reset-pwd"
            && endPoint !== "user-token"
            && endPoint !== "change-pwd"
        ) {
            headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + currentState.token
            }
        }
        let config = {
            headers: headers,
        }

        if (timeout) {
            config.timeout = timeout
        }

        if (method === "POST") {
            const res = await axios.post(url, json, config)
            return res.data
        } else if (method === "PUT") {
            const res = await axios.put(url, json, config)
            return res.data
        } else if (method === "GET") {
            const res = await axios.get(url, config)
            return res.data
        } else if (method === "DELETE") {
            const res = await axios.delete(url, config)
            return res.data
        } else if (method === "BLOB") {
            config.responseType = "blob"
            const res = await axios.get(url, config)
            return res.data
        }
    } catch (error) {
        if (error && error.response) {
            const url = envVars["scheme"] + window.location.host + prefixFront + "/login"
            if (error.hasOwnProperty("response") && error.response.status === 401) {
                localStorage.removeItem("state")
                localStorage.removeItem("persist:customer")
                window.location.href = url
            } else if (error.hasOwnProperty("response")) {
                if (error.response && error.response.hasOwnProperty("data")) {
                    return error.response.data
                }
                return error
            }
            return error
        } else {
            return error
        }
    }
}

export default connectApi