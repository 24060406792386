import React from "react"
import styled from "styled-components"
import {connect} from "react-redux"

// App
import CartLists from "./CartLists"
import CartDetail from "./CartDetail"
import {Column, Row} from "../../Mixed/Composants"
import {UploadOrderBase64} from "./index"
import articleDeleted from "../article-deleted.png"
import articleChanged from "../article-changed.png"
import {HTTP_PRODUCT_REPLACED, HTTP_STOCK_ABSENT} from "../../../constants"

const Paragraphe = styled.div`
  text-align: center;
  margin: 2rem 0;
`

/*
const Img = styled.img`
    display:inline-block;
    margin: 0;
    border: 0;
    max-width: 30px;
    @media screen and (max-width: 2000px){
        max-width: 25px;
    }
`;

const Upload = styled(Button)`
    font-size: 16px !important;
    line-height: 25px !important;
    padding: 4px 10px 4px 10px !important;
    margin-left: 5px !important;
`;
*/
class Cart extends React.Component {

    errorsDuplicationPanier = []

    _validateOrder = () => {
        const {onValidate} = this.props
        if (typeof (onValidate) === "function") {
            onValidate()
        }
    }

    _list_messages = (code) => {
        let message_str = []
        const msg_array = this.errorsDuplicationPanier.filter(error => error.CodeError === code)
        msg_array && msg_array.map(element => {
            message_str = [...message_str,
                <span key={element.CodeVarianteArticle}>{element.CodeArticle} - {element.Libelle}<br/></span>]
            return element
        })
        return message_str
    }

    _syntheseErrors = () => {
        let message_str = []
        if (this.errorsDuplicationPanier) {
            let codes_available = [...new Set(this.errorsDuplicationPanier.map(error => {
                return error.CodeError
            }))]
            codes_available.map(code => {
                let message_intro = ""
                if (code === HTTP_STOCK_ABSENT) {
                    if (this.errorsDuplicationPanier.filter(error => error.CodeError === code).length === 1) {
                        message_intro = `Désolé le produit suivant n'était plus disponible et aucune alternative viable n'a été trouvée. Il n'a donc pas pu être ajouté à votre panier :`
                    } else {
                        message_intro = `Désolé les produits suivants n'étaient plus disponibles et aucune alternative viable n'a été trouvée. Ils n'ont donc pas pu être ajoutés à votre panier :`
                    }
                } else if (code === HTTP_PRODUCT_REPLACED) {
                    if (this.errorsDuplicationPanier.filter(error => error.CodeError === code).length === 1) {
                        message_intro = `Le produit suivant n'était plus disponible dans la variante (DLC/DLUO et/ou date de livraison) initialement commandée et a été remplacé par l'alternative la plus proche possible :`
                    } else {
                        message_intro = `Les produits suivants n'étaient plus disponibles dans la variante (DLC/DLUO et/ou date de livraison) initialement commandée et ont été remplacés par l'alternative la plus proche possible :`
                    }
                }
                message_str = [...message_str,
                    <tr key={code}>
                        <td className="detail">
                            {
                                (code === HTTP_STOCK_ABSENT)
                                    ?
                                    <img src={articleDeleted} width={"50px"} height={"50px"} alt={""}></img>
                                    :
                                    <img src={articleChanged} width={"50px"} height={"50px"} alt={""}></img>
                            }
                        </td>
                        <td className="Motif">
                            {message_intro}<br/>
                            {this._list_messages(code)}
                        </td>
                    </tr>
                ]
                return code
            })

        }
        return message_str
    }

    async componentDidMount() {
        /*const frontOrderProducts = await localStorage.getItem('frontOrderProducts');
        if (frontOrderProducts == null || frontOrderProducts == undefined || frontOrderProducts == [] || JSON.parse(frontOrderProducts) == []) {
            localStorage.removeItem('errorsDuplicationPanier');
        }*/
        this.errorsDuplicationPanier = JSON.parse(localStorage.getItem("errorsDuplicationPanier"))
    }

    _renderCartContent = () => {
        const {order} = this.props
        const listError = this._syntheseErrors()
        if (!order || !order.length) {
            return (
                <Row>
                    <Column lg={12}>
                        <Paragraphe>Votre panier est vide. Veuillez ajouter des produits</Paragraphe>
                        <Paragraphe>
                            <UploadOrderBase64/>
                        </Paragraphe>
                    </Column>
                </Row>
            )
        }
        return (
            <div>
                <div className="col-lg-12 col-12">
                    <Row>
                        <table className="table-responsive table" style={{backgroundColor: "white"}}>
                            <tbody>
                            {listError}
                            </tbody>
                        </table>
                    </Row>
                </div>
                <Row>
                    <Column lg={8}>
                        <CartLists data={order}/>
                    </Column>
                    <Column lg={4}>
                        <CartDetail data={order} onValidate={() => this._validateOrder()}/>
                    </Column>
                </Row>
            </div>

        )
    }

    render() {
        return this._renderCartContent()
    }
}

const mapStateToProps = state => {
    return {
        order: state.order.order
    }
}

export default connect(mapStateToProps)(Cart)
