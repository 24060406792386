import React, {useContext} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {Link, useParams} from "react-router-dom"

// App
import RayonsFixtures from "../../../fixture_rayons"
import {envVars} from "../../../env_var"
import {strToSlug} from "../../../services/helpers/FrontHelper"
import {UrlPrefixContext} from "../../../context"

const Picto = styled.img`
  display: block;
  margin: 0 0 13px;
  border: 0;
  max-height: 44px;
`

const Name = styled.span`
  font-size: 17px;
  font-weight: 600;
  line-height: 1.21;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  display: block;
`

const Inner = styled.span`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99;
  padding: 1.3em 0;
`

const RayonLink = styled(Link)`
  display: inline-block;
  position: relative;
  flex: 0 0 12.5%;
  margin: 0;

  @media (max-width: 820px) {
    flex: none;
    width: 33%;
    overflow: hidden;
  }
  
  &.current-menu:before,
  &:not(.no-products):hover:before,
  &:not(.no-products):focus:before,
  &:not(.no-products):active:before {
    opacity: 1;
    visibility: visible;
  }

  &:before {
    content: '';
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    margin-left: .5em;
    background-size: contain;
    transition: all 0.5s ease;
    background: url(${envVars["scheme"] + window.location.host + "/img/trace-orang.png"}) no-repeat center;
  }

  &.no-products {
    filter: opacity(0.6);
    pointer-events: none;
  }
`

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PictoDivers = styled.span`
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 65px;
  font-weight: 300;
  font-style: italic;
  line-height: 0.4;
  text-align: center;
  color: #fff;
  margin: 11px 0 20px;
`

function MenuRayons(props) {
    // const { rayons } = props;
    const context = useContext(UrlPrefixContext)
    const {categorie} = useParams()

    const getRayonDetail = (param) => {
        let rayon = null
        const {rayons} = props
        if (rayons) {
            const data = rayons.filter(item => strToSlug(item.Nomcategorie) === param.Slugcategorie)
            if (data.length) {
                rayon = {...param, ...data[0]}
            }
        }
        return rayon
    }

    return (
        <Item className="front__wrap-menu">
            {
                RayonsFixtures && RayonsFixtures.map((rayon, index) => {
                    let cls = "mega-menu-item"
                    const rayonIde = rayon.Identifiant
                    const rayonName = rayon.Nomcategorie.toLowerCase()
                    const data = getRayonDetail(rayon)
                    if (data) {
                        if (props.familleID === data.Identifiant) {
                            cls += " menu-item-active"
                        }
                        if (categorie && categorie === data.Slugcategorie) {
                            cls += " current-menu"
                        }
                    } else {
                        cls += " no-products"
                    }
                    const slug = rayon.Slugcategorie
                    const pictoName = `icons-8-${slug}.svg`
                    const img_path = envVars["scheme"] + window.location.host + "/img/rayons/" + pictoName
                    // const cls = (categorie ===  rayonName) || (rayonId ===  rayonIde) ? 'mega-menu-item current-menu' : 'mega-menu-item';
                    return (
                        <RayonLink key={index} className={cls} to={{
                            pathname: `${context.prefix}/nos-produits/${slug}`,
                            rayonId: rayonIde,
                            termName: rayon.Nomcategorie
                        }}>
                            <Inner>
                                {slug === "divers" && <PictoDivers>D</PictoDivers>}
                                {slug !== "divers" && <Picto src={img_path} alt={rayonName}/>}
                                <Name>{rayonName}</Name>
                            </Inner>
                        </RayonLink>
                    )
                })
            }
        </Item>
    )

}

MenuRayons.propTypes = {
    rayons: PropTypes.array.isRequired
}

export default MenuRayons